import {
  getAllPersonnel,
  getPersonnelById,
  addPersonnel,
  updatePersonnel,
  deletePersonnel,
  getPersonnelByClientId,
  addPersonnelFromCSVService,
} from "../services/personnel.service";

export const GET_ALL_PERSONNEL = "GET_ALL_PERSONNEL";
export const GET_PERSONNEL_BY_ID = "GET_PERSONNEL_BY_ID";
export const ADD_PERSONNEL = "ADD_PERSONNEL";
export const UPDATE_PERSONNEL = "UPDATE_PERSONNEL";
export const DELETE_PERSONNEL = "DELETE_PERSONNEL";
export const FETCH_PERSONNELS_COPIE = "FETCH_PERSONNELS_COPIE";
export const FETCH_PERSONNELS_BY_CLIENT = "FETCH_PERSONNELS_BY_CLIENT";
export const FETCH_PERSONNELS_BY_CLIENT_COPIE =
  "FETCH_PERSONNELS_BY_CLIENT_COPIE";
export const ADD_PERSONNEL_FROM_CSV = "ADD_PERSONNEL_FROM_CSV";
export const getAllPersonnels = () => (dispatch) => {
  return getAllPersonnel().then((result) => {
    dispatch({
      type: GET_ALL_PERSONNEL,
      payload: result,
    });
    dispatch({
      type: FETCH_PERSONNELS_COPIE,
      payload: result,
    });
    return result;
  });
};

export const getPersonnel = (idPersonnel) => (dispatch) => {
  return getPersonnelById(idPersonnel).then((result) => {
    dispatch({
      type: GET_PERSONNEL_BY_ID,
      payload: result,
    });
    return result;
  });
};

export const addPersonnels = (data) => (dispatch) => {
  return addPersonnel(data).then((result) => {
    dispatch({
      type: ADD_PERSONNEL,
      payload: result,
    });
    return result;
  });
};

export const updatePersonnels = (idPersonnel, data) => (dispatch) => {
  return updatePersonnel(idPersonnel, data).then((result) => {
    dispatch({
      type: UPDATE_PERSONNEL,
      payload: result,
    });
    return result;
  });
};

export const deletePersonnels = (idPersonnel) => (dispatch) => {
  return deletePersonnel(idPersonnel).then((result) => {
    dispatch({
      type: DELETE_PERSONNEL,
      payload: idPersonnel,
    });
    return result;
  });
};

export const filterPersonnel = (data) => (dispatch) => {
  dispatch({
    type: GET_ALL_PERSONNEL,
    payload: data,
  });
};

export const getAllPersonnelsByClient = (id) => (dispatch) => {
  return getPersonnelByClientId(id).then((result) => {
    dispatch({
      type: FETCH_PERSONNELS_BY_CLIENT,
      payload: result,
    });
    dispatch({
      type: FETCH_PERSONNELS_BY_CLIENT_COPIE,
      payload: result,
    });
    return result;
  });
};

export const filterPersonnelByClient = (data) => (dispatch) => {
  dispatch({
    type: FETCH_PERSONNELS_BY_CLIENT,
    payload: data,
  });
};

export const addPersonnelFromCSV = (personnels) => (dispatch) => {
  return addPersonnelFromCSVService(personnels).then((result) => {
    dispatch({
      type: ADD_PERSONNEL_FROM_CSV,
      payload: result,
    });
  });
};
