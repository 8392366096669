import React from 'react'
import Image from '../../Images/WhyUs.png'
function WhyChooseUs() {
    return (
        <div className='whyUsContainer'>
            <div className="whyUsImageContainer" style={{ marginBottom: "20px" }}>
                <img src={Image} alt="" />
                <div>
                    <h3>Prêts à faire grandir votre entreprise avec nous.</h3>
                    <p>Rejoignez-nous pour booster la croissance de votre entreprise grâce à notre expertise et notre engagement.</p>
                </div>
            </div>

            <div className="whyUsDescreptionContainer">
                <div className='title-container'>
                    <p className='mb-0'><svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="11" height="11" fill="#1E5EFF" />
                    </svg>Valeurs ajoutées</p>
                    <h3>Pourquoi Nous Choisir ?</h3>
                </div>
                <ul>
                    <li className='mb-3'>
                        <div>
                            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#1E5EFF" />
                            </svg>
                        </div>

                        <div>
                            <h4>Expertise Approfondie</h4>
                            <p>Notre équipe est composée de professionnels chevronnés avec une expertise approfondie dans le domaine des affaires, de la gestion d'entreprises et de patrimoine.</p>
                        </div>
                    </li>
                    <li className='mb-3'>
                        <div>
                            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#1E5EFF" />
                            </svg>
                        </div>

                        <div>
                            <h4>Approche Personnalisée</h4>
                            <p>Nous savons que chaque entreprise et chaque patrimoine sont uniques. C'est pourquoi nos conseils sont entièrement personnalisés pour répondre à vos besoins spécifiques.</p>
                        </div>
                    </li>
                    <li className='mb-3'>
                        <div>
                            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#1E5EFF" />
                            </svg>
                        </div>

                        <div>
                            <h4>Engagement vers l'Excellence</h4>
                            <p>Chez CS Consulting, l'excellence est notre principe. Nous nous engageons à vous offrir des conseils précieux et à vous aider à atteindre vos objectifs.</p>
                        </div>
                    </li>
                    <li className='mb-3'>
                        <div>
                            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#1E5EFF" />
                            </svg>
                        </div>

                        <div>
                            <h4>Facilitation des Démarches Administratives</h4>
                            <p>Facilitez les  démarches administratives en laissant ces responsabilités à CS Consulting , ce qui vous permettra d'économiser du temps.</p>
                        </div>
                    </li>
                    <li className='mb-3'>
                        <div>
                            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#1E5EFF" />
                            </svg>
                        </div>

                        <div>
                            <h4>Accompagnement professionnel</h4>
                            <p>Profitez d'un accompagnement professionnel tout au long de votre parcours, que ce soit dans la préparation à des entretiens, la rédaction de CV, ou d'autres aspects de votre vie professionnelle.</p>
                        </div>
                    </li>
                </ul>
            </div>

        </div>
    )
}

export default WhyChooseUs