import {
    getAllConge,
    getCongeById,
    addConge,
    updateConge,
    deleteConge,
    getCongeByClientId,
    getCongeByEmploye
} from "../services/conge.servise";

export const GET_ALL_CONGE = "GET_ALL_CONGE";
export const GET_CONGE_BY_ID = "GET_CONGE_BY_ID";
export const ADD_CONGE = "ADD_CONGE";
export const UPDATE_CONGE = "UPDATE_CONGE";
export const DELETE_CONGE = "DELETE_CONGE";
export const FETCH_CONGES_COPIE = "FETCH_CONGES_COPIE"
export const FETCH_CONGES_BY_CLIENT = "FETCH_CONGES_BY_CLIENT"
export const FETCH_CONGES_BY_CLIENT_COPIE = "FETCH_CONGES_BY_CLIENT_COPIE"
export const FETCH_CONGES_BY_EMPLOYE = "FETCH_CONGES_BY_EMPLOYE"
export const getAllConges = () => (dispatch) => {
    return getAllConge()
        .then(
            (result) => {
                dispatch({
                    type: GET_ALL_CONGE,
                    payload: result,
                });
                dispatch({
                    type: FETCH_CONGES_COPIE,
                    payload: result,
                });
                return result;
            }

        );
};

export const getConge = (id) => (dispatch) => {
    return getCongeById(id)
        .then(
            (result) => {
                dispatch({
                    type: GET_CONGE_BY_ID,
                    payload: result,
                });
                return result;
            }
        );
}

export const addConges = (data) => (dispatch) => {
    return addConge(data)
        .then(
            (result) => {
                dispatch({
                    type: ADD_CONGE,
                    payload: result,
                });
                return result;
            }
        );
}

export const updateConges = (id, data) => (dispatch) => {
    return updateConge(id, data)
        .then(
            (result) => {
                dispatch({
                    type: UPDATE_CONGE,
                    payload: result,
                });
                return result;
            }
        );
}

export const deleteConges = (id) => (dispatch) => {
    return deleteConge(id)
        .then(
            (result) => {
                dispatch({
                    type: DELETE_CONGE,
                    payload: id,
                });
                return result;
            }
        );
}

export const filterConge = (data) => (dispatch) => {

    dispatch({
        type: GET_ALL_CONGE,
        payload: data,
    });

};


export const getAllCongesyClient = (id) => (dispatch) => {
    return getCongeByClientId(id)
        .then(
            (result) => {
                dispatch({
                    type: FETCH_CONGES_BY_CLIENT,
                    payload: result,
                });
                dispatch({
                    type: FETCH_CONGES_BY_CLIENT_COPIE,
                    payload: result,
                });
                return result;
            }

        );
};

export const filterPersonnelByClient = (data) => (dispatch) => {

    dispatch({
        type: FETCH_CONGES_BY_CLIENT,
        payload: data,
    });

};

export const fetchCongeEmploye = (id) => (dispatch) => {
    return getCongeByEmploye(id)
        .then(
            (result) => {
                dispatch({
                    type: FETCH_CONGES_BY_EMPLOYE,
                    payload: result.data,
                });
                return result.data;
            }
        );
};



