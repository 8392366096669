import {
    GET_ALL_CLIENT,
    GET_CLIENT_BY_ID,
    ADD_CLIENT,
    UPDATE_CLIENT,
    DELETE_CLIENT,
    FETCH_CLIENTS_COPIE,
    UPDATE_CLIENT_LOGO,
    ADD_CLIENT_FAILURE
} from '../actions/ClientAction';

// Initial state
const initialState = {
    clients: [],
    clientsCopie: [],
    client: {},
    error :null
};

// Reducer function
const clientReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_CLIENT:
            return { ...state, clients: action.payload };
        case GET_CLIENT_BY_ID:
            return { ...state, client: action.payload };
        case ADD_CLIENT:
            return { ...state, clients: [...state.clients, action.payload] };
        case ADD_CLIENT_FAILURE:
            return { ...state, error: action.error }; // Handle the error
        case UPDATE_CLIENT:
            const updatedClient = action.payload;
            const updatedClientsList = state.clients.map((client) => {
                if (client._id === updatedClient.id) {
                    return updatedClient;
                }
                return client;
            });
            return { ...state, clients: updatedClientsList };
        case UPDATE_CLIENT_LOGO:
            return { ...state, client: action.payload };
        case DELETE_CLIENT:
            const deletedClientId = action.payload;
            const updatedClients = state.clients.filter((client) => client._id !== deletedClientId);
            return { ...state, clients: updatedClients };
        case FETCH_CLIENTS_COPIE:

            return { ...state, clientsCopie: action.payload }
        default:
            return state;
    }
};

export default clientReducer;
