import { useTheme } from "@emotion/react";
import { Box, Button, Typography, List } from "@mui/material";
import CustomCircularProgress from "Components/CustomCircularProgress";
import CustomField from "Components/CustomField";
import FlexBetween from "Components/FlexBetween";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ClientInfo from "Components/ClientInfo";
import CustomDropDown from "Components/CustomDropDown";
import CustomDatePicker from "Components/CustomDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import DescriptionTable from "Components/DescriptionTable";
import { toast } from "react-toastify";
import { setGlobalIsLoading } from "state/global";
import { useDispatch } from "react-redux";
import AddSuccessPopUp from "Components/AddSuccessPopUp";
import PopUp from "Components/Popup";
import CommandeService from "Api/CommandeService";
import { addCommande, updateCommande } from "state/financials";
import { commandeSchema } from "Pages/schemas/schemas";
import DetailsPopUp from "Components/DetailsPopUp";
import ActionButton from "Components/ActionButton";
import { exportPdf } from "utils/esport_pdf";

const AddEditCommande = () => {
  const { commandeId } = useParams();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(!!commandeId);
  const dispatch = useDispatch();
  const [addSuccessOpen, setAddSuccessOpen] = useState(false);
  const [addSuccessText, setAddSuccessText] = useState("");
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [client, setClient] = useState({});
  const navigate = useNavigate();
  const [commande, setCommande] = useState({
    commande_number: "",
    date: new Date(),
    payment_date: new Date(),
    currency: "euro",
    language: "FR",
    ht_total: 0,
    tva_amount: 0,
    ttc_total: 0,
    payment_condition: "",
    supplier_name: "",
    supplier_address: "",
    supplier_phone: "",
    supplier_email: "",
    state: "tovalidate",
    descriptions: [],
  });
  const exportFile = async (fileName) => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      await exportPdf(".details-page", fileName);
      dispatch(setGlobalIsLoading({ isLoading: false }));
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };
  const getLanguage = (l) => {
    if (l === "FR") {
      return "Français";
    } else if (l === "ANG") {
      return "Anglais";
    } else {
      return "";
    }
  };
  const getCurrency = (c) => {
    if (c === "euro") {
      return "Euro €";
    } else if (c === "dollard") {
      return "Dollar $";
    } else {
      return "";
    }
  };
  const getState = (state) => {
    if (state === "draft") {
      return "Brouillon";
    } else if (state === "tovalidate") {
      return "À valider";
    } else if (state === "validated") {
      return "Validé";
    } else if (state === "late") {
      return "En retard de validation";
    } else {
      return "";
    }
  };
  const onSubmit = async (values) => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      const data = {
        commande_number: values.commande_number,
        date: values.date,
        payment_condition: values.payment_condition,
        payment_date: values.payment_date,
        language: values.language,
        ht_total: values.ht_total,
        tva_amount: values.tva_amount,
        ttc_total: values.ttc_total,
        currency: values.currency,
        supplier_name: values.supplier_name,
        supplier_phone: values.supplier_phone,
        supplier_email: values.supplier_email,
        supplier_address: values.supplier_address,
        state: values.state,
        descriptions: values.descriptions,
      };
      if (commandeId) {
        const updatedCommande = await CommandeService.updateCommandeDetails(
          commandeId,
          data
        );
        dispatch(updateCommande({ commande: updatedCommande }));
        setAddSuccessText(
          `Modification du bon commande ${updatedCommande.commande_number} confirmé`
        );
      } else {
        const newCommande = await CommandeService.createCommande(data);
        dispatch(addCommande({ commande: newCommande }));
        setAddSuccessText(`Ajout du bon de commande confirmé`);
      }
      dispatch(setGlobalIsLoading({ isLoading: false }));
      setAddSuccessOpen(true);
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };
  const saveDraft = async (values) => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      const data = {
        commande_number: values.commande_number,
        date: values.date,
        payment_condition: values.payment_condition,
        payment_date: values.payment_date,
        language: values.language,
        ht_total: values.ht_total,
        tva_amount: values.tva_amount,
        ttc_total: values.ttc_total,
        currency: values.currency,
        supplier_name: values.supplier_name,
        supplier_phone: values.supplier_phone,
        supplier_email: values.supplier_email,
        supplier_address: values.supplier_address,
        state: "draft",
        descriptions: values.descriptions,
      };
      const commande = await CommandeService.createCommande(data);
      dispatch(addCommande({ commande: commande }));
      setAddSuccessText("Ajout du bon de commande confirmé");
      dispatch(setGlobalIsLoading({ isLoading: false }));
      setAddSuccessOpen(true);
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };
  useEffect(() => {
    const getCommandeDetails = async () => {
      try {
        setIsLoading(true);
        const data = await CommandeService.getCommandeDetails(commandeId);
        setCommande(data);
        setIsLoading(false);
      } catch (error) {
        toast(`${error}`);
      }
    };
    if (commandeId) {
      getCommandeDetails();
    }
  }, []);

  return (
    <Box className='content'>
    {/* Header */}
      {isLoading ? (
        <CustomCircularProgress />
      ) : (
        <List
          sx={{
            height: "100%",
            width: "100%",
            // overflow: "auto",
            padding:'0'
          }}
        >
          <Box>
            {/* Header */}
            <FlexBetween className="header-gest-pages"> 
              <Typography
                variant="h3"
                fontWeight="bold"
                color={theme.palette.secondary.light}
              >
                {commandeId
                  ? `Modification du bon de commande N°${commande.commande_number}`
                  : "Nouveau Bon de commande"}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Button
                  sx={{
                    width: "100px",
                    fontSize: "16px",
                    fontWeight: "400",
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.alt.main,
                    border: "1px solid",
                    borderRadius: "4px",
                    borderColor: theme.palette.grey.light,
                    textTransform: "none",
                    ":hover": {
                      backgroundColor: theme.palette.primary[400],
                    },
                  }}
                  onClick={() => navigate(-1)}
                >
                  Annuler
                </Button>
                <Button
                  sx={{
                    width: "100px",
                    fontSize: "16px",
                    fontWeight: "400",
                    backgroundColor: theme.palette.alt.main,
                    textTransform: "none",
                    ":hover": {
                      backgroundColor: theme.palette.alt[400],
                    },
                  }}
                  onClick={() => setDetailsOpen(true)}
                >
                  Aperçu
                </Button>
              </Box>
            </FlexBetween>
            <Formik
              enableReinitialize={true}
              onSubmit={onSubmit}
              initialValues={commande}
              validationSchema={commandeSchema}
              validateOnMount={true}
            >
              {({ values, handleSubmit, setFieldValue, isValid }) => (
                <Box
                  sx={{
                    backgroundColor: theme.palette.grey[100],
                    borderRadius: "6px",
                    height: "calc(100vh - 140px)",
                    padding: "5px",
                    // mt: "20px",
                    p: "20px",
                    overflow:'auto',
                    width: "95%",
                    margin:"auto",
                  }}
                >
                  <Form>
                    {/* Top */}
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        rowGap: "30px",
                      }}
                    >
                      {/* Clinet Info */}
                      <ClientInfo client={client} setClient={setClient} />
                      {/* Language */}
                      <Box
                        sx={{
                          display: "flex",
                          m: "0 9px 0  14px",
                          gap: "5px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "start",
                            flexDirection: "column",
                            flex: "1",
                          }}
                        >
                          <Typography
                            height="15px"
                            variant="h6"
                            color={theme.palette.grey[600]}
                            mb=".6rem"
                            ml="10px"
                            textAlign="left"
                          >
                            Langue
                          </Typography>
                          <CustomDropDown
                            items={["FR", "ANG"]}
                            name="language"
                            value={values.language}
                            getItems={getLanguage}
                            sx={{
                              height: "43px",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "start",
                            flexDirection: "column",
                            flex: "1",
                          }}
                        >
                          <Typography
                            height="15px"
                            variant="h6"
                            color={theme.palette.grey[600]}
                            mb=".6rem"
                            textAlign="left"
                            pl="10px"
                            sx={{
                              textWrap: "nowrap",
                            }}
                          >
                            État du bon de commande
                          </Typography>
                          <CustomDropDown
                            items={["draft", "tovalidate", "validated", "late"]}
                            name="state"
                            value={values.state}
                            getItems={getState}
                            sx={{
                              height: "43px",
                            }}
                          />
                        </Box>
                      </Box>
                      {/* Form */}
                      <Box
                        sx={{
                          borderRight: "1px solid",
                          borderRightColor: theme.palette.grey.main,
                          pr: "20px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: theme.palette.secondary[700],
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Informations fournisseur
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <CustomField
                            name="supplier_name"
                            title="Nom de Fournisseur"
                            value={values.supplier_name}
                            sx={{
                              mt: "10px",
                              mb: "0",
                            }}
                          />
                          <CustomField
                            name="supplier_address"
                            title="Adresse"
                            value={values.supplier_address}
                            sx={{
                              mt: "11px",
                              mb: "0",
                            }}
                          />
                          <CustomField
                            name="supplier_phone"
                            title="Numéro de téléphone"
                            value={values.supplier_phone}
                            sx={{
                              mt: "11px",
                              mb: "0",
                            }}
                          />
                          <CustomField
                            name="supplier_email"
                            title="Mail"
                            value={values.supplier_email}
                            sx={{
                              mt: "11px",
                              mb: "0",
                            }}
                          />
                        </Box>
                      </Box>
                      {/* Form */}
                      <Box
                        sx={{
                          ml: "20px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: theme.palette.secondary[700],
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Informations bon de commande
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr",
                              rowGap: "10px",
                              p: "11px 10px 0 10px",
                              "& .MuiInputBase-root": {
                                color: theme.palette.grey[400],
                              },
                              "& .MuiButtonBase-root": {
                                color: theme.palette.grey[400],
                              },
                            }}
                          >
                            <CustomField
                              name="commande_number"
                              title="Numéro"
                              value={values.commande_number}
                              sx={{
                                m: "0",
                                mr: "10px",
                              }}
                            />
                            <Box
                              sx={{
                                ml: "10px",
                                mb: "-10px",
                              }}
                            >
                              <Typography
                                height="15px"
                                variant="h6"
                                color={theme.palette.grey[600]}
                                mb=".6rem"
                                textAlign="left"
                              >
                                Devise
                              </Typography>
                              <CustomDropDown
                                items={["euro", "dollard"]}
                                name="currency"
                                value={values.currency}
                                getItems={getCurrency}
                                sx={{
                                  m: "0",
                                  width: "100%",
                                  height: "38px",
                                  overflow: "clip",
                                }}
                              />
                            </Box>

                            <Box
                              sx={{
                                mr: "10px",
                                "& .MuiInputBase-root": {
                                  m: "-5px",
                                },
                              }}
                            >
                              <Typography
                                height="15px"
                                variant="h6"
                                color={theme.palette.grey[600]}
                                mb=".6rem"
                              >
                                Date
                              </Typography>
                              <CustomDatePicker
                                name="date"
                                value={dayjs(values.date)}
                                setFieldValue={setFieldValue}
                                sx={{
                                  m: "0",
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                ml: "10px",
                                "& .MuiInputBase-root": {
                                  m: "-5px",
                                },
                              }}
                            >
                              <Typography
                                height="15px"
                                variant="h6"
                                color={theme.palette.grey[600]}
                                mb=".6rem"
                              >
                                Echéance (date)
                              </Typography>
                              <CustomDatePicker
                                name="payment_date"
                                value={dayjs(values.payment_date)}
                                setFieldValue={setFieldValue}
                                sx={{
                                  m: "0",
                                  height: "40px",
                                }}
                              />
                            </Box>
                            <CustomField
                              name="payment_condition"
                              title="Conditions de paiement"
                              value={values.payment_condition}
                              sx={{
                                m: "0",
                                mr: "10px",
                              }}
                            />
                          </Box>
                        </LocalizationProvider>
                      </Box>
                    </Box>
                  </Form>
                  <DescriptionTable
                    setSuperFieldValue={setFieldValue}
                    superValues={values}
                  />
                  <DetailsPopUp
                    open={detailsOpen}
                    setOpen={setDetailsOpen}
                    descriptions={values.descriptions}
                    name={`Commande ${values.commande_number}`}
                    values={values}
                    data={{
                      name: values.supplier_name,
                      email: values.supplier_email,
                      phone: values.supplier_phone,
                      address: values.supplier_address,
                    }}
                    isSupplier={true}
                    client={client}
                    actionButtons={
                      <>
                        <ActionButton
                          iconName="confirm"
                          text="Confirmer le commande"
                          onClick={(event) => {
                            handleSubmit(event);
                            if (!isValid) {
                              setDetailsOpen(false);
                            }
                          }}
                        />
                        <ActionButton
                          iconName="download"
                          text="Exporter en PDF"
                          onClick={() =>
                            exportFile(`commande ${values.commande_number}.pdf`)
                          }
                        />
                        <ActionButton
                          iconName="save"
                          text="Enregistrer comme brouillon"
                          onClick={async () => await saveDraft(values)}
                        />
                        <ActionButton
                          iconName="adjust"
                          text="Continuer la création"
                          onClick={() => setDetailsOpen(false)}
                        />
                      </>
                    }
                  />
                </Box>
              )}
            </Formik>
            {/* Desctiption */}
          </Box>
        </List>
      )}
      <PopUp open={addSuccessOpen}>
        <AddSuccessPopUp
          title={addSuccessText}
          onClick={() => {
            setAddSuccessOpen(false);
            setDetailsOpen(false);
            navigate("/financials/commandes");
          }}
        />
      </PopUp>
    </Box>
  );
};

export default AddEditCommande;
