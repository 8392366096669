import CustomCircularProgress from "Components/CustomCircularProgress";
import FinancialsQuoteTable from "./FinancialsQuoteTable";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { selectFinancialsQuotes, setFinancialsQuotes } from "state/financials";
import FinancialsQuoteService from "Api/FinancialsQuoteService";
import { useLocation } from "react-router-dom";
const FinancialsQuotesList = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [filterFinancialsQuotes, setFilterFinancialsQuotes] = useState(null);
  const query = useLocation();
  const quotes = useSelector((state) => selectFinancialsQuotes(state));

  useEffect(() => {
    const getFinancialsQuotes = async () => {
      try {
        setIsLoading(true);
        const data =
          await FinancialsQuoteService.getClientFinancialsQuotesDetails();
        dispatch(setFinancialsQuotes({ quotes: data }));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast(`${error}`);
      }
    };
    getFinancialsQuotes();
  }, []);
  useEffect(() => {
    const search = async () => {
      try {
        const terms = query.search.split("=");
        if (!terms[1]) {
          setFilterFinancialsQuotes(null);
          return;
        }
        setIsLoading(true);
        const data = await FinancialsQuoteService.sortFinancialsQuotes(
          terms[1]
        );
        setFilterFinancialsQuotes(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast(`${error}`);
      }
    };
    search();
  }, [query]);
  return (
    <>
      {isLoading ? (
        <CustomCircularProgress sx={{ height: "60vh" }} />
      ) : (
        <FinancialsQuoteTable
          quotes={filterFinancialsQuotes ? filterFinancialsQuotes : quotes}
        />
      )}
    </>
  );
};

export default FinancialsQuotesList;
