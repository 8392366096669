import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../Images/logo.svg";

function CardClientDetails({ client }) {
  const navigate = useNavigate();
  return (
    <div className="container">
      <div className="cardClientDetailsContainer">
        <div className="ClientDetailsLeftSection">
          <div className="logo-container-client">
            <img
              src={
                client.logo
                  ? `${process.env.REACT_APP_UPLOAD_URL}${
                      client.logo.split("\\")[1]
                    }`
                  : `${logo}`
              }
              alt=""
            />
          </div>
          <h3>{client.nom}</h3>
          <button
            onClick={() => navigate(`/updateclient/${client._id}`)}
            className="DarkBtn"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 0C7.55228 0 8 0.447715 8 1V5.999L13 6C13.5523 6 14 6.44772 14 7C14 7.55228 13.5523 8 13 8L8 7.999V13C8 13.5523 7.55228 14 7 14C6.44772 14 6 13.5523 6 13V7.999L1 8C0.447715 8 0 7.55228 0 7C0 6.44772 0.447715 6 1 6L6 5.999V1C6 0.447715 6.44772 0 7 0Z"
                fill="white"
              />
            </svg>{" "}
            Modifier client
          </button>
        </div>
        <div className="ClientDetailsRightSection">
          <div className="container-fluid" style={{ height: "100%" }}>
            <div className="row" style={{ height: "100%" }}>
              <div
                className="col-md-4 d-flex flex-column justify-content-between"
                style={{ height: "100%" }}
              >
                <div className="mb-2">
                  <p className="ClientDetailsLabel">Dirigeant</p>
                  <p className="ClientDetailsText">{client.dirigeant}</p>
                </div>
                <div className="mb-2">
                  <p className="ClientDetailsLabel">Activité</p>
                  <p className="ClientDetailsText">{client.activite}</p>
                </div>
                <div className="mb-2">
                  <p className="ClientDetailsLabel">
                    Date création de l’entreprise
                  </p>
                  <p className="ClientDetailsText">{client.creationDate}</p>
                </div>
                <div className="mb-2">
                  <p className="ClientDetailsLabel">
                    Effectif (nombre de salariés)
                  </p>
                  <p className="ClientDetailsText">{client.effectif}</p>
                </div>
              </div>
              <div className="col-md-4 d-flex flex-column justify-content-between">
                <div className="mb-2">
                  <p className="ClientDetailsLabel">Adresse</p>
                  <p className="ClientDetailsText">{client.adresse}</p>
                </div>
                <div className="mb-2">
                  <p className="ClientDetailsLabel">Numéro de téléphone</p>
                  <p className="ClientDetailsText">{client.phone}</p>
                </div>
                <div className="mb-2">
                  <p className="ClientDetailsLabel">Mail</p>
                  <p className="ClientDetailsText">{client.email}</p>
                </div>
                <div className="mb-2">
                  <p className="ClientDetailsLabel">Site web</p>
                  <p className="ClientDetailsText">{client.webSite}</p>
                </div>
              </div>
              <div className="col-md-4 d-flex flex-column justify-content-between">
                <div className="mb-2">
                  <p className="ClientDetailsLabel">Forme juridique</p>
                  <p className="ClientDetailsText">{client.formeJuridique}</p>
                </div>
                <div className="mb-2">
                  <p className="ClientDetailsLabel">Code NAF</p>
                  <p className="ClientDetailsText">{client.codeNAF}</p>
                </div>
                <div className="mb-2">
                  <p className="ClientDetailsLabel">Numéro Siren</p>
                  <p className="ClientDetailsText">{client.numSiren}</p>
                </div>
                <div className="mb-2">
                  <p className="ClientDetailsLabel">Numéro TVA</p>
                  <p className="ClientDetailsText">{client.numTVA}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardClientDetails;
