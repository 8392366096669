import React from "react";
import { formatDate } from "utils/format_date";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import NotFoundError from "Components/NotFoundError";

const EstimatesTable = ({ estimates }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const tableHeader = [
    "Chantier",
    "Type",
    "Date de début",
    "Date de fin",
    "Charges estimées",
    "",
  ];
  const getSiteType = (type) => {
    if (type === "residential") {
      return "résidentiel";
    } else if (type === "commercial") {
      return "commercial";
    } else if (type === "infrastructure") {
      return "infrastructure";
    } else {
      return "";
    }
  };
  const getTotal = (items) => {
    let amount = 0;
    items.map((item) => (amount += item.amount));
    return amount;
  };
  return (
    <>
      {estimates && Array.isArray(estimates) && estimates.length !== 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "end",
            p: "15px",
          }}
        >
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: "440px",
              backgroundColor: theme.palette.primary.main,
              display: "flex",
            }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {tableHeader.map((header) => (
                    <TableCell
                      key={header}
                      sx={{
                        color: theme.palette.grey[600],
                        fontSize: "14px",
                        fontWeight: "400",
                        borderBottom: "2px solid",
                        borderBottomColor: theme.palette.grey.light,
                        pt: "5px",
                        pb: "5px,",
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {estimates.map((estimate) => (
                  <TableRow
                    key={estimate._id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "& .MuiTableCell-root": {
                        color: theme.palette.secondary.dark,
                        fontSize: "14px",
                        borderBottom: "1px solid",
                        borderBottomColor: theme.palette.grey.light,
                        pt: "5px",
                        pb: "5px",
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <Typography>{estimate.site.name}</Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography>{getSiteType(estimate.site.type)}</Typography>
                    </TableCell>
                    <TableCell>
                      {formatDate(estimate.site.begin_date)}
                    </TableCell>
                    <TableCell>
                      {formatDate(estimate.site.begin_date)}
                    </TableCell>
                    <TableCell>{getTotal(estimate.estimates)}</TableCell>
                    <TableCell align="center">
                      <Button
                        sx={{
                          color: theme.palette.alt.main,
                          textTransform: "none",
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                        onClick={() => navigate(`${estimate._id}`)}
                      >
                        Détails
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <NotFoundError
          text="Aucune estimation trouvé !"
          sx={{ height: "80%" }}
        />
      )}
    </>
  );
};

export default EstimatesTable;
