import { Box, Button, Divider, Typography } from "@mui/material";
import EquipmentList from "Components/EquipmentList";
import React from "react";
import FlexBetween from "./FlexBetween";
import { useTheme } from "@emotion/react";

const EquipmentsOverlay = ({
  site,
  equipments,
  checkedEquipments,
  handleEquipmentToggle,
  numbers,
  changeNumber,
  setOpen,
  onClick,
}) => {
  const theme = useTheme();
  return (
    <Box
      height="55vh"
      width="100%"
      sx={{
        backgroundColor: theme.palette.primary.main,
        borderRadius: "6px",
        p: "20px",
      }}
    >
      <FlexBetween gap="10px">
        <Typography
          variant="h5"
          fontWeight="bold"
          color={theme.palette.secondary.main}
        >
          Matériaux et Équipement
        </Typography>
        <Button
          size="small"
          variant="contained"
          sx={{
            bgcolor: theme.palette.alt.main,
            color: theme.palette.primary.main,
            fontSize: "16px",
            fontWeight: "bold",
            height: "30px",
            width: "30px",
            p: "0",
            ":hover": {
              bgcolor: theme.palette.alt.main,
              color: theme.palette.primary.main,
            },
          }}
          onClick={() => {
            onClick && onClick();
            setOpen(false);
          }}
        >
          OK
        </Button>
      </FlexBetween>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          color={theme.palette.grey[600]}
          fontSize={"16px"}
          width="100%"
          display="flex"
          justifyContent="space-between"
          mt="10px"
          p="0 45px"
        >
          <Typography>Équipement</Typography>
          <Typography>Nombre</Typography>
        </Box>
        <Divider
          sx={{
            textAlign: "center",
            height: "1px",
            width: "300px",
            backgroundColor: "black",
            m: "10px",
          }}
        />
      </Box>
      <EquipmentList
        site={site}
        numbers={numbers}
        changeNumber={changeNumber}
        checkedEquipments={checkedEquipments}
        handleEquipmentToggle={handleEquipmentToggle}
        equipments={equipments}
      />
    </Box>
  );
};

export default EquipmentsOverlay;
