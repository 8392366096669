import handleError from "utils/handelError";
import axiosClient from "./client";
import {
  createSiteEndPoint,
  deleteSiteEndPoint,
  getSiteDetailsEndPoint,
  getAllClientSitesEndPoint,
  updateSiteDetailsEndPoint,
  updateSiteEquipmetsEndPoint,
  updateSiteWorkersEndPoint,
  updateSiteStateEndPoint,
  searchSitesEndPoint,
  sortSitesEndPoint,
  getAllSitesEndPoints,
  getSitesWithoutEstimateEndPoints,
  getWorkerSitesEndPoint,
  deleteWorkerFromSitesEndPoint,
  getSitesInDateEndPoints,
} from "./Constants";

const SiteService = {
  createSite: async (data) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.post(
        createSiteEndPoint,
        { ...data, client: clientId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const site = response.data.site;
      return site;
    } catch (error) {
      handleError(error);
    }
  },
  getSiteDetails: async (id) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getSiteDetailsEndPoint(id), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const site = response.data.site;
      return site;
    } catch (error) {
      handleError(error);
    }
  },
  getSitesDetails: async () => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getAllSitesEndPoints, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const sites = response.data.sites;
      return sites;
    } catch (error) {
      handleError(error);
    }
  },
  getSitesInDateDetails: async (date) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getSitesInDateEndPoints, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          date,
        },
      });
      const sites = response.data.sites;
      return sites;
    } catch (error) {
      handleError(error);
    }
  },
  getSitesWithoutEstimate: async () => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.get(getSitesWithoutEstimateEndPoints, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          clientId,
        },
      });
      const sites = response.data.sites;
      return sites;
    } catch (error) {
      handleError(error);
    }
  },
  getAllClientSites: async () => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.get(getAllClientSitesEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          clientId,
        },
      });
      const sites = response.data.sites;
      return sites;
    } catch (error) {
      handleError(error);
    }
  },
  updateSiteDetails: async (id, data) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.put(
        updateSiteDetailsEndPoint(id),
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const site = response.data.site;
      return site;
    } catch (error) {
      handleError(error);
    }
  },
  deleteSite: async (id) => {
    try {
      const token = localStorage.getItem("@userCsc");
      await axiosClient.delete(deleteSiteEndPoint(id), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      handleError(error);
    }
  },
  updateSiteEquipments: async (id, data) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.put(
        updateSiteEquipmetsEndPoint(id),
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const site = response.data.site;
      return site;
    } catch (error) {
      handleError(error);
    }
  },
  updateSiteWorkers: async (id, data) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.put(
        updateSiteWorkersEndPoint(id),
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const site = response.data.site;
      return site;
    } catch (error) {
      handleError(error);
    }
  },
  updateSiteState: async (id, state) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.put(
        updateSiteStateEndPoint(id),
        { state },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const site = response.data.site;
      return site;
    } catch (error) {
      handleError(error);
    }
  },
  searchSites: async (query) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.get(searchSitesEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          query,
          client: clientId,
        },
      });
      const sites = response.data.sites;
      return sites;
    } catch (error) {
      handleError(error);
    }
  },
  sortSites: async (option) => {
    try {
      const checkedOption = () => {
        if (option === "Nom") {
          return "name";
        } else if (option === "Date") {
          return "date";
        } else {
          throw Error("option de tri invalide");
        }
      };
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.get(sortSitesEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          option: checkedOption(),
          client: clientId,
        },
      });
      const sites = response.data.sites;
      return sites;
    } catch (error) {
      handleError(error);
    }
  },
  getWorkerSites: async (workerId) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getWorkerSitesEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          workerId,
        },
      });
      const sites = response.data.sites;
      return sites;
    } catch (error) {
      handleError(error);
    }
  },
  deleteWorkerFromSites: async (workerId, sites) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.put(
        deleteWorkerFromSitesEndPoint,
        { sites },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            workerId,
          },
        }
      );
      const worker = response.data.worker;
      return worker;
    } catch (error) {
      handleError(error);
    }
  },
  getClientStats: async () => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.get(`client/${clientId}/stats`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const stats = response.data.stats;
      return stats;
    } catch (error) {
      handleError(error);
    }
  },
};
export default SiteService;
