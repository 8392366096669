import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  todos: [],
};

const todoSlice = createSlice({
  name: "todo",
  initialState,
  reducers: {
    // todo events
    setTodos: (state, action) => {
      const { todos } = action.payload;
      state.todos = todos;
    },
    updateTodo: (state, action) => {
      const { todo } = action.payload;
      for (const [key, value] of Object.entries(state.todos)) {
        if (value._id?.toString() === todo._id?.toString()) {
          state.todos[key] = todo;
          break;
        }
      }
    },
    updateTodoState: (state, action) => {
      const { todoId, newState } = action.payload;
      for (const [key, value] of Object.entries(state.todos)) {
        if (value._id?.toString() === todoId) {
          const todo = { ...value, state: newState };
          state.todos[key] = todo;
          break;
        }
      }
    },
    addTodo: (state, action) => {
      const { todo } = action.payload;
      state.todos.push(todo);
    },
    deleteTodo: (state, action) => {
      const { id } = action.payload;
      state.todos = state.todos.filter(
        (todo) => todo._id?.toString() !== id?.toString()
      );
    },
  },
});

export const { setTodos, updateTodo, addTodo, deleteTodo, updateTodoState } =
  todoSlice.actions;

export default todoSlice.reducer;

export const selectTodos = (state) => state.todo.todos;
