import handleError from "utils/handelError";
import axiosClient from "./client";
import {
  createTaskEndPoint,
  deleteTaskEndPoint,
  getTaskDetailsEndPoint,
  getTasksDetailsEndPoint,
  updateTaskDetailsEndPoint,
  updateTaskPriorityEndPoint,
  updateTaskStateEndPoint,
} from "./Constants";

const TaskService = {
  createTask: async (data, siteId) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.post(createTaskEndPoint, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          site_id: siteId,
        },
      });
      const task = response.data.task;
      return task;
    } catch (error) {
      handleError(error);
    }
  },
  getTaskDetails: async (id) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getTaskDetailsEndPoint(id), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const task = response.data.task;
      return task;
    } catch (error) {
      handleError(error);
    }
  },
  getTasksDetails: async (siteId) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getTasksDetailsEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          siteId: siteId,
        },
      });
      const tasks = response.data.tasks;
      return tasks;
    } catch (error) {
      handleError(error);
    }
  },
  updateTaskDetails: async (id, data) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.put(
        updateTaskDetailsEndPoint(id),
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const task = response.data.task;
      return task;
    } catch (error) {
      handleError(error);
    }
  },
  deleteTask: async (id) => {
    try {
      const token = localStorage.getItem("@userCsc");
      await axiosClient.delete(deleteTaskEndPoint(id), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      handleError(error);
    }
  },
  updateTaskState: async (id, state) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.put(
        updateTaskStateEndPoint(id),
        { state },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const task = response.data.task;
      return task;
    } catch (error) {
      handleError(error);
    }
  },
  updateTaskPriority: async (id, state) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.put(
        updateTaskPriorityEndPoint(id),
        { state },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const task = response.data.task;
      return task;
    } catch (error) {
      handleError(error);
    }
  },
};

export default TaskService;
