import {
  getUser,
  getUsers,
  login as loginFromAPI,
  register,
  updateUser,
  deleteUser,
} from "../services/user.service";
import { jwtDecode } from "jwt-decode";

export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_ERROR = "SIGN_IN_ERROR";
export const SIGN_OUT = "SIGN_OUT";
export const SET_INITIAL_STATE = "SET_INITIAL_STATE";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const ADD_ADMIN = "ADD_ADMIN";
export const ADD_ADMIN_SUCCESS = "ADD_ADMIN_SUCCESS";
export const ADD_ADMIN_ERROR = "ADD_ADMIN_ERROR";
export const UPDATE_ADMIN = "UPDATE_ADMIN";
export const UPDATE_ADMIN_SUCCESS = "UPDATE_ADMIN_SUCCESS";
export const UPDATE_ADMIN_ERROR = "UPDATE_ADMIN_ERROR";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_ERROR = "DELETE_ADMIN_ERROR";

export const setInitialState = (user) => ({
  type: SET_INITIAL_STATE,
  payload: user,
});

export const login = (values) => (dispatch) => {
  dispatch({ type: SIGN_IN });

  return loginFromAPI(values).then(async (result) => {
    if (result) {
      if (result.role === "ADMIN" || result.role === "SUPER_ADMIN") {
        localStorage.setItem("@userCsc", result.token);
        let decoded = jwtDecode(result.token);
        let user = await getUser(decoded.id);
        result._id = decoded.id;
        dispatch({
          type: SIGN_IN_SUCCESS,
          payload: { user, token: result.token },
        });
        return result;
      } else {
        return false;
      }
    } else {
      dispatch({
        type: SIGN_IN_ERROR,
        payload: "Erreur connexion",
      });
      return false;
    }
  });
};

export const fetchUsers = () => (dispatch) => {
  return getUsers().then((result) => {
    dispatch({
      type: GET_ALL_USERS,
      payload: result,
    });
    return result;
  });
};

export const fetchUser = (id) => (dispatch) => {
  return getUser(id).then((result) => {
    if (result) {
      dispatch({
        type: SIGN_IN_SUCCESS,
        payload: result,
      });
    }
    return result;
  });
};

export const signOut = () => async (dispatch) => {
  try {
    await localStorage.removeItem("@userCsc");
    dispatch({ type: SIGN_OUT });
  } catch (e) {
    console.log(e);
  }
};

export const addAdmin = (values) => (dispatch) => {
  return register(values).then((result) => {
    dispatch({
      type: ADD_ADMIN,
      payload: result,
    });
    return result;
  });
};

export const updateAdmin = (user_Id, values) => (dispatch) => {
  return updateUser(user_Id, values).then((result) => {
    dispatch({
      type: UPDATE_ADMIN,
      payload: result,
    });
    return result;
  });
};

export const deleteAdmin = (user_Id) => (dispatch) => {
  return deleteUser(user_Id).then((result) => {
    dispatch({
      type: DELETE_ADMIN,
      payload: user_Id,
    });
    return result;
  });
};
