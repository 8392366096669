import {
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import FinancialsLayout from "./FinancialLayout";
import Commandes from "Pages/Financials/Commande/Commandes";
import Estimates from "Pages/Financials/Estimates/Estimates";
import FinancialsInvoices from "Pages/Financials/FinancialsInvoices/FinancialsInvoices";
import FinancialsQuotes from "Pages/Financials/FinancialsQuote/FinancialsQuotes";
import NotFound from "Pages/NotFound";
import EstimatesList from "Pages/Financials/Estimates/EstimatesList";
import FinancialsQuotesList from "Pages/Financials/FinancialsQuote/FinancialsQuotesList";
import FinancialsInvoicesList from "Pages/Financials/FinancialsInvoices/FinancialsInvoicesList";
import CommandesList from "Pages/Financials/Commande/CommandesList";

const Financials = () => {
  return (
    <Routes>
      <Route path="/" element={<FinancialsLayout />}>
        <Route path="/" element={<Navigate to="estimates" />} />
        <Route path="/estimates" element={<EstimatesList />} />
        <Route path="/quotes" element={<FinancialsQuotesList />} />
        <Route path="/invoices" element={<FinancialsInvoicesList />} />
        <Route path="/commandes" element={<CommandesList />} />
      </Route>
      <Route path="/estimates/*" element={<Estimates />} />
      <Route path="/quotes/*" element={<FinancialsQuotes />} />
      <Route path="/invoices/*" element={<FinancialsInvoices />} />
      <Route path="/commandes/*" element={<Commandes />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default Financials;
