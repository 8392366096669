import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AddEditQuote from './AddEditQuote'
import QuotesList from './QuotesList'

const Quotes = () => {
  return (
    <Routes>
      <Route path='/' element={<QuotesList />} />
      <Route path='/:quoteId' element={<AddEditQuote />} />
      <Route path='/add' element={<AddEditQuote />} />
    </Routes>
  )
}

export default Quotes