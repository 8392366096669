import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tasks: [],
};

const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    // task events
    setTasks: (state, action) => {
      const { tasks } = action.payload;
      state.tasks = tasks;
    },
    updateTask: (state, action) => {
      const { task } = action.payload;
      for (const [key, value] of Object.entries(state.tasks)) {
        if (value._id?.toString() === task._id?.toString()) {
          state.tasks[key] = task;
          break;
        }
      }
    },
    updateTaskState: (state, action) => {
      const { taskId, newState } = action.payload;
      for (const [key, value] of Object.entries(state.tasks)) {
        if (value._id?.toString() === taskId) {
          const task = { ...value, state: newState };
          state.tasks[key] = task;
          break;
        }
      }
    },
    addTask: (state, action) => {
      const { task } = action.payload;
      state.tasks.push(task);
    },
    deleteTask: (state, action) => {
      const { id } = action.payload;
      state.tasks = state.tasks.filter(
        (task) => task._id?.toString() !== id?.toString()
      );
    },
  },
});

export const { setTasks, updateTask, addTask, deleteTask, updateTaskState } =
  taskSlice.actions;

export default taskSlice.reducer;

export const selectTasks = (state) => state.task.tasks;
