import handleError from "utils/handelError";
import axiosClient from "./client";
import {
  getAllUsersEndPoint,
  getAvailableWorkersEndPoint,
  getClientStatsEndPoint,
  getClientWorkersDetailsEndPoint,
  getClientetailsEndPoint,
  getMyDetailsEndPoint,
  getStaffEndPoint,
  getUserDetailsEndPoint,
} from "./Constants";

const AuthService = {
  getMyDetails: async () => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getMyDetailsEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const user = response.data;
      return user;
    } catch (error) {
      handleError(error);
    }
  },

  getUserDetails: async (id) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getUserDetailsEndPoint(id), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const user = response.data.user;
      return user;
    } catch (error) {
      handleError(error);
    }
  },
  getClientDetails: async () => {
    try {
      const clientId = localStorage.getItem("idClient");
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(
        getClientetailsEndPoint(clientId),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const user = response.data;
      return user;
    } catch (error) {
      handleError(error);
    }
  },

  getAllUsers: async () => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getAllUsersEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const users = response.data;
      return users;
    } catch (error) {
      handleError(error);
    }
  },

  getStaff: async () => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getStaffEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const users = response.data;
      return users;
    } catch (error) {
      handleError(error);
    }
  },

  getAvailableWorkers: async () => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getAvailableWorkersEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const workers = response.data.workers;
      return workers;
    } catch (error) {
      handleError(error);
    }
  },

  getClientWorkersDetails: async () => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.get(
        getClientWorkersDetailsEndPoint(clientId),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const workers = response.data;
      return workers;
    } catch (error) {
      handleError(error);
    }
  },
  getClientStats: async () => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getClientStatsEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const stats = response.data.stats;
      return stats;
    } catch (error) {
      handleError(error);
    }
  },
};

export default AuthService;
