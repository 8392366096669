import React from "react";
import AddPeronnel from "../../Components/Forms/AddPeronnel";
import Header from "../../Components/Headers/Header";
import Layout from "../../Components/Layouts/Layout";
import SimpleHeader from "../../Components/Headers/SimpleHeader";
import { Box } from "@mui/material";

function AddPersonel() {
  return (
    <Box flex={1}>
      <SimpleHeader title="Personnels" />
      <AddPeronnel />
    </Box>
  );
}

export default AddPersonel;
