import React from "react";
import UpdatePersonnel from "../../Components/Forms/UpdatePersonnel";
import Header from "../../Components/Headers/Header";
import Layout from "../../Components/Layouts/Layout";
import SimpleHeader from "../../Components/Headers/SimpleHeader";
import { Box } from "@mui/material";

function UpdatePersonnels() {
  return (
    <Box flex={1}>
      <SimpleHeader title="Personnels" />
      <UpdatePersonnel />
    </Box>
  );
}

export default UpdatePersonnels;
