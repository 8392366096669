import React, { useEffect, useState } from "react";
import Header from "../../Components/Headers/Header";
import Layout from "../../Components/Layouts/Layout";
import HeaderWithButton from "../../Components/Headers/HeaderWithButton";
import PersonnelList from "../../Components/Prepai/PersonnelList";
import FicheDePai from "../../Components/Prepai/FicheDePai";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFichesPaie,
  searchFichesPaieByMonth,
} from "../../redux/actions/FichePaieAction";
import FichePaieView from "../../Components/Modals/FichePaieView";
import { usePDF } from "react-to-pdf";
import ModalDeleteGuide from "../../Components/Modals/DeleteModal";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import NotFoundError from "Components/NotFoundError";
// import FicheDePaiForFdf from '../../Components/Prepai/fichePaieForPdf';
function PrepaieList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toPDF, targetRef } = usePDF({
    filename: "fichePaie.pdf",
  });
  const fiches = useSelector((state) => state.fichePaie.fichesPaie);
  const fichesCopie = useSelector((state) => state.fichePaie.fichePaieCopie);
  const [selectedFiche, setSelectedFiche] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedPersonnel, setSelectedPersonnel] = useState(null);
  const [filteredFiches, setFilteredFiches] = useState([]);

  const handleDataFromChild = (data) => {
    setSelectedFiche(data);
  };

  const handleMonthFromChild = (data) => {
    setSelectedMonth(data);
  };

  const handlePersonnelFromChild = (data) => {
    setSelectedPersonnel(data);
  };

  useEffect(() => {
    const idClient = localStorage.getItem("idClient");
    dispatch(searchFichesPaieByMonth(selectedMonth, idClient))
      .then((data) => {
        setFilteredFiches(data);
        if (data.length !== 0) {
          setSelectedFiche(data[0]);
        }
      })
      .catch((error) =>
        console.log("Erreur lors de la récupération des données:", error)
      );
  }, [selectedMonth]);

  useEffect(() => {
    if (selectedPersonnel !== null) {
      const filteredFiches = fichesCopie.filter(
        (fiche) => fiche.personnel._id == selectedPersonnel._id
      );
      setFilteredFiches(filteredFiches);
    } else {
      setFilteredFiches(fiches);
    }
  }, [selectedPersonnel, fiches]);
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  const [modalOpenDelete, setModalOpenDelete] = useState(false);
  const openModalDelete = () => {
    setModalOpenDelete(true);
  };

  const closeModalDelete = () => {
    setModalOpenDelete(false);
  };

  const handleDelete = (id) => {
    dispatch(deleteFichesPaie(id))
      .then((result) => {
        // if (result) {
        //     dispatch(fetchAllAmicals())
        // }
      })
      .catch((error) => {
        console.log("Error :", error);
      });
  };

  const deleteFichePaie = () => {
    openModalDelete();
  };
  const btnFunction = () => {
    navigate("/AddPrepaie");
  };
  return (
    <Box className="content">
      {modalOpen && (
        <FichePaieView
          isOpen={modalOpen}
          onClose={closeModal}
          data={selectedFiche}
        />
      )}
      {modalOpenDelete && (
        <ModalDeleteGuide
          isOpen={modalOpenDelete}
          onClose={closeModalDelete}
          handleAction={() => handleDelete(selectedFiche._id)}
          title="Supprimer le client X"
          message="Voulez-vous vraiment supprimer cette fiche ?"
        />
      )}
      <HeaderWithButton
        title="Fiches de prepaie"
        btnTitle="Générer une fiche"
        btnFunction={() => btnFunction()}
      />
      {filteredFiches.length > 0 ? (
        <div className="container-fluid  personnel-table-container">
          <div className="row">
            <div className="col-md-6">
              <PersonnelList
                handlePersonnelFromChild={handlePersonnelFromChild}
                handleMonthFromChild={handleMonthFromChild}
                handleDataFromChild={handleDataFromChild}
                fiches={filteredFiches}
              />
            </div>
            <div className="col-md-6">
              <div
                className="d-flex flex-row justify-content-between align-items-center"
                style={{ marginBottom: "20px" }}
              >
                <button onClick={() => openModal()} className="DarkBtn">
                  <svg
                    width="20"
                    height="12"
                    viewBox="0 0 20 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 0C12.5773 0 15.9107 2 20 6C15.9107 10 12.5773 12 10 12C7.42267 12 4.08934 10 0 6C4.08934 2 7.42267 0 10 0ZM10 2C8.41651 2 6.12903 3.20868 3.26355 5.70283L2.926 6L2.95433 6.02502C5.86439 8.61416 8.19959 9.91203 9.83348 9.99568L10 10C11.5835 10 13.871 8.79132 16.7364 6.29717L17.073 6L17.0457 5.97498C14.1356 3.38584 11.8004 2.08797 10.1665 2.00432L10 2ZM10 4C11.1046 4 12 4.89543 12 6C12 7.10457 11.1046 8 10 8C8.89543 8 8 7.10457 8 6C8 4.89543 8.89543 4 10 4Z"
                      fill="white"
                    />
                  </svg>
                </button>
                <button className="DarkBtn" onClick={() => toPDF()}>
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15 11C15.5128 11 15.9355 11.386 15.9933 11.8834L16 12V16C16 16.5128 15.614 16.9355 15.1166 16.9933L15 17H1C0.487164 17 0.0644928 16.614 0.00672773 16.1166L0 16V12C0 11.4477 0.447715 11 1 11C1.51284 11 1.93551 11.386 1.99327 11.8834L2 12V15H14V12C14 11.4872 14.386 11.0645 14.8834 11.0067L15 11ZM8 0C8.55229 0 9 0.447715 9 1L9.001 9.584L12.2929 6.29289C12.6534 5.93241 13.2206 5.90468 13.6129 6.2097L13.7071 6.29289C14.0676 6.65338 14.0953 7.22061 13.7903 7.6129L13.7071 7.70711L8.70711 12.7071C8.34662 13.0676 7.77939 13.0953 7.3871 12.7903L7.29289 12.7071L2.29289 7.70711C1.90237 7.31658 1.90237 6.68342 2.29289 6.29289C2.65338 5.93241 3.22061 5.90468 3.6129 6.2097L3.70711 6.29289L7.001 9.586L7 1C7 0.447715 7.44772 0 8 0Z"
                      fill="white"
                    />
                  </svg>
                  Télécharger
                </button>
                <button
                  className="DarkBtn"
                  onClick={() =>
                    navigate(`/UpdatePrepaie/${selectedFiche._id}`)
                  }
                >
                  <svg
                    width="16"
                    height="18"
                    viewBox="0 0 16 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15 16C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18H1C0.447715 18 0 17.5523 0 17C0 16.4477 0.447715 16 1 16H15ZM14.003 0.584917L15.4151 1.99703C16.195 2.77692 16.195 4.04137 15.4151 4.82126L7.17775 13.0586C7.02498 13.2114 6.82678 13.3105 6.61291 13.341L2 14L2.65899 9.38709C2.68954 9.17322 2.78864 8.97502 2.94141 8.82225L11.1787 0.584917C11.9586 -0.194972 13.2231 -0.194972 14.003 0.584917ZM12.5909 1.99703L4.58911 9.99878L4.35399 11.646L6.00122 11.4109L14.003 3.40914L12.5909 1.99703Z"
                      fill="white"
                    />
                  </svg>
                  Modifier
                </button>
                <button className="DarkBtn" onClick={() => deleteFichePaie()}>
                  <svg
                    width="14"
                    height="18"
                    viewBox="0 0 14 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13 5V16C13 17.1046 12.1046 18 11 18H3C1.89543 18 1 17.1046 1 16V5H13ZM11 7H3V16H11V7ZM7 0C7.55228 0 8 0.447715 8 1V2H13C13.5523 2 14 2.44772 14 3C14 3.55228 13.5523 4 13 4H1C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H6V1C6 0.447715 6.44772 0 7 0Z"
                      fill="white"
                    />
                  </svg>
                  Supprimer
                </button>
              </div>
              <div ref={targetRef}>
                {selectedFiche && selectedFiche.length !== 0 && (
                  <FicheDePai data={selectedFiche} />
                )}
              </div>
              {/* <div ref={targetRef} style={{display:'none'}}>
                                {selectedFiche && selectedFiche.length !== 0 && <FicheDePaiForFdf data={selectedFiche} />}
                            </div> */}
            </div>
          </div>
        </div>
      ) : (
        <tr>
          <NotFoundError
            text={"Aucune fiche de prepaie trouvée"}
            sx={{ width: "400%", height: "60px" }}
          />
        </tr>
      )}
    </Box>
  );
}

export default PrepaieList;
