import React, { useEffect } from "react";
import CardClientDetails from "../../Components/Cards/CardClientDetails";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getClient } from "../../redux/actions/ClientAction";
import NotifClientCard from "../../Components/Notifications/NotifClientCard";

function ClientDetails() {
  let { id } = useParams();
  const dispatch = useDispatch();
  // const [date, setDate] = useState(null);
  const client = useSelector((state) => state.client.client);
  useEffect(() => {
    dispatch(getClient(id));
  }, [dispatch, id]);

  return (
    <div style={{ width: "100%" }}>
      {client ? <CardClientDetails client={client} /> : <p>pas de client</p>}
      <div className="container">
        <div className="row">
          <NotifClientCard />
        </div>
      </div>
    </div>
  );
}

export default ClientDetails;
