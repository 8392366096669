import axios from "axios";
import refreshToken from "../../helpers/functions";
const API = process.env.REACT_APP_API_URL

export const login = async (values) => {
    return await axios
        .post(`${API}user/login`, values)
        .then((response) => { return response.data })
        .catch((err) => console.log(err));

}

export const register = async (values) => {
    return await axios
    .post(`${API}user/register`, values)
    .then((response) => { return response.data })
    .catch((err) => console.log(err));
}
export const getUser = async (id) => {
    return await axios
        .get(`${API}user/${id}`)
        .then((response) => { return response.data })
        .catch((err) => console.log(err));

}
export const getUsers = async () => {
    return await axios
        .get(`${API}user/`)
        .then((response) => { return response.data })
        .catch((err) => console.log(err));

}
export const deleteUser = async (user_Id) => {
    let http = await refreshToken()
    let result = await http.delete(`user/${user_Id}`)
    return result.data
}

export const updateUser = async (user_Id, values) => {
    let http= await refreshToken()
    let result = await http.put(`user/${user_Id}`,values)
    return result.data
}
