import { useTheme } from "@emotion/react";
import { Box, CircularProgress } from "@mui/material";
import React from "react";

const CustomCircularProgress = ({sx,style}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...sx
      }}
    >
      <CircularProgress
        size={30}
        sx={{
          color: theme.palette.secondary.main,
          ...style
        }}
      />
    </Box>
  );
};

export default CustomCircularProgress;
