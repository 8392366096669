import React, { useEffect, useState } from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { toast } from "react-toastify";
import AuthService from "Api/AuthService";

const ClientInfo = ({ client, setClient }) => {
  const theme = useTheme();
  const [isLoading, setIsloading] = useState(true);
  useEffect(() => {
    const getClientDetails = async () => {
      try {
        setIsloading(true);
        const data = await AuthService.getClientDetails();
        setClient(data);
        setIsloading(false);
      } catch (error) {
        toast(`${error}`);
      }
    };
    getClientDetails();
  }, []);
  return (
    <Box
      sx={{
        pr: "30px",
        height: "100px",
      }}
    >
      {isLoading ? (
        <Skeleton
          animation="pulse"
          variant="rectangular"
          sx={{
            bgcolor: theme.palette.grey[900],
            borderRadius: "6px",
            height: "100%",
          }}
        />
      ) : (
        <Box>
          <Typography
            sx={{
              color: theme.palette.secondary[700],
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Informations Client {client.nom}
          </Typography>
          <Box
            sx={{
              color: theme.palette.grey[400],
              fontSize: "16px",
            }}
          >
            <Typography>{client.adresse}</Typography>
            <Typography>{client.phone}</Typography>
            <Typography>{client.email}</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ClientInfo;
