import {
  Box,
  Typography,
  InputBase,
  IconButton,
  MenuItem,
  TextField,
  Button,
  List,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FlexBetween from "../../Components/FlexBetween";
import { AddOutlined, KeyboardArrowDown, Search } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import CustomCircularProgress from "Components/CustomCircularProgress";
import EquipmentService from "Api/EquipmentService";
import { selectEquipments, setEquipments } from "state/equipment";
import EquipmentsTable from "./EquipmentsTable";
import PopUp from "Components/Popup";
import AddEditEquipment from "./AddEditEquipment";
import { setGlobalIsLoading } from "state/global";
import Papa from "papaparse";
import { toast } from "react-toastify";

const EquipmentsList = () => {
  const filterOptions = ["", "Nom", "Date"];
  const theme = useTheme();
  const dispatch = useDispatch();
  const equipments = useSelector((state) => selectEquipments(state));
  const [isLoading, setIsLoading] = useState(true);
  const [filterBy, setFilterBy] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [addEditOpen, setAddEditOpen] = useState(false);
  const [filtredEquipments, setFilredEquipments] = useState(null);
  const [filterCount, setFilterCount] = useState(null);
  const [count, setCount] = useState(0);

  useEffect(() => {
    getPage(1);
  }, []);

  const searchEquipments = async (query) => {
    try {
      if (!query) {
        setFilredEquipments(null);
        setFilterCount(null);
        return;
      }
      setIsLoading(true);
      const data = await EquipmentService.searchEquipments(query, currentPage);
      setFilredEquipments(data.equipments);
      setFilterCount(data.count);
      setIsLoading(false);
    } catch (error) {
      setFilredEquipments(null);
      setIsLoading(false);
      toast(`${error}`);
    }
  };

  const sortEquipments = async (option) => {
    try {
      if (!option || !filterOptions.includes(option)) {
        setFilterBy("");
        setFilredEquipments(null);
        setFilterCount(null);
        return;
      }
      setIsLoading(true);
      const data = await EquipmentService.sortEquipments(option, currentPage);
      setFilredEquipments(data.equipments);
      setFilterCount(data.count);
      setFilterBy(option);
      setIsLoading(false);
    } catch (error) {
      setFilredEquipments(null);
      setIsLoading(false);
      toast(`${error}`);
    }
  };

  const getPage = async (page) => {
    try {
      const checkedPage = parseInt(page);
      if (!checkedPage || checkedPage < 1) {
        throw Error("Numéro de page invalide");
      }
      setIsLoading(true);
      // dispatch(setGlobalIsLoading({ isLoading: true }))
      const data = await EquipmentService.getEquipmentsPage(page);
      dispatch(setEquipments({ equipments: data.equipments }));
      setCount(data.count);
      setCurrentPage(page);
      // dispatch(setGlobalIsLoading({ isLoading: false }))
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // dispatch(setGlobalIsLoading({ isLoading: false }))
      toast(`${error}`);
    }
  };

  const createEquipmentList = async (result) => {
    try {
      const data = result.data;
      await EquipmentService.createEquipmentList(data);
      toast("Equipments ajouté avec success!");
      getPage(currentPage);
    } catch (error) {
      toast(`${error}`);
    }
  };

  const createFromCSV = (event) => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      const file = event.target.files[0];
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: createEquipmentList,
      });
      dispatch(setGlobalIsLoading({ isLoading: false }));
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
    event.target.value = "";
  };

  return (
    <Box className='content'>
      {/* Header */}
      <FlexBetween className="header-gest-pages">
        <Typography
          variant="h3"
          fontWeight="bold"
          color={theme.palette.secondary.light}
        >
          Ressources Matérielles
        </Typography>
      </FlexBetween>
      {/* body */}
      <Box
        sx={{
        
          // height: "82vh",
          height: "calc(100vh - 140px)",
          width: "95%",
          margin:"auto",
        }}
      >
        <List sx={{
              height: "100%"}}>
          <Box
            sx={{
              backgroundColor: theme.palette.grey[100],
              borderRadius: "6px",
              padding: "5px",
              height: "100%",
              overflow: "auto",
            }}
          >
            {/* tool bar */}
            <FlexBetween
              sx={{
                margin: "1rem 20px",
              }}
            >
              <Box display="flex" gap="12px" flex="1">
                {/* Drop Down Filter */}
                <TextField
                  inputProps={{
                    id: "filter",
                  }}
                  label="Tri par"
                  select
                  onChange={(e) => sortEquipments(e.target.value)}
                  value={filterBy}
                  SelectProps={{
                    IconComponent: () => (
                      <KeyboardArrowDown
                        sx={{
                          color: theme.palette.grey.main,
                        }}
                      />
                    ),
                    style: {
                      color: theme.palette.grey[400],
                      height: "40px",
                    },
                  }}
                  InputLabelProps={{
                    htmlFor: "filter",
                    style: {
                      fontSize: "16px",
                      color: theme.palette.grey[400],
                      marginTop: "-6px",
                    },
                  }}
                  sx={{
                    color: "black",
                    border: "1px solid",
                    borderColor: theme.palette.grey[50],
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: "4px",
                    width: "180px",
                  }}
                >
                  {filterOptions.map((item) => (
                    <MenuItem
                      key={item}
                      sx={{
                        color: theme.palette.grey.main,
                        height: "30px",
                        ":hover": {
                          backgroundColor: theme.palette.grey.light,
                        },
                      }}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
                {/* Search Bar */}
                <FlexBetween
                  border="1px solid"
                  borderColor={theme.palette.grey[50]}
                  borderRadius="4px"
                  backgroundColor={theme.palette.primary.main}
                  p="0.1rem 0.8rem"
                  height="40px"
                  width="40%"
                >
                  <IconButton>
                    <Search sx={{ color: theme.palette.grey.main }} />
                  </IconButton>
                  <InputBase
                    id="search-bar"
                    name="search-bar"
                    placeholder="Search..."
                    sx={{
                      flex: "2",
                      color: theme.palette.grey[400],
                    }}
                    onChange={(e) => searchEquipments(e.target.value)}
                  />
                </FlexBetween>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    fontSize: "16px",
                    border: "1px solid",
                    borderColor: theme.palette.grey[300],
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.alt.main,
                    textTransform: "none",
                    fontWeight: "500",
                    width: "110px",
                    ":hover": {
                      backgroundColor: theme.palette.primary.main,
                    },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: "nowrap",
                    ".css-n8ltx6-MuiInputBase-root-MuiInput-root": {
                      height: "100%",
                    },
                    ".css-ume8vi-MuiInputBase-input-MuiInput-input": {
                      width: "100%",
                      height: "100%",
                    },
                    "#file-upload": {
                      display: "none",
                    },
                    ".file-upload-button": {
                      cursor: "pointer",
                    },
                  }}
                >
                  <AddOutlined sx={{ color: theme.palette.alt.main }} />
                  <label htmlFor="file-upload" className="file-upload-button">
                    Par CSV
                  </label>
                  <input
                    type="file"
                    name="file"
                    accept=".csv"
                    onChange={createFromCSV}
                    id="file-upload"
                  />
                </Box>
                <Button
                  startIcon={<AddOutlined />}
                  sx={{
                    backgroundColor: theme.palette.alt.main,
                    textTransform: "none",
                    fontSize: "16px",
                    fontWeight: "400",
                    ":hover": {
                      backgroundColor: theme.palette.alt[400],
                    },
                  }}
                  onClick={() => setAddEditOpen(true)}
                >
                  Ajouter ressource
                </Button>
              </Box>
            </FlexBetween>
            {isLoading ? (
              <CustomCircularProgress sx={{ height: "80%" }} />
            ) : (
              <EquipmentsTable
                equipments={filtredEquipments ? filtredEquipments : equipments}
                currentPage={currentPage}
                equipmentsNumber={filterCount ? filterCount : count}
                getPage={getPage}
                showPaginate={!filtredEquipments}
              />
            )}
          </Box>
        </List>
      </Box>
      <PopUp open={addEditOpen} setOpen={setAddEditOpen}>
        <AddEditEquipment
          setOpen={setAddEditOpen}
          getPage={getPage}
          currentPage={currentPage}
        />
      </PopUp>
    </Box>
  );
};

export default EquipmentsList;
