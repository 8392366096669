import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AddEditFinancialsInvoice from './AddEditFinancialsInvoice'

const FinancialsInvoices = () => {
  return (
    <Routes>
      <Route path='/:invoiceId' element={<AddEditFinancialsInvoice />} />
      <Route path='/add' element={<AddEditFinancialsInvoice />} />
    </Routes>
  )
}

export default FinancialsInvoices