import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectEstimates, setEstimates } from "state/financials";
import { toast } from "react-toastify";
import EstimateService from "Api/EstimateService";
import { useDispatch } from "react-redux";
import EstimatesTable from "./EstimatesTable";
import CustomCircularProgress from "Components/CustomCircularProgress";
import { useLocation } from "react-router-dom";

const EstimatesList = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [filterEstimates, setFilterEstimates] = useState(null);
  const query = useLocation();
  const estimates = useSelector((state) => selectEstimates(state));
  useEffect(() => {
    const getEstimates = async () => {
      try {
        setIsLoading(true);
        const data = await EstimateService.getClientEstimates();
        dispatch(setEstimates({ estimates: data }));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast(`${error}`);
      }
    };
    getEstimates();
  }, []);
  useEffect(() => {
    const search = async () => {
      try {
        const terms = query.search.split("=");
        if (!terms[1]) {
          setFilterEstimates(null);
          return;
        }
        setIsLoading(true);
        const data = await EstimateService.searchEstimates(terms[1]);
        setFilterEstimates(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast(`${error}`);
      }
    };
    search();
  }, [query]);
  return (
    <>
      {isLoading ? (
        <CustomCircularProgress sx={{ height: "60vh" }} />
      ) : (
        <EstimatesTable
          estimates={filterEstimates ? filterEstimates : estimates}
        />
      )}
    </>
  );
};

export default EstimatesList;
