import {
  Box,
  Typography,
  Button,
  InputBase,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FlexBetween from "../../Components/FlexBetween";
import {
  AddOutlined,
  KeyboardArrowDown,
  Search,
  TableRows,
} from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { selectSites, setSites } from "state/site";
import KanbanView from "./KanbanView";
import ListView from "./ListView";
import { useDispatch, useSelector } from "react-redux";
import CustomCircularProgress from "Components/CustomCircularProgress";
import SiteService from "Api/SiteService";
import { toast } from "react-toastify";

const SitesList = () => {
  const filterOptions = ["","Nom", "Date"];
  const theme = useTheme();
  const dispatch = useDispatch();
  const sites = useSelector((state) => selectSites(state));
  const navigate = useNavigate();
  const [isKanban, setIsKanban] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filterBy, setFilterBy] = useState("");
  const [filtredSites, setFilredSites] = useState(null);

  useEffect(() => {
    const getSites = async () => {
      try {
        setIsLoading(true);
        const data = await SiteService.getAllClientSites();
        dispatch(setSites({ sites: data }));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast(`${error}`);
      }
    };
    getSites();
  }, [dispatch]);

  const searchSites = async (query) => {
    try {
      if (!query) {
        setFilredSites(null);
        return;
      }
      setIsLoading(true);
      const currentSites = await SiteService.searchSites(query);
      setFilredSites(currentSites);
      setIsLoading(false);
    } catch (error) {
      setFilredSites(null);
      setIsLoading(false);
      toast(`${error}`);;
    }
  };

  const sortSites = async (option) => {
    try {
      if (!option || !filterOptions.includes(option)) {
        setFilterBy("")
        setFilredSites(null);
        return;
      }
      setIsLoading(true);
      const currentSites = await SiteService.sortSites(option);
      setFilredSites(currentSites);
      setFilterBy(option)
      setIsLoading(false);
    } catch (error) {
      setFilredSites(null);
      setIsLoading(false);
      toast(`${error}`);;
    }
  };

  return (
    // <Box sx={{
    //   width:'100%',
    //   margin:'20px',
    //   overflowX:'auto',
    // }}>
    <Box  className='content' >
      {/* Header */}
      <FlexBetween className="header-gest-pages">
        <Typography
          variant="h3"
          fontWeight="bold"
          color={theme.palette.secondary.light}
        >
          Chantiers
        </Typography>
        <Button
          startIcon={<AddOutlined />}
          sx={{
            fontSize: "16px",
            backgroundColor: theme.palette.alt.main,
            textTransform: "none",
            ":hover": {
              backgroundColor: theme.palette.alt[400],
            },
          }}
          onClick={() => navigate("add")}
        >
          Nouveau Chantier
        </Button>
      </FlexBetween>
      {/* tool bar */}
      <FlexBetween
        sx={{
          margin: "0 auto 20px",
          width:"95%"
        }}
      >
        <Box display="flex" gap="12px">
          {/* Drop Down Filter */}
          <TextField
            inputProps={{
              id:"filter"
            }}
            label="Tri par"
            select
            onChange={(e) => sortSites(e.target.value)}
            value={filterBy}
            SelectProps={{
              IconComponent: () => (
                <KeyboardArrowDown
                  sx={{
                    color: theme.palette.grey.main,
                  }}
                />
              ),
              style: {
                color: theme.palette.grey[400],
                height: "40px",
              },
            }}
            InputLabelProps={{
              htmlFor: "filter",
              style: {
                fontSize: "16px",
                color: theme.palette.grey[400],
                marginTop: "-6px",
              },
            }}
            sx={{
              color: "black",
              border: "1px solid",
              borderColor: theme.palette.grey[50],
              borderRadius: "4px",
              width: "180px",
            }}
          >
            {filterOptions.map((item) => (
              <MenuItem
                key={item}
                sx={{
                  color: theme.palette.grey.main,
                  height:'30px',
                  ":hover": {
                    backgroundColor: theme.palette.grey.light,
                  },
                }}
                value={item}
              >
                {item}
              </MenuItem>
            ))}
          </TextField>
          {/* Search Bar */}
          <FlexBetween
            border="1px solid"
            borderColor={theme.palette.grey[50]}
            borderRadius="4px"
            p="0.1rem 0.8rem"
            height="40px"
          >
            <IconButton>
              <Search sx={{ color: theme.palette.grey.main }} />
            </IconButton>
            <InputBase
              id="search-bar"
              name="search-bar"
              placeholder="Search..."
              sx={{ flex: "2", color: theme.palette.grey[400] }}
              onChange={(e) => searchSites(e.target.value)}
            />
          </FlexBetween>
        </Box>
        {/* Arrangment */}
        <Button
          startIcon={
            isKanban ? (
              <TableRows
                color={theme.palette.alt.main}
                sx={{ transform: "rotate(90deg)" }}
              />
            ) : (
              <TableRows color={theme.palette.alt.main} />
            )
          }
          sx={{
            border: "1px solid",
            borderColor: theme.palette.grey[300],
            color: theme.palette.alt.main,
            textTransform: "none",
            fontSize: "16px",
            ":hover": {
              color: theme.palette.alt[300],
            },
          }}
          onClick={() => setIsKanban(!isKanban)}
        >
          Arrangement
        </Button>
      </FlexBetween>
      {/* body */}
      {isLoading ? (
        <CustomCircularProgress sx={{ height: "80%" }} />
      ) : (
      
        <div className="personnel-table-container">
          {isKanban ? (
              <KanbanView sites={filtredSites ? filtredSites :sites} />
          ) : (
              <ListView sites={filtredSites ? filtredSites :sites} />
          )}
        </div>
          
        
      )}
    </Box>
  );
};

export default SitesList;
