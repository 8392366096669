import React from 'react';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import './Modal.css';

const ModalPersonnelDetailsPDF = ({ personnel }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.header}>
                        <Text style={styles.title}>{personnel.firstname} {personnel.lastName} | {personnel.NumEmpl}</Text>
                        <Text style={styles.text}>{personnel.phone}</Text>
                        <Text style={styles.text}>{personnel.adresse}</Text>
                    </View>
                    <View style={styles.separation}></View>
                    <View style={styles.body}>
                        <View style={styles.left}>
                            <View>
                                <Text style={styles.label}>Nationalité</Text>
                                <Text style={styles.text}>{personnel.nationalite}</Text>
                            </View>
                            <View style={styles.textContainer}>
                                <Text style={styles.label} className='detail-personnel-label'>Identité</Text>
                                <Text style={styles.text} className='detail-personnel-text'>{personnel.identite}</Text>
                            </View>
                            <View style={styles.textContainer}>
                                <Text style={styles.label} className='detail-personnel-label'>Pays de naissance</Text>
                                <Text style={styles.text} className='detail-personnel-text'>{personnel.PaysNaissance}</Text>
                            </View>
                            <View style={styles.textContainer}>
                                <Text style={styles.label} className='detail-personnel-label'>Ville de naissance</Text>
                                <Text style={styles.text} className='detail-personnel-text'>{personnel.villeNaissance}</Text>
                            </View>
                            <View style={styles.textContainer}>
                                <Text style={styles.label} className='detail-personnel-label'>Date de naissance</Text>
                                <Text style={styles.text} className='detail-personnel-text'>{personnel.dateNaissance.slice(0, 10)}</Text>
                            </View>
                            <View style={styles.textContainer}>
                                <Text style={styles.label} className='detail-personnel-label'>Âge</Text>
                                <Text style={styles.text} className='detail-personnel-text'>{personnel.age}</Text>
                            </View>
                        </View>
                        <View style={styles.right}>
                            <View style={styles.textContainer}>
                                <Text style={styles.label}>Département</Text>
                                <Text style={styles.text}>{personnel.departement}</Text>
                            </View>
                            <View style={styles.textContainer}>
                                <Text style={styles.label}>NSS</Text>
                                <Text style={styles.text}>{personnel.nss}</Text>
                            </View>
                            <View style={styles.textContainer}>
                                <Text style={styles.label}>Type de contrat</Text>
                                <Text style={styles.text}>{personnel.TypeContrat}</Text>
                            </View>
                            <View style={styles.textContainer}>
                                <Text style={styles.label}>Temps de travail</Text>
                                <Text style={styles.text}>{personnel.tempsTravail}</Text>
                            </View>
                            <View style={styles.textContainer}>
                                <Text style={styles.label}>Type de travail</Text>
                                <Text style={styles.text}>{personnel.TypeTravail}</Text>
                            </View>
                            <View style={styles.textContainer}>
                                <Text style={styles.label}>Date d’entrée</Text>
                                <Text style={styles.text}>{personnel.dateEntree.slice(0, 10)}</Text>
                            </View>
                            <View style={styles.textContainer}>
                                <Text style={styles.label}>Date de sortie</Text>
                                <Text style={styles.text}>{personnel?.dateSortie && personnel?.dateSortie.slice(0, 10)}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.separation}></View>
                    <View style={styles.footer}>
                        <View style={styles.footerTop} >
                            <View>
                                <Text style={styles.label}>Dossier</Text>
                                <Text style={styles.text}>{personnel.dossierComplet ? "Oui" : "Non"}</Text>
                            </View>
                            <View style={styles.separationVert}></View>
                            <View>
                                <Text style={styles.label}>DPAIE</Text>
                                <Text style={styles.text}>{personnel.dpai ? "Oui" : "Non"}</Text>
                            </View>
                            <View style={styles.separationVert}></View>
                            <View>
                                <Text style={styles.label}>Contrat</Text>
                                <Text style={styles.text}>{personnel.contrat ? "Oui" : "Non"}</Text>
                            </View>
                        </View>
                        <View style={styles.footerBottom}>
                            <Text style={styles.label}>Commentaire: {personnel?.commentaire} </Text>

                        </View>
                    </View>
                </View>
            </Page >
        </Document >
    );
};

const ModalPersonnelDetails = ({ isOpen, onClose, personnel }) => {
    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`} onClick={handleOverlayClick}>
            <div className="modal-content-personnel">
                <div className="container-personnel-details">
                    <div className="header-personnel-details">
                        <div>
                            <h4 className='detail-personnel-title mb-2'>{personnel.firstname} {personnel.lastName} | {personnel.NumEmpl}</h4>
                            <p className='detail-personnel-label'>{personnel.phone}</p>
                            <p className='detail-personnel-label'>{personnel.adresse}</p>
                        </div>
                        <div>
                            <PDFDownloadLink document={<ModalPersonnelDetailsPDF personnel={personnel} />} fileName="details_personnel.pdf">
                                {({ blob, url, loading, error }) =>
                                    loading ? 'Chargement...' : <button className='detail-personnel-export-btn'>Exporter</button>
                                }
                            </PDFDownloadLink>
                        </div>
                    </div>
                    <div className='separation-line'></div>
                    <div className="body-personnel-details">
                        <div className="left-body-personnel-details">
                            <div className="mt-1">
                                <p className='detail-personnel-label'>Nationalité</p>
                                <p className='detail-personnel-text'>{personnel.nationalite}</p>
                            </div>
                            <div className="mt-1">
                                <p className='detail-personnel-label'>Identité</p>
                                <p className='detail-personnel-text'>{personnel.identite}</p>
                            </div>
                            <div className="mt-1">
                                <p className='detail-personnel-label'>Pays de naissance</p>
                                <p className='detail-personnel-text'>{personnel.PaysNaissance}</p>
                            </div>
                            <div className="mt-1">
                                <p className='detail-personnel-label'>Ville de naissance</p>
                                <p className='detail-personnel-text'>{personnel.villeNaissance}</p>
                            </div>
                            <div className="mt-1">
                                <p className='detail-personnel-label'>Date de naissance</p>
                                <p className='detail-personnel-text'>{personnel.dateNaissance.slice(0, 10)}</p>
                            </div>
                            <div className="mt-1">
                                <p className='detail-personnel-label'>Âge</p>
                                <p className='detail-personnel-text'>{personnel.age}</p>
                            </div>
                        </div>
                        <div className="right-body-personnel-details">
                            <div className="mt-1">
                                <p className='detail-personnel-label'>Département</p>
                                <p className='detail-personnel-text'>{personnel.departement}</p>
                            </div>
                            <div className="mt-1">
                                <p className='detail-personnel-label'>NSS</p>
                                <p className='detail-personnel-text'>{personnel.nss}</p>
                            </div>
                            <div className="mt-1">
                                <p className='detail-personnel-label'>Type de contrat</p>
                                <p className='detail-personnel-text'>{personnel.TypeContrat}</p>
                            </div>
                            <div className="mt-1">
                                <p className='detail-personnel-label'>Temps de travail</p>
                                <p className='detail-personnel-text'>{personnel.tempsTravail}</p>
                            </div>
                            <div className="mt-1">
                                <p className='detail-personnel-label'>Type de travail</p>
                                <p className='detail-personnel-text'>{personnel.TypeTravail}</p>
                            </div>
                            <div className="mt-1">
                                <p className='detail-personnel-label'>Date d’entrée</p>
                                <p className='detail-personnel-text'>{personnel.dateEntree.slice(0, 10)}</p>
                            </div>
                            <div className="mt-1">
                                <p className='detail-personnel-label'>Date de sortie</p>
                                <p className='detail-personnel-text'>{personnel?.dateSortie && personnel?.dateSortie.slice(0, 10)}</p>
                            </div>
                        </div>
                    </div>
                    <div className='separation-line'></div>
                    <div className="footer-personnel-details">
                        <div className='footer-personnel-details-top'>
                            <div>
                                <p className='detail-personnel-label'>Dossier</p>
                                <p className='detail-personnel-text'>{personnel.dossierComplet ? "Oui" : "Non"}</p>
                            </div>
                            <div className='vertical-line-separation'></div>
                            <div>
                                <p className='detail-personnel-label'>DPAIE</p>
                                <p className='detail-personnel-text'>{personnel.dpai ? "Oui" : "Non"}</p>
                            </div>
                            <div className='vertical-line-separation'></div>
                            <div>
                                <p className='detail-personnel-label'>Contrat</p>
                                <p className='detail-personnel-text'>{personnel.contrat ? "Oui" : "Non"}</p>
                            </div>
                        </div>
                        <div className='footer-personnel-details-bottom'>
                            <p className='detail-personnel-label'>Commentaire: {personnel?.commentaire} </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#ffffff',
        padding: 20
    },
    section: {
        flexGrow: 1
    },
    header: {
        marginBottom: 10
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#131523',
        marginBottom: 5
    },
    label: {
        fontSize: 16,
        fontWeight: 400,
        color: '#131523',
        marginBottom: 5
    },
    text: {
        fontSize: 16,
        color: '#A1A7C4',
        fontWeight: 400,
        marginBottom: 5
    },
    body: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
        marginTop: 20,
    },
    left: {
        width: '45%'
    },
    right: {
        width: '45%'
    },
    separation: {
        height: 2,
        width: '100%',
        backgroundColor: '#E6E9F4',
        marginVertical: 5
    },
    textContainer: {
        marginTop: 5
    },
    footerTop: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        marginBottom: 5,
        marginTop:20
    },
    footerBottom : {
        marginTop:20
    },
    separationVert : {
        width: 2,
        height: 30,
        backgroundColor: "#13152380"
    }
});

export default ModalPersonnelDetails;
