import React from 'react';
import './Layout.css'
import MainSideBar from '../Navigations/MainSideBar';


const MainLayout = ({ children }) => {
  return (
    <div className="layout">
      <MainSideBar />
      <div className="content">{children}</div>
    </div>
  );
};

export default MainLayout;