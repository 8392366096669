import React, { useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import CustomField from "Components/CustomField";
import CustomDropDown from "Components/CustomDropDown";
import FlexBetween from "Components/FlexBetween";
import { formatDate } from "utils/format_date";
import { formatCurrency } from "utils/format_currency";
import { Field } from "formik";

const DescriptionTable = ({ setSuperFieldValue, superValues }) => {
  const theme = useTheme();
  const tableHeader = [
    "",
    "Description",
    "Unité",
    "Quantité",
    "Prix U.",
    "TVA %",
    "Total HT",
  ];
  const getTTCTotal = () => {
    let TTCTotal = 0;
    const currentTTC = superValues.ttc_total ?? 0;
    const discountAmount = superValues.discount_amount ?? 0;
    if (superValues.is_discount) {
      if (superValues.discount_type === "precentage") {
        TTCTotal = currentTTC - (currentTTC * discountAmount) / 100;
      } else {
        TTCTotal = currentTTC - discountAmount;
      }
    } else {
      TTCTotal = currentTTC;
    }
    return TTCTotal;
  };
  const getUnit = (unit) => {
    if (unit === "hour") {
      return "en heure";
    } else if (unit === "piece") {
      return "en pièce";
    } else if (unit === "day") {
      return "en jour";
    } else {
      return "";
    }
  };
  const getDiscountType = (type) => {
    if (type === "currency") {
      return "€";
    } else if (type === "precentage") {
      return "%";
    } else {
      return "";
    }
  };

  return (
    <Box
      sx={{
        bgcolor: theme.palette.primary.main,
        borderRadius: "6px",
        mt: "40px",
      }}
    >
      <Box
        sx={{
          bgcolor: theme.palette.primary.main,
          borderRadius: "6px",
        }}
      >
        <TableContainer
          sx={{
            bgcolor: theme.palette.primary.main,
            height: "230px",
            boxShadow: "none",
            pb: "10px",
          }}
          component={Paper}
        >
          <Table>
            <TableHead>
              <TableRow>
                {tableHeader.map((header) => (
                  <TableCell
                    align="center"
                    key={header}
                    sx={{
                      color: theme.palette.grey[600],
                      fontSize: "14px",
                      fontWeight: "400",
                      borderBottom: "2px solid",
                      borderBottomColor: theme.palette.grey.light,
                      p: "10px",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {superValues.descriptions?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                      pt: "0",
                      pb: "0",
                      width: "50px",
                    }}
                  >
                    <IconButton
                      sx={{ p: "0" }}
                      onClick={() => {
                        setSuperFieldValue(
                          "descriptions",
                          superValues.descriptions.filter(
                            (currentDescription) => currentDescription !== item
                          )
                        );
                      }}
                    >
                      <RemoveCircleOutline
                        sx={{ color: theme.palette.grey[600] }}
                      />
                    </IconButton>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderBottom: "1px solid",
                      borderBottomColor: theme.palette.grey[200],
                      p: "0",
                    }}
                  >
                    <CustomField
                      name={`descriptions[${index}].description`}
                      type="text"
                      value={superValues.descriptions[index].description}
                      titleStyle={{
                        display: "none",
                      }}
                      sx={{
                        height: "38px",
                        overflow: "clip",
                        m: "0",
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderBottom: "1px solid",
                      borderBottomColor: theme.palette.grey[200],
                      p: "0",
                    }}
                  >
                    <CustomDropDown
                      items={["hour", "piece", "day"]}
                      getItems={getUnit}
                      value={superValues.descriptions[index].unit}
                      name={`descriptions[${index}].unit`}
                      sx={{
                        m: "0",
                        height: "38px",
                        p: "0",
                        width: "100%",
                        overflow: "clip",
                        borderRadius: "0",
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderBottom: "1px solid",
                      borderBottomColor: theme.palette.grey[200],
                      p: "0",
                    }}
                  >
                    <Field
                      id={`descriptions[${index}].quantity`}
                      as={TextField}
                      autoComplete={"off"}
                      value={superValues.descriptions[index].quantity}
                      name={`descriptions[${index}].quantity`}
                      type={"number"}
                      sx={{
                        width: "100%",
                        backgroundColor: theme.palette.primary.main,
                        border: "1px solid",
                        borderColor: theme.palette.grey[200],
                        height: "40px",
                      }}
                      onChange={(event) => {
                        const newQuantity = event.target.value ?? 0;
                        const currentQuantity =
                          superValues.descriptions[index].quantity ?? 0;
                        const currentUnitPrice =
                          superValues.descriptions[index].unit_price ?? 0;
                        const currentTVA =
                          superValues.descriptions[index].tva ?? 0;
                        const oldHTTotal = currentQuantity * currentUnitPrice;
                        const newHTTotal = newQuantity * currentUnitPrice;
                        setSuperFieldValue(
                          `descriptions[${index}].quantity`,
                          newQuantity
                        );
                        setSuperFieldValue(
                          `descriptions[${index}].ht_total`,
                          newHTTotal
                        );
                        const TotalHT =
                          superValues.ht_total - oldHTTotal + newHTTotal;
                        const oldTVA = (oldHTTotal * currentTVA) / 100;
                        const newTVA = (newHTTotal * currentTVA) / 100;
                        const TVAAmount = superValues.tva_amount ?? 0;
                        const newTVAAmount = TVAAmount - oldTVA + newTVA;
                        setSuperFieldValue("ht_total", TotalHT);
                        setSuperFieldValue("tva_amount", newTVAAmount);
                        const TTCTotal = newHTTotal + newTVAAmount;
                        setSuperFieldValue("ttc_total", TTCTotal);
                      }}
                      InputProps={{
                        id: `descriptions[${index}].quantity`,
                        style: {
                          height: "37px",
                          color: theme.palette.grey[400],
                          fontSize: "14px",
                          borderBottom: "none",
                          border: "none",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: theme.palette.grey[400],
                          margin: "-4px",
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderBottom: "1px solid",
                      borderBottomColor: theme.palette.grey[200],
                      p: "0",
                    }}
                  >
                    <Field
                      id={`descriptions[${index}].unit_price`}
                      as={TextField}
                      autoComplete={"off"}
                      value={superValues.descriptions[index].unit_price}
                      name={`descriptions[${index}].unit_price`}
                      type={"number"}
                      sx={{
                        width: "100%",
                        backgroundColor: theme.palette.primary.main,
                        border: "1px solid",
                        borderColor: theme.palette.grey[200],
                        height: "40px",
                      }}
                      onChange={(event) => {
                        const newUnitPrice = event.target.value ?? 0;
                        const currentQuantity =
                          superValues.descriptions[index].quantity ?? 0;
                        const currentUnitPrice =
                          superValues.descriptions[index].unit_price ?? 0;
                        const currentTVA =
                          superValues.descriptions[index].tva ?? 0;
                        const oldHTTotal = currentQuantity * currentUnitPrice;
                        const newHTTotal = currentQuantity * newUnitPrice;
                        setSuperFieldValue(
                          `descriptions[${index}].unit_price`,
                          newUnitPrice
                        );
                        setSuperFieldValue(
                          `descriptions[${index}].ht_total`,
                          newHTTotal
                        );
                        const TotalHT =
                          superValues.ht_total - oldHTTotal + newHTTotal;
                        const oldTVA = (oldHTTotal * currentTVA) / 100;
                        const newTVA = (newHTTotal * currentTVA) / 100;
                        const TVAAmount = superValues.tva_amount ?? 0;
                        const newTVAAmount = TVAAmount - oldTVA + newTVA;
                        setSuperFieldValue("ht_total", TotalHT);
                        setSuperFieldValue("tva_amount", newTVAAmount);
                        const TTCTotal = newHTTotal + newTVAAmount;
                        setSuperFieldValue("ttc_total", TTCTotal);
                      }}
                      InputProps={{
                        id: `descriptions[${index}].unit_price`,
                        style: {
                          height: "37px",
                          color: theme.palette.grey[400],
                          fontSize: "14px",
                          borderBottom: "none",
                          border: "none",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: theme.palette.grey[400],
                          margin: "-4px",
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderBottom: "1px solid",
                      borderBottomColor: theme.palette.grey[200],
                      p: "0",
                    }}
                  >
                    <Field
                      id={`descriptions[${index}].tva`}
                      as={TextField}
                      autoComplete={"off"}
                      value={superValues.descriptions[index].tva}
                      name={`descriptions[${index}].tva`}
                      type={"number"}
                      sx={{
                        width: "100%",
                        backgroundColor: theme.palette.primary.main,
                        border: "1px solid",
                        borderColor: theme.palette.grey[200],
                        height: "40px",
                      }}
                      onChange={(event) => {
                        const newTVAValue = event.target.value ?? 0;
                        const currentTVA =
                          superValues.descriptions[index].tva ?? 0;
                        const HTTotal = superValues.ht_total ?? 0;
                        setSuperFieldValue(
                          `descriptions[${index}].tva`,
                          newTVAValue
                        );
                        const oldTVA = (HTTotal * currentTVA) / 100;
                        const newTVA = (HTTotal * newTVAValue) / 100;
                        const TVAAmount = superValues.tva_amount ?? 0;
                        const newTVAAmount = TVAAmount - oldTVA + newTVA;
                        setSuperFieldValue("tva_amount", newTVAAmount);
                        const TTCTotal = HTTotal + newTVAAmount;
                        setSuperFieldValue("ttc_total", TTCTotal);
                      }}
                      InputProps={{
                        id: `descriptions[${index}].tva`,
                        style: {
                          height: "37px",
                          color: theme.palette.grey[400],
                          fontSize: "14px",
                          borderBottom: "none",
                          border: "none",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: theme.palette.grey[400],
                          margin: "-4px",
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderBottom: "1px solid",
                      borderBottomColor: theme.palette.grey[200],
                      p: "0",
                      fontSize: "14px",
                      color: theme.palette.grey[400],
                      width: "100px",
                      maxWidth: "100px",
                      overflow: "clip",
                      textOverflow: "ellipsis",
                      pl: "5px",
                    }}
                  >
                    {superValues.descriptions[index].ht_total}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    width: "50px",
                    pt: "5px",
                    pb: "0",
                  }}
                >
                  <IconButton
                    sx={{ p: "0" }}
                    onClick={() => {
                      setSuperFieldValue("descriptions", [
                        ...superValues.descriptions,
                        {
                          description: "",
                          unit: "hour",
                          quantity: "",
                          unit_price: "",
                          tva: "",
                          ht_total: "",
                        },
                      ]);
                    }}
                  >
                    <AddCircleOutline sx={{ color: theme.palette.grey[600] }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "top",
              mr: "10px",
            }}
          >
            {superValues.category === "deposit" && (
              <Box
                sx={{
                  textAlign: "right",
                  display: "flex",
                  justifyContent: "right",
                  border: "1px solid",
                  borderColor: theme.palette.alt[900],
                  borderRadius: "6px",
                  borderRight: "none",
                }}
              >
                <Box
                  sx={{
                    width: "260px",
                    p: "10px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <FlexBetween>
                    <Typography
                      sx={{
                        color: theme.palette.grey[600],
                        fontSize: "14px",
                      }}
                    >
                      Acompte :
                    </Typography>
                    <Typography
                      sx={{
                        color: theme.palette.secondary[700],
                        fontSize: "14px",
                        overflow: "clip",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {`${formatCurrency(
                        superValues.deposit_amount,
                        superValues.currency
                      )}`}
                    </Typography>
                  </FlexBetween>
                  <FlexBetween>
                    <Typography
                      sx={{
                        color: theme.palette.grey[600],
                        fontSize: "14px",
                      }}
                    >
                      Dû le
                    </Typography>
                    <Typography
                      sx={{
                        color: theme.palette.secondary[700],
                        fontSize: "14px",
                      }}
                    >
                      {formatDate(superValues.due_to)}
                    </Typography>
                  </FlexBetween>
                  <FlexBetween>
                    <Typography
                      sx={{
                        color: theme.palette.grey[600],
                        fontSize: "14px",
                        textWrap: "nowrap",
                      }}
                    >
                      Total dû après acompte:
                    </Typography>
                    <Typography
                      sx={{
                        color: theme.palette.secondary[700],
                        fontSize: "14px",
                        fontWeight: "bold",
                        overflow: "clip",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {formatCurrency(
                        (getTTCTotal() ?? 0) -
                          (superValues.deposit_amount ?? 0),
                        superValues.currency
                      )}
                    </Typography>
                  </FlexBetween>
                </Box>
              </Box>
            )}
            <Box
              sx={{
                width: "260px",
                p: "10px",
                border: "1px solid",
                borderColor: theme.palette.alt[900],
                borderRadius: "6px",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FlexBetween>
                <Typography
                  sx={{
                    color: theme.palette.grey[600],
                    fontSize: "14px",
                  }}
                >
                  Total HT :
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.secondary[700],
                    fontSize: "14px",
                    overflow: "clip",
                    textOverflow: "ellipsis",
                  }}
                >
                  {`${formatCurrency(
                    superValues.ht_total ?? 0,
                    superValues.currency
                  )}`}
                </Typography>
              </FlexBetween>
              <FlexBetween>
                <Typography
                  sx={{
                    color: theme.palette.grey[600],
                    fontSize: "14px",
                  }}
                >
                  Montant TVA :
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.secondary[700],
                    fontSize: "14px",
                    overflow: "clip",
                    textOverflow: "ellipsis",
                  }}
                >
                  {`${formatCurrency(
                    superValues.tva_amount ?? 0,
                    superValues.currency
                  )}`}
                </Typography>
              </FlexBetween>
              {superValues.is_discount && (
                <FlexBetween>
                  <Typography
                    sx={{
                      color: theme.palette.grey[600],
                      fontSize: "14px",
                    }}
                  >
                    Remise :
                  </Typography>
                  <Typography
                    sx={{
                      color: theme.palette.secondary[700],
                      fontSize: "14px",
                      overflow: "clip",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {`${superValues.discount_amount ?? 0} ${getDiscountType(
                      superValues.discount_type
                    )}`}
                  </Typography>
                </FlexBetween>
              )}
              <FlexBetween>
                <Typography
                  sx={{
                    color: theme.palette.grey[600],
                    fontSize: "14px",
                  }}
                >
                  Total TTC :
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.secondary[700],
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {`${formatCurrency(
                    getTTCTotal() ?? 0,
                    superValues.currency
                  )}`}
                </Typography>
              </FlexBetween>
            </Box>
          </Box>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default DescriptionTable;
