import CustomCircularProgress from "Components/CustomCircularProgress";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { selectInvoices, setInvoices } from "state/invoice";
import InvoiceService from "Api/InvoiceService";
import InvoiceTable from "./InvoiceTable";
import InvoiceBanner from "./InvoiceBanner";
import {
  Box,
  Button,
  List,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import FlexBetween from "Components/FlexBetween";
import { AddOutlined, KeyboardArrowDown, Search } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";

const InvoicesList = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelecteDate] = useState(new Date());
  const [filterInvoices, setFilterInvoices] = useState(null);
  const [filterBy, setFilterBy] = useState("");
  const invoices = useSelector((state) => selectInvoices(state));
  const getFilterItem = (option) => {
    if (option === "number") {
      return "Numéro";
    } else if (option === "date") {
      return "Date";
    } else {
      return "";
    }
  };

  const filterOptions = ["", "number", "date"];

  useEffect(() => {
    const getInvoices = async () => {
      try {
        setIsLoading(true);
        const data = await InvoiceService.getClientInvoicesDetails(
          selectedDate
        );
        dispatch(setInvoices({ invoices: data }));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast(`${error}`);
      }
    };
    getInvoices();
  }, [selectedDate]);
  const handleChange = async (option) => {
    try {
      if (!option || !filterOptions.includes(option)) {
        setFilterBy("")
        setFilterInvoices(null);
        return;
      }
      setIsLoading(true);
      const currentInvoices = await InvoiceService.sortInvoices(option);
      setFilterInvoices(currentInvoices);
      setFilterBy(option)
      setIsLoading(false);
    } catch (error) {
      setFilterInvoices(null);
      setIsLoading(false);
      toast(`${error}`);;
    }
  };
  return (
    <Box className='content'>
      {/* Header */}
      <FlexBetween className="header-gest-pages">
        <Typography
          variant="h3"
          fontWeight="bold"
          color={theme.palette.secondary.light}
        >
          Gestion des factures
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "nowrap",
            gap: "10px",
            
          }}
        >
          {/* Search Bar */}
          <form>
            <TextField
              inputProps={{
                id: "filter",
              }}
              label="Tri par"
              select
              value={filterBy}
              onChange={(event) => handleChange(event.target.value)}
              SelectProps={{
                IconComponent: () => (
                  <KeyboardArrowDown
                    sx={{
                      color: theme.palette.grey.main,
                    }}
                  />
                ),
                style: {
                  color: theme.palette.grey[400],
                  height: "40px",
                },
              }}
              InputLabelProps={{
                htmlFor: "filter",
                style: {
                  fontSize: "16px",
                  color: theme.palette.grey[400],
                  marginTop: "-6px",
                },
              }}
              sx={{
                color: "black",
                border: "1px solid",
                borderColor: theme.palette.grey[50],
                backgroundColor: theme.palette.primary.main,
                borderRadius: "4px",
                width: "180px",
              }}
            >
              {filterOptions.map((item) => (
                <MenuItem
                  key={item}
                  sx={{
                    color: theme.palette.grey.main,
                    height: "30px",
                    ":hover": {
                      backgroundColor: theme.palette.grey.light,
                    },
                  }}
                  value={item}
                >
                  {getFilterItem(item)}
                </MenuItem>
              ))}
            </TextField>
          </form>
          <Button
            startIcon={<AddOutlined />}
            sx={{
              backgroundColor: theme.palette.alt.main,
              textTransform: "none",
              fontSize: "16px",
              fontWeight: "400",
              ":hover": {
                backgroundColor: theme.palette.alt[400],
              },
            }}
            onClick={() => navigate("add")}
          >
            Nouvelle Facture
          </Button>
        </Box>
      </FlexBetween>
      {/* body */}
      <Box
        sx={{
          overflow: "auto",
          height: "calc(100vh - 140px)",
          width: "95%",
          margin:'auto'
        }}
      >
        <List>
          <Box
            sx={{
              backgroundColor: theme.palette.grey[100],
              borderRadius: "6px",
              height: "100%",
              padding: "5px",
              mt: "20px",
              p: "20px",
            }}
          >
            {isLoading ? (
              <CustomCircularProgress />
            ) : (
              <>
                <InvoiceBanner
                  selectedDate={selectedDate}
                  setSelectedDate={setSelecteDate}
                  invoices={invoices}
                />
                <InvoiceTable
                  invoices={filterInvoices ? filterInvoices : invoices}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelecteDate}
                />
              </>
            )}
          </Box>
        </List>
      </Box>
    </Box>
  );
};

export default InvoicesList;
