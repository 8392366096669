import React, { useState } from 'react'
import "./Header.css"
import FichePaieModal from '../Modals/FichePaieModal'
import { useNavigate } from 'react-router-dom';
import FichePaiModalUpdate from '../Modals/FichePaiModalUpdate';
function HeadersWithbuttonsPaie({ title,btnTitle,btn2,data,update }) {
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate()
    const openModal = () => {
        setModalOpen(true);
    };
    const closeModal = () => {
        setModalOpen(false);
    };
    return (
        <div className="header-gest-pages">
              {modalOpen && (
                update ? <FichePaiModalUpdate isOpen={modalOpen} onClose={closeModal} data={data}/> :
            <FichePaieModal isOpen={modalOpen} onClose={closeModal} data={data}/>
              )}
            <div className="header-gest-pages-left">
                <div className="text-and-back">
                    <h3>  {title} </h3>
                </div>
            </div>
            <div className="header-gest-pages-right">
                {
                    btn2 && <button  className='LightBtn' onClick={()=>navigate(-1)}>{btn2}</button>
                }
               <button onClick={()=> openModal()
                    } className='DarkBtn' disabled={data && !data.personnel}>{btnTitle}</button>
            </div>
        </div>
    )
}

export default HeadersWithbuttonsPaie