import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AddEditFinancialsQuote from './AddEditFinancialsQuote'

const FinancialsQuotes = () => {
  return (
    <Routes>
      <Route path='/:quoteId' element={<AddEditFinancialsQuote />} />
      <Route path='/add' element={<AddEditFinancialsQuote />} />
    </Routes>
  )
}

export default FinancialsQuotes