import { useTheme } from "@emotion/react";
import {
  ArrowBackOutlined,
  BorderColorOutlined,
  CalendarTodayOutlined,
  DeleteOutline,
  Info,
} from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  List,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import CustomDialog from "Components/CustomDialog";
import FlexBetween from "Components/FlexBetween";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { deleteSite } from "state/site";
import TasksColumn from "./TasksColumn";
import { selectTasks, setTasks, updateTaskState } from "state/task";
import { DragDropContext } from "@hello-pangea/dnd";
import CustomCircularProgress from "Components/CustomCircularProgress";
import SiteService from "Api/SiteService";
import TaskService from "Api/TaskService";
import { setGlobalIsLoading } from "state/global";
import { toast } from "react-toastify";

const TasksList = () => {
  const { siteId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [site, setSite] = useState({});
  const [open, setOpen] = useState(false);
  const stateButtonRef = useRef(null);
  const [stateMenuOpen, setStateMenuOpen] = useState(false);
  const tasks = useSelector((state) => selectTasks(state));
  const [isLoading, setIsLoading] = useState(true);
  
  const handleClick = async () => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      await SiteService.deleteSite(site._id);
      dispatch(deleteSite({ id: site._id }));
      dispatch(setGlobalIsLoading({isLoading:false}));
      navigate("/sites");
    } catch (error) {
      dispatch(setGlobalIsLoading({isLoading:false}));
      toast(`${error}`);;
    }
  };

  useEffect(() => {
    const getSiteTasks = async () => {
      try {
        dispatch(setTasks({ tasks: [] }));
        const currentTasks = await TaskService.getTasksDetails(siteId);
        dispatch(setTasks({ tasks: currentTasks }));
        setIsLoading(false);
      } catch (error) {
        toast(`${error}`);;
      }
    };
    const getSiteData = async () => {
      try {
        setIsLoading(true);
        const currentSite = await SiteService.getSiteDetails(siteId);
        setSite(currentSite);
        await getSiteTasks();
      } catch (error) {
        toast(`${error}`);;
      }
    };
    getSiteData();
  }, []);

  const changeSiteState = async (state) => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      const updatedSite = await SiteService.updateSiteState(site._id, state);
      setSite(updatedSite);
      dispatch(setGlobalIsLoading({ isLoading: false }));
      setStateMenuOpen(false);
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);;
    }
  };

  const stateColor = () => {
    switch (site?.state) {
      case "inprogress": {
        return theme.palette.warning.main;
      }
      case "suspended": {
        return theme.palette.error.main;
      }
      case "done": {
        return theme.palette.success.main;
      }
      case "todo": {
        return theme.palette.info.main;
      }
      default: {
        return theme.palette.primary.main;
      }
    }
  };

  const onDragEnd = async (result) => {
    const { draggableId, destination, source } = result;
    let prevState;
    try {
      if (!destination) {
        return;
      }
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }
      const task = tasks.find((item) => item._id.toString() === draggableId);
      if (task) {
        prevState = task.state;
        const state = destination.droppableId;
        dispatch(updateTaskState({ taskId:draggableId,newState:state}));
        await TaskService.updateTaskState(draggableId,state)
      }
    } catch (error) {
      dispatch(updateTaskState({ taskId:draggableId,newState:prevState }));
      toast(`${error}`);;
    }
  };

  return (
    <>
      {isLoading ? (
        <CustomCircularProgress sx={{ height: "85vh" }} />
      ) : (
        <Box sx={{
          overflow:'auto',
          height:'90vh',
          width:'100%'
        }}>
        <List>
        <Box margin="20px">
          {/* Header */}
          <FlexBetween>
            <Box>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={theme.palette.secondary.light}
              >
                {site?.name}
              </Typography>
              <Button
                startIcon={
                  <ArrowBackOutlined
                    sx={{
                      color: theme.palette.grey["600"],
                      height: "15px",
                      width: "18px",
                    }}
                  />
                }
                sx={{
                  color: theme.palette.grey["600"],
                  textTransform: "none",
                  fontSize: "14px",
                }}
                onClick={() => navigate(-1)}
              >
                Retour
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
              }}
            >
              <Box>
                <Button
                  ref={stateButtonRef}
                  startIcon={<Info />}
                  sx={{
                    backgroundColor: stateColor(),
                    border: "1px solid",
                    borderRadius: "4px",
                    borderColor: theme.palette.grey.light,
                    textTransform: "none",
                    fontSize: "16px",
                    fontWeight: "500",
                    ":hover": {
                      backgroundColor: stateColor(),
                    },
                  }}
                  onClick={() => setStateMenuOpen(true)}
                >
                  État du Chantier
                </Button>
                <Menu
                  anchorReference="anchorEl"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  anchorEl={stateButtonRef.current}
                  open={stateMenuOpen}
                  onClose={() => setStateMenuOpen(false)}
                  MenuListProps={{
                    style: {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.grey[600],
                      border: "1px solid",
                      borderColor: theme.palette.primary.main,
                      borderRadius: "6px",
                      width: "125px",
                    },
                  }}
                >
                  <MenuItem
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      ":hover": {
                        backgroundColor: theme.palette.grey[50],
                      },
                      backgroundColor:
                        site?.state === "todo" && theme.palette.grey[50],
                    }}
                    onClick={() => changeSiteState("todo")}
                  >
                    À faire
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      ":hover": {
                        backgroundColor: theme.palette.grey[50],
                      },
                      backgroundColor:
                        site?.state === "inprogress" && theme.palette.grey[50],
                    }}
                    onClick={() => changeSiteState("inprogress")}
                  >
                    En cours
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      ":hover": {
                        backgroundColor: theme.palette.grey[50],
                      },
                      backgroundColor:
                        site?.state === "suspended" && theme.palette.grey[50],
                    }}
                    onClick={() => changeSiteState("suspended")}
                  >
                    Suspendu
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      ":hover": {
                        backgroundColor: theme.palette.grey[50],
                      },
                      backgroundColor:
                        site?.state === "done" && theme.palette.grey[50],
                    }}
                    onClick={() => changeSiteState("done")}
                  >
                    Terminé
                  </MenuItem>
                </Menu>
              </Box>
              <IconButton
                sx={{
                  border: "1px solid",
                  borderRadius: "4px",
                  borderColor: theme.palette.grey.light,
                }}
                onClick={() => {
                  navigate(`/sites/${siteId}/edit`);
                }}
              >
                <BorderColorOutlined sx={{ color: theme.palette.alt.main }} />
              </IconButton>
              <IconButton
                sx={{
                  border: "1px solid",
                  borderRadius: "4px",
                  borderColor: theme.palette.grey.light,
                }}
                onClick={() => setOpen(true)}
              >
                <DeleteOutline sx={{ color: theme.palette.alt.main }} />
              </IconButton>
            </Box>
          </FlexBetween>
          {/* Body */}
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  gap: "5px",
                  alignItems: "baseline",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    border: "1px solid",
                    borderRadius: "4px",
                    borderColor: theme.palette.error.main,
                    color: theme.palette.error.main,
                    p: "1px 5px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "10",
                      fontWeight: "bold",
                    }}
                  >
                    Urgente
                  </Typography>
                </Box>
                <Box
                  sx={{
                    border: "1px solid",
                    borderRadius: "4px",
                    borderColor: theme.palette.warning[600],
                    color: theme.palette.warning[600],
                    p: "1px 5px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "10",
                      fontWeight: "bold",
                    }}
                  >
                    importante
                  </Typography>
                </Box>
                <Box
                  sx={{
                    border: "1px solid",
                    borderRadius: "4px",
                    borderColor: theme.palette.success.main,
                    color: theme.palette.success.main,
                    p: "1px 5px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "10",
                      fontWeight: "bold",
                    }}
                  >
                    Régulière
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Button
                  startIcon={<CalendarTodayOutlined />}
                  sx={{
                    backgroundColor: theme.palette.alt.main,
                    border: "1px solid",
                    borderRadius: "4px",
                    borderColor: theme.palette.grey.light,
                    textTransform: "none",
                    fontSize: "16px",
                    fontWeight: "500",
                    ":hover": {
                      backgroundColor: theme.palette.alt.main,
                    },
                  }}
                  onClick={() => {
                    navigate("calendar");
                  }}
                >
                  Calendrier
                </Button>
                <Button
                  sx={{
                    backgroundColor: theme.palette.alt.main,
                    border: "1px solid",
                    borderRadius: "4px",
                    borderColor: theme.palette.grey.light,
                    textTransform: "none",
                    fontSize: "16px",
                    fontWeight: "500",
                    ":hover": {
                      backgroundColor: theme.palette.alt.main,
                    },
                  }}
                  onClick={() => {
                    navigate(`/sites/${siteId}`);
                  }}
                >
                  À propos Du chantier
                </Button>
              </Box>
            </Box>
            <DragDropContext onDragEnd={onDragEnd}>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  mt: "10px",
                }}
              >
                <TasksColumn
                  state="new"
                  tasks={tasks}
                  isFirst={true}
                  title="Nouveau"
                  color={theme.palette.info.main}
                  site={site}
                />
                <TasksColumn
                  state="inprogress"
                  tasks={tasks}
                  isFirst={false}
                  title="En Cours"
                  color={theme.palette.warning.main}
                  site={site}
                />
                <TasksColumn
                  state="done"
                  tasks={tasks}
                  isFirst={false}
                  title="Terminée"
                  color={theme.palette.success[400]}
                  site={site}
                />
                <TasksColumn
                  state="validated"
                  tasks={tasks}
                  isFirst={false}
                  title="Validée"
                  color={theme.palette.success.main}
                  site={site}
                />
              </Box>
            </DragDropContext>
          </Box>
          <CustomDialog
            title={`Supprimer chantier ${site?.name}`}
            content={`Vous êtes sûr de supprimer le chantier ${site?.name}?`}
            onClick={handleClick}
            open={open}
            setOpen={setOpen}
          />
        </Box>
        </List>
        </Box>
      )}
    </>
  );
};

export default TasksList;
