import PopUp from "Components/Popup";
import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableCell,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import FlexBetween from "Components/FlexBetween";
import avatar from "Images/details_logo.png";
import { formatDate } from "utils/format_date";
import ReactDOM from "react-dom";

const DetailsPopUp = ({
  open,
  setOpen,
  name,
  data,
  client,
  descriptions,
  actionButtons,
  isSupplier,
  values,
}) => {
  const theme = useTheme();
  const ref = useRef(null);
  const [distance, setDistance] = useState({
    left: null,
    right: null,
    top: null,
    bottom: null,
  });
  const tableHeader = [
    "Description",
    "Unité",
    "Quantité",
    "Prix U.",
    "TVA",
    "Total HT",
  ];
  const getUnit = (unit) => {
    if (unit === "hour") {
      return "en heure";
    } else if (unit === "piece") {
      return "en pièce";
    } else if (unit === "day") {
      return "en jour";
    } else {
      return "";
    }
  };
  useEffect(() => {
    const calculateDistance = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        const distanceFromLeft = rect.left;
        const distanceFromTop = rect.top;
        setDistance({
          left: distanceFromLeft,
          right: rect.right,
          top: distanceFromTop,
          bottom: rect.bottom,
        });
      }
    };

    calculateDistance();

    const handleResize = () => {
      calculateDistance();
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("animationend", handleResize);
    window.addEventListener("animationstart", handleResize);
  }, []);

  return ReactDOM.createPortal(
    <Box
      sx={{
        "@keyframes fadIn": {
          "0%": {
            transform: "translateX(250%)",
          },
          "100%": {
            transform: "translateX(0)",
          },
        },
        "@keyframes fadOut": {
          "0%": {
            transform: "translateX(250%)",
          },
          "100%": {
            transform: "translateX(0)",
          },
        },
      }}
    >
      <PopUp
        open={open}
        setOpen={setOpen}
        style={{
          backgroundColor: theme.palette.primary.main,
          animation: "fadIn 1s ease-in",
        }}
        sx={{
          mt: "30px",
          zIndex: "1",
        }}
      >
        <Box
          ref={ref}
          position="relative"
          sx={{
            borderRadius: "11px",
            height: "90vh",
            width: "35vw",
            p: "20px",
          }}
          className="details-page"
        >
          <FlexBetween>
            <Box
              sx={{
                flex: "1",
                display: "flex",
                gap: "15px",
              }}
            >
              <Box
                sx={{
                  height: "67px",
                  width: "67px",
                  borderRadius: "50%",
                  backgroundImage: `url(${avatar})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "100%",
                }}
              />
              <Box>
                <Typography
                  sx={{
                    color: theme.palette.secondary[700],
                    fontSize: "11px",
                    fontWeight: "bold",
                  }}
                >
                  Client {client.nom}
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.grey.main,
                    fontSize: "10px",
                  }}
                >
                  {client.adresse}
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.grey.main,
                    fontSize: "10px",
                  }}
                >
                  {client.phone}
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.grey.main,
                    fontSize: "10px",
                  }}
                >
                  {client.email}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                flex: "1",
                textAlign: "right",
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.secondary[700],
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                {name}
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.grey.main,
                  fontSize: "8px",
                }}
              >
                Date de création: {formatDate(values.createdAt)}
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.grey.main,
                  fontSize: "8px",
                }}
              >
                échéance: {formatDate(values.payment_date)}
              </Typography>
            </Box>
          </FlexBetween>
          <Box
            sx={{
              m: "50px 0",
            }}
          >
            <Typography
              sx={{
                color: theme.palette.secondary[700],
                fontSize: "11px",
                fontWeight: "bold",
              }}
            >
              {isSupplier
                ? `Fournisseur ${data.name}`
                : `Destinataire  ${data.name}`}
            </Typography>

            <Typography
              sx={{
                color: theme.palette.grey.main,
                fontSize: "10px",
              }}
            >
              {data.address}
            </Typography>
            <Typography
              sx={{
                color: theme.palette.grey.main,
                fontSize: "10px",
              }}
            >
              {data.phone}
            </Typography>
            <Typography
              sx={{
                color: theme.palette.grey.main,
                fontSize: "10px",
              }}
            >
              {data.email}
            </Typography>
          </Box>
          <TableContainer
            sx={{
              bgcolor: theme.palette.primary.main,
              height: "333px",
              boxShadow: "none",
            }}
            component={Paper}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {tableHeader.map((header) => (
                    <TableCell
                      align="center"
                      key={header}
                      sx={{
                        bgcolor: theme.palette.grey.light,
                        color: theme.palette.secondary[700],
                        fontSize: "10px",
                        borderBottom: "2px solid",
                        borderBottomColor: theme.palette.grey.light,
                        height: "24px",
                        p: "0",
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {descriptions?.map((item) => (
                  <TableRow key={Math.random() * 100}>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: "1px solid",
                        borderBottomColor: theme.palette.grey[200],
                        color: theme.palette.secondary[700],
                        fontSize: "14px",
                        fontWeight: "500",
                        height: "24px",
                        p: "0",
                      }}
                    >
                      {item.description}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: "1px solid",
                        borderBottomColor: theme.palette.grey[200],
                        borderLeft: "1px solid",
                        borderLeftColor: theme.palette.grey[200],
                        color: theme.palette.secondary[700],
                        fontSize: "14px",
                        fontWeight: "500",
                        width: "130px",
                        height: "24px",
                        p: "0",
                      }}
                    >
                      {getUnit(item.unit)}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: "1px solid",
                        borderBottomColor: theme.palette.grey[200],
                        color: theme.palette.secondary[700],
                        fontSize: "14px",
                        fontWeight: "500",
                        height: "24px",
                        p: "0",
                      }}
                    >
                      {item.quantity}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: "1px solid",
                        borderBottomColor: theme.palette.grey[200],
                        color: theme.palette.secondary[700],
                        fontSize: "14px",
                        fontWeight: "500",
                        height: "24px",
                        p: "0",
                      }}
                    >
                      {item.unit_price}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: "1px solid",
                        borderBottomColor: theme.palette.grey[200],
                        color: theme.palette.secondary[700],
                        fontSize: "14px",
                        fontWeight: "500",
                        height: "24px",
                        p: "0",
                      }}
                    >
                      {item.tva}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: "1px solid",
                        borderBottomColor: theme.palette.grey[200],
                        color: theme.palette.secondary[700],
                        fontSize: "14px",
                        fontWeight: "500",
                        height: "24px",
                        p: "0",
                      }}
                    >
                      {item.ht_total}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
              }}
            >
              <Box
                sx={{
                  textAlign: "right",
                  display: "flex",
                  justifyContent: "right",
                  width: "50%",
                }}
              >
                <Box
                  sx={{
                    height: "94px",
                    width: "260px",
                    p: "10px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    mb: "20px",
                  }}
                >
                  <FlexBetween>
                    <Typography
                      sx={{
                        color: theme.palette.grey[600],
                        fontSize: "14px",
                      }}
                    >
                      Total HT :
                    </Typography>
                    <Typography
                      sx={{
                        color: theme.palette.secondary[700],
                        fontSize: "14px",
                      }}
                    >
                      {values.ht_total}
                    </Typography>
                  </FlexBetween>
                  <FlexBetween>
                    <Typography
                      sx={{
                        color: theme.palette.grey[600],
                        fontSize: "14px",
                      }}
                    >
                      Montant TVA :
                    </Typography>
                    <Typography
                      sx={{
                        color: theme.palette.secondary[700],
                        fontSize: "14px",
                      }}
                    >
                      {values.tva_amount}
                    </Typography>
                  </FlexBetween>
                  <FlexBetween>
                    <Typography
                      sx={{
                        color: theme.palette.grey[600],
                        fontSize: "14px",
                      }}
                    >
                      Total TTC :
                    </Typography>
                    <Typography
                      sx={{
                        color: theme.palette.secondary[700],
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      {values.ttc_total}
                    </Typography>
                  </FlexBetween>
                </Box>
              </Box>
              {values.category == "deposit" && (
                <Box
                  sx={{
                    textAlign: "right",
                    display: "flex",
                    justifyContent: "right",
                    borderTop: "1px solid",
                    borderTopColor: theme.palette.grey[400],
                    width: "50%",
                  }}
                >
                  <Box
                    sx={{
                      height: "94px",
                      width: "260px",
                      p: "10px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      mb: "20px",
                    }}
                  >
                    <FlexBetween>
                      <Typography
                        sx={{
                          color: theme.palette.grey[600],
                          fontSize: "14px",
                        }}
                      >
                        Acompte :
                      </Typography>
                      <Typography
                        sx={{
                          color: theme.palette.secondary[700],
                          fontSize: "14px",
                        }}
                      >
                        {values.deposit_amount}
                      </Typography>
                    </FlexBetween>
                    <FlexBetween>
                      <Typography
                        sx={{
                          color: theme.palette.grey[600],
                          fontSize: "14px",
                        }}
                      >
                        Dû le
                      </Typography>
                      <Typography
                        sx={{
                          color: theme.palette.secondary[700],
                          fontSize: "14px",
                        }}
                      >
                        {formatDate(values.payment_date)}
                      </Typography>
                    </FlexBetween>
                    <FlexBetween>
                      <Typography
                        sx={{
                          color: theme.palette.grey[600],
                          fontSize: "14px",
                        }}
                      >
                        Total dû après acompte:
                      </Typography>
                      <Typography
                        sx={{
                          color: theme.palette.secondary[700],
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        {values.total_due_after_deposit}
                      </Typography>
                    </FlexBetween>
                  </Box>
                </Box>
              )}
            </Box>
          </TableContainer>
        </Box>
      </PopUp>
      {open && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            zIndex: 2,
            animation: "fadIn 1s ease-in",
            top: distance.top,
            right: window.innerWidth - distance.right - 260,
          }}
        >
          {actionButtons}
        </Box>
      )}
    </Box>,
    document.body
  );
};
export default DetailsPopUp;
