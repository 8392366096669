import React from 'react'
import './Modal.css';
import FicheDePai from '../Prepai/FicheDePai';
function FichePaieView({ isOpen, onClose, data }) {
    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };


    return (
        <div className={`modalPaie modal ${isOpen ? 'open' : ''}`} onClick={handleOverlayClick}>
            <div className="modal-content-fichePaie">
                <div style={{
                    width: "600px",
                    height: "800px"
                }}>
                     <FicheDePai data={data} />
                   
                </div>
            </div>

        </div>
    )
}

export default FichePaieView