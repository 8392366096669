import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import confirm from "Images/confirm.png";
import download from "Images/download.png";
import save from "Images/save.png";
import adjust from "Images/adjust.png";
import edit from "Images/edit.png";
import blank from "Images/blank.png";
import info from "Images/info.png";
import remove from "Images/remove.png";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";

const ActionButton = ({iconName,text,onClick,type,isEndIcon,subText,ref}) => {
  const theme = useTheme();
  const icons = {
    confirm,
    download,
    save,
    adjust,
    edit,
    blank,
    info,
    remove,
  };
  return (
    <Button
      ref={ref}
      sx={{
        width: "234px",
        height: "64px",
        bgcolor: theme.palette.grey.light,
        color: theme.palette.secondary[700],
        borderRadius: "8px",
        textTransform: "none",
        ":hover": {
          bgcolor: theme.palette.grey.light,
        },
        display: "flex",
        flexWrap: "nowrap",
        mb: "20px",
      }}
      onClick={onClick}
      type={type}
      endIcon={
        isEndIcon && <KeyboardArrowDownOutlined/>
      }
    >
      <Box
        sx={{
          height: "33.6px",
          width: "27.3px",
          backgroundImage: `url(${icons[iconName]})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "100%",
          ml: "20px",
        }}
      />
      <Box
        sx={{
          color: theme.palette.secondary[700],
          fontSize: "15px",
          fontWeight: "bold",
          flex: "2",
          textAlign: "left",
          ml: "20px",
        }}
      >
        {text}
        <Typography>{subText}</Typography>
      </Box>
    </Button>
  );
};

export default ActionButton;
