import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  workers: [],
};

const workerSlice = createSlice({
  name: "worker",
  initialState,
  reducers: {
    // worker events
    setWorkers: (state, action) => {
      const { workers } = action.payload;
      state.workers = workers;
    },
    updateWorker: (state, action) => {
      const { worker } = action.payload;
      for (const [key, value] of Object.entries(state.workers)) {
        if (value._id?.toString() === worker._id?.toString()) {
          state.workers[key] = worker;
          break;
        }
      }
    },
    addWorker: (state, action) => {
      const { worker } = action.payload;
      state.workers.push(worker);
    },
    deleteWorker: (state, action) => {
      const { id } = action.payload;
      state.workers = state.workers.filter(
        (worker) => worker._id?.toString() !== id?.toString()
      );
    },
  },
});

export const { setWorkers, updateWorker, addWorker, deleteWorker } =
  workerSlice.actions;

export default workerSlice.reducer;

export const selectWorkers = (state) => state.worker.workers;
