import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import React from "react";

const NotFoundError = ({text,sx}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        height:'100%',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...sx
      }}
    >
      <Typography
        variant="h3"
        sx={{
          color: theme.palette.secondary.main,
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default NotFoundError;
