import React, { useEffect, useState } from 'react'
import TopBar from '../Components/LandingPage/TopBar'
import NavBar from '../Components/LandingPage/NavBar'
import Header from '../Components/LandingPage/Header'
import Ascension from '../Components/LandingPage/Ascension'
import Services from '../Components/LandingPage/Services'
import ServicePartic from '../Components/LandingPage/ServicePartic'
import WhyChooseUs from '../Components/LandingPage/WhyChooseUs'
import Devis from '../Components/LandingPage/Devis'
import Contact from '../Components/LandingPage/Contact'
import Image from "../Images/Vector.svg"
import Footer from '../Components/LandingPage/Footer'
function LandingPage() {
  const [showToTop, setShowToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 100) {
        setShowToTop(true);
      } else {
        setShowToTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleToTopClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div style={{position:"relative"}}>
      <TopBar />
      <NavBar />
      <Header />
      <Ascension />
      <Services />
      <ServicePartic />
      <WhyChooseUs/>
      <Devis/>
      <Contact/>
      <Footer/>
      {showToTop && (
        <a className='toTop'  onClick={handleToTopClick}>
          <img src={Image} alt="icon" />
        </a>
      )}
    </div>
  )
}

export default LandingPage