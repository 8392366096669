import React from 'react'
import './Prepai.css'
function FicheDePai({data}) {
    return (
        <div className="fichePaiContainer">
            <div className="fichePaiEntete">
                <div className="fichePaiEntete-clientDetails">
                    <div className="fichePaiEntete-client-logo">
                        <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="20.5" cy="20.5" r="20" fill="#D7DBEC" stroke="#979797" />
                            <g opacity="0.48">
                                <path d="M22.1822 13.2702C21.2558 12.6583 18.8448 12.0591 16.7891 12.0591C11.4976 12.0591 7.69071 15.4121 7.23389 20.0655H9.20077V19.989C9.20077 15.0679 12.1574 12.518 15.9643 12.518C18.8195 12.518 21.1036 13.9842 22.0299 16.177L22.271 16.126L22.1822 13.2702ZM22.3979 25.0249C21.4462 27.5364 19.4539 28.8623 16.9033 28.8623C13.8832 28.8623 11.7006 27.2814 10.4443 25.0249H8.05871C9.42918 27.7914 12.3351 29.3468 16.2435 29.3468C18.9337 29.3468 21.2051 28.6711 22.3345 28.0464L22.7025 25.0376H22.3979V25.0249Z" fill="black" />
                                <path d="M32.9677 25.0249C33.0185 25.2416 33.0438 25.4583 33.0438 25.7006C33.0438 27.6512 31.3942 28.9006 29.4654 28.9006C26.4833 28.9006 24.7068 27.1922 23.7043 25.0121H23.1333L23.2221 27.6129C24.3515 28.4289 26.458 29.3213 28.9832 29.3213C31.648 29.3213 34.262 27.7022 34.5666 24.9994H32.9677V25.0249ZM33.9829 13.1682C33.0565 12.6583 31.4957 12.0591 29.3131 12.0591C25.7854 12.0591 23.4632 13.8439 23.4632 16.6615C23.4632 18.2679 24.1738 19.2623 25.4047 20.0655H30.4806C30.1506 19.9252 29.808 19.785 29.4654 19.6575C27.4097 18.8033 24.9479 17.8981 24.9479 15.6288C24.9479 13.5762 26.5976 12.4416 28.5517 12.4416C31.28 12.4416 32.9677 13.9332 33.856 16.0368L34.0971 15.9603L33.9829 13.1682Z" fill="black" />
                                <path d="M12.2466 21.4549C12.2466 21.1489 12.4877 20.9194 12.8176 20.9194C12.9445 20.9194 13.0841 20.9577 13.1349 20.9959V21.1617H13.1222C13.0714 21.0342 12.9318 20.9449 12.7669 20.9449C12.5384 20.9449 12.3608 21.0979 12.3608 21.3911C12.3608 21.6589 12.5258 21.9139 12.8176 21.9139C12.9699 21.9139 13.0968 21.8374 13.1475 21.6716H13.1602L13.1475 21.8756C13.0841 21.9139 12.9445 21.9521 12.7795 21.9521C12.4623 21.9521 12.2466 21.7736 12.2466 21.4549Z" fill="black" />
                                <path d="M14.4297 21.4423C14.4297 21.1491 14.6327 20.9324 14.9373 20.9324C15.1784 20.9324 15.4449 21.0981 15.4449 21.4551C15.4449 21.8121 15.1784 21.965 14.9373 21.965C14.6962 21.9523 14.4297 21.7993 14.4297 21.4423ZM15.0007 21.914C15.2291 21.914 15.3433 21.7483 15.3306 21.5316C15.318 21.2384 15.1403 20.9579 14.8738 20.9579C14.6581 20.9579 14.5312 21.1236 14.5439 21.3531C14.5566 21.6463 14.7469 21.914 15.0007 21.914Z" fill="black" />
                                <path d="M16.7885 21.0726V21.6973C16.7885 21.8631 16.8266 21.9013 16.89 21.914V21.9268H16.7124V21.914C16.7505 21.9013 16.7631 21.8631 16.7631 21.7483V21.1109C16.7631 20.9961 16.7505 20.9579 16.687 20.9451V20.9324H16.8266L17.4484 21.7483V21.1746C17.4484 21.0089 17.4103 20.9706 17.3469 20.9579V20.9451H17.5245V20.9579C17.4865 20.9706 17.4738 21.0089 17.4738 21.1236V21.9523H17.4611L16.7885 21.0726Z" fill="black" />
                                <path d="M18.7559 21.8503L18.7432 21.6463H18.7559C18.8066 21.7993 18.9208 21.9268 19.1239 21.9268C19.2381 21.9268 19.3396 21.8503 19.3396 21.7356C19.3396 21.5953 19.1619 21.5316 19.0477 21.4806C18.8827 21.4168 18.7685 21.3531 18.7685 21.2001C18.7685 21.0344 18.9081 20.9324 19.1112 20.9324C19.2381 20.9324 19.3396 20.9706 19.3903 20.9961V21.1619H19.3776C19.3269 21.0344 19.2254 20.9451 19.0604 20.9451C18.9462 20.9451 18.8447 21.0089 18.8447 21.1364C18.8447 21.2766 18.997 21.3276 19.1112 21.3786C19.2634 21.4423 19.4157 21.5061 19.4157 21.6718C19.4157 21.8503 19.2507 21.965 19.0858 21.965C18.9589 21.9523 18.832 21.9013 18.7559 21.8503Z" fill="black" />
                                <path d="M20.7099 21.5703V21.124C20.7099 21.0093 20.6972 20.9711 20.6592 20.9583V20.9456H20.8876V20.9583C20.8495 20.9711 20.8368 21.0093 20.8368 21.124V21.5703C20.8368 21.838 20.9891 21.9272 21.1668 21.9272C21.2937 21.9272 21.4586 21.8507 21.4586 21.5703V21.1878C21.4586 21.0221 21.4206 20.9838 21.3571 20.9711V20.9583H21.5348V20.9711C21.4967 20.9838 21.484 21.0221 21.484 21.1368V21.5703C21.484 21.8635 21.3317 21.9655 21.1287 21.9655C20.913 21.9527 20.7099 21.8635 20.7099 21.5703Z" fill="black" />
                                <path d="M22.7661 21.9272C22.8042 21.9145 22.8169 21.8762 22.8169 21.7615V21.124C22.8169 21.0093 22.8042 20.9711 22.7661 20.9583V20.9456H22.9945V20.9583C22.9565 20.9711 22.9438 21.0093 22.9438 21.124V21.8635C22.9438 21.9017 22.9691 21.9272 23.0072 21.9272H23.1722C23.3118 21.9272 23.3752 21.889 23.426 21.787H23.4387L23.426 21.9527H22.7661V21.9272Z" fill="black" />
                                <path d="M24.8734 21.9272C24.9115 21.9145 24.9242 21.8762 24.9242 21.7615V20.9583H24.848C24.7211 20.9583 24.6704 20.9966 24.6196 21.0985H24.6069L24.6196 20.9456H25.3556L25.3683 21.0985H25.3556C25.3049 20.9966 25.2541 20.9583 25.1272 20.9583H25.0638V21.7615C25.0638 21.8762 25.0764 21.9145 25.1145 21.9272V21.94H24.8734V21.9272Z" fill="black" />
                                <path d="M26.5728 21.9272C26.6108 21.9145 26.6235 21.8762 26.6235 21.7615V21.124C26.6235 21.0093 26.6108 20.9711 26.5728 20.9583V20.9456H26.8012V20.9583C26.7631 20.9711 26.7504 21.0093 26.7504 21.124V21.7615C26.7504 21.8762 26.7631 21.9145 26.8012 21.9272V21.94H26.5728V21.9272Z" fill="black" />
                                <path d="M28.1718 21.0726V21.6973C28.1718 21.8631 28.2099 21.9013 28.2733 21.914V21.9268H28.0957V21.914C28.1338 21.9013 28.1465 21.8631 28.1465 21.7483V21.1109C28.1465 20.9961 28.1338 20.9579 28.0703 20.9451V20.9324H28.2099L28.8317 21.7483V21.1746C28.8317 21.0089 28.7936 20.9706 28.7302 20.9579V20.9451H28.9078V20.9579C28.8698 20.9706 28.8571 21.0089 28.8571 21.1236V21.9523H28.8444L28.1718 21.0726Z" fill="black" />
                                <path d="M30.1504 21.4421C30.1504 21.1489 30.3661 20.9194 30.6707 20.9194C30.7976 20.9194 30.9371 20.9577 30.9879 20.9959V21.1617H30.9752C30.9245 21.0342 30.7976 20.9449 30.6199 20.9449C30.4169 20.9449 30.2646 21.0852 30.2519 21.3529C30.2519 21.6206 30.4169 21.9139 30.7087 21.9139C30.8102 21.9139 30.9118 21.8629 30.9498 21.7864V21.6461C30.9498 21.5569 30.9371 21.5186 30.8737 21.5059V21.4931H31.064V21.7609C30.9752 21.8756 30.8102 21.9521 30.6707 21.9521C30.3534 21.9521 30.1504 21.7481 30.1504 21.4421Z" fill="black" />
                                <path d="M15.2534 23.6733L15.2407 23.4693H15.2534C15.3042 23.6223 15.4184 23.7498 15.6214 23.7498C15.7356 23.7498 15.8371 23.6733 15.8371 23.5586C15.8371 23.4183 15.6595 23.3546 15.5453 23.3036C15.3803 23.2398 15.2661 23.1761 15.2661 23.0231C15.2661 22.8574 15.4057 22.7554 15.6087 22.7554C15.7356 22.7554 15.8371 22.7936 15.8879 22.8191V22.9849H15.8752C15.8244 22.8574 15.7229 22.7681 15.558 22.7681C15.4438 22.7681 15.3422 22.8319 15.3422 22.9594C15.3422 23.0996 15.4945 23.1506 15.6087 23.2016C15.761 23.2653 15.9133 23.3291 15.9133 23.4948C15.9133 23.6733 15.7483 23.788 15.5833 23.788C15.4438 23.7753 15.3169 23.7243 15.2534 23.6733Z" fill="black" />
                                <path d="M17.1567 23.7495C17.1948 23.7367 17.2075 23.6985 17.2075 23.5838V22.9463C17.2075 22.8316 17.1948 22.7933 17.1567 22.7806V22.7678H17.8166L17.8293 22.9591H17.8166C17.7658 22.8316 17.677 22.7933 17.5374 22.7933H17.3217V23.2268H17.4994C17.6643 23.2268 17.7024 23.1885 17.7151 23.1248H17.7278V23.3415H17.7151C17.7024 23.2778 17.6516 23.2395 17.4994 23.2395H17.3217V23.673C17.3217 23.7113 17.3471 23.7367 17.3852 23.7367H17.5755C17.7151 23.7367 17.8039 23.6985 17.8547 23.571H17.8674L17.8547 23.7622H17.1567V23.7495Z" fill="black" />
                                <path d="M19.6565 23.5455C19.5677 23.3543 19.5677 23.2523 19.39 23.2523H19.3012V23.5838C19.3012 23.6985 19.3139 23.7367 19.3519 23.7495V23.7622H19.1235V23.7495C19.1616 23.7367 19.1743 23.6985 19.1743 23.5838V22.9463C19.1743 22.8316 19.1616 22.7933 19.1235 22.7806V22.7678H19.4027C19.6946 22.7678 19.7834 22.8698 19.7834 22.9973C19.7834 23.1375 19.6819 23.2395 19.5042 23.2523C19.6819 23.2778 19.7073 23.367 19.7707 23.5328C19.8342 23.6985 19.8722 23.7495 19.9103 23.7495C19.923 23.7495 19.923 23.7495 19.9357 23.7495V23.7622C19.9103 23.775 19.8722 23.775 19.8468 23.775C19.7453 23.775 19.7199 23.6985 19.6565 23.5455ZM19.4154 23.2268C19.5804 23.2268 19.6565 23.1375 19.6565 23.0228C19.6565 22.8953 19.5677 22.7933 19.3646 22.7933C19.3393 22.7933 19.3266 22.7933 19.3012 22.7933V23.2268H19.4154Z" fill="black" />
                                <path d="M21.0405 22.7806V22.7678H21.2689V22.7806C21.2309 22.7933 21.2309 22.8188 21.2816 22.9336L21.5608 23.6475L21.8273 22.9463C21.8653 22.8571 21.8527 22.8061 21.8146 22.7933V22.7806H21.9796V22.7933C21.9415 22.8061 21.9034 22.8316 21.8653 22.9463L21.5481 23.7877H21.51L21.1801 22.9463C21.104 22.8188 21.0786 22.7933 21.0405 22.7806Z" fill="black" />
                                <path d="M23.1597 23.7495C23.1977 23.7367 23.2104 23.6985 23.2104 23.5838V22.9463C23.2104 22.8316 23.1977 22.7933 23.1597 22.7806V22.7678H23.3881V22.7806C23.35 22.7933 23.3373 22.8316 23.3373 22.9463V23.5838C23.3373 23.6985 23.35 23.7367 23.3881 23.7495V23.7622H23.1597V23.7495Z" fill="black" />
                                <path d="M24.6689 23.2779C24.6689 22.9719 24.91 22.7424 25.24 22.7424C25.3669 22.7424 25.5065 22.7807 25.5572 22.8189V22.9847H25.5445C25.4938 22.8572 25.3542 22.7679 25.1892 22.7679C24.9608 22.7679 24.7832 22.9209 24.7832 23.2141C24.7832 23.4819 24.9481 23.7369 25.24 23.7369C25.3923 23.7369 25.5191 23.6604 25.5699 23.4946H25.5826L25.5572 23.6859C25.4938 23.7241 25.3542 23.7624 25.1892 23.7624C24.8847 23.7751 24.6689 23.5966 24.6689 23.2779Z" fill="black" />
                                <path d="M26.8521 23.7495C26.8901 23.7367 26.9028 23.6985 26.9028 23.5838V22.9463C26.9028 22.8316 26.8901 22.7933 26.8521 22.7806V22.7678H27.5119L27.5246 22.9591H27.5119C27.4611 22.8316 27.3723 22.7933 27.2327 22.7933H27.0297V23.2268H27.2074C27.3723 23.2268 27.4104 23.1885 27.4231 23.1248H27.4358V23.3415H27.4231C27.4104 23.2778 27.3596 23.2395 27.2074 23.2395H27.0297V23.673C27.0297 23.7113 27.0551 23.7367 27.0932 23.7367H27.2835C27.4231 23.7367 27.5119 23.6985 27.5627 23.571H27.5754L27.5627 23.7622H26.8647V23.7495H26.8521Z" fill="black" />
                                <path d="M28.8057 23.6733L28.793 23.4693H28.8057C28.8564 23.6223 28.9706 23.7498 29.1737 23.7498C29.2879 23.7498 29.3894 23.6733 29.3894 23.5586C29.3894 23.4183 29.2117 23.3546 29.0975 23.3036C28.9326 23.2398 28.8183 23.1761 28.8183 23.0231C28.8183 22.8574 28.9579 22.7554 29.161 22.7554C29.2879 22.7554 29.3894 22.7936 29.4401 22.8191V22.9849H29.4274C29.3767 22.8574 29.2752 22.7681 29.1102 22.7681C28.996 22.7681 28.8945 22.8319 28.8945 22.9594C28.8945 23.0996 29.0468 23.1506 29.161 23.2016C29.3132 23.2653 29.4655 23.3291 29.4655 23.4948C29.4655 23.6733 29.3006 23.788 29.1356 23.788C28.996 23.7753 28.8818 23.7243 28.8057 23.6733Z" fill="black" />
                            </g>
                        </svg>
                    </div>
                    <div className="fichePaiEntete-client-Info">
                        <h5>{data?.personnel && data?.personnel.client.nom} {data?.personnel && data?.personnel.client.formeJuridique}</h5>
                        <p>{data?.personnel && data?.personnel.client.adresse}</p>
                        <p>{data?.personnel && data?.personnel.client.phone}</p>
                    </div>
                </div>
                <div className="fichePaiEntete-date">
                    <h4>PREPAIE {
                        data &&  data?.creationDate.slice(5,7) === "01" ? "Janvier" : data?.creationDate.slice(5,7) === "02" ? "Février" : data?.creationDate.slice(5,7) === "03" ? "Mars" : data?.creationDate.slice(5,7) === "04" ? "Avril" : data?.creationDate.slice(5,7) === "05" ? "Mai" : data?.creationDate.slice(5,7) === "06" ? "Juin" : data?.creationDate.slice(5,7) === "07" ? "Juillet" : data?.creationDate.slice(5,7) === "08" ? "Août" : data?.creationDate.slice(5,7) === "09" ? "Septembre" : data?.creationDate.slice(5,7) === "10" ? "Octobre" : data?.creationDate.slice(5,7) === "11" ? "Novembre" : "Décembre"} 2024 </h4>
                    <p>Date de création: {data && data?.creationDate.slice(0,10)}</p>
                    
                       
                </div>
            </div>
            <div>
                <div className="fichePaiPersonnelInfo">
                    <h4>{data?.personnel && data?.personnel.firstname} {data?.personnel && data?.personnel.lastName}</h4>
                    <h5>{data?.personnel && data?.personnel.NumEmpl}</h5>
                </div>
                <div className="fichePaiTable">
                    <div className="fichePaiTableLeft">
                        <p className='fichePaiTableLabel'>Adresse:</p>
                        <p className='fichePaiTableText'>{data?.personnel && data?.personnel.adresse}</p>
                    </div>
                    <div className="fichePaiTableRight">
                        <div>
                            <p className='fichePaiTableLabel'>Période de contrat:</p>
                            <p className='fichePaiTableText'>{data?.personnel && data?.personnel.dateEntree.slice(0,10)} au {data?.personnel && data?.personnel.dateSortie && data?.personnel.dateSortie.slice(0,10)}</p>
                        </div>
                        <div>
                            <p className='fichePaiTableLabel'>Heures de contrat:</p>
                            <p className='fichePaiTableText'>{data &&data?.heuresContrat}</p>
                        </div>
                        <div>
                            <p className='fichePaiTableLabel'>Type de contrat:</p>
                            <p className='fichePaiTableText'>{data?.personnel && data?.personnel.TypeContrat} {data?.personnel && data?.personnel.tempsTravail}</p>
                        </div>
                        <div>
                            <p className='fichePaiTableLabel d-flex flex-column'>Type de travail: <span>(Qalification)</span></p>
                            <p className='fichePaiTableText'>{data?.personnel && data?.personnel.TypeTravail}</p>
                        </div>
                        <div>
                            <p className='fichePaiTableLabel'>Heures supplémentaires en mois entier: </p>
                            <p className='fichePaiTableText'>{data && data?.heuresSupplementaires}</p>
                        </div>
                    </div>


                </div>
            </div>
            <div className="fichePaiTableContainer">
                <table>
                    <thead>
                        <tr>
                            <th colSpan="2">Composants</th>
                            <th >Commentaires</th>
                            <th>Qte</th>
                            <th>Taux</th>
                            <th>Montant</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                            <td>HTJ</td>
                            <td>Heures de travail de jour</td>
                            <td>{data?.HTJ.commentaires && data?.HTJ.commentaires}</td>
                            <td className='numberPaiTable'>{data?.HTJ.qte && data?.HTJ.qte}</td>
                            <td className='numberPaiTable'>{data?.HTJ.taux && data?.HTJ.taux}</td>
                            <td className='numberPaiTable'>{data?.HTJ.montant && data?.HTJ.montant} €</td>
                        </tr>
                        <tr>
                            <td>HTN</td>
                            <td>Heures de travail de nuit</td>
                            <td>{data?.HTN.commentaires && data?.HTN.commentaires}</td>
                            <td className='numberPaiTable'>{data?.HTN.qte && data?.HTN.qte}</td>
                            <td className='numberPaiTable'>{data?.HTN.taux && data?.HTN.taux}</td>
                            <td className='numberPaiTable'>{data?.HTN.montant && data?.HTN.montant} €</td>
                        </tr>
                        <tr>
                            <td>HTD</td>
                            <td>Heures de travail Dimanche</td>
                            <td>{data?.HTD.commentaires && data?.HTD.commentaires}</td>
                            <td className='numberPaiTable'>{data?.HTD.qte && data?.HTD.qte}</td>
                            <td className='numberPaiTable'>{data?.HTD.taux && data?.HTD.taux}</td>
                            <td className='numberPaiTable'>{data?.HTD.montant && data?.HTD.montant} €</td>
                        </tr>
                        <tr>
                            <td>HCP</td>
                            <td>Heures congés payés</td>
                            <td>{data?.HCP.commentaires && data?.HCP.commentaires}</td>
                            <td className='numberPaiTable'>{data?.HCP.qte && data?.HCP.qte}</td>
                            <td className='numberPaiTable'>{data?.HCP.taux && data?.HCP.taux}</td>
                            <td className='numberPaiTable'>{data?.HCP.montant && data?.HCP.montant} €</td>
                        </tr>
                        <tr>
                            <td>HF</td>
                            <td>Heures fériées</td>
                            <td>{data?.HF.commentaires && data?.HF.commentaires}</td>
                            <td className='numberPaiTable'>{data?.HF.qte && data?.HF.qte}</td>
                            <td className='numberPaiTable'>{data?.HF.taux && data?.HF.taux}</td>
                            <td className='numberPaiTable'>{data?.HF.montant && data?.HF.montant} €</td>
                        </tr>
                        <tr>
                            <td>RCN</td>
                            <td>Repos comp acquis travail nuit</td>
                            <td>{data?.RCN.commentaires && data?.RCN.commentaires}</td>
                            <td className='numberPaiTable'>{data?.RCN.qte && data?.RCN.qte}</td>
                            <td className='numberPaiTable'>{data?.RCN.taux && data?.RCN.taux}</td>
                            <td className='numberPaiTable'>{data?.RCN.montant && data?.RCN.montant} €</td>
                        </tr>
                        <tr>
                            <td>PANJ</td>
                            <td>Prime panier</td>
                            <td>{data?.PANJ.commentaires && data?.PANJ.commentaires}</td>
                            <td className='numberPaiTable'>{data?.PANJ.qte && data?.PANJ.qte}</td>
                            <td className='numberPaiTable'>{data?.PANJ.taux && data?.PANJ.taux}</td>
                            <td className='numberPaiTable'>{data?.PANJ.montant && data?.PANJ.montant} €</td>
                        </tr>
                        <tr>
                            <td>PEN1</td>
                            <td>Pénibilité Travail nuit</td>
                            <td>{data?.PEN1.commentaires && data?.PEN1.commentaires}</td>
                            <td className='numberPaiTable'>{data?.PEN1.qte && data?.PEN1.qte}</td>
                            <td className='numberPaiTable'>{data?.PEN1.taux && data?.PEN1.taux}</td>
                            <td className='numberPaiTable'>{data?.PEN1.montant && data?.PEN1.montant} €</td>
                        </tr>
                        <tr>
                            <td>PEN2</td>
                            <td>Pénibilité Équipe Alternante</td>
                            <td>{data?.PEN2.commentaires && data?.PEN2.commentaires}</td>
                            <td className='numberPaiTable'>{data?.PEN2.qte && data?.PEN2.qte}</td>
                            <td className='numberPaiTable'>{data?.PEN2.taux && data?.PEN2.taux}</td>
                            <td className='numberPaiTable'>{data?.PEN2.montant && data?.PEN2.montant} €</td>
                        </tr>
                        <tr>
                            <td>ACOMPT</td>
                            <td>Acompte</td>
                            <td>{data?.ACOMPT.commentaires && data?.ACOMPT.commentaires}</td>
                            <td className='numberPaiTable'>{data?.ACOMPT.qte && data?.ACOMPT.qte}</td>
                            <td className='numberPaiTable'>{data?.ACOMPT.taux && data?.ACOMPT.taux}</td>
                            <td className='numberPaiTable'>{data?.ACOMPT.montant && data?.ACOMPT.montant} €</td>
                        </tr>
                    </tbody>
                </table>


            </div>
            <div className="fichePaiTimeWork">
                <div className="fichePaiTimeWorkLeft">
                    <h3>Ventilation des heures</h3>
                    <div>
                        <p>Heures travaillées productives</p>
                        <p>{data?.heuresContrat} €</p>
                    </div>
                    <div>
                        <p>Heures congés payés</p>
                        <p>{data?.HCP.montant} €</p>
                    </div>
                    <div>
                        <p>Heures hors production</p>
                        <p>00 €</p>
                    </div>
                    <div>
                        <p>Heures totales planifiées</p>
                        <p>00 €</p>
                    </div>
                </div>
                <div className="fichePaiTimeWorkRight">
                    <h3>Totaux des heures</h3>
                    <div>
                        <p>Total d'heures jour</p>
                        <p>{data?.HTJ.montant} €</p>
                    </div>
                    <div>
                        <p>Total d'heure nuit</p>
                        <p>{data?.HTN.montant} €</p>
                    </div>
                    <div>
                        <p>Dont Heures férié</p>
                        <p>{data?.HF.montant} €</p>
                    </div>
                    <div>
                        <p>Dont heure dimanche</p>
                        <p>{data?.HTD.montant} €</p>
                    </div>

                </div>
            </div>
            <div className="fichePaiTimeWork">
                <div className="fichePaiComment">
                    <h3>Commentaires</h3>
                    <p>Votre commentaire ici</p>
                </div>
            </div>
        </div >
    )
}

export default FicheDePai