import refreshToken from "../../helpers/functions";

export const getAllClient = async () => {
    let http = await refreshToken();
    let result = await http.get(`client`);
    return result.data;
}

export const getclientById = async (idClient) => {
    let http = await refreshToken();
    let result = await http.get(`client/${idClient}`);
    return result.data;
}

export const addClient = async (data) => {
    let http = await refreshToken();
    const formData = new FormData();
    formData.set("data", JSON.stringify(data));
    let result = await http.post(`client/`, data, {
        headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
        }
    });
    return result.data;
}

export const updateClient = async (idClient, data) => {
    let http = await refreshToken();
    let result = await http.put(`client/${idClient}`, data );
    return result.data;
}

export const updateClientLogo = async (idClient, data) => {
    let http = await refreshToken();
    let result = await http.put(`client/update-logo/${idClient}`, data , {
        headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
        }
    });
    return result.data;
}

export const deleteClient = async (idClient) => {
    let http = await refreshToken();
    let result = await http.delete(`client/${idClient}`);
    return result.data;
}