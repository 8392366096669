import React, { useEffect, useState } from "react";
import "./Personnel.css";
import {
  LeftOutlined,
  RightOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
} from "@ant-design/icons";
import {
  addPersonnelFromCSV,
  deletePersonnels,
  getAllPersonnelsByClient,
} from "../../redux/actions/PersonnelAction";
import { useDispatch, useSelector } from "react-redux";
import ModalDeleteGuide from "../Modals/DeleteModal";
// import ModalPersonnelDetails from '../Modals/ModalPersonnelDetails';
import ModalPersonnelDetailsPDF from "../Modals/ModalPersonnelPdf";
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";
import NotFoundError from "Components/NotFoundError";

function Exemple() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [employees, setEmployees] = useState([]);
  const personnell = useSelector((state) => state.personnel.personnels);
  const personnellCopie = useSelector(
    (state) => state.personnel.personnelsCopie
  );
  const pageSize = 2;
  useEffect(() => {
    const idClient = localStorage.getItem("idClient");
    dispatch(getAllPersonnelsByClient(idClient))
      .then((data) => {
        setEmployees(data);
        setCurrentPage(1);
      })
      .catch((error) =>
        console.log(
          "Erreur lors de la récupération des données personnelles :",
          error
        )
      );
  }, [dispatch]);

  const handleSearchChange = (e) => {
    const newQuery = e.target.value;
    setSearchQuery(newQuery);
    const newEmployees = personnellCopie.filter(
      (item) =>
        item.firstname.toLowerCase().includes(newQuery.toLowerCase()) ||
        item.lastName.toLowerCase().includes(newQuery.toLowerCase()) ||
        item.departement.toLowerCase().includes(newQuery.toLowerCase()) ||
        item.TypeContrat.toLowerCase().includes(newQuery.toLowerCase())
    );
    setEmployees(newEmployees);
    setCurrentPage(1);
  };

  const handleSortChange = (e) => {
    const newSelectedOption = e.target.value;
    setSelectedOption(newSelectedOption);
    let sortedData = [...personnellCopie];

    // Effectuer le tri sur les données filtrées si elles existent, sinon sur les données originales
    const dataToSort = employees.length > 0 ? employees : sortedData;

    if (newSelectedOption === "firstname") {
      dataToSort.sort((a, b) => a.firstname.localeCompare(b.firstname));
    } else if (newSelectedOption === "departement") {
      dataToSort.sort((a, b) => a.departement.localeCompare(b.departement));
    } else if (newSelectedOption === "TypeContrat") {
      dataToSort.sort((a, b) => a.TypeContrat.localeCompare(b.TypeContrat));
    } else if (newSelectedOption === "entryDate") {
      dataToSort.sort((a, b) => a.dateEntree.localeCompare(b.dateEntree));
    } else if (newSelectedOption === "lastName") {
      dataToSort.sort((a, b) => a.lastName.localeCompare(b.lastName));
    }

    // Mettre à jour les données triées
    setEmployees(dataToSort);
    setCurrentPage(1);
  };
  const totalPages = Math.ceil(employees.length / pageSize);

  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;

  const currentEmployees = employees.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPagination = () => {
    const paginationButtons = [];
    paginationButtons.push(
      <button
        key="prev"
        className="prev-next-pagination-btn"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <LeftOutlined />
      </button>
    );

    for (let i = 1; i <= totalPages; i++) {
      paginationButtons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={currentPage === i ? "active" : "btn-paginationNumber"}
        >
          {i}
        </button>
      );
    }

    paginationButtons.push(
      <button
        className="prev-next-pagination-btn"
        key="next"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <RightOutlined />
      </button>
    );

    paginationButtons.unshift(
      <button
        key="first"
        className="prev-next-pagination-btn"
        onClick={() => handlePageChange(1)}
        disabled={currentPage === 1}
      >
        <DoubleLeftOutlined />
      </button>
    );

    paginationButtons.push(
      <button
        key="last"
        className="prev-next-pagination-btn"
        onClick={() => handlePageChange(totalPages)}
        disabled={currentPage === totalPages}
      >
        <DoubleRightOutlined />
      </button>
    );

    return paginationButtons;
  };

  //  delete Personnel
  const [modalOpenDelete, setModalOpenDelete] = useState(false);
  const [personnelToDelete, setPersonnelToDelete] = useState(null);
  const openModalDelete = () => {
    setModalOpenDelete(true);
  };
  const closeModalDelete = () => {
    setModalOpenDelete(false);
  };
  const handleDelete = (id) => {
    dispatch(deletePersonnels(id))
      .then((result) => {
        if (result) {
          setEmployees(personnell.filter((emp) => emp._id !== id));
          setCurrentPage(1);
        }
      })
      .catch((error) => {
        console.log("Error :", error);
      });
  };
  const testDelete = (id) => {
    openModalDelete();
    setPersonnelToDelete(id);
  };

  const handelCSVAdd = async (event) => {
    try {
      const csvFile = event.target.files[0];
      Papa.parse(csvFile, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => dispatch(addPersonnelFromCSV(result.data)),
      });
      event.target.value = "";
    } catch (error) {
      console.log(error);
    }
  };

  // details personnel
  const [modalOpenDetails, setModalOpenDetails] = useState(false);
  const [selectedPersonnel, setSelectedPersonnel] = useState(false);
  const openModalDetails = (pesonnel) => {
    setModalOpenDetails(true);
    setSelectedPersonnel(pesonnel);
  };
  const closeModalDetails = () => {
    setModalOpenDetails(false);
  };
  return (
    <div className="container-fluid personnel-table-container">
      {modalOpenDelete && (
        <ModalDeleteGuide
          isOpen={modalOpenDelete}
          onClose={closeModalDelete}
          handleAction={() => handleDelete(personnelToDelete)}
          title="Supprimer le personnel"
          message="Voulez-vous vraiment supprimer le personnel ?"
        />
      )}
      {modalOpenDetails && (
        <ModalPersonnelDetailsPDF
          isOpen={modalOpenDetails}
          onClose={closeModalDetails}
          personnel={selectedPersonnel}
        />
      )}
      <div className="personnel-header-container">
        <div className="triAndSearch">
          <select
            className="sort-select"
            value={selectedOption}
            onChange={handleSortChange}
          >
            <option value="none">Trier par</option>
            <option value="firstname">Prénom</option>
            <option value="lastName">Nom</option>
            <option value="departement">Département</option>
            <option value="TypeContrat">Contrat</option>
            <option value="dateEntree">Date d'entrée</option>
          </select>
          <input
            className="search-container-personnel"
            type="search"
            placeholder="Recherche..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <div className="actionsContainer">
          <div className="LightBtn">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 0C7.55228 0 8 0.447715 8 1V5.999L13 6C13.5523 6 14 6.44772 14 7C14 7.55228 13.5523 8 13 8L8 7.999V13C8 13.5523 7.55228 14 7 14C6.44772 14 6 13.5523 6 13V7.999L1 8C0.447715 8 0 7.55228 0 7C0 6.44772 0.447715 6 1 6L6 5.999V1C6 0.447715 6.44772 0 7 0Z"
                fill="#1E5EFF"
              />
            </svg>
            <label htmlFor="file-upload" style={{ cursor: "pointer" }}>
              Par CSV
            </label>
            <input
              type="file"
              name="file"
              accept=".csv"
              onChange={(event) => handelCSVAdd(event)}
              id="file-upload"
              style={{
                display: "none",
              }}
            />
          </div>
          <button onClick={() => navigate("/addPersonnel")} className="DarkBtn">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 0C7.55228 0 8 0.447715 8 1V5.999L13 6C13.5523 6 14 6.44772 14 7C14 7.55228 13.5523 8 13 8L8 7.999V13C8 13.5523 7.55228 14 7 14C6.44772 14 6 13.5523 6 13V7.999L1 8C0.447715 8 0 7.55228 0 7C0 6.44772 0.447715 6 1 6L6 5.999V1C6 0.447715 6.44772 0 7 0Z"
                fill="white"
              />
            </svg>{" "}
            Ajouter personnel
          </button>
        </div>
      </div>
      <div className="personnel-table-content">
        <table className="table-personnel">
          <thead>
            <tr>
              <th>Employé</th>
              <th>Département</th>
              <th>Contrat</th>
              <th>Date d'entrée</th>
              <th>Date de sortie</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {currentEmployees.length > 0 ? (
              <>
                {currentEmployees.map((emp, index) => (
                  <tr key={index}>
                    <td>
                      {emp.firstname} {emp.lastName}
                    </td>
                    <td>{emp?.departement}</td>
                    <td>{emp?.TypeContrat}</td>
                    <td>{emp?.dateEntree.substr(0, 10)}</td>
                    <td>{emp.dateSortie && emp.dateSortie.substr(0, 10)}</td>
                    <td>
                      <button
                        className="actionsBtn"
                        onClick={() => navigate(`/UpdatePersonnel/${emp._id}`)}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M19 19C19.5523 19 20 19.4477 20 20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20C4 19.4477 4.44772 19 5 19H19ZM18.003 3.58492L19.4151 4.99703C20.195 5.77692 20.195 7.04137 19.4151 7.82126L11.1778 16.0586C11.025 16.2114 10.8268 16.3105 10.6129 16.341L6 17L6.65899 12.3871C6.68954 12.1732 6.78864 11.975 6.94141 11.8222L15.1787 3.58492C15.9586 2.80503 17.2231 2.80503 18.003 3.58492ZM16.5909 4.99703L8.58911 12.9988L8.35399 14.646L10.0012 14.4109L18.003 6.40914L16.5909 4.99703Z"
                            fill="#1E5EFF"
                          />
                        </svg>
                      </button>
                      <button
                        className="actionsBtn"
                        onClick={() => testDelete(emp._id)}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M18 8V19C18 20.1046 17.1046 21 16 21H8C6.89543 21 6 20.1046 6 19V8H18ZM16 10H8V19H16V10ZM12 3C12.5523 3 13 3.44772 13 4V5H18C18.5523 5 19 5.44772 19 6C19 6.55228 18.5523 7 18 7H6C5.44772 7 5 6.55228 5 6C5 5.44772 5.44772 5 6 5H11V4C11 3.44772 11.4477 3 12 3Z"
                            fill="#1E5EFF"
                          />
                        </svg>
                      </button>
                      <button
                        className="detailsBtn"
                        onClick={() => openModalDetails(emp)}
                      >
                        Détails
                      </button>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <NotFoundError
                  text={"Aucun personnel trouvé"}
                  sx={{ width: "250%", height: "60px" }}
                />
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">{renderPagination()}</div>
    </div>
  );
}

export default Exemple;
