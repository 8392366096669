import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  emails: [],
};

const emailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {
    // email events
    setEmails: (state, action) => {
      const { emails } = action.payload;
      state.emails = emails;
    },
    updateEmail: (state, action) => {
      const { email } = action.payload;
      for (const [key, value] of Object.entries(state.emails)) {
        if (value._id?.toString() === email._id?.toString()) {
          state.emails[key] = email;
          break;
        }
      }
    },
    addEmail: (state, action) => {
      const { email } = action.payload;
      state.emails.push(email);
    },
    deleteEmail: (state, action) => {
      const { id } = action.payload;
      state.emails = state.emails.filter(
        (email) => email._id?.toString() !== id?.toString()
      );
    },
  },
});

export const { setEmails, updateEmail, addEmail, deleteEmail } =
  emailSlice.actions;

export default emailSlice.reducer;

export const selectEmails = (state) => state.email.emails;
