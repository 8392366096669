import React from 'react'
import "./styles.css"
function Footer() {
    return (
        <footer>
            <div className='separation-footer mb-3'></div>
            <div className='foter-content'>
                <p>Copyright © <span>Consulting Services</span>  </p>
                <div className='footer-icons'>
                    <p>Follow : </p>  <div className='footerSocial'>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/cs-consulting-13/?viewAsMember=true">
                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.4852 0.0406392H1.85137C1.69812 0.0385235 1.54594 0.0664368 1.40354 0.122785C1.26114 0.179133 1.13129 0.262813 1.02143 0.369044C0.911567 0.475276 0.823832 0.601979 0.763237 0.741917C0.702642 0.881855 0.670373 1.03229 0.668274 1.18462V14.8964C0.670373 15.0488 0.702642 15.1992 0.763237 15.3391C0.823832 15.4791 0.911567 15.6058 1.02143 15.712C1.13129 15.8182 1.26114 15.9019 1.40354 15.9583C1.54594 16.0146 1.69812 16.0425 1.85137 16.0404H15.4852C15.6384 16.0425 15.7906 16.0146 15.933 15.9583C16.0754 15.9019 16.2053 15.8182 16.3151 15.712C16.425 15.6058 16.5127 15.4791 16.5733 15.3391C16.6339 15.1992 16.6662 15.0488 16.6683 14.8964V1.18462C16.6662 1.03229 16.6339 0.881855 16.5733 0.741917C16.5127 0.601979 16.425 0.475276 16.3151 0.369044C16.2053 0.262813 16.0754 0.179133 15.933 0.122785C15.7906 0.0664368 15.6384 0.0385235 15.4852 0.0406392ZM5.52139 13.4325H3.10691V6.23255H5.52139V13.4325ZM4.31415 5.22457C3.98116 5.22457 3.66181 5.09308 3.42635 4.85904C3.19089 4.625 3.05862 4.30757 3.05862 3.97658C3.05862 3.6456 3.19089 3.32817 3.42635 3.09413C3.66181 2.86009 3.98116 2.7286 4.31415 2.7286C4.49097 2.70867 4.67003 2.72608 4.8396 2.77971C5.00918 2.83333 5.16545 2.92195 5.29818 3.03977C5.43091 3.15758 5.53711 3.30194 5.60982 3.46338C5.68253 3.62482 5.72012 3.7997 5.72012 3.97658C5.72012 4.15346 5.68253 4.32835 5.60982 4.48979C5.53711 4.65123 5.43091 4.79559 5.29818 4.9134C5.16545 5.03122 5.00918 5.11984 4.8396 5.17346C4.67003 5.22708 4.49097 5.2445 4.31415 5.22457ZM14.2296 13.4325H11.8152V9.56851C11.8152 8.60052 11.4691 7.96853 10.5918 7.96853C10.3203 7.9705 10.056 8.05515 9.83434 8.21106C9.61273 8.36697 9.44452 8.58666 9.35238 8.84052C9.2894 9.02855 9.26211 9.22657 9.2719 9.42451V13.4245H6.85741C6.85741 13.4245 6.85741 6.88054 6.85741 6.22455H9.2719V7.24054C9.49124 6.86223 9.81025 6.55056 10.1946 6.33911C10.5789 6.12766 11.014 6.02444 11.453 6.04056C13.0626 6.04056 14.2296 7.07254 14.2296 9.28851V13.4325Z" fill="white" />
                            </svg>
                        </a>
                        <a href="https://www.instagram.com/cs.consulting.pro?igsh=cDA3OXByc2d0eWZj" target="_blank" rel="noopener noreferrer">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.7721 2.18412C13.7155 2.18683 14.6194 2.54891 15.2865 3.19127C15.9535 3.83362 16.3295 4.70407 16.3323 5.6125V12.4685C16.3295 13.377 15.9535 14.2474 15.2865 14.8898C14.6194 15.5321 13.7155 15.8942 12.7721 15.8969H5.65238C4.70901 15.8942 3.80509 15.5321 3.13802 14.8898C2.47096 14.2474 2.09496 13.377 2.09214 12.4685V5.6125C2.09496 4.70407 2.47096 3.83362 3.13802 3.19127C3.80509 2.54891 4.70901 2.18683 5.65238 2.18412H12.7721ZM12.7721 0.812988H5.65238C2.91106 0.812988 0.668274 2.97271 0.668274 5.6125V12.4685C0.668274 15.1083 2.91106 17.268 5.65238 17.268H12.7721C15.5134 17.268 17.7562 15.1083 17.7562 12.4685V5.6125C17.7562 2.97271 15.5134 0.812988 12.7721 0.812988Z" fill="white" />
                                <path d="M13.8401 5.61303C13.6289 5.61303 13.4224 5.55272 13.2467 5.43971C13.0711 5.3267 12.9342 5.16608 12.8534 4.97816C12.7726 4.79024 12.7514 4.58345 12.7926 4.38395C12.8338 4.18446 12.9355 4.00121 13.0849 3.85738C13.2343 3.71355 13.4246 3.6156 13.6317 3.57591C13.8389 3.53623 14.0536 3.5566 14.2488 3.63444C14.4439 3.71228 14.6107 3.8441 14.7281 4.01322C14.8454 4.18235 14.9081 4.38119 14.9081 4.58459C14.9084 4.71973 14.881 4.8536 14.8274 4.9785C14.7738 5.10341 14.6952 5.2169 14.5959 5.31246C14.4967 5.40802 14.3789 5.48376 14.2491 5.53534C14.1194 5.58692 13.9804 5.61332 13.8401 5.61303ZM9.21223 6.29842C9.77553 6.29842 10.3262 6.45927 10.7946 6.76063C11.2629 7.062 11.628 7.49034 11.8435 7.99149C12.0591 8.49264 12.1155 9.04409 12.0056 9.57611C11.8957 10.1081 11.6245 10.5968 11.2262 10.9804C10.8278 11.3639 10.3203 11.6252 9.76787 11.731C9.21539 11.8368 8.64273 11.7825 8.1223 11.5749C7.60188 11.3673 7.15706 11.0158 6.84411 10.5648C6.53115 10.1137 6.36412 9.58349 6.36412 9.04105C6.36492 8.31389 6.66525 7.61675 7.1992 7.10257C7.73315 6.5884 8.45711 6.29919 9.21223 6.29842ZM9.21223 4.92728C8.36731 4.92728 7.54137 5.16855 6.83884 5.62058C6.13632 6.0726 5.58877 6.71509 5.26543 7.46678C4.9421 8.21847 4.8575 9.04561 5.02233 9.8436C5.18717 10.6416 5.59404 11.3746 6.19148 11.9499C6.78893 12.5252 7.55012 12.917 8.37881 13.0758C9.20749 13.2345 10.0664 13.153 10.847 12.8417C11.6276 12.5303 12.2948 12.003 12.7643 11.3265C13.2337 10.65 13.4842 9.85467 13.4842 9.04105C13.4842 7.95001 13.0341 6.90366 12.233 6.13218C11.4318 5.3607 10.3452 4.92728 9.21223 4.92728Z" fill="white" />
                            </svg>
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=61556645497542&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M17.8441 9.08983C17.8441 4.54632 14.0184 0.862305 9.30013 0.862305C4.58188 0.862305 0.756165 4.54632 0.756165 9.08983C0.756165 13.1962 3.88005 16.6 7.96514 17.2178V11.4688H5.7952V9.08983H7.96514V7.2772C7.96514 5.21555 9.24101 4.07581 11.1924 4.07581C12.1273 4.07581 13.1052 4.23669 13.1052 4.23669V6.26162H12.0273C10.9666 6.26162 10.6347 6.89558 10.6347 7.54717V9.08983H13.0042L12.6258 11.4688H10.6351V17.2185C14.7202 16.6011 17.8441 13.1973 17.8441 9.08983Z" fill="white" />
                            </svg>
                        </a>


                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer