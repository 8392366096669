import CustomCircularProgress from "Components/CustomCircularProgress";
import QuoteTable from "./QuoteTable";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { selectQuotes, setQuotes } from "state/quote";
import QuoteService from "Api/QuoteService";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  List,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import FlexBetween from "Components/FlexBetween";
import { useTheme } from "@emotion/react";
import { AddOutlined, KeyboardArrowDown } from "@mui/icons-material";
const QuotesList = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [filterQuotes, setFilterQuotes] = useState(null);
  const quotes = useSelector((state) => selectQuotes(state));
  const [filterBy, setFilterBy] = useState("");
  const filterOptions = ["", "number", "date"];
  const getFilterItem = (option) => {
    if (option === "number") {
      return "Numéro";
    } else if (option === "date") {
      return "Date";
    } else {
      return "";
    }
  };
  useEffect(() => {
    const getQuotes = async () => {
      try {
        setIsLoading(true);
        const data = await QuoteService.getClientQuotesDetails();
        dispatch(setQuotes({ quotes: data }));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast(`${error}`);
      }
    };
    getQuotes();
  }, []);
  const handleChange = async (option) => {
    try {
      if (!option || !filterOptions.includes(option)) {
        setFilterBy("")
        setFilterQuotes(null);
        return;
      }
      setIsLoading(true);
      const currentQuotes = await QuoteService.sortQuotes(option);
      setFilterQuotes(currentQuotes);
      setFilterBy(option)
      setIsLoading(false);
    } catch (error) {
      setFilterQuotes(null);
      setIsLoading(false);
      toast(`${error}`);;
    }
  };
  return (
    <Box className='content'>
      {/* Header */}
      <FlexBetween className="header-gest-pages">
        <Typography
          variant="h3"
          fontWeight="bold"
          color={theme.palette.secondary.light}
        >
          Gestion des devis
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "nowrap",
            gap: "10px",
            
          }}
        >
          {/* Search Bar */}
          <form>
            <TextField
              inputProps={{
                id: "filter",
              }}
              label="Tri par"
              select
              value={filterBy}
              onChange={(event) => handleChange(event.target.value)}
              SelectProps={{
                IconComponent: () => (
                  <KeyboardArrowDown
                    sx={{
                      color: theme.palette.grey.main,
                    }}
                  />
                ),
                style: {
                  color: theme.palette.grey[400],
                  height: "40px",
                },
              }}
              InputLabelProps={{
                htmlFor: "filter",
                style: {
                  fontSize: "16px",
                  color: theme.palette.grey[400],
                  marginTop: "-6px",
                },
              }}
              sx={{
                color: "black",
                border: "1px solid",
                borderColor: theme.palette.grey[50],
                backgroundColor: theme.palette.primary.main,
                borderRadius: "4px",
                width: "180px",
              }}
            >
              {filterOptions.map((item) => (
                <MenuItem
                  key={item}
                  sx={{
                    color: theme.palette.grey.main,
                    height: "30px",
                    ":hover": {
                      backgroundColor: theme.palette.grey.light,
                    },
                  }}
                  value={item}
                >
                  {getFilterItem(item)}
                </MenuItem>
              ))}
            </TextField>
          </form>
          <Button
            startIcon={<AddOutlined />}
            sx={{
              backgroundColor: theme.palette.alt.main,
              textTransform: "none",
              fontSize: "16px",
              fontWeight: "400",
              ":hover": {
                backgroundColor: theme.palette.alt[400],
              },
            }}
            onClick={() => navigate("add")}
          >
            Nouveau Devis
          </Button>
        </Box>
      </FlexBetween>
      {/* body */}
      <Box
        sx={{
          overflow: "auto",
          height: "calc(100vh - 140px)",
          width: "95%",
          margin:'auto'
        }}
      >
        <List>
          <Box
            sx={{
              backgroundColor: theme.palette.grey[100],
              borderRadius: "6px",
              height: "100%",
              padding: "5px",
              mt: "20px",
              p: "20px",
            }}
          >
            {isLoading ? (
              <CustomCircularProgress />
            ) : (
              <>
                {isLoading ? (
                  <CustomCircularProgress />
                ) : (
                  <QuoteTable quotes={filterQuotes ? filterQuotes : quotes} />
                )}
              </>
            )}
          </Box>
        </List>
      </Box>
    </Box>
  );
};

export default QuotesList;
