import { useTheme } from "@emotion/react";
import { Add, Remove } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@mui/material";
import React from "react";
import NotFoundError from "./NotFoundError";

const EquipmentList = ({
  site,
  equipments,
  numbers,
  changeNumber,
  checkedEquipments,
  handleEquipmentToggle,
}) => {
  const theme = useTheme();
  return (
    <>
      {equipments && equipments?.length !== 0 ? (
        <Box
          sx={{
            width: "100%",
            overflow: "auto",
            height: "75%",
          }}
        >
          <List>
            {equipments.map((equipment) => (
              <ListItem
                key={equipment._id}
                disablePadding
                sx={{ ml: "-15px" }}
                secondaryAction={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      border: "1px solid",
                      borderRadius: "4px",
                      borderColor: theme.palette.grey[50],
                      height: "36px",
                      width: "100px",
                    }}
                  >
                    <IconButton
                      onClick={() => changeNumber(equipment, "DEC")}
                      disabled={numbers[equipments.findIndex(item => item._id === equipment._id)] <= 0}
                    >
                      <Remove sx={{ color: theme.palette.grey.main }} />
                    </IconButton>
                    <TextField
                      name="number"
                      id="number"
                      InputProps={{
                        style: {
                          height: "50px",
                          width: "50px",
                          color: "black",
                          fontSize: "14px",
                          textAlign: "center",
                          position: "relative",
                          top: "0",
                          left: "-5px",
                        },
                      }}
                      value={(equipment.available === 0 && !site.equipments.some(item => item.equipment._id === equipment._id)) ? 'R' : numbers[equipments.findIndex(item => item._id === equipment._id)]}
                      onChange={(e) =>
                        changeNumber(equipment, "V", e.target.value || 0)
                      }
                    />
                    <IconButton onClick={() => changeNumber(equipment, "INC")}
                    disabled={numbers[equipments.findIndex(item => item._id === equipment._id)] >= ((site.equipments.find(item => item.equipment._id === equipment._id)?.number ?? 0) + equipment.available)}
                    >
                      <Add sx={{ color: theme.palette.grey.main }} />
                    </IconButton>
                  </Box>
                }
              >
                <ListItemButton
                  onClick={() => handleEquipmentToggle(equipment)}
                  dense
                  disabled={equipment.available === 0 && !site.equipments.some(item => item.equipment._id === equipment._id)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checkedEquipments.some(item => item._id === equipment._id)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": "member-label-id",
                      }}
                      style={{
                        color: checkedEquipments.some(item => item._id === equipment._id) ? theme.palette.alt.main :  theme.palette.grey.light,
                      }}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: "28px",
                        },
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id="member-label-id"
                    sx={{ ml: "-15px", color: "black" }}
                    primaryTypographyProps={{
                      style: {
                        color: theme.palette.secondary.main,
                        fontSize: "14px",
                        fontWeight: "500",
                      },
                    }}
                    primary={`${equipment.name}`}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      ) : (
        <NotFoundError sx={{height:'60%'}} text='Aucun Équipement trouvé !' />
      )}
    </>
  );
};

export default EquipmentList;
