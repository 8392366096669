import { io } from "socket.io-client";
import { toast } from "react-toastify";

class MessagingService {
  static baseURL = "https://www.csconsultingservices.fr/";
  socket;
  static init = () => {
    try {
      this.socket = io(this.baseURL);
    } catch (error) {
      toast(`${error}`);
    }
  };
  static connect = (user) => {
    try {
      if (!this.socket) this.init();
      this.socket.emit("setup", user);
    } catch (error) {
      toast(`${error}`);
    }
  };
  static disconnect = (id) => {
    try {
      if (!this.socket) this.init();
      this.socket.emit("disconnect", { id });
    } catch (error) {
      toast(`${error}`);
    }
  };
  static emit = (event, data) => {
    try {
      if (!this.socket) this.init();
      this.socket.emit(event, data);
    } catch (error) {
      toast(`${error}`);
    }
  };
  static on = (event, callback) => {
    try {
      if (!this.socket) this.init();
      this.socket.on(event, callback);
    } catch (error) {
      toast(`${error}`);
    }
  };
}

export default MessagingService;
