import { useTheme } from "@emotion/react";
import { AddCircleOutline, CloseOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import CustomField from "Components/CustomField";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { taskSchema } from "Pages/schemas/schemas";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { addTask, updateTask } from "state/task";
import PopUp from "Components/Popup";
import { formatDate } from "utils/format_date";
import { useParams } from "react-router-dom";
import WorkersOverlay from "Components/WorkersOverlay";
import FlexBetween from "Components/FlexBetween";
import CustomCircularProgress from "Components/CustomCircularProgress";
import { setGlobalIsLoading } from "state/global";
import TaskService from "Api/TaskService";
import SiteService from "Api/SiteService";
import CustomDatePicker from "Components/CustomDatePicker";
import { toast } from "react-toastify";
import NotFoundError from "Components/NotFoundError";

const EquipmentsViewOverlay = ({
  equipments,
  checkedEquipments,
  handleEquipmentToggle,
  setOpen,
  onClick,
}) => {
  const theme = useTheme();
  return (
    <Box
      height="55vh"
      width="100%"
      sx={{
        backgroundColor: theme.palette.primary.main,
        borderRadius: "6px",
        p: "20px",
      }}
    >
      <FlexBetween gap="10px">
        <Typography
          variant="h5"
          fontWeight="bold"
          color={theme.palette.secondary.main}
        >
          Matériaux et Équipement
        </Typography>
        <Button
          size="small"
          variant="contained"
          sx={{
            bgcolor: theme.palette.alt.main,
            color: theme.palette.primary.main,
            fontSize: "16px",
            fontWeight: "bold",
            height: "30px",
            width: "30px",
            p: "0",
            ":hover": {
              bgcolor: theme.palette.alt.main,
              color: theme.palette.primary.main,
            },
          }}
          onClick={() => {
            onClick && onClick();
            setOpen(false);
          }}
        >
          OK
        </Button>
      </FlexBetween>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box color={theme.palette.grey[600]} fontSize={"16px"}>
          <Typography>Équipement</Typography>
        </Box>
        <Divider
          sx={{
            textAlign: "center",
            height: "1px",
            width: "300px",
            backgroundColor: "black",
            m: "10px",
          }}
        />
      </Box>
      <>
        {equipments && equipments?.length !== 0 ? (
          <Box
            sx={{
              width: "100%",
              overflow: "auto",
              height: "75%",
            }}
          >
            <List>
              {equipments.map((equipment) => (
                <ListItem
                  key={equipment.equipment._id}
                  disablePadding
                  sx={{ ml: "-15px" }}
                  secondaryAction={
                    <Typography
                      sx={{
                        color: theme.palette.secondary.light,
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      {equipment.number}
                    </Typography>
                  }
                >
                  <ListItemButton
                    onClick={() => handleEquipmentToggle(equipment)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checkedEquipments.some(
                          (item) =>
                            item.equipment._id === equipment.equipment._id
                        )}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          "aria-labelledby": "worker-label-id",
                        }}
                        style={{
                          color: checkedEquipments.some(
                            (item) =>
                              item.equipment._id === equipment.equipment._id
                          )
                            ? theme.palette.alt.main
                            : theme.palette.grey.light,
                        }}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "28px",
                          },
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id="worker-label-id"
                      sx={{ ml: "-15px", color: "black" }}
                      primaryTypographyProps={{
                        style: {
                          color: theme.palette.secondary.main,
                          fontSize: "14px",
                          fontWeight: "500",
                        },
                      }}
                      primary={`${equipment.equipment.name}`}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        ) : (
          <NotFoundError
            sx={{ height: "60%" }}
            text="Aucun Équipement trouvé !"
          />
        )}
      </>
    </Box>
  );
};

const AddEditTask = ({ taskId, setOpen, setDetails }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { siteId } = useParams();
  const [contactKeys, setContactKeys] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [priority, setPriority] = useState("urgent");
  const [isLoading, setIsLoading] = useState(true);
  const [contactKeysInput, setContactKeysInput] = useState("");
  const [workersOverlayOpen, setWorkersOverlayOpen] = useState(false);
  const [equipmentsOverlayOpen, setEquipmentsOverlayOpen] = useState(false);
  const [checkedWorkers, setCheckedWorkers] = useState([]);
  const [checkedEquipments, setCheckedEquipments] = useState([]);
  const [site, setSite] = useState([]);
  const [task, setTask] = useState({
    name: "",
    description: "",
    begin_date: new Date(),
    end_date: new Date(),
    workers: "",
    equipments: "",
    contact_keys: "",
    place: "",
    instructions: "",
    priority: "urgent",
  });

  const handleWorkerToggle = (worker) => {
    const currentIndex = checkedWorkers.findIndex(
      (item) => item._id === worker._id
    );
    const newChecked = [...checkedWorkers];

    if (currentIndex === -1) {
      newChecked.push(worker);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedWorkers(newChecked);
  };

  const handleEquipmentToggle = (equipment) => {
    const currentIndex = checkedEquipments.findIndex(
      (item) => item.equipment._id === equipment.equipment._id
    );
    const newChecked = [...checkedEquipments];

    if (currentIndex === -1) {
      newChecked.push(equipment);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedEquipments(newChecked);
  };

  useEffect(() => {
    const getTaskData = async () => {
      try {
        const currentTask = await TaskService.getTaskDetails(taskId);
        setTask(currentTask);
        setCheckedEquipments(currentTask.equipments);
        setCheckedWorkers(currentTask.workers);
        setContactKeys(currentTask.contact_keys);
        setPriority(currentTask.priority);
        setIsLoading(false);
      } catch (error) {
        toast(`${error}`);
      }
    };
    const getSiteData = async () => {
      try {
        setIsLoading(true);
        const currentSite = await SiteService.getSiteDetails(siteId);
        setEquipments(currentSite.equipments);
        setWorkers(currentSite.workers);
        setSite(currentSite);
        if (taskId) {
          getTaskData();
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        toast(`${error}`);
      }
    };
    getSiteData();
  }, []);

  const onSubmit = async (values) => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      const data = {
        site: siteId,
        name: values.name,
        description: values.description,
        begin_date: formatDate(values.begin_date),
        end_date: formatDate(values.end_date),
        workers: checkedWorkers,
        equipments: checkedEquipments,
        contact_keys: contactKeys,
        place: values.place,
        instructions: values.instructions,
        priority: priority,
        state: values.state,
      };
      if (taskId) {
        const updatedTask = await TaskService.updateTaskDetails(task._id, data);
        dispatch(updateTask({ task: updatedTask }));
        setDetails(updatedTask);
        setOpen(false);
      } else {
        const newTask = await TaskService.createTask(data, siteId);
        dispatch(addTask({ task: newTask }));
        setOpen(false);
      }
      dispatch(setGlobalIsLoading({ isLoading: false }));
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      onSubmit={onSubmit}
      initialValues={task}
      validationSchema={taskSchema}
      validateOnMount={true}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <Box
          sx={{
            "span.error-message": {
              m: ".5px -10px",
              fontSize: "12px",
              color: theme.palette.error.main,
              position: "absolute",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "50%",
            },
            bgcolor: theme.palette.primary.main,
            p: "20px",
            pr: "10px",
            width: "580px",
          }}
        >
          <Form onSubmit={(e) => handleSubmit(e)}>
            <Box flex="1">
              {isLoading ? (
                <CustomCircularProgress />
              ) : (
                <List>
                  <Box
                    sx={{
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                    }}
                  >
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      color={theme.palette.secondary.main}
                    >
                      {taskId ? "Modifier de la tâche" : "Ajout de la tâche"}
                    </Typography>
                  </Box>
                  <CustomField
                    value={values.name}
                    isDisabled={false}
                    name="name"
                    type="text"
                    title="Nom de la tâche"
                  />
                  <CustomField
                    value={values.description}
                    multiline={true}
                    isDisabled={false}
                    rows={3}
                    name="description"
                    type="text"
                    title="Description de la tâche"
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box
                      display="flex"
                      sx={{
                        "& .MuiInputBase-root": {
                          color: theme.palette.grey[400],
                          m: "-5px",
                        },
                        "& .MuiButtonBase-root": {
                          color: theme.palette.grey[400],
                        },
                        gap: "20px",
                        m: "1.5rem .7rem",
                      }}
                    >
                      <Box flex="1">
                        <Typography
                          height="15px"
                          variant="h6"
                          color={theme.palette.grey[600]}
                          mb=".6rem"
                        >
                          Date de début
                        </Typography>
                        <CustomDatePicker
                          name="begin_date"
                          value={dayjs(values.end_date)}
                          setFieldValue={setFieldValue}
                        />
                      </Box>
                      <Box flex="1">
                        <Typography
                          height="15px"
                          variant="h6"
                          color={theme.palette.grey[600]}
                          mb=".6rem"
                        >
                          Date de fin
                        </Typography>
                        <CustomDatePicker
                          name="end_date"
                          value={dayjs(values.end_date)}
                          setFieldValue={setFieldValue}
                        />
                      </Box>
                    </Box>
                  </LocalizationProvider>
                  {/* workers */}
                  <Box m="1.5rem .6rem" position="relative">
                    <Typography
                      height="15px"
                      fontWeight="400"
                      color={theme.palette.grey[600]}
                      mb=".6rem"
                      whiteSpace="nowrap"
                      textOverflow="clip"
                    >
                      La personne ou les personnes concernées par cette tâche
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                        flexWrap: "wrap",
                        minHeight: "40px",
                        width: "100%",
                        backgroundColor: theme.palette.primary.main,
                        border: "1px solid",
                        borderRaduis: "4px",
                        borderColor: theme.palette.grey[200],
                        p: "5px",
                      }}
                    >
                      {checkedWorkers.map((worker) => (
                        <Box
                          key={worker._id}
                          sx={{
                            backgroundColor: theme.palette.grey[200],
                            textAlign: "center",
                            padding: "5px 10px",
                            borderRadius: "4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: "10px",
                            flexWrap: "nowrap",
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              color: theme.palette.grey[600],
                            }}
                          >
                            {worker.firstname + " " + worker.lastName}
                          </Typography>
                          <IconButton
                            size="medium"
                            sx={{
                              p: "0",
                            }}
                            onClick={() => {
                              setCheckedWorkers(
                                checkedWorkers.filter(
                                  (key) =>
                                    key._id.toString() !== worker._id.toString()
                                )
                              );
                            }}
                          >
                            <CloseOutlined
                              sx={{
                                color: theme.palette.grey.main,
                                fontSize: "15px",
                              }}
                            />
                          </IconButton>
                        </Box>
                      ))}
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        right: "0px",
                        top: "25px",
                      }}
                    >
                      <IconButton onClick={() => setWorkersOverlayOpen(true)}>
                        <AddCircleOutline
                          sx={{ color: theme.palette.grey[600] }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                  {/* equipments */}
                  <Box m="1.5rem .6rem" position="relative">
                    <Typography
                      height="15px"
                      fontWeight="400"
                      color={theme.palette.grey[600]}
                      mb=".6rem"
                    >
                      Matériaux et équipements requis
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                        flexWrap: "wrap",
                        minHeight: "40px",
                        width: "100%",
                        backgroundColor: theme.palette.primary.main,
                        border: "1px solid",
                        borderRaduis: "4px",
                        borderColor: theme.palette.grey[200],
                        p: "5px",
                      }}
                    >
                      {checkedEquipments.map((equipment) => (
                        <Box
                          key={equipment.equipment._id}
                          sx={{
                            backgroundColor: theme.palette.grey[200],
                            textAlign: "center",
                            padding: "5px 15px",
                            borderRadius: "4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: "10px",
                            flexWrap: "nowrap",
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              color: theme.palette.grey[600],
                            }}
                          >
                            {equipment.equipment.name}
                          </Typography>
                          <IconButton
                            size="medium"
                            sx={{
                              p: "0",
                            }}
                            onClick={() => {
                              setCheckedEquipments(
                                checkedEquipments.filter(
                                  (item) =>
                                    item.equipment._id.toString() !==
                                    equipment.equipment._id.toString()
                                )
                              );
                            }}
                          >
                            <CloseOutlined
                              sx={{
                                color: theme.palette.grey.main,
                                fontSize: "15px",
                              }}
                            />
                          </IconButton>
                        </Box>
                      ))}
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        right: "0px",
                        top: "25px",
                      }}
                    >
                      <IconButton
                        onClick={() => setEquipmentsOverlayOpen(true)}
                      >
                        <AddCircleOutline
                          sx={{ color: theme.palette.grey[600] }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                  {/* contact keys */}
                  <Box m="1.5rem .5rem" flexGrow="1" position="relative">
                    <Typography
                      height="15px"
                      variant="h6"
                      color={theme.palette.grey[600]}
                      mb=".6rem"
                    >
                      Contacts Clés
                    </Typography>
                    <Field
                      as={TextField}
                      value={contactKeysInput}
                      onChange={(e) => setContactKeysInput(e.target.value)}
                      id="contact_keys"
                      name="contact_keys"
                      type="text"
                      helperText={
                        <ErrorMessage
                          component="span"
                          className="error-message"
                          name="contact_keys"
                        />
                      }
                      sx={{
                        width: "100%",
                        backgroundColor: theme.palette.primary.main,
                        border: "1px solid",
                        borderRaduis: "4px",
                        borderColor: theme.palette.grey[200],
                        height: "40px",
                      }}
                      InputProps={{
                        style: {
                          height: "40px",
                          color: theme.palette.grey[400],
                          fontSize: "14px",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: theme.palette.grey[400],
                          margin: "-4px",
                        },
                      }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        right: "0px",
                        top: "25px",
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          if (
                            contactKeysInput.length > 0 &&
                            !contactKeys.includes(contactKeysInput)
                          ) {
                            setContactKeys((prevKeys) => [
                              ...prevKeys,
                              contactKeysInput,
                            ]);
                            setContactKeysInput("");
                          }
                        }}
                      >
                        <AddCircleOutline
                          sx={{ color: theme.palette.grey[600] }}
                        />
                      </IconButton>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                        flexWrap: "wrap",
                        p: "5px",
                      }}
                    >
                      {contactKeys.map((contact_key) => (
                        <Box
                          key={contact_key}
                          sx={{
                            backgroundColor: theme.palette.grey[200],
                            textAlign: "center",
                            padding: "5px 15px",
                            borderRadius: "4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: "10px",
                            flexWrap: "nowrap",
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              color: theme.palette.grey[600],
                            }}
                          >
                            {contact_key}
                          </Typography>
                          <IconButton
                            size="medium"
                            sx={{
                              p: "0",
                            }}
                            onClick={() => {
                              setContactKeys(
                                contactKeys.filter((key) => key !== contact_key)
                              );
                            }}
                          >
                            <CloseOutlined
                              sx={{
                                color: theme.palette.grey.main,
                                fontSize: "15px",
                              }}
                            />
                          </IconButton>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  <CustomField
                    value={values.place}
                    isDisabled={false}
                    name="place"
                    type="text"
                    title="Lieu"
                  />
                  <CustomField
                    value={values.instructions}
                    isDisabled={false}
                    rows={3}
                    name="instructions"
                    type="text"
                    title="Instructions et Directives"
                  />
                  {/* priority */}
                  <Box m="1.5rem .6rem" position="relative">
                    <Typography
                      height="15px"
                      variant="h6"
                      color={theme.palette.grey[600]}
                      mb=".6rem"
                    >
                      Priorité
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                        flexWrap: "nowrap",
                        width: "100%",
                        backgroundColor: theme.palette.primary.main,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <PriorityButton
                        priority={priority}
                        setPriority={setPriority}
                        currentPriority={"urgent"}
                        color={theme.palette.error[600]}
                      />
                      <PriorityButton
                        priority={priority}
                        setPriority={setPriority}
                        currentPriority={"important"}
                        color={theme.palette.warning[600]}
                      />
                      <PriorityButton
                        priority={priority}
                        setPriority={setPriority}
                        currentPriority={"regular"}
                        color={theme.palette.success.main}
                      />
                    </Box>
                  </Box>
                </List>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Button
                sx={{
                  width: "100px",
                  fontSize: "16px",
                  fontWeight: "400",
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.alt.main,
                  border: "1px solid",
                  borderRadius: "4px",
                  borderColor: theme.palette.grey.light,
                  textTransform: "none",
                  ":hover": {
                    backgroundColor: theme.palette.primary[400],
                  },
                }}
                onClick={() => setOpen(false)}
              >
                Annuler
              </Button>
              <Button
                sx={{
                  width: "100px",
                  fontSize: "16px",
                  fontWeight: "400",
                  backgroundColor: theme.palette.alt.main,
                  textTransform: "none",
                  ":hover": {
                    backgroundColor: theme.palette.alt[400],
                  },
                }}
                disabled={isLoading}
                type="submit"
              >
                {taskId ? "Modifier" : "Ajouter"}
              </Button>
            </Box>
          </Form>
          <PopUp open={workersOverlayOpen} setOpen={setWorkersOverlayOpen}>
            <WorkersOverlay
              checkedWorkers={checkedWorkers}
              handleWorkerToggle={handleWorkerToggle}
              workers={workers}
              setOpen={setWorkersOverlayOpen}
              site={site}
              setWorkers={setWorkers}
            />
          </PopUp>
          <PopUp
            open={equipmentsOverlayOpen}
            setOpen={setEquipmentsOverlayOpen}
          >
            <EquipmentsViewOverlay
              checkedEquipments={checkedEquipments}
              equipments={equipments}
              handleEquipmentToggle={handleEquipmentToggle}
              setOpen={setEquipmentsOverlayOpen}
            />
          </PopUp>
        </Box>
      )}
    </Formik>
  );
};

const PriorityButton = ({ priority, currentPriority, color, setPriority }) => {
  const theme = useTheme();
  return (
    <Button
      onClick={() => setPriority(currentPriority)}
      variant={priority === currentPriority ? "contained" : "outlined"}
      sx={{
        border: "1px solid",
        borderColor:
          priority === currentPriority ? color : theme.palette.grey[800],
        color:
          priority === currentPriority
            ? theme.palette.primary.main
            : theme.palette.grey[800],
        backgroundColor:
          priority === currentPriority ? color : theme.palette.primary.main,
        fontSize: "14px",
        fontWeight: "bold",
        ":hover": {
          border: "1px solid",
          borderColor:
            priority === currentPriority ? color : theme.palette.grey[800],
          color:
            priority === currentPriority
              ? theme.palette.primary.main
              : theme.palette.grey[800],
          backgroundColor:
            priority === currentPriority ? color : theme.palette.primary.main,
          fontSize: "14px",
          fontWeight: "bold",
        },
        height: "32px",
      }}
    >
      {currentPriority}
    </Button>
  );
};

export default AddEditTask;
