import React from 'react'
import MainLayout from '../../Components/Layouts/MainLayout'
import SimpleHeader from "../../Components/Headers/SimpleHeader";
import AddClient from "../../Components/Forms/AddClient"
import MainHeader from 'Components/Headers/MainHeader';
function AddClients() {
  return (
    <>
      <MainHeader />
      <MainLayout>
        <SimpleHeader title="Clients" />
        <AddClient />
      </MainLayout>
    </>
  )
}

export default AddClients