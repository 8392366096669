import handleError from "utils/handelError";
import axiosClient from "./client";
import {
  createEquipmentEndPoint,
  createEquipmentListEndPoint,
  deleteEquipmentEndPoint,
  getAllEquipmentsEndPoint,
  getClientEquipmentsEndPoint,
  getEquipmentDetailsEndPoint,
  getEquipmentsPageEndPoint,
  searchEquipmentsEndPoint,
  sortEquipmentsEndPoint,
  updateEquipmentDetailsEndPoint,
  updateEquipmentStateEndPoint,
} from "./Constants";

const EquipmentService = {
  createEquipment: async (data) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.post(
        createEquipmentEndPoint,
        { ...data, client: clientId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const equipment = response.data.equipment;
      return equipment;
    } catch (error) {
      handleError(error);
    }
  },

  createEquipmentList: async (data) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.post(
        createEquipmentListEndPoint,
        { equipments: data, client: clientId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const equipments = response.equipments;
      return equipments;
    } catch (error) {
      handleError(error);
    }
  },

  getEquipmentDetails: async (id) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getEquipmentDetailsEndPoint(id), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const equipment = response.data.equipment;
      return equipment;
    } catch (error) {
      handleError(error);
    }
  },

  getEquipmentsDetails: async () => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getAllEquipmentsEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const equipments = response.data.equipments;
      return equipments;
    } catch (error) {
      handleError(error);
    }
  },

  getClientEquipmentsDetails: async () => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.get(getClientEquipmentsEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          clientId,
        },
      });
      const equipments = response.data.equipments;
      return equipments;
    } catch (error) {
      handleError(error);
    }
  },

  getEquipmentsPage: async (page) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.get(getEquipmentsPageEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          clientId,
        },
      });
      const equipments = response.data.equipments;
      const count = response.data.count;
      return { equipments, count };
    } catch (error) {
      handleError(error);
    }
  },

  updateEquipmentDetails: async (id, data) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.put(
        updateEquipmentDetailsEndPoint(id),
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const equipment = response.data.equipment;
      return equipment;
    } catch (error) {
      handleError(error);
    }
  },

  deleteEquipment: async (id) => {
    try {
      const token = localStorage.getItem("@userCsc");
      await axiosClient.delete(deleteEquipmentEndPoint(id), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      handleError(error);
    }
  },

  updateEquipmentState: async (id, state) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.put(
        updateEquipmentStateEndPoint(id),
        { state },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const equipment = response.data.equipment;
      return equipment;
    } catch (error) {
      handleError(error);
    }
  },

  searchEquipments: async (query, page) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.get(searchEquipmentsEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          query,
          page,
          client: clientId,
        },
      });
      const equipments = response.data.equipments;
      const count = response.data.count;
      return { equipments, count };
    } catch (error) {
      handleError(error);
    }
  },

  sortEquipments: async (option, page) => {
    try {
      const checkedOption = () => {
        if (option === "Nom") {
          return "name";
        } else if (option === "Date") {
          return "date";
        } else {
          throw Error("option de tri invalide");
        }
      };
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.get(sortEquipmentsEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          option: checkedOption(),
          page,
          client: clientId,
        },
      });
      const equipments = response.data.equipments;
      const count = response.data.count;
      return { equipments, count };
    } catch (error) {
      handleError(error);
    }
  },
};

export default EquipmentService;
