import React from "react";
import "./Header.css";
function HeaderWithButton({ title, btnTitle, btnFunction, btn2 }) {
  return (
    <div className="header-gest-pages">
      <div className="header-gest-pages-left">
        <div className="text-and-back">
          <h3> {title} </h3>
        </div>
      </div>
      <div className="header-gest-pages-right">
        {btn2 && (
          <button onClick={() => btnFunction()} className="LightBtn">
            {btn2}
          </button>
        )}
        <button onClick={() => btnFunction()} className="DarkBtn">
          {btnTitle}
        </button>
      </div>
    </div>
  );
}

export default HeaderWithButton;
