import handleError from "utils/handelError";
import axiosClient from "./client";
import {
  createInvoiceEndPoint,
  deleteInvoiceEndPoint,
  getAllClientInvoicesEndPoint,
  getAllInvoiceStatsEndPoint,
  getAllInvoicesEndPoint,
  getInvoiceDetailsEndPoint,
  getInvoiceStatsEndPoint,
  sortInvoicesEndPoint,
  updateInvoiceDetailsEndPoint,
  updateInvoiceStateEndPoint,
} from "./Constants";
import invoice from "state/invoice";

const InvoiceService = {
  createInvoice: async (data) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.post(
        createInvoiceEndPoint,
        { ...data, client: clientId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const invoice = response.data.invoice;
      return invoice;
    } catch (error) {
      handleError(error);
    }
  },

  getInvoiceDetails: async (id) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getInvoiceDetailsEndPoint(id), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const invoice = response.data.invoice;
      return invoice;
    } catch (error) {
      handleError(error);
    }
  },

  getInvoiceStats: async (date) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.get(getInvoiceStatsEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          date,
          clientId,
        },
      });
      const stats = response.data.stats;
      return stats;
    } catch (error) {
      handleError(error);
    }
  },

  getAllInvoiceStats: async (date) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getAllInvoiceStatsEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          date,
        },
      });
      const stats = response.data.stats;
      return stats;
    } catch (error) {
      handleError(error);
    }
  },

  getInvoicesDetails: async () => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getAllInvoicesEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const invoices = response.data.invoices;
      return invoices;
    } catch (error) {
      handleError(error);
    }
  },

  getClientInvoicesDetails: async (date) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.get(getAllClientInvoicesEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          clientId,
          date,
        },
      });
      const invoices = response.data.invoices;
      return invoices;
    } catch (error) {
      handleError(error);
    }
  },

  updateInvoiceDetails: async (id, data) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.put(
        updateInvoiceDetailsEndPoint(id),
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const invoice = response.data.invoice;
      return invoice;
    } catch (error) {
      handleError(error);
    }
  },

  deleteInvoice: async (id) => {
    try {
      const token = localStorage.getItem("@userCsc");
      await axiosClient.delete(deleteInvoiceEndPoint(id), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      handleError(error);
    }
  },

  updateInvoiceState: async (id, state) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.put(
        updateInvoiceStateEndPoint(id),
        { state },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const invoice = response.data.invoice;
      return invoice;
    } catch (error) {
      handleError(error);
    }
  },

  sortInvoices: async (option) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.get(sortInvoicesEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          option,
          client: clientId,
        },
      });
      const invoices = response.data.invoices;
      return invoices;
    } catch (error) {
      handleError(error);
    }
  },
};

export default InvoiceService;
