import { useTheme } from "@emotion/react";
import { AccessTime } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import FlexBetween from "Components/FlexBetween";
import PopUp from "Components/Popup";
import React, { useState } from "react";
import TaskDetails from "./TaskDetails";
import { formatDate } from "utils/format_date";

const TaskCard = ({ task ,sx}) => {
  const theme = useTheme();
  const [open ,setOpen] = useState(false)
  const borderColor = () => {
    if (task.priority === "urgent") {
      return theme.palette.error.main;
    } else if (task.priority === "important") {
      return theme.palette.warning[600];
    } else {
      return theme.palette.success.main;
    }
  };
  return (
    <Box
      sx={{
        bgcolor: theme.palette.secondary.light,
        height: "180px",
        m: "10px 5px 10px 10px",
        p: "10px",
        border: "1px solid",
        borderRadius: "6px",
        borderColor: borderColor(),
        textAlign: "start",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "space-around",
        ...sx,
      }}
    >
      <Typography
        sx={{
          fontWeight: "bold",
        }}
      >
        {task.name}
      </Typography>
      <Typography
        sx={{
          fontSize: "11",
          color: theme.palette.grey["800"],
          overflow:'hidden',
          maxWidth:'200px',
          textOverflow:'ellipsis',
          textWrap:'nowrap'
        }}
      >
        {task.description}
      </Typography>
      <FlexBetween width="100%">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "3px",
          }}
        >
          <AccessTime />
          {formatDate(task.begin_date)?.slice(5, 10).replace('/','-')}
        </Box>
        <Button
          variant="contained"
          size="small"
          disableElevation={true}
          sx={{
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.alt.main,
            textTransform: "none",
            fontSize: "12px",
            fontWeight: "600",
            ":hover": {
              backgroundColor: theme.palette.alt.main,
              boxShadow: "none",
            },
            boxShadow: "none",
          }}
          onClick={() => {
            setOpen(true)
          }}
        >
          Détails
        </Button>
      </FlexBetween>
      <PopUp open={open} setOpen={setOpen}>
          <TaskDetails taskId={task._id} setOpen={setOpen}/>
      </PopUp>
    </Box>
  );
};

export default TaskCard;
