import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: [],
  totalCount: 0,
  notificationCount: 0,
  messageNotificationCount: 0,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    resetTotalCount: (state, action) => {
      state.totalCount = 0;
    },
    resetMessageNotificationCount: (state, action) => {
      state.messageNotificationCount = 0;
    },
    resetNotificationCount: (state, action) => {
      state.notificationCount = 0;
    },
    incrementNotificationCount: (state, action) => {
      state.notificationCount += 1;
      state.totalCount += 1;
    },
    incrementMessageNotificationCount: (state, action) => {
      state.messageNotificationCount += 1;
      state.totalCount += 1;
    },
    // notification events
    setNotifications: (state, action) => {
      const { notifications } = action.payload;
      state.notifications = notifications;
    },
    updateNotification: (state, action) => {
      const { notification } = action.payload;
      for (const [key, value] of Object.entries(state.notifications)) {
        if (value._id?.toString() === notification._id?.toString()) {
          state.notifications[key] = notification;
          break;
        }
      }
    },
    addNotification: (state, action) => {
      const { notification } = action.payload;
      state.notifications.unshift(notification);
    },
    deleteNotification: (state, action) => {
      const { id } = action.payload;
      state.notifications = state.notifications.filter(
        (notification) => notification._id?.toString() !== id?.toString()
      );
    },
  },
});

export const {
  setNotifications,
  updateNotification,
  addNotification,
  deleteNotification,
  incrementNotificationCount,
  incrementMessageNotificationCount,
  resetTotalCount,
  resetMessageNotificationCount,
  resetNotificationCount,
} = notificationSlice.actions;

export default notificationSlice.reducer;

export const selectNotifications = (state) => state.notification.notifications;

export const selectTotalNotificationCount = (state) =>
  state.notification.totalCount;
export const selectNotificationCount = (state) =>
  state.notification.notificationCount;
export const selectMessageNotificationCount = (state) =>
  state.notification.messageNotificationCount;
