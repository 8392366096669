// color design tokens export
export const tokens = {
  primary: {
    500: "#FFFFFF",
  },
  secondary: {
    300: "#333752",
    500: "#131523",
    700: "#00001B",
    800: "#212121",
    900: "#011627",
  },
  grey: {
    10: "#707991",
    50: "#D9E1EC",
    100: "#F5F6FA",
    200: "#E6E9F4",
    300: "#D7DBEC",
    400: "#A1A7C4",
    500: "#7E84A3",
    600: "#5A607F",
    700: "#333752",
    800: "#979797",
    900: "#D9D9D9",
    1000: "#ACACB7",
    1100: "#F0F0F0",
    1200: "#FAFAFA",
    1300: "#F5F5F5",
    1400: "#E0E0E0",
    1500: "#D1D1D1",
  },
  alt: {
    100: "#ECF2FF",
    200: "#a5bfff",
    300: "#789eff",
    400: "#4b7eff",
    500: "#1e5eff",
    600: "#184bcc",
    700: "#123899",
    800: "#0c2666",
    900: "#1E5EFF",
    1000: "#8BABD8",
  },
  info: {
    500: "#4FC0FF",
  },
  success: {
    400: "#8CEE10",
    500: "#06A561",
  },
  error: {
    300: "#C74821",
    500: "#F12B43",
    600: "#F0142F",
  },
  warning: {
    500: "#FBDD4E",
    600: "#FF862F",
  },
  dark: {
    400: "#151B26",
    500: "#000",
  },
};
// mui theme settings
export const themeSettings = () => {
  return {
    palette: {
      mode: "dark",
      // palette values for dark mode
      primary: {
        ...tokens.primary,
        main: tokens.primary[500],
      },
      secondary: {
        ...tokens.secondary,
        main: tokens.secondary[500],
        light: tokens.secondary[300],
        dark: tokens.secondary[700],
      },
      grey: {
        ...tokens.grey,
        main: tokens.grey[500],
        light: tokens.grey[300],
        dark: tokens.grey[700],
      },
      alt: {
        ...tokens.alt,
        main: tokens.alt[500],
      },
      background: {
        default: tokens.primary[500],
        alt: tokens.secondary[500],
      },
      info: {
        ...tokens.info,
        main: tokens.info[500],
      },
      success: {
        ...tokens.success,
        main: tokens.success[500],
      },
      error: {
        ...tokens.error,
        light: tokens.error[300],
        main: tokens.error[500],
      },
      warning: {
        ...tokens.warning,
        main: tokens.warning[500],
      },
      dark: {
        ...tokens.dark,
        main: tokens.dark[500],
      },
    },
    typography: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 36,
        fontWeight: "400",
      },
      h2: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 32,
        fontWeight: "400",
      },
      h3: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 24,
        fontWeight: "400",
      },
      h4: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 18,
        fontWeight: "400",
      },
      h5: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 16,
        fontWeight: "400",
      },
      h6: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 14,
        fontWeight: "400",
      },
    },
  };
};
