import React from 'react'
import { Route, Routes } from 'react-router-dom'
import SitesList from './SitesList'
import AddEditSite from './AddEditSite'
import SiteDetails from './SiteDetails'
import Tasks from '../Tasks/Tasks'
import NotFound from 'Pages/NotFound'

const Sites = () => {
  return (
    <Routes>
        <Route path='/' element={<SitesList />} />
        <Route path='/:siteId'>
            <Route path='' element={<SiteDetails />} />
            <Route path='edit' element={<AddEditSite />} />
            <Route path='tasks/*' element={<Tasks />} />
        </Route>
        <Route path='/add' element={<AddEditSite/>} />
        <Route path="/*" element={<NotFound />} />
    </Routes>
  )
}

export default Sites