import { useTheme } from "@emotion/react";
import {
  ArrowBackOutlined,
  BorderColorOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import CustomDialog from "Components/CustomDialog";
import EquipmentsOverlay from "Components/EquipmentsOverlay";
import FlexBetween from "Components/FlexBetween";
import WorkersOverlay from "Components/WorkersOverlay";
import PopUp from "Components/Popup";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { deleteSite, updateSite } from "state/site";
import SiteService from "Api/SiteService";
import EquipmentService from "Api/EquipmentService";
import AuthService from "Api/AuthService";
import { formatDate } from "utils/format_date";
import { setGlobalIsLoading } from "state/global";
import { toast } from "react-toastify";
import NotFoundError from "Components/NotFoundError";
import CustomCircularProgress from "Components/CustomCircularProgress";

const SiteDetails = () => {
  const { siteId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [equipments, setEquipments] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [workersOverlayOpen, setWorkersOverlayOpen] = useState(false);
  const [equipmentsOverlayOpen, setEquipmentsOverlayOpen] = useState(false);
  const [site, setSite] = useState({});
  const [checkedWorkers, setCheckedWorkers] = useState([]);
  const [checkedEquipments, setCheckedEquipments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [numbers, setNumbers] = useState(
    Array.from({ length: equipments.length }, () => 0)
  );

  const handleWorkerToggle = (worker) => {
    const currentIndex = checkedWorkers.findIndex(
      (item) => item._id === worker._id
    );
    const newChecked = [...checkedWorkers];

    if (currentIndex === -1) {
      newChecked.push(worker);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedWorkers(newChecked);
  };

  const handleEquipmentToggle = (equipment) => {
    const currentIndex = checkedEquipments.findIndex(
      (item) => item._id === equipment._id
    );
    const newChecked = [...checkedEquipments];

    if (currentIndex === -1) {
      newChecked.push(equipment);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedEquipments(newChecked);
  };

  const changeNumber = (equipment, action, value) => {
    const currentIndex = equipments.findIndex(
      (item) => item._id === equipment._id
    );
    const newNumbers = [...numbers];
    if (action === "INC") {
      newNumbers[currentIndex] += 1;
    } else if (action === "DEC") {
      newNumbers[currentIndex] -= 1;
    } else {
      const number = parseInt(value);
      if (
        number >= 0 &&
        number <=
          (site.equipments.find((item) => item.equipment._id === equipment._id)
            ?.number ?? 0) +
            equipment.available
      ) {
        newNumbers[currentIndex] = number;
      }
    }
    setNumbers(newNumbers);
  };

  const onWorkersSubmit = async () => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      const updatedSite = await SiteService.updateSiteWorkers(siteId, {
        workers: checkedWorkers,
      });
      dispatch(updateSite({ site: updatedSite }));
      setSite(updatedSite);
      dispatch(setGlobalIsLoading({ isLoading: false }));
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };

  const onEquipmentsSubmit = async () => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      const currentEquipments = checkedEquipments.map((equipment, index) => {
        return {
          equipment: equipment,
          number:
            numbers[equipments.findIndex((item) => item._id === equipment._id)],
        };
      });
      const updatedSite = await SiteService.updateSiteEquipments(siteId, {
        equipments: currentEquipments,
      });
      dispatch(updateSite({ site: updatedSite }));
      setSite(updatedSite);
      dispatch(setGlobalIsLoading({ isLoading: false }));
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };

  useEffect(() => {
    const getSiteData = async (equipmentData) => {
      try {
        const currentSite = await SiteService.getSiteDetails(siteId);
        const currentEquipments = [];
        const currentNumbers = Array.from(
          { length: equipmentData.length },
          () => 0
        );
        currentSite.equipments.forEach((equipment) => {
          currentEquipments.push(equipment.equipment);
          currentNumbers[
            equipmentData.findIndex(
              (item) => item._id === equipment.equipment._id
            )
          ] = equipment.number ?? 0;
        });
        setSite(currentSite);
        setCheckedWorkers(currentSite.workers);
        setCheckedEquipments(currentEquipments);
        setNumbers(currentNumbers);
        setIsLoading(false);
      } catch (error) {
        toast(`${error}`);
      }
    };
    const getData = async () => {
      try {
        setIsLoading(true);
        const equipmentData =
          await EquipmentService.getClientEquipmentsDetails();
        const workersData = await AuthService.getClientWorkersDetails();
        setEquipments(equipmentData);
        setWorkers(workersData);
        setNumbers(Array.from({ length: equipmentData.length }, () => 0));
        if (siteId) {
          getSiteData(equipmentData);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        toast(`${error}`);
      }
    };
    getData();
  }, [siteId]);

  const handleClick = async () => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      await SiteService.deleteSite(site._id);
      dispatch(deleteSite({ id: site._id }));
      dispatch(setGlobalIsLoading({ isLoading: false }));
      navigate("/sites");
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };

  return (
    <>
      {isLoading ? (
        <CustomCircularProgress sx={{ height: "80vh" }} />
      ) : (
        <Box className='content'>
          {/* Header */}
          <FlexBetween className="header-gest-pages"> 
            <Box>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={theme.palette.secondary.light}
              >
                {site.name}
              </Typography>
              <Button
                startIcon={
                  <ArrowBackOutlined
                    sx={{
                      color: theme.palette.grey["600"],
                      height: "15px",
                      width: "18px",
                    }}
                  />
                }
                sx={{
                  color: theme.palette.grey["600"],
                  textTransform: "none",
                  fontSize: "14px",
                }}
                onClick={() => navigate(-1)}
              >
                Retour
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
              }}
            >
              <IconButton
                sx={{
                  border: "1px solid",
                  borderRadius: "4px",
                  borderColor: theme.palette.grey.light,
                }}
                onClick={() => {
                  navigate(`edit`);
                }}
              >
                <BorderColorOutlined sx={{ color: theme.palette.alt.main }} />
              </IconButton>
              <IconButton
                sx={{
                  border: "1px solid",
                  borderRadius: "4px",
                  borderColor: theme.palette.grey.light,
                }}
                onClick={() => setOpen(true)}
              >
                <DeleteOutline sx={{ color: theme.palette.alt.main }} />
              </IconButton>
            </Box>
          </FlexBetween>
          {/* Body */}
          <Box
            sx={{
              backgroundColor: theme.palette.grey[100],
              p: "20px",
              height: "calc(100vh - 140px)",
              overflow: "auto",
              margin:"auto",
              width:"95%"
            }}
          >
            <List
              sx={{
                height: "100%",
              }}
            >
              {/* Details */}
              <Box
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  p: "10px",
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.grey["600"],
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  À propos
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "70px",
                    rowGap: "20px",
                    padding: "10px",
                    alignItems: "start",
                    justifyContent: "start",
                  }}
                >
                  <CustomText title="Nom du chantier" subTitle={site.name} />
                  <CustomText
                    title="Adresse"
                    subTitle={`${site.zip_code} ${site.zone}, ${site.region}, ${site.country}`}
                  />
                  <CustomText
                    title="Date de début"
                    subTitle={formatDate(site.begin_date)}
                  />
                  <CustomText
                    title="Date de fin"
                    subTitle={formatDate(site.end_date)}
                  />
                  <CustomText
                    title="Contacts Clés"
                    subTitle={site.contact_keys?.map((key) => (
                      <Box key={key}>{key}</Box>
                    ))}
                  />
                  <CustomText
                    title="Propriétaire du chantier "
                    subTitle={site.owner}
                  />
                  <CustomText title="Type de chantier" subTitle={site.type} />
                </Box>
              </Box>
              {/* Workers and Equipements */}
              <Box
                sx={{
                  display: "flex",
                  mt: "20px",
                  gap: "10px",
                  flexWrap: "nowrap",
                }}
              >
                {/* Workers */}
                <Box
                  height="430px"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: "6px",
                    pt: "20px",
                    flex: "1",
                  }}
                >
                  <FlexBetween>
                    <Typography
                      fontSize="20px"
                      fontWeight="bold"
                      color={theme.palette.grey[600]}
                      sx={{
                        pl: "15px",
                      }}
                    >
                      Équipe
                    </Typography>
                    <IconButton
                      size="small"
                      sx={{
                        border: "1px solid",
                        borderRadius: "4px",
                        borderColor: theme.palette.grey.light,
                        mr: "15px",
                      }}
                      onClick={() => {
                        setWorkersOverlayOpen(true);
                      }}
                    >
                      <BorderColorOutlined
                        sx={{ color: theme.palette.alt.main }}
                      />
                    </IconButton>
                  </FlexBetween>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      color={theme.palette.grey[600]}
                      fontSize={"16px"}
                      width="100%"
                      display="flex"
                      justifyContent="space-between"
                      m="10px"
                      p="0 30px"
                    >
                      <Typography variant="h6">Employé</Typography>
                      <Typography variant="h6">Département</Typography>
                    </Box>
                    <Divider
                      sx={{
                        textAlign: "center",
                        height: "2px",
                        width: "100%",
                        backgroundColor: "black",
                      }}
                    />
                  </Box>
                  {site.workers && site.workers?.length !== 0 ? (
                    <Box
                      sx={{
                        width: "100%",
                        overflow: "auto",
                        height: "70%",
                      }}
                    >
                      <List>
                        {site.workers.map((item) => (
                          <Box key={item._id}>
                            <ListItem
                              sx={{
                                padding: "10px 30px",
                              }}
                              disablePadding
                              secondaryAction={
                                <Typography
                                  variant="h6"
                                  sx={{
                                    color: theme.palette.secondary.main,
                                    textAlign: "start",
                                    ml: "-100px",
                                  }}
                                >
                                  {item.departement}
                                </Typography>
                              }
                            >
                              <ListItemText
                                id="worker-label-id"
                                sx={{ color: "black" }}
                                primaryTypographyProps={{
                                  style: {
                                    color: theme.palette.secondary.main,
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  },
                                }}
                                secondaryTypographyProps={{
                                  style: {
                                    color: theme.palette.grey[600],
                                    fontSize: "14px",
                                  },
                                }}
                                primary={`${item.firstname} ${item.lastName}`}
                                secondary={item.phone}
                              />
                            </ListItem>
                            <Divider
                              sx={{
                                textAlign: "center",
                                height: "1px",
                                backgroundColor: "black",
                                m: "0 10px",
                              }}
                            />
                          </Box>
                        ))}
                      </List>
                    </Box>
                  ) : (
                    <NotFoundError text="Aucun Membre trouvé !" />
                  )}
                </Box>
                {/* Equipments */}
                <Box
                  height="430px"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: "6px",
                    pt: "20px",
                    flex: "1",
                  }}
                >
                  <FlexBetween>
                    <Typography
                      fontSize="20px"
                      fontWeight="bold"
                      color={theme.palette.grey[600]}
                      sx={{
                        pl: "15px",
                      }}
                    >
                      Matériaux et Équipement
                    </Typography>
                    <IconButton
                      size="small"
                      sx={{
                        border: "1px solid",
                        borderRadius: "4px",
                        borderColor: theme.palette.grey.light,
                        mr: "15px",
                      }}
                      onClick={() => {
                        setEquipmentsOverlayOpen(true);
                      }}
                    >
                      <BorderColorOutlined
                        sx={{ color: theme.palette.alt.main }}
                      />
                    </IconButton>
                  </FlexBetween>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      color={theme.palette.grey[600]}
                      fontSize={"16px"}
                      width="100%"
                      display="flex"
                      justifyContent="space-between"
                      m="10px"
                      p="0 30px"
                    >
                      <Typography variant="h6">Équipement</Typography>
                      <Typography variant="h6">Nombre</Typography>
                    </Box>
                    <Divider
                      sx={{
                        textAlign: "center",
                        height: "2px",
                        width: "100%",
                        backgroundColor: "black",
                      }}
                    />
                  </Box>
                  {site.equipments && site.equipments?.length !== 0 ? (
                    <Box
                      sx={{
                        width: "100%",
                        overflow: "auto",
                        height: "70%",
                      }}
                    >
                      <List>
                        {site.equipments.map((item) => (
                          <Box key={item.equipment._id}>
                            <ListItem
                              sx={{
                                padding: "10px 30px",
                              }}
                              disablePadding
                              secondaryAction={
                                <Typography
                                  variant="h6"
                                  sx={{
                                    color: theme.palette.secondary.main,
                                    textAlign: "start",
                                    ml: "-50px",
                                  }}
                                >
                                  {item.number}
                                </Typography>
                              }
                            >
                              <ListItemText
                                id="worker-label-id"
                                sx={{ color: "black" }}
                                primaryTypographyProps={{
                                  style: {
                                    color: theme.palette.secondary.main,
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  },
                                }}
                                secondaryTypographyProps={{
                                  style: {
                                    color: theme.palette.grey[600],
                                    fontSize: "14px",
                                  },
                                }}
                                primary={`${item.equipment.name}`}
                              />
                            </ListItem>
                            <Divider
                              sx={{
                                textAlign: "center",
                                height: "1px",
                                backgroundColor: "black",
                                m: "0 10px",
                              }}
                            />
                          </Box>
                        ))}
                      </List>
                    </Box>
                  ) : (
                    <NotFoundError text="Aucun Équipement trouvé !" />
                  )}
                </Box>
              </Box>
            </List>
          </Box>
          <PopUp open={workersOverlayOpen} setOpen={setWorkersOverlayOpen}>
            <WorkersOverlay
              checkedWorkers={checkedWorkers}
              handleWorkerToggle={handleWorkerToggle}
              workers={workers}
              setOpen={setWorkersOverlayOpen}
              onClick={onWorkersSubmit}
              setWorkers={setWorkers}
              site={site}
            />
          </PopUp>
          <PopUp
            open={equipmentsOverlayOpen}
            setOpen={setEquipmentsOverlayOpen}
          >
            <EquipmentsOverlay
              site={site}
              changeNumber={changeNumber}
              checkedEquipments={checkedEquipments}
              equipments={equipments}
              handleEquipmentToggle={handleEquipmentToggle}
              numbers={numbers}
              setOpen={setEquipmentsOverlayOpen}
              onClick={onEquipmentsSubmit}
            />
          </PopUp>
          <CustomDialog
            title={`Supprimer chantier ${site.name}`}
            content={`Vous êtes sûr de supprimer le chantier ${site.name}?`}
            onClick={handleClick}
            open={open}
            setOpen={setOpen}
          />
        </Box>
      )}
    </>
  );
};

const CustomText = ({ title, subTitle, sx }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        flex: "1",
        textAlign: "start",
        whiteSpace: "nowrap",
        ...sx,
      }}
    >
      <Typography
        sx={{
          color: theme.palette.grey["600"],
          fontSize: "14",
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="h5"
        sx={{
          color: theme.palette.grey["400"],
        }}
      >
        {subTitle}
      </Typography>
    </Box>
  );
};

export default SiteDetails;
