import handleError from "utils/handelError";
import axiosClient from "./client";
import {
  createFinancialsQuoteEndPoint,
  deleteFinancialsQuoteEndPoint,
  getAllClientFinancialsQuotesEndPoint,
  getAllFinancialsQuotesEndPoint,
  getFinancialsQuoteDetailsEndPoint,
  sortFinancialsQuotesEndPoint,
  updateFinancialsQuoteDetailsEndPoint,
  updateFinancialsQuoteStateEndPoint,
} from "./Constants";

const FinancialsQuoteService = {
  createFinancialsQuote: async (data) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.post(
        createFinancialsQuoteEndPoint,
        { ...data, client: clientId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const quote = response.data.quote;
      return quote;
    } catch (error) {
      handleError(error);
    }
  },

  getFinancialsQuoteDetails: async (id) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(
        getFinancialsQuoteDetailsEndPoint(id),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const quote = response.data.quote;
      return quote;
    } catch (error) {
      handleError(error);
    }
  },

  getFinancialsQuotesDetails: async () => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getAllFinancialsQuotesEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const quotes = response.data.quotes;
      return quotes;
    } catch (error) {
      handleError(error);
    }
  },

  getClientFinancialsQuotesDetails: async () => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.get(
        getAllClientFinancialsQuotesEndPoint,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            clientId,
          },
        }
      );
      const quotes = response.data.quotes;
      return quotes;
    } catch (error) {
      handleError(error);
    }
  },

  updateFinancialsQuoteDetails: async (id, data) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.put(
        updateFinancialsQuoteDetailsEndPoint(id),
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const quote = response.data.quote;
      return quote;
    } catch (error) {
      handleError(error);
    }
  },

  deleteFinancialsQuote: async (id) => {
    try {
      const token = localStorage.getItem("@userCsc");
      await axiosClient.delete(deleteFinancialsQuoteEndPoint(id), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      handleError(error);
    }
  },

  updateFinancialsQuoteState: async (id, state) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.put(
        updateFinancialsQuoteStateEndPoint(id),
        { state },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const quote = response.data.quote;
      return quote;
    } catch (error) {
      handleError(error);
    }
  },

  sortFinancialsQuotes: async (option) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.get(sortFinancialsQuotesEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          option,
          client: clientId,
        },
      });
      const quotes = response.data.quotes;
      return quotes;
    } catch (error) {
      handleError(error);
    }
  },
};

export default FinancialsQuoteService;
