import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import { getAllPersonnelsByClient } from '../../redux/actions/PersonnelAction';
function FicheDePaieForm({ onDataPassed }) {
    // State variables
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        heuresContrat: '',
        heuresSupplementaires: '',
        commentaire: '',
        HTJ: { commentaires: '', qte: 0, taux: 0, montant: 0 },
        HTN: { commentaires: '', qte: 0, taux: 0, montant: 0 },
        HTD: { commentaires: '', qte: 0, taux: 0, montant: 0 },
        HCP: { commentaires: '', qte: 0, taux: 0, montant: 0 },
        HF: { commentaires: '', qte: 0, taux: 0, montant: 0 },
        RCN: { commentaires: '', qte: 0, taux: 0, montant: 0 },
        PANJ: { commentaires: '', qte: 0, taux: 0, montant: 0 },
        PEN1: { commentaires: '', qte: 0, taux: 0, montant: 0 },
        PEN2: { commentaires: '', qte: 0, taux: 0, montant: 0 },
        ACOMPT: { commentaires: '', qte: 0, taux: 0, montant: 0 },
        creationDate: new Date().toISOString().slice(0, 10)
    });
    const [selectedOption, setSelectedOption] = useState(null);
    const [searchInput, setSearchInput] = useState('');
    const personnels = useSelector(state => state.personnel.personnels);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const idClient = localStorage.getItem('idClient')
                setLoading(true);
                await dispatch(getAllPersonnelsByClient(idClient));
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log('Erreur lors de la récupération des données :', error);
            }
        };
        fetchData();
    }, [dispatch]);

    // Fetching data and calculations
    useEffect(() => {
        const calculerMontant = (Qte, Taux) => {
            const montant = parseInt(Qte) * parseInt(Taux);
            return isNaN(montant) ? 0 : montant;
        };

        const MontantHTJ = calculerMontant(formData.HTJ.qte, formData.HTJ.taux);
        const MontantHTN = calculerMontant(formData.HTN.qte, formData.HTN.taux);
        const MontantHTD = calculerMontant(formData.HTD.qte, formData.HTD.taux);
        const MontantHCP = calculerMontant(formData.HCP.qte, formData.HCP.taux);
        const MontantHF = calculerMontant(formData.HF.qte, formData.HF.taux);
        const MontantRCN = calculerMontant(formData.RCN.qte, formData.RCN.taux);
        const MontantPANJ = calculerMontant(formData.PANJ.qte, formData.PANJ.taux);
        const MontantPEN1 = calculerMontant(formData.PEN1.qte, formData.PEN1.taux);
        const MontantPEN2 = calculerMontant(formData.PEN2.qte, formData.PEN2.taux);
        const MontantACOMPT = calculerMontant(formData.ACOMPT.qte, formData.ACOMPT.taux);

        setFormData(prevState => ({
            ...prevState,
            HTJ: { ...formData.HTJ, montant: MontantHTJ },
            HTN: { ...formData.HTN, montant: MontantHTN },
            HTD: { ...formData.HTD, montant: MontantHTD },
            HCP: { ...formData.HCP, montant: MontantHCP },
            HF: { ...formData.HF, montant: MontantHF },
            RCN: { ...formData.RCN, montant: MontantRCN },
            PANJ: { ...formData.PANJ, montant: MontantPANJ },
            PEN1: { ...formData.PEN1, montant: MontantPEN1 },
            PEN2: { ...formData.PEN2, montant: MontantPEN2 },
            ACOMPT: { ...formData.ACOMPT, montant: MontantACOMPT }
        }));
    }, [
        formData.HTJ.qte, formData.HTJ.taux, formData.HTN.qte, formData.HTN.taux,
        formData.HTD.qte, formData.HTD.taux, formData.HCP.qte, formData.HCP.taux,
        formData.HF.qte, formData.HF.taux, formData.RCN.qte, formData.RCN.taux,
        formData.PANJ.qte, formData.PANJ.taux, formData.PEN1.qte, formData.PEN1.taux,
        formData.PEN2.qte, formData.PEN2.taux, formData.ACOMPT.qte, formData.ACOMPT.taux,
    ]);




    //  useEffect(() => {
    //     setFormData(prevState => ({
    //         ...prevState,
    //             HTJ: { ...formData.HTJ, montant: MontantHTJ },
    //             HTN: { ...formData.HTN, montant: MontantHTN },
    //             HTD: { ...formData.HTD, montant: MontantHTD },
    //             HCP: { ...formData.HCP, montant: MontantHCP },
    //             HF: { ...formData.HF, montant: MontantHF },
    //             RCN: { ...formData.RCN, montant: MontantRCN },
    //             PANJ: { ...formData.PANJ, montant: MontantPANJ },
    //             PEN1: { ...formData.PEN1, montant: MontantPEN1 },
    //             PEN2: { ...formData.PEN2, montant: MontantPEN2 },
    //             ACOMPT: { ...formData.ACOMPT, montant: MontantACOMPT }
    //         }));


    // }, [
    //     MontantHTJ, MontantHTN, MontantHTD, MontantHCP, MontantHF,
    //     MontantRCN, MontantPANJ, MontantPEN1, MontantPEN2, MontantACOMPT,formData
    // ]);
    useEffect(() => {

        onDataPassed(formData);
    }, [formData]);
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: parseInt(e.target.value) });
    };



    const filteredOptions = personnels.filter(personnel =>
        `${personnel?.firstname} ${personnel?.lastName}` || `${personnel?.NumEmpl}`
            .toLowerCase()
            .includes(searchInput.toLowerCase())
    );

    const handleChangeEmp = (selectedOption) => {
        setSelectedOption(selectedOption);
        setFormData({
            ...formData,
            personnel: selectedOption,

        })
    };
    return (
        <div className="container">
            <div className="conatinerPaieEmp">
                <div className="formulaire-paie-employe">
                    <div>
                        <p>Nom de l’employé</p>
                        <Select
                            style={{ height: "38px" }}
                            name="employe"
                            value={selectedOption}
                            onChange={(selectedOption) => handleChangeEmp(selectedOption)}
                            options={filteredOptions}
                            isSearchable
                            onInputChange={(inputValue) => setSearchInput(inputValue)}
                            placeholder="Sélectionnez un employé"
                            className='gestionContainerHeaderSelect'
                            getOptionLabel={(option) => `${option.firstname} ${option.lastName} - ${option.NumEmpl}`}
                            getOptionValue={(option) => option._id}
                        />
                    </div>
                    <div>
                        <p>Heures de contrat</p>
                        <input type="number" name="heuresContrat" value={formData.heuresContrat} onChange={handleChange} />
                    </div>
                    <div>
                        <p>Heures supplémentaires en mois entier </p>
                        <input type="number" name="heuresSupplementaires" value={formData.heuresSupplementaires} onChange={handleChange} />
                    </div>
                </div>
                <div className='information-paie-employe'>
                    <div>
                        <div>
                            <p>Adresse:</p>
                            <p> {selectedOption ? selectedOption.adresse : "---"}</p>
                        </div>
                        <div>
                            <p>NSS:</p>
                            <p>{selectedOption ? selectedOption.nss : "---"}</p>
                        </div>
                        <div>
                            <p>Type de travail:</p>
                            <p>{selectedOption ? selectedOption.TypeTravail : "---"}</p>
                        </div>
                    </div>
                    <div>
                        <div>
                            <p>Date d’entrée:</p>
                            <p>{selectedOption ? selectedOption.dateEntree.slice(0, 10) : "---"}</p>
                        </div>
                        <div>
                            <p>Date de sortie:</p>
                            <p>{(selectedOption && selectedOption.dateSortie) ? selectedOption.dateSortie.slice(0, 10) : "---"}</p>
                        </div>
                        <div>
                            <p>Type de contrat:</p>
                            <p>{selectedOption ? selectedOption.TypeContrat : "---"}</p>
                        </div>
                        <div>
                            <p>Temps de travail:</p>
                            <p>{selectedOption ? selectedOption.tempsTravail : "---"}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fichePaiTableContainerForAdd">
                <table>
                    <thead>
                        <tr>
                            <th>Abréviation</th>
                            <th>Composants</th>
                            <th>Commentaires</th>
                            <th>Qte</th>
                            <th>Taux</th>
                            <th>Montant</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>HTJ</td>
                            <td>Heures de travail de jour</td>
                            <td className='comment-td'><input type="text" placeholder='Votre commentaire ici' value={formData.HTJ.commentaires} onChange={(e) => setFormData({ ...formData, HTJ: { ...formData.HTJ, commentaires: e.target.value } })} /></td>
                            <td className='numberPaiTableForAdd'><input type="number" onChange={e => setFormData({ ...formData, HTJ: { ...formData.HTJ, qte: parseInt(e.target.value) } })} value={formData.HTJ.qte} /></td>
                            <td className='numberPaiTableForAdd'><input type="number" onChange={e => setFormData({ ...formData, HTJ: { ...formData.HTJ, taux: parseInt(e.target.value) } })} value={formData.HTJ.taux} /></td>
                            <td className='numberPaiTableForAdd'>{formData.HTJ.montant}</td>
                        </tr>
                        <tr>
                            <td>HTN</td>
                            <td>Heures de travail de nuit</td>
                            <td className='comment-td'><input type="text" placeholder='Votre commentaire ici' value={formData.HTN.commentaires} onChange={(e) => setFormData({ ...formData, HTN: { ...formData.HTN, commentaires: e.target.value } })} /></td>
                            <td className='numberPaiTableForAdd'><input type="number" onChange={e => setFormData({ ...formData, HTN: { ...formData.HTN, qte: parseInt(e.target.value) } })} value={formData.HTN.qte} /></td>
                            <td className='numberPaiTableForAdd'><input type="number" onChange={e => setFormData({ ...formData, HTN: { ...formData.HTN, taux: parseInt(e.target.value) } })} value={formData.HTN.taux} /></td>
                            <td className='numberPaiTableForAdd'>{formData.HTN.montant}</td>
                        </tr>
                        <tr>
                            <td>HTD</td>
                            <td>Heures de travail Dimanche</td>
                            <td className='comment-td'><input type="text" placeholder='Votre commentaire ici' value={formData.HTD.commentaires} onChange={(e) => setFormData({ ...formData, HTD: { ...formData.HTD, commentaires: e.target.value } })} /></td>
                            <td className='numberPaiTableForAdd'><input type="number" onChange={e => setFormData({ ...formData, HTD: { ...formData.HTD, qte: parseInt(e.target.value) } })} value={formData.HTD.qte} /></td>
                            <td className='numberPaiTableForAdd'><input type="number" onChange={e => setFormData({ ...formData, HTD: { ...formData.HTD, taux: parseInt(e.target.value) } })} value={formData.HTD.taux} /></td>
                            <td className='numberPaiTableForAdd'>{formData.HTD.montant}</td>
                        </tr>
                        <tr>
                            <td>HCP</td>
                            <td>Heures congés payés</td>
                            <td className='comment-td'><input type="text" placeholder='Votre commentaire ici' value={formData.HCP.commentaires} onChange={(e) => setFormData({ ...formData, HCP: { ...formData.HCP, commentaires: e.target.value } })} /></td>
                            <td className='numberPaiTableForAdd'><input type="number" onChange={e => setFormData({ ...formData, HCP: { ...formData.HCP, qte: parseInt(e.target.value) } })} value={formData.HCP.qte} /></td>
                            <td className='numberPaiTableForAdd'><input type="number" onChange={e => setFormData({ ...formData, HCP: { ...formData.HCP, taux: parseInt(e.target.value) } })} value={formData.HCP.taux} /></td>
                            <td className='numberPaiTableForAdd'>{formData.HCP.montant}</td>
                        </tr>
                        <tr>
                            <td>HF</td>
                            <td>Heures fériées</td>
                            <td className='comment-td'><input type="text" placeholder='Votre commentaire ici' value={formData.HF.commentaires} onChange={(e) => setFormData({ ...formData, HF: { ...formData.HF, commentaires: e.target.value } })} /></td>
                            <td className='numberPaiTableForAdd'><input type="number" onChange={e => setFormData({ ...formData, HF: { ...formData.HF, qte: parseInt(e.target.value) } })} value={formData.HF.qte} /></td>
                            <td className='numberPaiTableForAdd'><input type="number" onChange={e => setFormData({ ...formData, HF: { ...formData.HF, taux: parseInt(e.target.value) } })} value={formData.HF.taux} /></td>
                            <td className='numberPaiTableForAdd'>{formData.HF.montant}</td>
                        </tr>
                        <tr>
                            <td>RCN</td>
                            <td>Repos comp acquis travail nuit</td>
                            <td className='comment-td'><input type="text" placeholder='Votre commentaire ici' value={formData.RCN.commentaires} onChange={(e) => setFormData({ ...formData, RCN: { ...formData.RCN, commentaires: e.target.value } })} /></td>
                            <td className='numberPaiTableForAdd'><input type="number" onChange={e => setFormData({ ...formData, RCN: { ...formData.RCN, qte: parseInt(e.target.value) } })} value={formData.RCN.qte} /></td>
                            <td className='numberPaiTableForAdd'><input type="number" onChange={e => setFormData({ ...formData, RCN: { ...formData.RCN, taux: parseInt(e.target.value) } })} value={formData.RCN.taux} /></td>
                            <td className='numberPaiTableForAdd'>{formData.RCN.montant}</td>
                        </tr>
                        <tr>
                            <td>PANJ</td>
                            <td>Prime panier</td>
                            <td className='comment-td'><input type="text" placeholder='Votre commentaire ici' value={formData.PANJ.commentaires} onChange={(e) => setFormData({ ...formData, PANJ: { ...formData.PANJ, commentaires: e.target.value } })} /></td>
                            <td className='numberPaiTableForAdd'><input type="number" onChange={e => setFormData({ ...formData, PANJ: { ...formData.PANJ, qte: parseInt(e.target.value) } })} value={formData.PANJ.qte} /></td>
                            <td className='numberPaiTableForAdd'><input type="number" onChange={e => setFormData({ ...formData, PANJ: { ...formData.PANJ, taux: parseInt(e.target.value) } })} value={formData.PANJ.taux} /></td>
                            <td className='numberPaiTableForAdd'>{formData.PANJ.montant}</td>
                        </tr>
                        <tr>
                            <td>PEN1</td>
                            <td>Pénibilité Travail nuit</td>
                            <td className='comment-td'><input type="text" placeholder='Votre commentaire ici' value={formData.PEN1.commentaires} onChange={(e) => setFormData({ ...formData, PEN1: { ...formData.PEN1, commentaires: e.target.value } })} /></td>
                            <td className='numberPaiTableForAdd'><input type="number" onChange={e => setFormData({ ...formData, PEN1: { ...formData.PEN1, qte: parseInt(e.target.value) } })} value={formData.PEN1.qte} /></td>
                            <td className='numberPaiTableForAdd'><input type="number" onChange={e => setFormData({ ...formData, PEN1: { ...formData.PEN1, taux: parseInt(e.target.value) } })} value={formData.PEN1.taux} /></td>
                            <td className='numberPaiTableForAdd'>{formData.PEN1.montant}</td>
                        </tr>
                        <tr>
                            <td>PEN2</td>
                            <td>Pénibilité Équipe Alternante</td>
                            <td className='comment-td'><input type="text" placeholder='Votre commentaire ici' value={formData.PEN2.commentaires} onChange={(e) => setFormData({ ...formData, PEN2: { ...formData.PEN2, commentaires: e.target.value } })} /></td>
                            <td className='numberPaiTableForAdd'><input type="number" onChange={e => setFormData({ ...formData, PEN2: { ...formData.PEN2, qte: parseInt(e.target.value) } })} value={formData.PEN2.qte} /></td>
                            <td className='numberPaiTableForAdd'><input type="number" onChange={e => setFormData({ ...formData, PEN2: { ...formData.PEN2, taux: parseInt(e.target.value) } })} value={formData.PEN2.taux} /></td>
                            <td className='numberPaiTableForAdd'>{formData.PEN2.montant}</td>
                        </tr>
                        <tr>
                            <td>ACOMPT</td>
                            <td>Acompte</td>
                            <td className='comment-td'><input type="text" placeholder='Votre commentaire ici' value={formData.ACOMPT.commentaires} onChange={(e) => setFormData({ ...formData, ACOMPT: { ...formData.ACOMPT, commentaires: e.target.value } })} /></td>
                            <td className='numberPaiTableForAdd'><input type="number" onChange={e => setFormData({ ...formData, ACOMPT: { ...formData.ACOMPT, qte: parseInt(e.target.value) } })} value={formData.ACOMPT.qte} /></td>
                            <td className='numberPaiTableForAdd'><input type="number" onChange={e => setFormData({ ...formData, ACOMPT: { ...formData.ACOMPT, taux: parseInt(e.target.value) } })} value={formData.ACOMPT.taux} /></td>
                            <td className='numberPaiTableForAdd'>{formData.ACOMPT.montant}</td>
                        </tr>

                    </tbody>
                </table>


            </div>
            <div className="fichePaiTimeWorkForAdd">
                <div className="fichePaiCommentForAdd">
                    <h3>Commentaires</h3>
                    <p>Votre commentaire ici</p>
                </div>
                <div className="fichePaiTimeWorkLeftForAdd">
                    <h3>Ventilation des heures</h3>
                    <div>
                        <p>Heures travaillées productives</p>
                        <p>{formData.heuresContrat} €</p>
                    </div>
                    <div>
                        <p>Heures congés payés</p>
                        <p>{formData.HCP.montant} €</p>
                    </div>
                    <div>
                        <p>Heures hors production</p>
                        <p>00 €</p>
                    </div>
                    <div>
                        <p>Heures totales planifiées</p>
                        <p>00 €</p>
                    </div>
                </div>
                <div className="fichePaiTimeWorkRightForAdd">
                    <h3>Totaux des heures</h3>
                    <div>
                        <p>Total d'heures jour</p>
                        <p>{formData.HTJ.montant} €</p>
                    </div>
                    <div>
                        <p>Total d'heure nuit</p>
                        <p>{formData.HTN.montant} €</p>
                    </div>
                    <div>
                        <p>Dont Heures férié</p>
                        <p>{formData.HF.montant} €</p>
                    </div>
                    <div>
                        <p>Dont heure dimanche</p>
                        <p>{formData.HTD.montant} €</p>
                    </div>
                </div>

            </div>
            {/* <button onClick={handleSubmit}>submit</button> */}
        </div>
    )
}

export default FicheDePaieForm