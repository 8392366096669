import refreshToken from "../../helpers/functions";

export const getAllConge = async () => {
    let http = await refreshToken();
    let result = await http.get(`conge`);
    return result.data;
}

export const getCongeById = async (id) => {
    let http = await refreshToken();
    let result = await http.get(`conge/${id}`);
    return result.data;
}

export const addConge = async (data) => {
    let http = await refreshToken();
    let result = await http.post(`conge`, data);
    return result.data;
}

export const updateConge = async (id, data) => {
    let http = await refreshToken();
    let result = await http.put(`conge/${id}`, data);
    return result.data;
}

export const deleteConge = async (id) => {
    let http = await refreshToken();
    let result = await http.delete(`conge/${id}`);
    return result.data;
}

export const getCongeByClientId = async (id) => {
    let http = await refreshToken();
    let result = await http.get(`conge/client/${id}`);
    return result.data
}

export const getCongeByEmploye = async (id) => {
let http =  await refreshToken();
let result = await http.get(`conge/employe/${id}`)
return result
}