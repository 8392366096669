import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  invoices: [],
  commandes: [],
  estimates: [],
  quotes: [],
};

const financialsSlice = createSlice({
  name: "financials",
  initialState,
  reducers: {
    // financials estimate events
    setEstimates: (state, action) => {
      const { estimates } = action.payload;
      state.estimates = estimates;
    },
    updateEstimate: (state, action) => {
      const { estimate } = action.payload;
      for (const [key, value] of Object.entries(state.estimates)) {
        if (value._id?.toString() === estimate._id?.toString()) {
          state.estimates[key] = estimate;
          break;
        }
      }
    },
    addEstimate: (state, action) => {
      const { estimate } = action.payload;
      state.estimates.push(estimate);
    },
    deleteEstimate: (state, action) => {
      const { id } = action.payload;
      state.estimates = state.estimates.filter(
        (estimate) => estimate._id?.toString() !== id?.toString()
      );
    },
    // financials quote events
    setFinancialsQuotes: (state, action) => {
      const { quotes } = action.payload;
      state.quotes = quotes;
    },
    updateFinancialsQuote: (state, action) => {
      const { quote } = action.payload;
      for (const [key, value] of Object.entries(state.quotes)) {
        if (value._id?.toString() === quote._id?.toString()) {
          state.quotes[key] = quote;
          break;
        }
      }
    },
    addFinancialsQuote: (state, action) => {
      const { quote } = action.payload;
      state.quotes.push(quote);
    },
    deleteFinancialsQuote: (state, action) => {
      const { id } = action.payload;
      state.quotes = state.quotes.filter(
        (quote) => quote._id?.toString() !== id?.toString()
      );
    },
    // financials commande events
    setCommandes: (state, action) => {
      const { commandes } = action.payload;
      state.commandes = commandes;
    },
    updateCommande: (state, action) => {
      const { commande } = action.payload;
      for (const [key, value] of Object.entries(state.commandes)) {
        if (value._id?.toString() === commande._id?.toString()) {
          state.commandes[key] = commande;
          break;
        }
      }
    },
    addCommande: (state, action) => {
      const { commande } = action.payload;
      state.commandes.push(commande);
    },
    deleteCommande: (state, action) => {
      const { id } = action.payload;
      state.commandes = state.commandes.filter(
        (commande) => commande._id?.toString() !== id?.toString()
      );
    },
    // financials invoice events
    setFinancialsInvoices: (state, action) => {
      const { invoices } = action.payload;
      state.invoices = invoices;
    },
    updateFinancialsInvoice: (state, action) => {
      const { invoice } = action.payload;
      for (const [key, value] of Object.entries(state.invoices)) {
        if (value._id?.toString() === invoice._id?.toString()) {
          state.invoices[key] = invoice;
          break;
        }
      }
    },
    addFinancialsInvoice: (state, action) => {
      const { invoice } = action.payload;
      state.invoices.push(invoice);
    },
    deleteFinancialsInvoice: (state, action) => {
      const { id } = action.payload;
      state.invoices = state.invoices.filter(
        (invoice) => invoice._id?.toString() !== id?.toString()
      );
    },
  },
});

export const {
  setFinancialsInvoices,
  updateFinancialsInvoice,
  addFinancialsInvoice,
  deleteFinancialsInvoice,
  setCommandes,
  updateCommande,
  addCommande,
  deleteCommande,
  setEstimates,
  updateEstimate,
  addEstimate,
  deleteEstimate, 
  setFinancialsQuotes, 
  updateFinancialsQuote, 
  addFinancialsQuote, 
  deleteFinancialsQuote,
} =
  financialsSlice.actions;

export default financialsSlice.reducer;

export const selectFinancialsInvoices = (state) => state.financials.invoices;
export const selectCommandes = (state) => state.financials.commandes;
export const selectEstimates = (state) => state.financials.estimates;
export const selectFinancialsQuotes = (state) => state.financials.quotes;

