import { useTheme } from "@emotion/react";
import { Box, Button, Divider, List, Typography } from "@mui/material";
import { formatDate } from "utils/format_date";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useState } from "react";

const PlanificationExport = ({ tasks, setOpen, selectedDate }) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const currentTasks = tasks.filter(
    (item) => formatDate(item.begin_date) === formatDate(selectedDate)
  );
  const downloadPlan = async () => {
    try {
      setIsLoading(true);
      const capture = document.querySelector('.planification-body')
      const canvas = await html2canvas(capture)
      const imgData = canvas.toDataURL('img/png')
      const doc = new jsPDF('p','em','a4')
      const componentWidth = doc.internal.pageSize.getWidth()
      const componentHeight = doc.internal.pageSize.getHeight()
      doc.addImage(imgData,'PNG',0,0,componentWidth,componentHeight)
      doc.save(`planification${formatDate(selectedDate)}.pdf`)

      setIsLoading(false);
      setOpen(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <Box
      sx={{
        width: "482px",
        borderRadius: "6px",
        backgroundColor: theme.palette.primary.main,
        p: "20px",
      }}
    >
      <Box className="planification-body" sx={{
        width: "100%",
        borderRadius: "6px",
        backgroundColor: theme.palette.primary.main,
        p: "20px",
      }}>
        <Box
          sx={{
            width: "100%",
            textAlign: "right",
            color: theme.palette.secondary.light,
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          Les tâches de {formatDate(selectedDate)}
        </Box>
        <Box mb="10px">
          <List
            sx={{
              bgcolor: theme.palette.primary.main,
            }}
          >
            {currentTasks.map((task) => (
              <Box key={task._id?.toString()} width="100%">
                <Box
                  sx={{
                    width: "100%",
                    minHeight: "80px",
                    display: "flex",
                    wordBreak:'break-word'
                  }}
                >
                  {/* Top part */}
                  <Box
                    sx={{
                      height: "100%",
                      width: "8px",
                      bgcolor: theme.palette.error[600],
                      borderTopLeftRadius: "6px",
                      borderBottomLeftRadius: "6px",
                    }}
                  />
                  <Box
                    sx={{
                      flexGrow: "1",
                      bgcolor: theme.palette.grey[900],
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      p: "5px",
                      borderTopRightRadius: "6px",
                      borderBottomRightRadius: "6px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: "bold",
                      }}
                    >
                      {task.name}
                    </Typography>
                    <Typography
                      sx={{
                        color: theme.palette.secondary.light,
                      }}
                    >
                      {task.description}
                    </Typography>
                  </Box>
                </Box>
                {/* Bottm part */}
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "nowrap",
                    p: "3px 10px",
                  }}
                >
                  <Box flex="1">
                    <Typography
                      sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: "600",
                      }}
                    >
                      Foulen Fouleni
                    </Typography>
                    <Typography sx={{ color: theme.palette.secondary.light }}>
                      {task.place}
                    </Typography>
                    <Box>
                      {task.contact_kays?.map((key) => (
                        <Typography
                          sx={{ color: theme.palette.secondary.light }}
                          key={key}
                        >
                          {key}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                  <Box flex="1" display="flex">
                    <Divider
                      orientation="horizontal"
                      sx={{
                        m: "5px",
                        width: "3px",
                        bgcolor: theme.palette.grey[900],
                      }}
                    />
                    <Typography
                      sx={{
                        color: theme.palette.secondary.light,
                      }}
                    >
                      {task.instructions}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </List>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <Button
          sx={{
            width: "100px",
            fontSize: "16px",
            fontWeight: "400",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.alt.main,
            border: "1px solid",
            borderRadius: "4px",
            borderColor: theme.palette.grey.light,
            textTransform: "none",
            ":hover": {
              backgroundColor: theme.palette.primary[400],
            },
          }}
          onClick={() => setOpen(false)}
        >
          Annuler
        </Button>
        <Button
          sx={{
            width: "150px",
            fontSize: "16px",
            fontWeight: "400",
            backgroundColor: theme.palette.alt.main,
            textTransform: "none",
            ":hover": {
              backgroundColor: theme.palette.alt[400],
            },
          }}
          onClick={() => downloadPlan()}
          disabled={isLoading || currentTasks?.length === 0}
        >
          {isLoading ? "Téléchargement..." : "Télécharger"}
        </Button>
      </Box>
    </Box>
  );
};

export default PlanificationExport;
