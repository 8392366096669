import React from 'react'

function ServiceCard({ service }) {
    // console.log('service', service)
    return (
        <div className='col-lg-3 col-md-6 col-sm-12 mb-4'>
            <div className="serviceCardContainer">
                <div>
                    <h4>{service.title}</h4>
                    <div>
                        {service.image}
                    </div>
                </div>
                <p>{service.descreption}</p>
            </div>

        </div>
    )
}

export default ServiceCard