import React, { useEffect, useState } from "react";
import "./Header.css";
import Logo from "../../Images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { Badge, IconButton, Menu, MenuItem } from "@mui/material";
import { NotificationsNoneOutlined } from "@mui/icons-material";
import {
  resetMessageNotificationCount,
  resetNotificationCount,
  resetTotalCount,
  selectMessageNotificationCount,
  selectNotificationCount,
  selectTotalNotificationCount,
} from "state/notification";
import { useNavigate } from "react-router-dom";
function MainHeader() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector((state) => state.authentification.user);
  const isOpen = Boolean(anchorEl);
  const navigate = useNavigate();
  const totalNotificationCount = useSelector((state) =>
    selectTotalNotificationCount(state)
  );
  const notificationCount = useSelector((state) =>
    selectNotificationCount(state)
  );
  const messageNotificationCount = useSelector((state) =>
    selectMessageNotificationCount(state)
  );
  return (
    <div className="navBar-container">
      <div className="header-container">
        <div className="logo-container">
          <img src={Logo} alt="logo-csc" />
        </div>
        <div className="header-right-container">
          <div className="header-right-container-notification">
            <IconButton
              onClick={(event) => {
                dispatch(resetTotalCount({}));
                setAnchorEl(event.currentTarget);
              }}
            >
              <Badge
                badgeContent={totalNotificationCount}
                color="error"
                sx={{
                  color: "#fff",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                <NotificationsNoneOutlined
                  sx={{ fontSize: "24px", color: "#979797" }}
                />
              </Badge>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={isOpen}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <MenuItem
                sx={{
                  height: "70px",
                  color: "#000",
                  fontSize: "14px",
                }}
                onClick={() => {
                  dispatch(resetMessageNotificationCount({}));
                  navigate("/messages");
                }}
              >
                Nouveaux messages : {messageNotificationCount}
              </MenuItem>
              <MenuItem
                sx={{
                  height: "70px",
                  color: "#000",
                  fontSize: "14px",
                }}
                onClick={() => {
                  dispatch(resetNotificationCount({}));
                  navigate("/");
                }}
              >
                Nouvelles notifications : {notificationCount}
              </MenuItem>
            </Menu>
          </div>
          {/* <div className="header-right-container-user">
            <div className="header-profilePic">
              <img src="" alt="" />
            </div>
            <div className="header-userName">
              <p>
                {user.firstName} {user.lastName}
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default MainHeader;
