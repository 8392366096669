import handleError from "utils/handelError";
import axiosClient from "./client";
import {
  createQuoteEndPoint,
  deleteQuoteEndPoint,
  getAllClientQuotesEndPoint,
  getAllQuotesEndPoint,
  getQuoteDetailsEndPoint,
  sortQuotesEndPoint,
  updateQuoteDetailsEndPoint,
  updateQuoteStateEndPoint,
} from "./Constants";

const QuoteService = {
  createQuote: async (data) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.post(
        createQuoteEndPoint,
        { ...data, client: clientId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const quote = response.data.quote;
      return quote;
    } catch (error) {
      handleError(error);
    }
  },

  getQuoteDetails: async (id) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getQuoteDetailsEndPoint(id), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const quote = response.data.quote;
      return quote;
    } catch (error) {
      handleError(error);
    }
  },

  getQuotesDetails: async () => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getAllQuotesEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const quotes = response.data.quotes;
      return quotes;
    } catch (error) {
      handleError(error);
    }
  },

  getClientQuotesDetails: async () => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.get(getAllClientQuotesEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          clientId,
        },
      });
      const quotes = response.data.quotes;
      return quotes;
    } catch (error) {
      handleError(error);
    }
  },

  updateQuoteDetails: async (id, data) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.put(
        updateQuoteDetailsEndPoint(id),
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const quote = response.data.quote;
      return quote;
    } catch (error) {
      handleError(error);
    }
  },

  deleteQuote: async (id) => {
    try {
      const token = localStorage.getItem("@userCsc");
      await axiosClient.delete(deleteQuoteEndPoint(id), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      handleError(error);
    }
  },

  updateQuoteState: async (id, state) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.put(
        updateQuoteStateEndPoint(id),
        { state },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const quote = response.data.quote;
      return quote;
    } catch (error) {
      handleError(error);
    }
  },

  sortQuotes: async (option) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.get(sortQuotesEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          option,
          client: clientId,
        },
      });
      const quotes = response.data.quotes;
      return quotes;
    } catch (error) {
      handleError(error);
    }
  },
};

export default QuoteService;
