import { useTheme } from "@emotion/react";
import { AddOutlined, KeyboardArrowDown, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputBase,
  TextField,
  Typography,
  MenuItem,
  List,
} from "@mui/material";
import FlexBetween from "Components/FlexBetween";
import React, { useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const NavButton = ({ onClick, path, title }) => {
  const theme = useTheme();
  const location = useLocation();
  return (
    <Button
      sx={{
        color: location.pathname.includes(path)
          ? theme.palette.alt[900]
          : theme.palette.grey[600],
        fontSize: "16px",
        textTransform: "none",
        borderBottom: location.pathname.includes(path) && "2px solid",
        borderBottomColor:
          location.pathname.includes(path) && theme.palette.alt[900],
        borderRadius: "0",
      }}
      onClick={onClick}
    >
      {title}
    </Button>
  );
};

const FinancialsLayout = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [filterBy, setFilterBy] = useState("");

  const handleChange = (value) => {
    setFilterBy(value);
    if (location.pathname.includes("quotes")) {
      navigate(`quotes?query=${value}`);
    } else if (location.pathname.includes("invoices")) {
      navigate(`invoices?query=${value}`);
    } else if (location.pathname.includes("commandes")) {
      navigate(`commandes?query=${value}`);
    }
  };

  const getSelectedPageTitle = () => {
    if (location.pathname.includes("commandes")) {
      return "Bon de commande";
    } else if (location.pathname.includes("invoices")) {
      return "Factures";
    } else if (location.pathname.includes("estimates")) {
      return "Budgétisation";
    } else if (location.pathname.includes("quotes")) {
      return "Devis";
    } else {
      return "";
    }
  };

  const getAddButtonTextTitle = () => {
    if (location.pathname.includes("commandes")) {
      return "Nouveau Bon de commande";
    } else if (location.pathname.includes("invoices")) {
      return "Nouvelle Facture";
    } else if (location.pathname.includes("estimates")) {
      return "Nouvelle Estimation";
    } else if (location.pathname.includes("quotes")) {
      return "Nouveau Devis";
    } else {
      return "";
    }
  };

  const addNavigate = () => {
    if (location.pathname.includes("commandes")) {
      navigate("commandes/add");
    } else if (location.pathname.includes("invoices")) {
      navigate("invoices/add");
    } else if (location.pathname.includes("estimates")) {
      navigate("estimates/add");
    } else if (location.pathname.includes("quotes")) {
      navigate("quotes/add");
    } else {
      return "";
    }
  };
  const getFilterItem = (option) => {
    if (option === "number") {
      return "Numéro";
    } else if (option === "date") {
      return "Date";
    } else {
      return "";
    }
  };

  const filterOptions = ["", "number", "date"];

  return (
    <Box
      // sx={{
      //   padding: "20px",
      //   width: "100%",
      //   overflow: "auto",
      // }}
      className='content' 
    >
      {/* Header */}
      <FlexBetween className="header-gest-pages">
        <Typography
          variant="h3"
          fontWeight="bold"
          color={theme.palette.secondary.light}
        >
          Ressources financières
        </Typography>
        <Typography
          variant="h3"
          fontWeight="bold"
          color={theme.palette.grey.main}
          sx={{
            textWrap: "nowrap",
          }}
        >
          {getSelectedPageTitle()}
        </Typography>
      </FlexBetween>

      {/* body */}
      <Box
        sx={{
          backgroundColor: theme.palette.grey[100],
          borderRadius: "6px",
          height: "calc(100vh - 140px)",
          p: "20px",
          width: "95%",
          margin:'auto'
          // overflow: "auto",
        }}
      >
        {/* tool bar */}
        <FlexBetween>
          <Box display="flex" gap="12px">
            <NavButton
              title="Budgétisation"
              path="estimates"
              onClick={() => {
                setFilterBy("");
                navigate("estimates");
              }}
            />
            <NavButton
              title="Devis"
              path="quotes"
              onClick={() => {
                setFilterBy("");
                navigate("quotes");
              }}
            />
            <NavButton
              title="Factures"
              path="invoices"
              onClick={() => {
                setFilterBy("");
                navigate("invoices");
              }}
            />
            <NavButton
              title="Bon de commande"
              path="commandes"
              onClick={() => {
                setFilterBy("");
                navigate("commandes");
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "nowrap",
              gap: "10px",
            }}
          >
            {/* Search Bar */}
            {location.pathname.includes("estimates") ? (
              <FlexBetween
                border="1px solid"
                borderColor={theme.palette.grey[50]}
                borderRadius="4px"
                backgroundColor={theme.palette.primary.main}
                p="0.1rem 0.8rem"
                height="40px"
                width="209px"
              >
                <IconButton>
                  <Search sx={{ color: theme.palette.grey.main }} />
                </IconButton>
                <InputBase
                  name="search-bar"
                  placeholder="Search..."
                  onChange={(event) =>
                    navigate(`estimates?query=${event.target.value}`)
                  }
                  sx={{
                    flex: "2",
                    color: theme.palette.grey[400],
                  }}
                />
              </FlexBetween>
            ) : (
              <form>
                <TextField
                  inputProps={{
                    id: "filter",
                  }}
                  label="Tri par"
                  select
                  value={filterBy}
                  onChange={(event) => handleChange(event.target.value)}
                  SelectProps={{
                    IconComponent: () => (
                      <KeyboardArrowDown
                        sx={{
                          color: theme.palette.grey.main,
                        }}
                      />
                    ),
                    style: {
                      color: theme.palette.grey[400],
                      height: "40px",
                    },
                  }}
                  InputLabelProps={{
                    htmlFor: "filter",
                    style: {
                      fontSize: "16px",
                      color: theme.palette.grey[400],
                      marginTop: "-6px",
                    },
                  }}
                  sx={{
                    color: "black",
                    border: "1px solid",
                    borderColor: theme.palette.grey[50],
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: "4px",
                    width: "180px",
                  }}
                >
                  {filterOptions.map((item) => (
                    <MenuItem
                      key={item}
                      sx={{
                        color: theme.palette.grey.main,
                        height: "30px",
                        ":hover": {
                          backgroundColor: theme.palette.grey.light,
                        },
                      }}
                      value={item}
                    >
                      {getFilterItem(item)}
                    </MenuItem>
                  ))}
                </TextField>
              </form>
            )}
            <Button
              startIcon={<AddOutlined />}
              sx={{
                backgroundColor: theme.palette.alt.main,
                textTransform: "none",
                fontSize: "16px",
                fontWeight: "400",
                textWrap: "nowrap",
                mr: "20px",
                ":hover": {
                  backgroundColor: theme.palette.alt[400],
                },
              }}
              onClick={addNavigate}
            >
              {getAddButtonTextTitle()}
            </Button>
          </Box>
        </FlexBetween>
        <Outlet />
      </Box>
    </Box>
  );
};

export default FinancialsLayout;
