import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  groups: [],
  chats: [],
  selectedConversation: null,
};

const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    setSelectedConversation: (state, action) => {
      const { conversation } = action.payload;
      state.selectedConversation = conversation;
    },
    // group events
    setGroups: (state, action) => {
      const { groups } = action.payload;
      state.groups = groups;
    },
    setChats: (state, action) => {
      const { chats } = action.payload;
      state.chats = chats;
    },
    addConversation: (state, action) => {
      const { conversation } = action.payload;
      if (conversation.isGroupChat) {
        state.groups.push(conversation);
      } else {
        state.chats.push(conversation);
      }
    },
    updateConversation: (state, action) => {
      const { conversation } = action.payload;
      if (conversation.isGroupChat) {
        const index = state.groups.findIndex(
          (item) => item._id === conversation._id
        );
        let updatedConversation = { ...conversation };
        if (state.selectedConversation?._id !== conversation._id) {
          updatedConversation.unseenCount =
            (state.groups[index].unseenCount ?? 0) + 1;
        }
        if (conversation.updatedAt === state.groups[index].updatedAt) {
          state.groups[index] = updatedConversation;
        } else {
          state.groups.splice(index, 1);
          state.groups.unshift(updatedConversation);
        }
      } else {
        const index = state.chats.findIndex(
          (item) => item._id === conversation._id
        );
        let updatedConversation = { ...conversation };
        if (state.selectedConversation?._id !== conversation._id) {
          updatedConversation.unseenCount =
            (state.chats[index].unseenCount ?? 0) + 1;
        }
        if (conversation.updatedAt === state.chats[index].updatedAt) {
          state.chats[index] = updatedConversation;
        } else {
          state.chats.splice(index, 1);
          state.chats.unshift(updatedConversation);
        }
      }
    },
    deleteConversation: (state, action) => {
      const { id } = action.payload;
      state.chats = state.chats.filter(
        (chat) => chat._id?.toString() !== id?.toString()
      );
      state.groups = state.groups.filter(
        (group) => group._id?.toString() !== id?.toString()
      );
    },
  },
});

export const {
  setGroups,
  setChats,
  addConversation,
  updateConversation,
  deleteConversation,
  setSelectedConversation,
} = conversationSlice.actions;

export default conversationSlice.reducer;

export const selectGroups = (state) => state.conversation.groups;
export const selectChats = (state) => state.conversation.chats;
export const selectSelectedConversation = (state) =>
  state.conversation.selectedConversation;
