import handleError from "utils/handelError";
import axiosClient from "./client";
import {
  createCommandeEndPoint,
  deleteCommandeEndPoint,
  getAllClientCommandesEndPoint,
  getAllCommandesEndPoint,
  getCommandeDetailsEndPoint,
  sortCommandesEndPoint,
  updateCommandeDetailsEndPoint,
  updateCommandeStateEndPoint,
} from "./Constants";

const CommandeService = {
  createCommande: async (data) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.post(
        createCommandeEndPoint,
        { ...data, client: clientId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const commande = response.data.commande;
      return commande;
    } catch (error) {
      handleError(error);
    }
  },

  getCommandeDetails: async (id) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getCommandeDetailsEndPoint(id), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const commande = response.data.commande;
      return commande;
    } catch (error) {
      handleError(error);
    }
  },

  getCommandesDetails: async () => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getAllCommandesEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const commandes = response.data.commandes;
      return commandes;
    } catch (error) {
      handleError(error);
    }
  },

  getClientCommandesDetails: async () => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.get(getAllClientCommandesEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          clientId,
        },
      });
      const commandes = response.data.commandes;
      return commandes;
    } catch (error) {
      handleError(error);
    }
  },

  updateCommandeDetails: async (id, data) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.put(
        updateCommandeDetailsEndPoint(id),
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const commande = response.data.commande;
      return commande;
    } catch (error) {
      handleError(error);
    }
  },

  deleteCommande: async (id) => {
    try {
      const token = localStorage.getItem("@userCsc");
      await axiosClient.delete(deleteCommandeEndPoint(id), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      handleError(error);
    }
  },

  updateCommandeState: async (id, state) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.put(
        updateCommandeStateEndPoint(id),
        { state },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const commande = response.data.commande;
      return commande;
    } catch (error) {
      handleError(error);
    }
  },

  sortCommandes: async (option) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.get(sortCommandesEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          option,
          client: clientId,
        },
      });
      const commandes = response.data.commandes;
      return commandes;
    } catch (error) {
      handleError(error);
    }
  },
};

export default CommandeService;
