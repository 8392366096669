import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sites: [],
};

const siteSlice = createSlice({
  name: "site",
  initialState,
  reducers: {
    // site events
    setSites: (state, action) => {
      const { sites } = action.payload;
      state.sites = sites;
    },
    updateSite: (state, action) => {
      const { site } = action.payload;
      for (const [key, value] of Object.entries(state.sites)) {
        if (value._id?.toString() === site._id?.toString()) {
          state.sites[key] = site;
          break;
        }
      }
    },
    addSite: (state, action) => {
      const { site } = action.payload;
      state.sites.push(site);
    },
    deleteSite: (state, action) => {
      const { id } = action.payload;
      state.sites = state.sites.filter(
        (site) => site._id?.toString() !== id?.toString()
      );
    },
  },
});

export const { setSites, updateSite, addSite, deleteSite } = siteSlice.actions;

export default siteSlice.reducer;

export const selectSites = (state) => state.site.sites;
