import { useTheme } from "@emotion/react";
import { Box, Button, Typography, List } from "@mui/material";
import CustomCircularProgress from "Components/CustomCircularProgress";
import CustomField from "Components/CustomField";
import FlexBetween from "Components/FlexBetween";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomDropDown from "Components/CustomDropDown";
import CustomDatePicker from "Components/CustomDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import InvoiceService from "Api/InvoiceService";
import { toast } from "react-toastify";
import { setGlobalIsLoading } from "state/global";
import { useDispatch } from "react-redux";
import { addInvoice, updateInvoice } from "state/invoice";
import { invoiceSchema } from "Pages/schemas/schemas";
import AddSuccessPopUp from "Components/AddSuccessPopUp";
import PopUp from "Components/Popup";
import DescriptionTable from "Components/DescriptionTable";
import CustomCheckBox from "Components/CustomCheckBox";
import DetailsPopUp from "Components/DetailsPopUp";
import ActionButton from "Components/ActionButton";
import { exportPdf } from "utils/esport_pdf";
import RecipientInfo from "Components/RecipientInfo";
const AddEditInvoice = () => {
  const { invoiceId } = useParams();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(!!invoiceId);
  const dispatch = useDispatch();
  const [addSuccessOpen, setAddSuccessOpen] = useState(false);
  const [addSuccessText, setAddSuccessText] = useState("");
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [client, setClient] = useState({});
  const navigate = useNavigate();
  const [invoice, setInvoice] = useState({
    invoice_number: "",
    billing_date: new Date(),
    payment_condition: "",
    payment_date: new Date(),
    language: "FR",
    ht_total: 0,
    tva_amount: 0,
    ttc_total: 0,
    type: "sales",
    category: null,
    due_to: new Date(),
    is_discount: true,
    discount_type: "precentage",
    discount_amount: "",
    first_invoice: new Date(),
    frequency: "monthly",
    limited_to: "",
    deposit_amount: "",
    deposit_currency: "euro",
    deposit_total_amount: 0,
    total_due_after_deposit: 0,
    currency: "euro",
    recipient_name: "",
    recipient_phone: "",
    recipient_email: "",
    recipient_address: "",
    descriptions: [],
  });
  const getLanguage = (l) => {
    if (l === "FR") {
      return "Français";
    } else if (l === "ANG") {
      return "Anglais";
    } else {
      return "";
    }
  };
  const getCurrency = (c) => {
    if (c === "euro") {
      return "Euro €";
    } else if (c === "dollard") {
      return "Dollar $";
    } else {
      return "";
    }
  };
  const getType = (t) => {
    if (t === "sales") {
      return "Facture de vente";
    } else if (t === "with_tva") {
      return "Facture de vente TVA à taux zéro";
    } else if (t === "without_tva") {
      return "Facture vente entreprise exonérée de TVA";
    } else {
      return "";
    }
  };
  const getFrequency = (f) => {
    if (f === "monthly") {
      return "mensuelle";
    } else if (f === "annual") {
      return "annuelle";
    } else if (f === "quarterly") {
      return "trimestrielle";
    } else if (f === "biannual") {
      return "semestrielle";
    } else {
      return "";
    }
  };
  const getCategory = (c) => {
    if (c === "recurring") {
      return "Facture Récurrente";
    } else if (c === "deposit") {
      return "Facture Acompte";
    } else {
      return "";
    }
  };
  const getDiscountType = (type) => {
    if (type === "currency") {
      return "€";
    } else if (type === "precentage") {
      return "%";
    } else {
      return "";
    }
  };
  const exportFile = async (fileName) => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      await exportPdf(".details-page", fileName);
      dispatch(setGlobalIsLoading({ isLoading: false }));
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };
  const onSubmit = async (values) => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      const data = {
        invoice_number: values.invoice_number,
        billing_date: values.billing_date,
        payment_condition: values.payment_condition,
        payment_date: values.payment_date,
        language: values.language,
        ht_total: values.ht_total,
        tva_amount: values.tva_amount,
        ttc_total: values.ttc_total,
        type: values.type,
        category: values.category,
        currency: values.currency,
        recipient_name: values.recipient_name,
        recipient_phone: values.recipient_phone,
        recipient_email: values.recipient_email,
        recipient_address: values.recipient_address,
        is_discount: values.is_discount,
        descriptions: values.descriptions,
      };
      if (values.is_discount) {
        data.discount_type = values.discount_type;
        data.discount_amount = values.discount_amount;
        if (values.discount_type === "precentage") {
          data.ttc_total =
            data.ttc_total - (data.ttc_total * values.discount_amount) / 100;
        } else {
          data.ttc_total = data.ttc_total - values.discount_amount;
        }
      }
      if (values.category === "recurring") {
        data.first_invoice = values.first_invoice;
        data.frequency = values.frequency;
        data.limited_to = values.limited_to;
      }
      if (values.category === "deposit") {
        data.due_to = values.due_to;
        data.deposit_amount = values.deposit_amount;
        data.deposit_currency = values.deposit_currency;
        data.deposit_total_amount = values.deposit_amount;
        data.total_due_after_deposit = data.ttc_total - values.deposit_amount;
      }
      if(values.state == 'draft'){
        data.state = 'topay'
      }
      if (invoiceId) {
        const updatedInvoice = await InvoiceService.updateInvoiceDetails(
          invoiceId,
          data
        );
        dispatch(updateInvoice({ invoice: updatedInvoice }));
        setAddSuccessText(
          `Modification de devis ${
            updatedInvoice.invoice_number ?? ""
          } confirmé`
        );
      } else {
        const newInvoice = await InvoiceService.createInvoice(data);
        dispatch(addInvoice({ invoice: newInvoice }));
        setAddSuccessText("Ajout du facture confirmé");
      }
      dispatch(setGlobalIsLoading({ isLoading: false }));
      setAddSuccessOpen(true);
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };
  const saveDraft = async (values) => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      const data = {
        invoice_number: values.invoice_number,
        billing_date: values.billing_date,
        payment_condition: values.payment_condition,
        payment_date: values.payment_date,
        language: values.language,
        ht_total: values.ht_total,
        tva_amount: values.tva_amount,
        ttc_total: values.ttc_total,
        type: values.type,
        state: "draft",
        category: values.category,
        currency: values.currency,
        recipient_name: values.recipient_name,
        recipient_phone: values.recipient_phone,
        recipient_email: values.recipient_email,
        recipient_address: values.recipient_address,
        is_discount: values.is_discount,
        descriptions: values.descriptions,
      };
      if (values.is_discount) {
        data.discount_type = values.discount_type;
        data.discount_amount = values.discount_amount;
        if (values.discount_type === "precentage") {
          data.ttc_total =
            data.ttc_total - (data.ttc_total * values.discount_amount) / 100;
        } else {
          data.ttc_total = data.ttc_total - values.discount_amount;
        }
      }
      if (values.category === "recurring") {
        data.first_invoice = values.first_invoice;
        data.frequency = values.frequency;
        data.limited_to = values.limited_to;
      }
      if (values.category === "deposit") {
        data.due_to = values.due_to;
        data.deposit_amount = values.deposit_amount;
        data.deposit_currency = values.deposit_currency;
        data.deposit_total_amount = values.deposit_total_amount;
        data.total_due_after_deposit = values.total_due_after_deposit;
      }
      const newInvoice = await InvoiceService.createInvoice(data);
      dispatch(addInvoice({ invoice: newInvoice }));
      setAddSuccessText("Facture Ajoutée comme brouillon");
      dispatch(setGlobalIsLoading({ isLoading: false }));
      setAddSuccessOpen(true);
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };
  useEffect(() => {
    const getInvoiceDetails = async () => {
      try {
        setIsLoading(true);
        const data = await InvoiceService.getInvoiceDetails(invoiceId);
        setInvoice(data);
        setIsLoading(false);
      } catch (error) {
        toast(`${error}`);
      }
    };
    if (invoiceId) {
      getInvoiceDetails();
    }
  }, []);

  return (
    <Box className='content'>
      {isLoading ? (
        <CustomCircularProgress />
      ) : (
        <List
        sx={{
          height: "100%",
          width: "100%",
          // overflow: "auto",
          padding:'0'
        }}
        >
          <Box>
            {/* Header */}
            <FlexBetween className="header-gest-pages"> 
              <Typography
                variant="h3"
                fontWeight="bold"
                color={theme.palette.secondary.light}
              >
                {invoiceId
                  ? `Modification du facture N°${invoice.invoice_number}`
                  : "Nouvelle Facture"}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Button
                  sx={{
                    width: "100px",
                    fontSize: "16px",
                    fontWeight: "400",
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.alt.main,
                    border: "1px solid",
                    borderRadius: "4px",
                    borderColor: theme.palette.grey.light,
                    textTransform: "none",
                    ":hover": {
                      backgroundColor: theme.palette.primary[400],
                    },
                  }}
                  onClick={() => navigate(-1)}
                >
                  Annuler
                </Button>
                <Button
                  sx={{
                    width: "100px",
                    fontSize: "16px",
                    fontWeight: "400",
                    backgroundColor: theme.palette.alt.main,
                    textTransform: "none",
                    ":hover": {
                      backgroundColor: theme.palette.alt[400],
                    },
                  }}
                  onClick={() => setDetailsOpen(true)}
                >
                  Aperçu
                </Button>
              </Box>
            </FlexBetween>
            <Formik
              enableReinitialize={true}
              onSubmit={onSubmit}
              initialValues={invoice}
              validationSchema={invoiceSchema}
              validateOnMount={true}
              validateOnChange={true}
            >
              {({ values, handleSubmit, setFieldValue, isValid }) => (
                <Box
                  sx={{
                    backgroundColor: theme.palette.grey[100],
                    borderRadius: "6px",
                    height: "calc(100vh - 140px)",
                    padding: "5px",
                    // mt: "20px",
                    p: "20px",
                    overflow: "auto",
                    width: "95%",
                    margin:"auto",
                  }}
                >
                  {/* Top */}
                  <Form>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: values.category
                          ? "1fr 2fr"
                          : "1fr 1fr",
                        rowGap: "30px",
                      }}
                    >
                      {/* CSC Info */}
                      <Box>
                        <Typography
                          sx={{
                            color: theme.palette.secondary[700],
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          CS Consulting
                        </Typography>
                        <Box
                          sx={{
                            color: theme.palette.grey[400],
                            fontSize: "16px",
                          }}
                        >
                          <Typography>11 AV. Jack, Lyon, France</Typography>
                          <Typography>33 548 66 52 23</Typography>
                          <Typography>CS Consulting@gmail.com</Typography>
                        </Box>
                      </Box>
                      {/* Language */}
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            m: "0 9px 0  14px",
                            gap: "5px",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "start",
                              flexDirection: "column",
                              width: "50%",
                            }}
                          >
                            <Typography
                              height="15px"
                              variant="h6"
                              color={theme.palette.grey[600]}
                              mb=".6rem"
                              textAlign="left"
                              sx={{
                                ml: "10px",
                              }}
                            >
                              Type de Facteur
                            </Typography>
                            <CustomDropDown
                              items={["sales", "with_tva", "without_tva"]}
                              name="type"
                              value={values.type}
                              getItems={getType}
                              sx={{
                                height: "43px",
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "start",
                              flexDirection: "column",
                              width: "50%",
                            }}
                          >
                            <Typography
                              height="15px"
                              variant="h6"
                              color={theme.palette.grey[600]}
                              mb=".6rem"
                              ml="10px"
                              textAlign="left"
                            >
                              Langue
                            </Typography>
                            <CustomDropDown
                              items={["FR", "ANG"]}
                              name="language"
                              value={values.language}
                              getItems={getLanguage}
                              sx={{
                                height: "43px",
                              }}
                            />
                          </Box>
                        </Box>
                        <CustomCheckBox
                          name="category"
                          defaultValue={null}
                          options={["recurring", "deposit"]}
                          getOptionsLabel={getCategory}
                          value={values.category}
                        />
                      </Box>
                      {/* Form */}
                      <RecipientInfo
                        client={client}
                        setClient={setClient}
                        values={values}
                        isGrid={false}
                        setFieldValue={setFieldValue}
                      />
                      {/* Form */}
                      <Box
                        sx={{
                          ml: "20px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: theme.palette.secondary[700],
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Informations Facture
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: values.category
                                ? "1fr 1fr 1fr"
                                : "1fr 1fr",
                              rowGap: "10px",
                              p: "11px 10px 0 10px",
                              "& .MuiInputBase-root": {
                                color: theme.palette.grey[400],
                              },
                              "& .MuiButtonBase-root": {
                                color: theme.palette.grey[400],
                              },
                            }}
                          >
                            <CustomField
                              name="invoice_number"
                              title="Numéro"
                              value={values.invoice_number}
                              sx={{
                                m: "0",
                                mr: "10px",
                              }}
                            />
                            <Box
                              sx={{
                                ml: "10px",
                                mb: "-10px",
                              }}
                            >
                              <Typography
                                height="15px"
                                variant="h6"
                                color={theme.palette.grey[600]}
                                mb=".6rem"
                                textAlign="left"
                              >
                                Devise
                              </Typography>
                              <CustomDropDown
                                items={["euro", "dollard"]}
                                name="currency"
                                value={values.currency}
                                getItems={getCurrency}
                                sx={{
                                  m: "0",
                                  width: "100%",
                                  height: "38px",
                                  overflow: "clip",
                                }}
                                iconStyle={{
                                  mr: "5px",
                                }}
                              />
                            </Box>
                            {values.category === "deposit" && (
                              <Box
                                sx={{
                                  ml: "20px",
                                  "& .MuiInputBase-root": {
                                    m: "-5px",
                                  },
                                }}
                              >
                                <Typography
                                  height="15px"
                                  variant="h6"
                                  color={theme.palette.grey[600]}
                                  mb=".6rem"
                                >
                                  Dû le
                                </Typography>
                                <CustomDatePicker
                                  name="due_to"
                                  value={
                                    values.category === "deposit"
                                      ? dayjs(values.due_to)
                                      : null
                                  }
                                  setFieldValue={setFieldValue}
                                  sx={{
                                    m: "0",
                                    height: "40px",
                                  }}
                                />
                              </Box>
                            )}
                            {values.category === "recurring" && (
                              <Box
                                sx={{
                                  ml: "20px",
                                  "& .MuiInputBase-root": {
                                    m: "-5px",
                                  },
                                }}
                              >
                                <Typography
                                  height="15px"
                                  variant="h6"
                                  color={theme.palette.grey[600]}
                                  mb=".6rem"
                                >
                                  Première facture
                                </Typography>
                                <CustomDatePicker
                                  name="first_invoice"
                                  value={
                                    values.category === "recurring"
                                      ? dayjs(values.first_invoice)
                                      : null
                                  }
                                  setFieldValue={setFieldValue}
                                  sx={{
                                    m: "0",
                                    height: "40px",
                                  }}
                                />
                              </Box>
                            )}
                            <Box
                              sx={{
                                mr: "10px",
                                "& .MuiInputBase-root": {
                                  m: "-5px",
                                },
                              }}
                            >
                              <Typography
                                height="15px"
                                variant="h6"
                                color={theme.palette.grey[600]}
                                mb=".6rem"
                              >
                                Date
                              </Typography>
                              <CustomDatePicker
                                name="billing_date"
                                value={dayjs(values.billing_date)}
                                setFieldValue={setFieldValue}
                                sx={{
                                  m: "0",
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                ml: "10px",
                                "& .MuiInputBase-root": {
                                  m: "-5px",
                                },
                              }}
                            >
                              <Typography
                                height="15px"
                                variant="h6"
                                color={theme.palette.grey[600]}
                                mb=".6rem"
                              >
                                Echéance (date)
                              </Typography>
                              <CustomDatePicker
                                name="payment_date"
                                value={dayjs(values.payment_date)}
                                setFieldValue={setFieldValue}
                                sx={{
                                  m: "0",
                                  height: "40px",
                                }}
                              />
                            </Box>
                            {values.category === "deposit" && (
                              <Box
                                sx={{
                                  ml: "20px",
                                  mt: "2px",
                                }}
                              >
                                <Typography
                                  height="15px"
                                  variant="h6"
                                  color={theme.palette.grey[600]}
                                  mb=".5rem"
                                >
                                  Acompte
                                </Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                  }}
                                >
                                  <CustomField
                                    name="deposit_amount"
                                    value={values.deposit_amount}
                                    titleStyle={{
                                      display: "none",
                                    }}
                                    fieldStyle={{
                                      height: "40px",
                                    }}
                                    sx={{
                                      m: "0",
                                    }}
                                  />
                                  <CustomDropDown
                                    name="deposit_currency"
                                    items={["euro", "dollard"]}
                                    getItems={(value) =>
                                      value === "euro" ? "€" : "$"
                                    }
                                    value={values.deposit_currency}
                                    selectPropsStyle={{
                                      height: "37px",
                                    }}
                                    sx={{
                                      m: "0",
                                      height: "40px",
                                      width: "90px",
                                    }}
                                  />
                                </Box>
                              </Box>
                            )}
                            {values.category === "recurring" && (
                              <Box
                                sx={{
                                  ml: "20px",
                                  mb: "-10px",
                                }}
                              >
                                <Typography
                                  height="15px"
                                  variant="h6"
                                  color={theme.palette.grey[600]}
                                  mb=".6rem"
                                  textAlign="left"
                                >
                                  Fréquence
                                </Typography>
                                <CustomDropDown
                                  items={[
                                    "monthly",
                                    "annual",
                                    "quarterly",
                                    "biannual",
                                  ]}
                                  name="frequency"
                                  value={values.frequency}
                                  getItems={getFrequency}
                                  sx={{
                                    m: "0",
                                    width: "100%",
                                    height: "38px",
                                    overflow: "clip",
                                  }}
                                  iconStyle={{
                                    mb: "-10px",
                                    mr: "5px",
                                  }}
                                />
                              </Box>
                            )}
                            <CustomField
                              name="payment_condition"
                              title="Conditions de paiement"
                              value={values.payment_condition}
                              sx={{
                                m: "0",
                                mr: "10px",
                              }}
                            />
                            {/* Discount */}
                            <Box
                              sx={{
                                ml: "10px",
                                mt: "2px",
                              }}
                            >
                              <Typography
                                height="15px"
                                variant="h6"
                                color={theme.palette.grey[600]}
                                mb=".5rem"
                              >
                                Remise
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                }}
                              >
                                <CustomDropDown
                                  name="is_discount"
                                  title="Remise"
                                  items={[true, false]}
                                  getItems={(value) => (value ? "Oui" : "Non")}
                                  value={values.is_discount}
                                  selectPropsStyle={{
                                    height: "38px",
                                    backgroundColor: values.is_discount
                                      ? theme.palette.primary.main
                                      : theme.palette.grey.light,
                                  }}
                                  sx={{
                                    m: "0",
                                    height: "40px",
                                    width: "100px",
                                  }}
                                  iconStyle={{
                                    ml: "-10px",
                                  }}
                                />
                                <CustomDropDown
                                  name="discount_type"
                                  items={["precentage", "currency"]}
                                  getItems={getDiscountType}
                                  value={values.discount_type}
                                  isDisabled={!values.is_discount}
                                  selectPropsStyle={{
                                    height: "38px",
                                  }}
                                  sx={{
                                    m: "0",
                                    height: "40px",
                                    width: "80px",
                                  }}
                                  iconStyle={{
                                    ml: "-20px",
                                  }}
                                />
                                <CustomField
                                  name="discount_amount"
                                  value={values.discount_amount}
                                  isDisabled={!values.is_discount}
                                  titleStyle={{
                                    display: "none",
                                  }}
                                  fieldStyle={{
                                    height: "40px",
                                  }}
                                  sx={{
                                    m: "0",
                                  }}
                                />
                              </Box>
                            </Box>
                            {values.category === "recurring" && (
                              <Box
                                sx={{
                                  position: "relative",
                                }}
                              >
                                <CustomField
                                  name="limited_to"
                                  title="Limitée à"
                                  value={values.limited_to}
                                  sx={{
                                    m: "0",
                                    ml: "20px",
                                  }}
                                />
                                <Typography
                                  sx={{
                                    color: theme.palette.grey[600],
                                    position: "absolute",
                                    top: "50%",
                                    right: "10px",
                                  }}
                                >
                                  Fois
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </LocalizationProvider>
                      </Box>
                    </Box>
                  </Form>
                  {/* Desctiption */}
                  <DescriptionTable
                    superValues={values}
                    setSuperFieldValue={setFieldValue}
                  />
                  <DetailsPopUp
                    open={detailsOpen}
                    setOpen={setDetailsOpen}
                    descriptions={values.descriptions}
                    data={{
                      name: values.recipient_name,
                      email: values.recipient_email,
                      phone: values.recipient_phone,
                      address: values.recipient_address,
                    }}
                    values={values}
                    isSupplier={false}
                    name={`Facture ${values.invoice_number ?? ""}`}
                    client={client}
                    actionButtons={
                      <>
                        <ActionButton
                          iconName="confirm"
                          text="Confirmer la facture"
                          onClick={(event) => {
                            handleSubmit(event);
                            if (!isValid) {
                              setDetailsOpen(false);
                            }
                          }}
                        />
                        <ActionButton
                          iconName="download"
                          text="Exporter en PDF"
                          onClick={() =>
                            exportFile(`facture ${values.invoice_number}.pdf`)
                          }
                        />
                        <ActionButton
                          iconName="save"
                          text="Enregistrer comme brouillon"
                          onClick={async () => await saveDraft(values)}
                        />
                        <ActionButton
                          iconName="adjust"
                          text="Continuer la création"
                          onClick={() => setDetailsOpen(false)}
                        />
                      </>
                    }
                  />
                </Box>
              )}
            </Formik>
          </Box>
        </List>
      )}
      <PopUp open={addSuccessOpen}>
        <AddSuccessPopUp
          title={addSuccessText}
          onClick={() => {
            setAddSuccessOpen(false);
            setDetailsOpen(false);
            navigate("/invoices");
          }}
        />
      </PopUp>
    </Box>
  );
};

export default AddEditInvoice;
