import {
  GET_ALL_PERSONNEL,
  GET_PERSONNEL_BY_ID,
  ADD_PERSONNEL,
  UPDATE_PERSONNEL,
  DELETE_PERSONNEL,
  FETCH_PERSONNELS_COPIE,
  FETCH_PERSONNELS_BY_CLIENT,
  FETCH_PERSONNELS_BY_CLIENT_COPIE,
  ADD_PERSONNEL_FROM_CSV,
} from "../actions/PersonnelAction";

// État initial
const initialState = {
  personnels: [],
  personnelsCopie: [],
  personnel: {},
};

// Fonction réductrice
const personnelReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PERSONNEL:
      return { ...state, personnels: action.payload };
    case GET_PERSONNEL_BY_ID:
      return { ...state, personnel: action.payload };
    case ADD_PERSONNEL:
      return { ...state, personnels: [...state.personnels, action.payload] };
    case UPDATE_PERSONNEL:
      const updatedPersonnel = action.payload;
      const updatedPersonnelsList = state.personnels.map((personnel) => {
        if (personnel._id === updatedPersonnel._id) {
          return updatedPersonnel;
        }
        return personnel;
      });
      return { ...state, personnels: updatedPersonnelsList };
    case DELETE_PERSONNEL:
      const deletedPersonnelId = action.payload;
      const updatedPersonnels = state.personnels.filter(
        (personnel) => personnel._id !== deletedPersonnelId
      );
      return {
        ...state,
        personnels: updatedPersonnels,
        personnelsCopie: updatedPersonnels,
      };
    case FETCH_PERSONNELS_COPIE:
      return { ...state, personnelsCopie: action.payload };
    case FETCH_PERSONNELS_BY_CLIENT:
      return { ...state, personnels: action.payload };
    case FETCH_PERSONNELS_BY_CLIENT_COPIE:
      return { ...state, personnelsCopie: action.payload };
    case ADD_PERSONNEL_FROM_CSV:
      return { ...state, personnels: [...state.personnels, action.payload] };
    default:
      return state;
  }
};

export default personnelReducer;
