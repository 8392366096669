import {
    getAllClient,
    getclientById,
    addClient,
    updateClient,
    deleteClient,
    updateClientLogo
} from "../services/client.service";

export const GET_ALL_CLIENT = "GET_ALL_CLIENT";
export const GET_CLIENT_BY_ID = "GET_CLIENT_BY_ID";
export const ADD_CLIENT = "ADD_CLIENT";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const FETCH_CLIENTS_COPIE = "FETCH_CLIENTS_COPIE"
export const UPDATE_CLIENT_LOGO = "UPDATE_CLIENT_LOGO";
export const ADD_CLIENT_FAILURE ="ADD_CLIENT_FAILURE";
export const getAllClients = () => (dispatch) => {
    return getAllClient()
        .then(
            (result) => {
                dispatch({
                    type: GET_ALL_CLIENT,
                    payload: result,
                });
                dispatch({
                    type: FETCH_CLIENTS_COPIE,
                    payload: result,
                })
                return result;
            }
        );
};

export const getClient = (idClient) => (dispatch) => {
    return getclientById(idClient)
        .then(
            (result) => {
                dispatch({
                    type: GET_CLIENT_BY_ID,
                    payload: result,
                });
                return result;
            }
        );
}

// export const addClients = (data) => (dispatch) => {
//     return addClient(data)
//         .then(
//             (result) => {
//                 dispatch({
//                     type: ADD_CLIENT,
//                     payload: result,
//                 });
//                 return result;
//             }
//         );
// }
// actions.js
export const addClients = (data) => (dispatch) => {
    return addClient(data)
        .then(
            (result) => {
                dispatch({
                    type: ADD_CLIENT,
                    payload: result,
                });
                return result;
            }
        )
        .catch((error) => {
            // Handle error by dispatching an error action if needed
            dispatch({
                type: ADD_CLIENT_FAILURE, // Optional: Define this action type to handle errors
                error: error.message,
            });
            throw error; // Re-throw the error to be caught in handleSave
        });
}


export const updateClients = (idClient, data) => (dispatch) => {
    return updateClient(idClient, data)
        .then(
            (result) => {
                dispatch({
                    type: UPDATE_CLIENT,
                    payload: result,
                });
                return result;
            }
        );
}
export const updateClientsLogo = (idClient, data) => (dispatch) => {
    return updateClientLogo(idClient, data)
        .then(
            (result) => {
                dispatch({
                    type: UPDATE_CLIENT_LOGO,
                    payload: result,
                });
                return result;
            }
        );
}
export const deleteClients = (idClient) => (dispatch) => {
    return deleteClient(idClient)
        .then(
            (result) => {
                dispatch({
                    type: DELETE_CLIENT,
                    payload: idClient,
                });
                return result;

            }
        );
}

export const filterClient = (data) => (dispatch) => {

    dispatch({
        type: GET_ALL_CLIENT,
        payload: data,
    });

};