import { useTheme } from "@emotion/react";
import {
  BorderColorOutlined,
  CloseOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import { Box, IconButton, List, Typography } from "@mui/material";
import CustomDialog from "Components/CustomDialog";
import PopUp from "Components/Popup";
import React, { useEffect, useState } from "react";
import AddEditTask from "./AddEditTask";
import FlexBetween from "Components/FlexBetween";
import { useDispatch } from "react-redux";
import { deleteTask } from "state/task";
import { formatDate } from "utils/format_date";
import CustomCircularProgress from "Components/CustomCircularProgress";
import TaskService from "Api/TaskService";
import { setGlobalIsLoading } from "state/global";
import { toast } from "react-toastify";

const TaskDetails = ({ taskId, setOpen }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [task, setTask] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const priorityColor = () => {
    switch (task.priority) {
      case "urgent": {
        return { color: theme.palette.error[600], priority: "Urgente" };
      }
      case "important": {
        return { color: theme.palette.warning[600], priority: "Importante" };
      }
      case "regular": {
        return { color: theme.palette.success.main, priority: "Régulière" };
      }
      default: {
        return { color: theme.palette.primary.main, priority: "" };
      }
    }
  };
  const handleClick = async () => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      await TaskService.deleteTask(taskId);
      dispatch(deleteTask({ id: task._id }));
      setOpen(false);
      dispatch(setGlobalIsLoading({ isLoading: false }));
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };
  useEffect(() => {
    const getTaskDetails = async () => {
      try {
        setIsLoading(true);
        const currentTask = await TaskService.getTaskDetails(taskId);
        setTask(currentTask);
        setIsLoading(false);
      } catch (error) {
        toast(`${error}`);
      }
    };
    getTaskDetails();
  }, []);

  return (
    <Box
      sx={{
        padding: "10px",
        border: "3px solid",
        borderColor: priorityColor().color,
        borderRadius: "8px",
        backgroundColor: theme.palette.secondary.main,
        width: "100%",
        minWidth: "50vw",
        overflow: "auto",
        height: "480px",
      }}
    >
      <List sx={{ width: "100%" }}>
        {isLoading ? (
          <CustomCircularProgress
            style={{ color: theme.palette.primary.main }}
            sx={{ height: "40vh" }}
          />
        ) : (
          <>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                alignItems: "start",
                height: "100%",
              }}
            >
              <IconButton onClick={() => setOpen(false)}>
                <CloseOutlined
                  sx={{
                    fontSize: "15px",
                    color: theme.palette.grey.main,
                  }}
                />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "start",
                padding: "10px",
                gap: "30px",
              }}
            >
              {/* left side */}
              <Box>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                  }}
                >
                  {task.name}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    m: "10px 0",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      bgcolor: priorityColor().color,
                      color: theme.palette.primary.main,
                      fontWeight: "bold",
                      height: "32px",
                      width: "95px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "2px",
                    }}
                  >
                    {priorityColor().priority}
                  </Typography>
                  <IconButton
                    size="small"
                    sx={{
                      border: "1px solid",
                      borderRadius: "4px",
                      backgroundColor: theme.palette.primary.main,
                      height: "32px",
                      ":hover": {
                        backgroundColor: theme.palette.primary.main,
                      },
                    }}
                    onClick={() => {
                      setEditOpen(true);
                    }}
                  >
                    <BorderColorOutlined
                      sx={{ color: theme.palette.alt.main }}
                    />
                  </IconButton>
                  <IconButton
                    size="small"
                    sx={{
                      border: "1px solid",
                      borderRadius: "4px",
                      backgroundColor: theme.palette.primary.main,
                      height: "32px",
                      ":hover": {
                        backgroundColor: theme.palette.primary.main,
                      },
                    }}
                    onClick={() => setDeleteOpen(true)}
                  >
                    <DeleteOutline sx={{ color: theme.palette.alt.main }} />
                  </IconButton>
                </Box>
                <Typography
                  variant="h6"
                  sx={{
                    width: "500px",
                    fontWeight: "bold",
                    m: "20px 0",
                    wordBreak: "break-all",
                  }}
                >
                  {task.description}
                </Typography>
                <FlexBetween m="20px 0" width="80%">
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        color: theme.palette.grey[600],
                      }}
                    >
                      Date de début
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "bold",
                        color: theme.palette.success.main,
                      }}
                    >
                      {formatDate(task.begin_date)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        color: theme.palette.grey[600],
                      }}
                    >
                      Date de fin
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "bold",
                        color: theme.palette.error.main,
                      }}
                    >
                      {formatDate(task.end_date)}
                    </Typography>
                  </Box>
                </FlexBetween>
                <Box m="20px 0">
                  <Typography
                    variant="h6"
                    sx={{
                      color: theme.palette.grey[600],
                      mb: "10px",
                    }}
                  >
                    La personne ou les personnes concernées par cette tâche
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      rowGap: "10px",
                    }}
                  >
                    {task.workers.map((worker) => (
                      <Box
                        key={worker._id}
                        sx={{
                          backgroundColor: theme.palette.grey[200],
                          textAlign: "center",
                          padding: "3px 10px",
                          borderRadius: "4px",
                          ml: "10px",
                          height: "28px",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            color: theme.palette.grey[600],
                          }}
                        >
                          {worker.firstname + " " + worker.lastName}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Box m="20px 0">
                  <Typography
                    variant="h6"
                    sx={{
                      color: theme.palette.grey[600],
                      mb: "10px",
                    }}
                  >
                    Matériaux et équipements requis
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      rowGap: "10px",
                    }}
                  >
                    {task.equipments.map((equipment) => (
                      <Box
                        key={equipment.equipment._id}
                        sx={{
                          backgroundColor: theme.palette.grey[200],
                          textAlign: "center",
                          padding: "3px 10px",
                          borderRadius: "4px",
                          ml: "10px",
                          height: "28px",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            color: theme.palette.grey[600],
                          }}
                        >
                          {equipment.equipment.name}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
              {/* right side */}
              <Box mt="50px">
                <Box m="20px 0">
                  <Typography
                    variant="h6"
                    sx={{
                      color: theme.palette.grey[600],
                    }}
                  >
                    Contacts Clés
                  </Typography>
                  {task.contact_keys.map((key) => (
                    <Typography
                      key={key}
                      variant="h6"
                      sx={{
                        color: theme.palette.primary.main,
                        ml: "10px",
                      }}
                    >
                      {key}
                    </Typography>
                  ))}
                </Box>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      color: theme.palette.grey[600],
                    }}
                  >
                    Lieu
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      color: theme.palette.primary.main,
                      ml: "10px",
                    }}
                  >
                    {task.place}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      color: theme.palette.grey[600],
                    }}
                  >
                    Instructions et Directives
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      color: theme.palette.primary.main,
                      ml: "10px",
                    }}
                  >
                    {task.instructions}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </List>
      <CustomDialog
        title={`Supprimer tâche ${task.name}`}
        content={`Vous êtes sûr de supprimer la tâche ${task.name}?`}
        onClick={handleClick}
        open={deleteOpen}
        setOpen={setDeleteOpen}
      />
      <PopUp open={editOpen} setOpen={setEditOpen}>
        <AddEditTask
          setOpen={setEditOpen}
          taskId={task._id}
          setDetails={setTask}
        />
      </PopUp>
    </Box>
  );
};

export default TaskDetails;
