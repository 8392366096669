import { useTheme } from "@emotion/react";
import { AddCircleOutline, CloseOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  TextField,
  Typography,
} from "@mui/material";
import CustomField from "Components/CustomField";
import FlexBetween from "Components/FlexBetween";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { siteSchema } from "Pages/schemas/schemas";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { addSite, updateSite } from "state/site";
import EquipmentList from "../../Components/EquipmentList";
import WorkersList from "../../Components/WorkersList";
import { formatDate } from "utils/format_date";
import PopUp from "Components/Popup";
import CustomCircularProgress from "Components/CustomCircularProgress";
import EquipmentService from "Api/EquipmentService";
import AuthService from "Api/AuthService";
import SiteService from "Api/SiteService";
import { setGlobalIsLoading } from "state/global";
import CustomDatePicker from "Components/CustomDatePicker";
import AddSuccessPopUp from "Components/AddSuccessPopUp";
import CustomDropDown from "Components/CustomDropDown";
import { toast } from "react-toastify";
dayjs.locale("fr");

const AddEditSite = () => {
  const { siteId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addSiteSuccessOpen, setAddSuccessOpen] = useState(false);
  const [equipments, setEquipments] = useState([]);
  const [workers, setWorkers] = useState([]);
  const siteTypes = ["residential", "commercial", "infrastructure"];
  const [contactKeys, setContactKeys] = useState([]);
  const [contactKeysInput, setContactKeysInput] = useState("");
  const [checkedWorkers, setCheckedWorkers] = useState([]);
  const [checkedEquipments, setCheckedEquipments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [numbers, setNumbers] = useState([]);

  const [site, setSite] = useState({
    name: "",
    owner: "",
    country: "",
    region: "",
    zone: "",
    zip_code: "",
    type: siteTypes[0],
    begin_date: new Date(),
    end_date: new Date(),
    workers: [],
    equipments: [],
  });
  const getSiteType = (type) => {
    if (type === "residential") {
      return "résidentiel";
    } else if (type === "commercial") {
      return "commercial";
    } else if (type === "infrastructure") {
      return "infrastructure";
    } else {
      return "";
    }
  };

  const handleWorkerToggle = (worker) => {
    const currentIndex = checkedWorkers.findIndex(
      (item) => item._id === worker._id
    );
    const newChecked = [...checkedWorkers];

    if (currentIndex === -1) {
      newChecked.push(worker);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedWorkers(newChecked);
  };

  const handleEquipmentToggle = (equipment) => {
    const currentIndex = checkedEquipments.findIndex(
      (item) => item._id === equipment._id
    );
    const newChecked = [...checkedEquipments];

    if (currentIndex === -1) {
      newChecked.push(equipment);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedEquipments(newChecked);
  };

  const changeNumber = (equipment, action, value) => {
    const currentIndex = equipments.findIndex(
      (item) => item._id === equipment._id
    );
    const newNumbers = [...numbers];
    if (action === "INC") {
      newNumbers[currentIndex] += 1;
    } else if (action === "DEC") {
      newNumbers[currentIndex] -= 1;
    } else {
      const number = parseInt(value);
      if (
        number >= 0 &&
        number <=
          (site.equipments.find((item) => item.equipment._id === equipment._id)
            ?.number ?? 0) +
            equipment.available
      ) {
        newNumbers[currentIndex] = number;
      }
    }
    setNumbers(newNumbers);
  };

  useEffect(() => {
    const getSiteData = async (equipmentData) => {
      try {
        const currentSite = await SiteService.getSiteDetails(siteId);
        const currentEquipments = [];
        const currentNumbers = Array.from(
          { length: equipmentData.length },
          () => 0
        );
        currentSite.equipments.forEach((equipment) => {
          currentEquipments.push(equipment.equipment);
          currentNumbers[
            equipmentData.findIndex(
              (item) => item._id === equipment.equipment._id
            )
          ] = equipment.number ?? 0;
        });
        setSite(currentSite);
        setCheckedWorkers(currentSite.workers);
        setCheckedEquipments(currentEquipments);
        setNumbers(currentNumbers);
        setContactKeys(currentSite.contact_keys);
        setIsLoading(false);
      } catch (error) {
        toast(`${error}`);
      }
    };
    const getData = async () => {
      try {
        setIsLoading(true);
        const equipmentData =
          await EquipmentService.getClientEquipmentsDetails();
        const workersData = await AuthService.getClientWorkersDetails();
        setEquipments(equipmentData);
        setWorkers(workersData);
        setNumbers(Array.from({ length: equipmentData.length }, () => 0));
        if (siteId) {
          getSiteData(equipmentData);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        toast(`${error}`);
      }
    };
    getData();
  }, []);

  const onSubmit = async (values) => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      const updatedEquipments = checkedEquipments.map((equipment) => {
        return {
          equipment: equipment,
          number:
            numbers[equipments.findIndex((item) => item._id === equipment._id)],
        };
      });
      const data = {
        name: values.name,
        owner: values.owner,
        type: values.type,
        country: values.country,
        region: values.region,
        zone: values.zone,
        zip_code: values.zip_code,
        begin_date: formatDate(values.begin_date),
        end_date: formatDate(values.end_date),
        contact_keys: contactKeys,
        workers: checkedWorkers,
        equipments: updatedEquipments,
      };
      if (siteId) {
        const siteData = await SiteService.updateSiteDetails(site._id, data);
        dispatch(updateSite({ site: siteData }));
        navigate("/sites");
      } else {
        const siteData = await SiteService.createSite(data);
        dispatch(addSite({ site: siteData }));
        setAddSuccessOpen(true);
      }
      dispatch(setGlobalIsLoading({ isLoading: false }));
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      onSubmit={onSubmit}
      initialValues={site}
      validationSchema={siteSchema}
      validateOnMount={true}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <Box
        className='content' 
          sx={{
            "span.error-message": {
              m: ".5px -10px",
              fontSize: "12px",
              color: theme.palette.error.main,
              position: "absolute",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            },
          }}
        >
          {isLoading ? (
            <CustomCircularProgress />
          ) : (
            <>
              <Form onSubmit={(e) => handleSubmit(e)}>
                {/* Header */}
                <FlexBetween className="header-gest-pages">
                  <Typography
                    variant="h3"
                    fontWeight="bold"
                    color={theme.palette.secondary.light}
                  >
                    Nouveau Chantier
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Button
                      sx={{
                        width: "100px",
                        fontSize: "16px",
                        fontWeight: "400",
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.alt.main,
                        border: "1px solid",
                        borderRadius: "4px",
                        borderColor: theme.palette.grey.light,
                        textTransform: "none",
                        ":hover": {
                          backgroundColor: theme.palette.primary[400],
                        },
                      }}
                      onClick={() => navigate(-1)}
                    >
                      Annuler
                    </Button>
                    <Button
                      sx={{
                        width: "100px",
                        fontSize: "16px",
                        fontWeight: "400",
                        backgroundColor: theme.palette.alt.main,
                        textTransform: "none",
                        ":hover": {
                          backgroundColor: theme.palette.alt[400],
                        },
                      }}
                      type="submit"
                    >
                      {siteId ? "Modifier" : "Ajouter"}
                    </Button>
                  </Box>
                </FlexBetween>
                <Box
                  sx={{
                    overflow: "auto",
                    height: "calc(100vh - 140px)",
                  }}
                >
                  <FlexBetween
                    sx={{
                      marginTop: "30px",
                      backgroundColor: theme.palette.grey[100],
                      borderRadius: "6px",
                      minHeight: "712px",
                      padding: "5px",
                      alignItems: "start",
                      p: "20px 20px 35px 15px",
                    }}
                  >
                    {/* Left Side */}
                    <Box flex="1">
                      <List>
                        <Box
                          sx={{
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                          }}
                        >
                          <Typography
                            variant="h5"
                            fontWeight="bold"
                            color={theme.palette.secondary.main}
                          >
                            Informations Général
                          </Typography>
                        </Box>
                        <CustomField
                          value={values.name}
                          isDisabled={false}
                          name="name"
                          type="text"
                          title="Nom du chantier"
                        />
                        <CustomField
                          value={values.owner}
                          isDisabled={false}
                          name="owner"
                          type="text"
                          title="Propriétaire du chantier "
                        />
                        <Box
                          display="grid"
                          gridTemplateColumns="1fr 1fr"
                          mt="-25px"
                          gap="5px"
                        >
                          <CustomField
                            value={values.country}
                            isDisabled={false}
                            label="pays"
                            name="country"
                            type="text"
                            title="Adresse"
                            sx={{
                              marginBottom: "-15px",
                            }}
                          />
                          <CustomField
                            value={values.region}
                            isDisabled={false}
                            label="région"
                            name="region"
                            type="text"
                            title=""
                            sx={{
                              marginBottom: "-15px",
                            }}
                          />
                          <CustomField
                            value={values.zone}
                            isDisabled={false}
                            label="zone"
                            name="zone"
                            type="text"
                            title=""
                          />
                          <CustomField
                            value={values.zip_code}
                            isDisabled={false}
                            label="code postal"
                            name="zip_code"
                            type="text"
                            title=""
                          />
                        </Box>
                        <Typography
                          height="15px"
                          variant="h6"
                          color={theme.palette.grey[600]}
                          mb=".6rem"
                          ml="5px"
                        >
                          Type de chantier
                        </Typography>
                        <CustomDropDown
                          name="type"
                          value={values.type}
                          items={siteTypes}
                          getItems={getSiteType}
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Box
                            display="flex"
                            sx={{
                              "& .MuiInputBase-root": {
                                color: theme.palette.grey[400],
                                m: "-5px",
                              },
                              "& .MuiButtonBase-root": {
                                color: theme.palette.grey[400],
                              },
                              gap: "5px",
                              m: "1.5rem 4px",
                            }}
                          >
                            <Box flex="1">
                              <Typography
                                height="15px"
                                variant="h6"
                                color={theme.palette.grey[600]}
                                mb=".6rem"
                              >
                                Date de début
                              </Typography>
                              <CustomDatePicker
                                name="begin_date"
                                value={dayjs(values.begin_date)}
                                setFieldValue={setFieldValue}
                              />
                            </Box>
                            <Box flex="1">
                              <Typography
                                height="15px"
                                variant="h6"
                                color={theme.palette.grey[600]}
                                mb=".6rem"
                              >
                                Date de fin
                              </Typography>
                              <CustomDatePicker
                                name="end_date"
                                value={dayjs(values.end_date)}
                                setFieldValue={setFieldValue}
                              />
                            </Box>
                          </Box>
                        </LocalizationProvider>
                        {/* contact keys */}
                        <Box m="1.5rem .5rem" flexGrow="1">
                          <Typography
                            height="15px"
                            variant="h6"
                            color={theme.palette.grey[600]}
                            mb=".6rem"
                          >
                            Contacts Clés
                          </Typography>
                          <Field
                            as={TextField}
                            value={contactKeysInput}
                            onChange={(e) =>
                              setContactKeysInput(e.target.value)
                            }
                            name="contact_keys"
                            type="text"
                            helperText={
                              <ErrorMessage
                                component="span"
                                className="error-message"
                                name="contact_keys"
                              />
                            }
                            sx={{
                              width: "100%",
                              backgroundColor: theme.palette.primary.main,
                              border: "1px solid",
                              borderRaduis: "4px",
                              borderColor: theme.palette.grey[200],
                              height: "40px",
                            }}
                            InputProps={{
                              style: {
                                height: "40px",
                                color: theme.palette.grey[400],
                                fontSize: "14px",
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: theme.palette.grey[400],
                                margin: "-4px",
                              },
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            position: "absolute",
                            right: "0px",
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              if (
                                contactKeysInput.length > 0 &&
                                !contactKeys.includes(contactKeysInput)
                              ) {
                                setContactKeys((prevKeys) => [
                                  ...prevKeys,
                                  contactKeysInput,
                                ]);
                                setContactKeysInput("");
                              }
                            }}
                          >
                            <AddCircleOutline
                              sx={{ color: theme.palette.grey[600] }}
                            />
                          </IconButton>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "5px",
                            flexWrap: "wrap",
                          }}
                        >
                          {contactKeys.map((contact_key) => (
                            <Box
                              key={contact_key}
                              sx={{
                                backgroundColor: theme.palette.grey[200],
                                textAlign: "center",
                                padding: "5px 15px",
                                borderRadius: "4px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                gap: "10px",
                                flexWrap: "nowrap",
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{
                                  color: theme.palette.grey[600],
                                }}
                              >
                                {contact_key}
                              </Typography>
                              <IconButton
                                size="medium"
                                sx={{
                                  p: "0",
                                }}
                                onClick={() => {
                                  setContactKeys(
                                    contactKeys.filter(
                                      (key) => key !== contact_key
                                    )
                                  );
                                }}
                              >
                                <CloseOutlined
                                  sx={{
                                    color: theme.palette.grey.main,
                                    fontSize: "15px",
                                  }}
                                />
                              </IconButton>
                            </Box>
                          ))}
                        </Box>
                      </List>
                    </Box>
                    {/* Divider */}
                    <Divider
                      orientation="vertical"
                      sx={{
                        width: "2px",
                        margin: "30px 20px",
                        backgroundColor: theme.palette.secondary.main,
                        minHeight: "600px",
                      }}
                    />
                    {/* Right Side */}
                    <Box
                      flex="1"
                      display="flex"
                      gap="15px"
                      flexDirection="column"
                      height="650px"
                    >
                      {/* staff */}
                      <Box
                        height="55%"
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: "6px",
                          pl: "20px",
                          pt: "20px",
                        }}
                      >
                        <Typography
                          variant="h5"
                          fontWeight="bold"
                          color={theme.palette.secondary.main}
                        >
                          Équipe
                        </Typography>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Box
                            color={theme.palette.grey[600]}
                            fontSize={"16px"}
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                            mt="10px"
                            p="0 45px"
                          >
                            <Typography>Employé</Typography>
                            <Typography>Département</Typography>
                          </Box>
                          <Divider
                            sx={{
                              textAlign: "center",
                              height: "1px",
                              width: "100%",
                              backgroundColor: "black",
                              m: "10px",
                            }}
                          />
                        </Box>
                        <WorkersList
                          checkedWorkers={checkedWorkers}
                          handleWorkerToggle={handleWorkerToggle}
                          workers={workers}
                          site={site}
                          setWorkers={setWorkers}
                        />
                      </Box>
                      {/* materials */}
                      <Box
                        height="55%"
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: "6px",
                          pl: "20px",
                          pt: "20px",
                        }}
                      >
                        <Typography
                          variant="h5"
                          fontWeight="bold"
                          color={theme.palette.secondary.main}
                        >
                          Matériaux et Équipement
                        </Typography>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Box
                            color={theme.palette.grey[600]}
                            fontSize={"16px"}
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                            mt="10px"
                            p="0 45px"
                          >
                            <Typography>Équipement</Typography>
                            <Typography>Nombre</Typography>
                          </Box>
                          <Divider
                            sx={{
                              textAlign: "center",
                              height: "1px",
                              width: "100%",
                              backgroundColor: "black",
                              m: "10px",
                            }}
                          />
                        </Box>
                        <EquipmentList
                          site={site}
                          numbers={numbers}
                          changeNumber={changeNumber}
                          checkedEquipments={checkedEquipments}
                          handleEquipmentToggle={handleEquipmentToggle}
                          equipments={equipments}
                        />
                      </Box>
                    </Box>
                  </FlexBetween>
                </Box>
              </Form>
              <PopUp open={addSiteSuccessOpen}>
                <AddSuccessPopUp
                  title={"Ajout de chantier confirmé"}
                  onClick={() => {
                    setAddSuccessOpen(false);
                    navigate("/sites");
                  }}
                />
              </PopUp>
            </>
          )}
        </Box>
      )}
    </Formik>
  );
};

export default AddEditSite;
