import refreshToken from "../../helpers/functions";

export const getAllPersonnel = async () => {
  let http = await refreshToken();
  let result = await http.get(`personnel`);
  return result.data;
};

export const getPersonnelById = async (id) => {
  let http = await refreshToken();
  let result = await http.get(`personnel/${id}`);
  return result.data;
};

export const addPersonnel = async (data) => {
  let http = await refreshToken();
  let result = await http.post(`personnel`, data);
  return result.data;
};

export const updatePersonnel = async (id, data) => {
  let http = await refreshToken();
  let result = await http.put(`personnel/${id}`, data);
  return result.data;
};

export const deletePersonnel = async (id) => {
  let http = await refreshToken();
  let result = await http.delete(`personnel/${id}`);
  return result.data;
};

export const getPersonnelByClientId = async (id) => {
  let http = await refreshToken();
  let result = await http.get(`personnel/client/${id}`);
  return result.data;
};

export const addPersonnelFromCSVService = async (personnels) => {
  const clientId = localStorage.getItem("idClient");
  let http = await refreshToken();
  let result = await http.post(`personnel/from-csv/${clientId}`, {
    personnels,
  });
  return result.data.personnels;
};
