const { useTheme } = require("@emotion/react");
const { Box, Typography, Divider, Button } = require("@mui/material");
const { default: FlexBetween } = require("./FlexBetween");
const { default: WorkersList } = require("Components/WorkersList");

const WorkersOverlay = ({
  workers,
  checkedWorkers,
  handleWorkerToggle,
  setOpen,
  onClick,
  site,
  setWorkers,
}) => {
  const theme = useTheme();
  return (
    <Box
      height="55vh"
      width="100%"
      sx={{
        backgroundColor: theme.palette.primary.main,
        borderRadius: "6px",
        p: "20px",
      }}
    >
      <FlexBetween>
        <Typography
          variant="h5"
          fontWeight="bold"
          color={theme.palette.secondary.main}
        >
          Équipe
        </Typography>
        <Button
          size="small"
          variant="contained"
          sx={{
            bgcolor: theme.palette.alt.main,
            color: theme.palette.primary.main,
            fontSize: "16px",
            fontWeight: "bold",
            height: "30px",
            width: "30px",
            p: "0",
            ":hover": {
              bgcolor: theme.palette.alt.main,
              color: theme.palette.primary.main,
            },
          }}
          onClick={() => {
            onClick && onClick();
            setOpen(false);
          }}
        >
          OK
        </Button>
      </FlexBetween>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          color={theme.palette.grey[600]}
          fontSize={"16px"}
          width="100%"
          display="flex"
          justifyContent="space-between"
          mt="10px"
          p="0 45px"
        >
          <Typography>Employé</Typography>
          <Typography>Département</Typography>
        </Box>
        <Divider
          sx={{
            textAlign: "center",
            height: "1px",
            width: "300px",
            backgroundColor: "black",
            m: "10px",
          }}
        />
      </Box>
      <WorkersList
        checkedWorkers={checkedWorkers}
        handleWorkerToggle={handleWorkerToggle}
        workers={workers}
        site={site}
        setWorkers={setWorkers}
      />
    </Box>
  );
};
export default WorkersOverlay;
