import { useTheme } from "@emotion/react";
import {
  BorderColorOutlined,
  DeleteOutline,
  EastOutlined,
  WestOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import EquipmentService from "Api/EquipmentService";
import CustomDialog from "Components/CustomDialog";
import PopUp from "Components/Popup";
import React, { useState } from "react";
import { setGlobalIsLoading } from "state/global";
import AddEditEquipment from "./AddEditEquipment";
import EquipmentExport from "./EquipmentExport";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import NotFoundError from "Components/NotFoundError";
import { useDispatch } from "react-redux";

const EquipmentsTable = ({
  equipments,
  currentPage,
  getPage,
  equipmentsNumber,
  showPaginate,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState(false);
  const [addEditOpen, setAddEditOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const tableHeader = [
    "équipement",
    "Catégorie",
    "État",
    "Lieu",
    "Nombre",
    "Acquisition",
    "",
  ];
  const handleClick = async () => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      await EquipmentService.deleteEquipment(selectedEquipment._id);
      dispatch(setGlobalIsLoading({ isLoading: false }));
      getPage(currentPage);
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };

  return (
    <>
      {equipments && Array.isArray(equipments) && equipments.length !== 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "end",
            p: "15px",
          }}
        >
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: "460px",
              backgroundColor: theme.palette.primary.main,
              display: "flex",
            }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {tableHeader.map((header) => (
                    <TableCell
                      key={header}
                      sx={{
                        color: theme.palette.grey[600],
                        fontSize: "14px",
                        fontWeight: "400",
                        borderBottom: "2px solid",
                        borderBottomColor: theme.palette.grey.light,
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {equipments.map((equipment) => (
                  <TableRow
                    key={equipment._id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "& .MuiTableCell-root": {
                        color: theme.palette.secondary.dark,
                        fontSize: "14px",
                        borderBottom: "1px solid",
                        borderBottomColor: theme.palette.grey.light,
                        pt: "5px",
                        pb: "5px",
                      },
                    }}
                  >
                    <TableCell component="th" scope="row" sx={{}}>
                      <Box>
                        <Typography>{equipment.name}</Typography>
                        <Typography>{equipment.serial_number}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{equipment.category}</TableCell>
                    <TableCell>{equipment.state}</TableCell>
                    <TableCell>{equipment.place}</TableCell>
                    <TableCell>{equipment.number}</TableCell>
                    <TableCell>
                      {equipment.is_rental === true ? "Oui" : "Non"}
                    </TableCell>
                    <TableCell align="right">
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "nowrap",
                          alignItems: "end",
                          justifyContent: "end",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                          }}
                        >
                          <IconButton
                            sx={{
                              border: "1px solid",
                              borderRadius: "4px",
                              borderColor: theme.palette.grey.light,
                            }}
                            onClick={() => {
                              setSelectedEquipment(equipment);
                              setAddEditOpen(true);
                            }}
                          >
                            <BorderColorOutlined
                              sx={{ color: theme.palette.alt.main }}
                            />
                          </IconButton>
                          <IconButton
                            sx={{
                              border: "1px solid",
                              borderRadius: "4px",
                              borderColor: theme.palette.grey.light,
                            }}
                            onClick={() => {
                              setSelectedEquipment(equipment);
                              setOpen(true);
                            }}
                          >
                            <DeleteOutline
                              sx={{ color: theme.palette.alt.main }}
                            />
                          </IconButton>
                        </Box>
                        <Button
                          sx={{
                            color: theme.palette.alt.main,
                            textTransform: "none",
                            fontSize: "12px",
                            fontWeight: "600",
                          }}
                          onClick={() => {
                            setSelectedEquipment(equipment);
                            setDetailsOpen(true);
                          }}
                        >
                          Détails
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {showPaginate && (
            <Box
              sx={{
                ".pagination-container": {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  listStyle: "none",
                  width: "100%",
                  p: "0",
                  m: "0",
                },
                ".pagination-link-container": {
                  height: "36px",
                  width: "36px",
                  backgroundColor: theme.palette.primary.main,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "4px",
                },
                ".pagination-page-link": {
                  textDecoration: "none",
                  color: theme.palette.grey[600],
                  height: "36px",
                  width: "36px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "4px",
                },
                ".pagination-active": {
                  bgcolor: theme.palette.alt[100],
                },
                ".pagination-active a": {
                  cursor: "auto",
                  color: theme.palette.alt[900],
                },
                ".pagination-break": {
                  bgcolor: theme.palette.alt[100],
                  color: theme.palette.alt[900],
                  fontSize: "14px",
                  height: "36px",
                  width: "36px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "4px",
                },
                ".pagination-disabled-link": {
                  cursor: "auto",
                },
              }}
            >
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <EastOutlined sx={{ color: theme.palette.grey.main }} />
                }
                onPageChange={(data) => {
                  getPage(data.selected + 1);
                }}
                pageRangeDisplayed={3}
                pageCount={Math.ceil(equipmentsNumber / 5)}
                marginPagesDisplayed={1}
                previousLabel={
                  <WestOutlined sx={{ color: theme.palette.grey.main }} />
                }
                renderOnZeroPageCount={null}
                containerClassName="pagination-container"
                nextClassName="pagination-link-container"
                previousClassName="pagination-link-container"
                pageLinkClassName="pagination-page-link"
                activeClassName="pagination-active"
                breakClassName="pagination-break"
                disabledLinkClassName="pagination-disabled-link"
              />
            </Box>
          )}
        </Box>
      ) : (
        <NotFoundError
          text="Aucune ressources matérielle trouvé !"
          sx={{ height: "80%" }}
        />
      )}
      <CustomDialog
        title={`Supprimer équipement ${selectedEquipment.name}`}
        content={`Vous êtes sûr de supprimer l’équipement ${selectedEquipment.name}?`}
        onClick={handleClick}
        open={open}
        setOpen={setOpen}
      />
      <PopUp open={addEditOpen}>
        <AddEditEquipment
          setOpen={setAddEditOpen}
          equipmentId={selectedEquipment._id}
        />
      </PopUp>
      <PopUp open={detailsOpen} setOpen={setDetailsOpen}>
        <EquipmentExport
          setOpen={setDetailsOpen}
          equipment={selectedEquipment}
        />
      </PopUp>
    </>
  );
};

export default EquipmentsTable;
