import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  invoices: [],
};

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    // invoice events
    setInvoices: (state, action) => {
      const { invoices } = action.payload;
      state.invoices = invoices;
    },
    updateInvoice: (state, action) => {
      const { invoice } = action.payload;
      for (const [key, value] of Object.entries(state.invoices)) {
        if (value._id?.toString() === invoice._id?.toString()) {
          state.invoices[key] = invoice;
          break;
        }
      }
    },
    addInvoice: (state, action) => {
      const { invoice } = action.payload;
      state.invoices.push(invoice);
    },
    deleteInvoice: (state, action) => {
      const { id } = action.payload;
      state.invoices = state.invoices.filter(
        (invoice) => invoice._id?.toString() !== id?.toString()
      );
    },
  },
});

export const { setInvoices, updateInvoice, addInvoice, deleteInvoice } =
  invoiceSlice.actions;

export default invoiceSlice.reducer;

export const selectInvoices = (state) => state.invoice.invoices;
