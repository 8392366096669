import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AddEstimate from './AddEstimate'
import EstimateDetails from './EstimateDetails'

const Estimates = () => {
  return (
    <Routes>
      <Route path='/:estimateId' element={<EstimateDetails />} />
      <Route path='/add' element={<AddEstimate/>} />
    </Routes>
  )
}

export default Estimates