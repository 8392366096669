import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AddEditInvoice from './AddEditInvoice'
import InvoicesList from './InvoicesList'

const Invoices = () => {
  return (
    <Routes>
      <Route path='/' element={<InvoicesList />} />
      <Route path='/:invoiceId' element={<AddEditInvoice />} />
      <Route path='/add' element={<AddEditInvoice />} />
    </Routes>
  )
}

export default Invoices