import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Skeleton } from "@mui/material";
import { useTheme } from "@emotion/react";
import done from "Images/done.png";
import up from "Images/up.png";
import danger from "Images/danger.png";
import circle from "Images/circle.png";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { toast } from "react-toastify";
import FinancialsInvoiceService from "Api/FinancialsInvoiceService";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";

const FinancialsInvoiceBanner = ({ selectedDate, setSelectedDate ,invoices}) => {
  const theme = useTheme();
  const [pickerOpen, setPickerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState({});
  const getDate = (value) => {
    const date = new Date(value);
    const month = date
      .toLocaleDateString("fr", { month: "long" })
    const year = date.getFullYear();
    return `${month[0].toUpperCase() + month.slice(1)} ${year}`;
  };
  useEffect(() => {
    const getFinancialsInvoiceStats = async () => {
      try {
        setPickerOpen(false)
        setIsLoading(true);
        const data = await FinancialsInvoiceService.getFinancialsInvoiceStats(selectedDate);
        setStats(data);
        setIsLoading(false);
      } catch (error) {
        toast(`${error}`);
      }
    };
    getFinancialsInvoiceStats();
  }, [selectedDate,invoices]);

  return (
    <>
      {isLoading ? (
        <Skeleton
          animation="pulse"
          variant="rectangular"
          sx={{
            bgcolor: theme.palette.grey[900],
            m: "20px",
            borderRadius: "6px",
            height: "80px",
          }}
        />
      ) : (
        <Box
          sx={{
            height: "80px",
            display: "flex",
            bgcolor: theme.palette.primary.main,
            justifyContent: "space-between",
            alignItems: "center",
            p: "20px",
            m: "20px",
            borderRadius: "6px",
          }}
        >
          <BannerItem
            icon={done}
            text={`${stats.payedNB} Factures Payées`}
            subText={`total: ${stats.payedTotal} €`}
            color={theme.palette.success[500]}
          />
          <BannerItem
            icon={up}
            text={`${stats.toPayNB} Factures À Payer`}
            subText={`total: ${stats.toPayTotal} €`}
            color={theme.palette.info[500]}
          />
          <BannerItem
            icon={danger}
            text={`${stats.lateNB} Facture En Retard`}
            subText={`total: ${stats.lateTotal} €`}
            color={theme.palette.error[600]}
          />
          <BannerItem
            icon={circle}
            text={`Total ${getDate(selectedDate).substring(0, 3)}.`}
            subText={`total: ${stats.total} €`}
            color={theme.palette.alt[900]}
          />
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Button
                endIcon={
                  <KeyboardArrowDownOutlined
                    sx={{ color: theme.palette.grey.main }}
                  />
                }
                sx={{
                  color: theme.palette.alt[900],
                  fontSize: "12px",
                  fontWeight: "bold",
                  textTransform:'none'
                }}
                onClick={() => setPickerOpen(!pickerOpen)}
              >
                {getDate(selectedDate)}
              </Button>
              <DatePicker
                sx={{
                  width: "0px",
                  overflow: "clip",
                  position: "absolute",
                }}
                open={pickerOpen}
                views={["year", "month"]}
                value={dayjs(selectedDate)}
                onChange={(value) => setSelectedDate(new Date(value))}
                onClose={() => setPickerOpen(false)}
              />
            </LocalizationProvider>
          </Box>
        </Box>
      )}
    </>
  );
};

const BannerItem = ({ icon, text, subText, color }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "cnter",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Box
        sx={{
          height: "31px",
          width: "31px",
          backgroundImage: `url(${icon})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "100%",
        }}
      />
      <Box>
        <Typography
          sx={{
            color,
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          {text}
        </Typography>
        <Typography
          sx={{
            color: theme.palette.grey.main,
            fontSize: "12px",
          }}
        >
          {subText}
        </Typography>
      </Box>
    </Box>
  );
};

export default FinancialsInvoiceBanner;
