import {
    ADD_CONGE,
    GET_ALL_CONGE,
    GET_CONGE_BY_ID,
    FETCH_CONGES_COPIE,
    DELETE_CONGE,
    UPDATE_CONGE,
    FETCH_CONGES_BY_EMPLOYE,
    FETCH_CONGES_BY_CLIENT,
    FETCH_CONGES_BY_CLIENT_COPIE

} from '../actions/CongeAction';

// État initial
const initialState = {
    conges: [],
    congesCopie: [],
    conge: {},
    congeEmploye: []
};

// Fonction réductrice
const congeReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_CONGE:
            return { ...state, conges: action.payload };
        case FETCH_CONGES_BY_CLIENT:
            return { ...state, conges: action.payload };
        case FETCH_CONGES_BY_EMPLOYE:
            return { ...state, congeEmploye: action.payload };
        case GET_CONGE_BY_ID:
            return { ...state, conge: action.payload };
        case ADD_CONGE:
            return { ...state, conges: [...state.conges, action.payload] };
        case UPDATE_CONGE:
            const updatedConge = action.payload;
            const updatedCongeList = state.conges.map((conge) => {
                if (conge._id === updatedConge._id) {
                    return updatedConge;
                }
                return conge;
            });
            return { ...state, conges: updatedCongeList };
        case DELETE_CONGE:
            const deletedCongelId = action.payload;
            const updatedConges = state.conges.filter((conge) => conge._id !== deletedCongelId);
            return { ...state, conges: updatedConges, congesCopie: updatedConges };
        case FETCH_CONGES_COPIE:
            return { ...state, congesCopie: action.payload };
        case FETCH_CONGES_BY_CLIENT_COPIE:
            return { ...state, congesCopie: action.payload };
        default:
            return state;
    }
};

export default congeReducer;
