import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./SideBar.css";
import { signOut } from "../../redux/actions/UserAction";
import { useDispatch } from "react-redux";
const MainSideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const getInitialIndex = () => {
    const pathname = location.pathname;
    if (pathname === "/") {
      return 1;
    } else if (
      pathname.includes("clients") ||
      pathname.includes("addclient") ||
      pathname.includes("updateclient")
    ) {
      return 2;
    } else if (pathname.includes("messages")) {
      return 3;
    } else if (pathname.includes("emails")) {
      return 4;
    } else if (pathname.includes("gestionrole")) {
      return 5;
    } else if (pathname.includes("parametreerp")) {
      return 6;
    } else {
      return 1;
    }
  };
  const [activeNavItem, setActiveNavItem] = useState(getInitialIndex());

  const handleNavItemClick = (item, index) => {
    navigate(`/${item.link.replace(" ", "-").toLowerCase()}`);
    setActiveNavItem(index);
  };

  const navItems = [
    {
      id: 1,
      icon: (
        <svg
          width="16"
          height="18"
          viewBox="0 0 16 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.32873 0.515579C9.3873 0.567641 9.44276 0.6231 9.49482 0.68167L15.4948 7.43167C15.8202 7.79777 16 8.27057 16 8.7604V16C16 17.1046 15.1046 18 14 18H10.002C10.0013 18 10.0007 18 10 18H6C5.99934 18 5.99868 18 5.99803 18H2C0.89543 18 0 17.1046 0 16V8.7604C0 8.27057 0.179757 7.79777 0.505181 7.43167L6.50518 0.68167C7.23902 -0.143896 8.50316 -0.218257 9.32873 0.515579ZM7 16H9V12H7V16ZM11 16V11C11 10.4477 10.5523 10 10 10H6C5.44772 10 5 10.4477 5 11V16H2V8.7604L8 2.0104L14 8.7604V16H11Z"
            fill="#7E84A3"
          />
        </svg>
      ),
      activeIcon: (
        <svg
          width="16"
          height="18"
          viewBox="0 0 16 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.32873 0.515579C9.3873 0.567641 9.44276 0.6231 9.49482 0.68167L15.4948 7.43167C15.8202 7.79777 16 8.27057 16 8.7604V16C16 17.1046 15.1046 18 14 18H10.002C10.0013 18 10.0007 18 10 18H6C5.99934 18 5.99868 18 5.99803 18H2C0.89543 18 0 17.1046 0 16V8.7604C0 8.27057 0.179757 7.79777 0.505181 7.43167L6.50518 0.68167C7.23902 -0.143896 8.50316 -0.218257 9.32873 0.515579ZM7 16H9V12H7V16ZM11 16V11C11 10.4477 10.5523 10 10 10H6C5.44772 10 5 10.4477 5 11V16H2V8.7604L8 2.0104L14 8.7604V16H11Z"
            fill="#fff"
          />
        </svg>
      ),
      name: "Dashboard Général",
      link: "",
      categorie: "",
    },
    {
      id: 2,
      icon: (
        <svg
          width="20"
          height="16"
          viewBox="0 0 20 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 7C17.0871 7 18.9253 8.06562 20.0002 9.68246L19.9993 13H12L11.999 14.952L12.0215 16H0V15C0 11.6863 2.68629 9 6 9C7.37834 9 8.65093 9.46574 9.66616 10.2518C10.662 8.32039 12.6768 7 15 7ZM6 11C4.26204 11 2.78296 12.1084 2.23109 13.6569L2.16936 13.8447L2.126 14H9.873L9.8362 13.8625C9.38265 12.3295 8.0355 11.1846 6.4051 11.0203L6.19987 11.0049L6 11ZM15 9C13.6048 9 12.3764 9.71436 11.6606 10.7973L11.535 11H18.464L18.3707 10.845C17.6927 9.78716 16.5304 9.07078 15.2007 9.00496L15 9ZM6 0C8.20914 0 10 1.79086 10 4C10 6.20914 8.20914 8 6 8C3.79086 8 2 6.20914 2 4C2 1.79086 3.79086 0 6 0ZM15 0C16.6569 0 18 1.34315 18 3C18 4.65685 16.6569 6 15 6C13.3431 6 12 4.65685 12 3C12 1.34315 13.3431 0 15 0ZM6 2C4.89543 2 4 2.89543 4 4C4 5.10457 4.89543 6 6 6C7.10457 6 8 5.10457 8 4C8 2.89543 7.10457 2 6 2ZM15 2C14.7239 2 14 2 14 2C14 2 14 2.72386 14 3C14 3.27614 14 4 14 4C14 4 14.7239 4 15 4C15.2761 4 16 4 16 4C16 4 16 3.27614 16 3C16 2.86441 16 2 16 2C16 2 15.1356 2 15 2Z"
            fill="#7E84A3"
          />
        </svg>
      ),
      activeIcon: (
        <svg
          width="20"
          height="16"
          viewBox="0 0 20 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 7C17.0871 7 18.9253 8.06562 20.0002 9.68246L19.9993 13H12L11.999 14.952L12.0215 16H0V15C0 11.6863 2.68629 9 6 9C7.37834 9 8.65093 9.46574 9.66616 10.2518C10.662 8.32039 12.6768 7 15 7ZM6 11C4.26204 11 2.78296 12.1084 2.23109 13.6569L2.16936 13.8447L2.126 14H9.873L9.8362 13.8625C9.38265 12.3295 8.0355 11.1846 6.4051 11.0203L6.19987 11.0049L6 11ZM15 9C13.6048 9 12.3764 9.71436 11.6606 10.7973L11.535 11H18.464L18.3707 10.845C17.6927 9.78716 16.5304 9.07078 15.2007 9.00496L15 9ZM6 0C8.20914 0 10 1.79086 10 4C10 6.20914 8.20914 8 6 8C3.79086 8 2 6.20914 2 4C2 1.79086 3.79086 0 6 0ZM15 0C16.6569 0 18 1.34315 18 3C18 4.65685 16.6569 6 15 6C13.3431 6 12 4.65685 12 3C12 1.34315 13.3431 0 15 0ZM6 2C4.89543 2 4 2.89543 4 4C4 5.10457 4.89543 6 6 6C7.10457 6 8 5.10457 8 4C8 2.89543 7.10457 2 6 2ZM15 2C14.7239 2 14 2 14 2C14 2 14 2.72386 14 3C14 3.27614 14 4 14 4C14 4 14.7239 4 15 4C15.2761 4 16 4 16 4C16 4 16 3.27614 16 3C16 2.86441 16 2 16 2C16 2 15.1356 2 15 2Z"
            fill="#fff"
          />
        </svg>
      ),
      name: "Clients",
      link: "clients",
      categorie: "",
    },
    {
      id: 3,
      icon: (
        <svg
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 0C17.1046 0 18 0.89543 18 2V14C18 15.1046 17.1046 16 16 16H6L0 19L0 2C0 0.89543 0.89543 0 2 0H16ZM16 2H2V15.468L5.44603 14H16V2ZM8 9V11H4V9H8ZM14 5V7H4V5H14Z"
            fill="#7E84A3"
          />
        </svg>
      ),
      activeIcon: (
        <svg
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 0C17.1046 0 18 0.89543 18 2V14C18 15.1046 17.1046 16 16 16H6L0 19L0 2C0 0.89543 0.89543 0 2 0H16ZM16 2H2V15.468L5.44603 14H16V2ZM8 9V11H4V9H8ZM14 5V7H4V5H14Z"
            fill="#fff"
          />
        </svg>
      ),
      name: "Messagerie interne",
      link: "messages",
      categorie: "Messagerie",
    },
    {
      id: 4,
      icon: (
        <svg
          width="20"
          height="16"
          viewBox="0 0 20 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 0C19.1046 0 20 0.89543 20 2V14C20 15.1046 19.1046 16 18 16H2C0.89543 16 0 15.1046 0 14V2C0 0.89543 0.89543 0 2 0H18ZM3.51862 2L10 7.671L16.4814 2H3.51862ZM18 3.32877L10 10.3288L2 3.32877V14H18V3.32877Z"
            fill="#7E84A3"
          />
        </svg>
      ),
      activeIcon: (
        <svg
          width="20"
          height="16"
          viewBox="0 0 20 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 0C19.1046 0 20 0.89543 20 2V14C20 15.1046 19.1046 16 18 16H2C0.89543 16 0 15.1046 0 14V2C0 0.89543 0.89543 0 2 0H18ZM3.51862 2L10 7.671L16.4814 2H3.51862ZM18 3.32877L10 10.3288L2 3.32877V14H18V3.32877Z"
            fill="#fff"
          />
        </svg>
      ),
      name: "Messagerie externe",
      link: "emails",
      categorie: "Messagerie",
    },
    {
      id: 5,
      icon: (
        <svg
          width="16"
          height="18"
          viewBox="0 0 16 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 9C12.3349 9 15.8645 12.4478 15.9962 16.7508L16 17V18H0V17C0 12.5817 3.58172 9 8 9ZM8 11C5.14444 11 2.75479 12.9948 2.14861 15.667L2.10104 15.8977L2.084 16H13.915L13.899 15.8977C13.4095 13.2618 11.1969 11.2312 8.47386 11.0184L8.22494 11.0041L8 11ZM8 0C10.2091 0 12 1.79086 12 4C12 6.20914 10.2091 8 8 8C5.79086 8 4 6.20914 4 4C4 1.79086 5.79086 0 8 0ZM8 2C6.89543 2 6 2.89543 6 4C6 5.10457 6.89543 6 8 6C9.10457 6 10 5.10457 10 4C10 2.89543 9.10457 2 8 2Z"
            fill="#7E84A3"
          />
        </svg>
      ),
      activeIcon: (
        <svg
          width="16"
          height="18"
          viewBox="0 0 16 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 9C12.3349 9 15.8645 12.4478 15.9962 16.7508L16 17V18H0V17C0 12.5817 3.58172 9 8 9ZM8 11C5.14444 11 2.75479 12.9948 2.14861 15.667L2.10104 15.8977L2.084 16H13.915L13.899 15.8977C13.4095 13.2618 11.1969 11.2312 8.47386 11.0184L8.22494 11.0041L8 11ZM8 0C10.2091 0 12 1.79086 12 4C12 6.20914 10.2091 8 8 8C5.79086 8 4 6.20914 4 4C4 1.79086 5.79086 0 8 0ZM8 2C6.89543 2 6 2.89543 6 4C6 5.10457 6.89543 6 8 6C9.10457 6 10 5.10457 10 4C10 2.89543 9.10457 2 8 2Z"
            fill="#fff"
          />
        </svg>
      ),
      name: "Gestion des rôles",
      link: "gestionRole",
      categorie: "Paramètres Globale",
    },
    {
      id: 6,
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5 0L14 3L18 3.5L20 6.5L18 10L20 13.5L18 16.5L14 17L11.5 20H8.5L6 17L2 16.5L0 13.5L2 10L0 6.5L2 3.5L6 3L8.5 0H11.5ZM10.562 1.999H9.437L7.0312 4.88666L3.156 5.37L2.35 6.581L4.3035 10L2.35 13.418L3.156 14.629L7.0312 15.1133L9.436 17.999H10.563L12.9688 15.1133L16.843 14.629L17.649 13.418L15.6965 10L17.649 6.581L16.843 5.37L12.9688 4.88666L10.562 1.999ZM10 6C12.2091 6 14 7.79086 14 10C14 12.2091 12.2091 14 10 14C7.79086 14 6 12.2091 6 10C6 7.79086 7.79086 6 10 6ZM10 8C8.89543 8 8 8.89543 8 10C8 11.1046 8.89543 12 10 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8Z"
            fill="#7E84A3"
          />
        </svg>
      ),
      activeIcon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5 0L14 3L18 3.5L20 6.5L18 10L20 13.5L18 16.5L14 17L11.5 20H8.5L6 17L2 16.5L0 13.5L2 10L0 6.5L2 3.5L6 3L8.5 0H11.5ZM10.562 1.999H9.437L7.0312 4.88666L3.156 5.37L2.35 6.581L4.3035 10L2.35 13.418L3.156 14.629L7.0312 15.1133L9.436 17.999H10.563L12.9688 15.1133L16.843 14.629L17.649 13.418L15.6965 10L17.649 6.581L16.843 5.37L12.9688 4.88666L10.562 1.999ZM10 6C12.2091 6 14 7.79086 14 10C14 12.2091 12.2091 14 10 14C7.79086 14 6 12.2091 6 10C6 7.79086 7.79086 6 10 6ZM10 8C8.89543 8 8 8.89543 8 10C8 11.1046 8.89543 12 10 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8Z"
            fill="#fff"
          />
        </svg>
      ),
      name: "Paramètres CS ERP",
      link: "parametreERP",
      categorie: "Paramètres Globale",
    },
  ];
  const categories = {};

  navItems.forEach((item) => {
    if (!categories[item.categorie]) {
      categories[item.categorie] = [];
    }
    categories[item.categorie].push(item);
  });

  const handleLogout = () => {
    dispatch(signOut());
    window.location.href = "/login";
  };

  return (
    <div className="sideBar">
      <div className="top-sideBar">
        {/* <h4 className='sideBar-client'>Client X</h4> */}
        <div className="side-bar-main-links">
          {Object.keys(categories).map((categorie, categorieIndex) => (
            <React.Fragment key={categorieIndex}>
              {categorie != "" && (
                <div className="category-title">
                  <p>{categorie.toLowerCase()}</p>
                </div>
              )}

              <ul>
                {categories[categorie].map((item) => (
                  <li
                    key={item.id}
                    className={
                      item.id === parseInt(activeNavItem)
                        ? "active "
                        : "notActive"
                    }
                    onClick={() => handleNavItemClick(item, item.id)}
                  >
                    <div className="sideBar-icons-conatainer">
                      {item.id === parseInt(activeNavItem)
                        ? item.activeIcon
                        : item.icon}
                    </div>
                    <span>{item.name}</span>
                  </li>
                ))}
              </ul>
            </React.Fragment>
          ))}
          <button className="logout-button" onClick={handleLogout}>
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.57 13.4746H15C15.1989 13.4746 15.3897 13.3956 15.5303 13.2549C15.671 13.1143 15.75 12.9235 15.75 12.7246C15.75 12.5257 15.671 12.3349 15.5303 12.1942C15.3897 12.0536 15.1989 11.9746 15 11.9746H3.57L6.48 9.55207C6.63316 9.42477 6.72948 9.24183 6.74777 9.04351C6.76605 8.8452 6.70481 8.64773 6.5775 8.49457C6.51447 8.41873 6.43711 8.35605 6.34985 8.31011C6.2626 8.26417 6.16714 8.23586 6.06894 8.22681C5.87063 8.20852 5.67316 8.26977 5.52 8.39707L1.02 12.1471C0.935072 12.2174 0.866709 12.3057 0.819786 12.4055C0.772863 12.5053 0.748535 12.6143 0.748535 12.7246C0.748535 12.8349 0.772863 12.9438 0.819786 13.0436C0.866709 13.1435 0.935072 13.2317 1.02 13.3021L5.52 17.0521C5.65494 17.1639 5.82476 17.2249 6 17.2246C6.11019 17.2248 6.21905 17.2007 6.31887 17.154C6.41868 17.1073 6.50699 17.0392 6.5775 16.9546C6.64065 16.8788 6.68823 16.7913 6.71752 16.6971C6.7468 16.6029 6.75721 16.5038 6.74815 16.4056C6.7391 16.3074 6.71075 16.2119 6.66474 16.1246C6.61872 16.0374 6.55595 15.96 6.48 15.8971L3.57 13.4746Z"
                fill="white"
              />
              <path
                d="M15.0001 4.47461C13.3951 4.47485 11.8249 4.94328 10.4821 5.82251C9.13931 6.70173 8.08213 7.95356 7.44011 9.42461C7.40039 9.51518 7.37897 9.61271 7.37709 9.71158C7.37521 9.81046 7.3929 9.90873 7.42915 10.0007C7.46539 10.0928 7.51948 10.1767 7.5883 10.2477C7.65711 10.3188 7.73929 10.3755 7.83011 10.4146C8.01219 10.4925 8.21768 10.4952 8.40175 10.4221C8.58581 10.3491 8.73351 10.2062 8.81261 10.0246C9.43291 8.60333 10.5237 7.43897 11.9015 6.72733C13.2793 6.01568 14.8601 5.80016 16.3782 6.11699C17.8962 6.43382 19.2589 7.26369 20.237 8.46705C21.2151 9.67041 21.7491 11.1739 21.7491 12.7246C21.7491 14.2754 21.2151 15.7788 20.237 16.9822C19.2589 18.1855 17.8962 19.0154 16.3782 19.3322C14.8601 19.6491 13.2793 19.4335 11.9015 18.7219C10.5237 18.0102 9.43291 16.8459 8.81261 15.4246C8.73351 15.2431 8.58581 15.1002 8.40175 15.0271C8.21768 14.954 8.01219 14.9567 7.83011 15.0346C7.73929 15.0738 7.65711 15.1305 7.5883 15.2015C7.51948 15.2725 7.46539 15.3565 7.42915 15.4485C7.3929 15.5405 7.37521 15.6388 7.37709 15.7376C7.37897 15.8365 7.40039 15.934 7.44011 16.0246C8.03509 17.3879 8.98757 18.5649 10.1967 19.4312C11.4059 20.2974 12.8268 20.8207 14.309 20.9455C15.7912 21.0703 17.2796 20.7921 18.6166 20.1403C19.9536 19.4885 21.0895 18.4873 21.9041 17.2428C22.7188 15.9982 23.1817 14.5566 23.2441 13.0705C23.3065 11.5843 22.9659 10.109 22.2584 8.80058C21.5509 7.49216 20.5028 6.39936 19.2251 5.63783C17.9474 4.8763 16.4876 4.47438 15.0001 4.47461Z"
                fill="white"
              />
            </svg>
            <span>Déconnexion</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MainSideBar;
