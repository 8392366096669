import React, { useState } from 'react'
import './Modal.css';
import FicheDePai from '../Prepai/FicheDePai';
import { updateFichesPaie } from '../../redux/actions/FichePaieAction';
import { useDispatch } from 'react-redux';
import ValidateModal from './ValidateModal';
import { useNavigate } from 'react-router-dom';
function FichePaiModalUpdate({ isOpen, onClose, data }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const successAdd = () => {
        openModal()
        setTimeout(() => {
            closeModal()
            onClose();
            navigate('/prepaielist')
        }, 2000);
    }
    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const handleSubmit = async () => {
        try {
            await dispatch(updateFichesPaie(data._id, data)).then(
                successAdd()
            );
        } catch (error) {
            console.log('Erreur lors de la création de la fiche de paie :', error);
        }
    };
    return (
        <div className={`modalPaie modal ${isOpen ? 'open' : ''}`} onClick={handleOverlayClick}>
            {modalOpen &&
                <ValidateModal isOpen={modalOpen} onClose={closeModal} message="Devis modifié..." />
            }
            <div className="modal-content-fichePaie">
                <div style={{
                    width: "600px",
                    height: "800px"
                }}>
                    <FicheDePai data={data} />
                </div>
                <button onClick={() => handleSubmit()} className='confirm-paie'> <svg width="28" height="34" viewBox="0 0 28 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 4C0 1.79086 1.79086 0 4 0H22.4L27.3 4.9V24.5H0V4Z" fill="#0030A6" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 0H22.4V4.9H27.3V33.6H0V0Z" fill="#336DFF" />
                    <rect x="4.89941" y="9.44995" width="17.5" height="1.4" fill="white" />
                    <rect x="4.89941" y="13.6499" width="17.5" height="1.4" fill="white" />
                    <rect x="4.89941" y="17.8511" width="17.5" height="1.4" fill="white" />
                    <rect x="4.89941" y="22.051" width="7" height="1.4" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M19.1989 20.9338C22.1904 20.9338 24.6156 23.359 24.6156 26.3505C24.6156 29.342 22.1904 31.7672 19.1989 31.7672C16.2074 31.7672 13.7822 29.342 13.7822 26.3505C13.7822 23.359 16.2074 20.9338 19.1989 20.9338ZM19.1989 22.0172C16.8057 22.0172 14.8656 23.9573 14.8656 26.3505C14.8656 28.7437 16.8057 30.6838 19.1989 30.6838C21.5921 30.6838 23.5322 28.7437 23.5322 26.3505C23.5322 23.9573 21.5921 22.0172 19.1989 22.0172ZM20.7984 24.5688C21.0048 24.3578 21.3445 24.3583 21.5503 24.5699C21.7489 24.774 21.7486 25.0993 21.5496 25.303L18.4102 28.5172L16.8466 26.9094C16.6484 26.7056 16.6484 26.3811 16.8466 26.1773C17.0527 25.9654 17.3931 25.9653 17.5993 26.1772L18.4102 27.0108L20.7984 24.5688Z" fill="#00001B" />
                </svg>
                    Modifier la fiche</button>
            </div>

        </div>
    )
}

export default FichePaiModalUpdate