export const baseURL = "https://www.csconsultingservices.fr/api/v1";
// auth endpoints
export const getMyDetailsEndPoint = "user/validate";
export const getAllUsersEndPoint = `user`;
export const getUserDetailsEndPoint = (id) => `user/${id}/`;
export const getClientetailsEndPoint = (id) => `client/${id}/`;
export const getClientWorkersDetailsEndPoint = (id) => `personnel/client/${id}`;
export const getAvailableWorkersEndPoint = `user/workers/available/get-details`;
export const getStaffEndPoint = `user`;
export const getClientStatsEndPoint = `user/clients-stats`;
// site endpoints
export const createSiteEndPoint = "sites/create-site";
export const getSiteDetailsEndPoint = (id) => `sites/${id}/get-details`;
export const getAllSitesEndPoints = "sites/get-details";
export const getSitesInDateEndPoints = "sites/get-sites-in-date";
export const getSitesWithoutEstimateEndPoints =
  "sites/get-sites-without-estimate";
export const getAllClientSitesEndPoint = "sites/get-client-sites";
export const getWorkerSitesEndPoint = `sites/get-worker-sites`;
export const updateSiteDetailsEndPoint = (id) => `sites/${id}/update-details`;
export const deleteSiteEndPoint = (id) => `sites/${id}/delete-site`;
export const updateSiteStateEndPoint = (id) => `sites/${id}/update-state`;
export const updateSiteEquipmetsEndPoint = (id) =>
  `sites/${id}/update-equipments`;
export const updateSiteWorkersEndPoint = (id) => `sites/${id}/update-workers`;
export const deleteWorkerFromSitesEndPoint = `sites/delete-worker-from-sites`;
export const searchSitesEndPoint = `sites/search`;
export const sortSitesEndPoint = `sites/sort`;
// task endpoints
export const createTaskEndPoint = `tasks/create-task`;
export const getTaskDetailsEndPoint = (id) => `tasks/${id}/get-details`;
export const getTasksDetailsEndPoint = `tasks/get-details`;
export const updateTaskDetailsEndPoint = (id) => `tasks/${id}/update-details`;
export const deleteTaskEndPoint = (id) => `tasks/${id}/delete-task`;
export const updateTaskStateEndPoint = (id) => `tasks/${id}/update-state`;
export const updateTaskPriorityEndPoint = (id) => `tasks/${id}/update-priority`;
// equipments endpoints
export const createEquipmentEndPoint = `equipments/create-equipment`;
export const createEquipmentListEndPoint = `equipments/create-many`;
export const getEquipmentDetailsEndPoint = (id) =>
  `equipments/${id}/get-details`;
export const getAllEquipmentsEndPoint = `equipments/get-details`;
export const getClientEquipmentsEndPoint = `equipments/get-client-equipments`;
export const getEquipmentsPageEndPoint = `equipments/get-page`;
export const updateEquipmentDetailsEndPoint = (id) =>
  `equipments/${id}/update-details`;
export const deleteEquipmentEndPoint = (id) =>
  `equipments/${id}/delete-equipment`;
export const updateEquipmentStateEndPoint = (id) =>
  `equipments/${id}/update-state`;
export const searchEquipmentsEndPoint = `equipments/search`;
export const sortEquipmentsEndPoint = `equipments/sort`;
//conversation endpoints
export const getAllChatsEndPoint = `conversations/chats/get-details`;
export const getAllGroupsEndPoint = `conversations/groups/get-details`;
export const createConversationEndPoint = `conversations/create-conversation`;
//message endpoints
export const getAllConversationMessagesEndPoint = `messages/get-conversation-messages`;
export const sendMessageEndPoint = `messages/create-message`;
//estimate endpoints
export const createEstimateEndPoint = `financials/estimates/create-estimate`;
export const deleteEstimateEndPoint = (id) =>
  `financials/estimates/${id}/delete-estimate`;
export const getAllEstimatesEndPoint = `financials/estimates/get-details`;
export const getClientEstimatesEndPoint = `financials/estimates/get-client-estimates`;
export const getSitesEstimateEndPoint = `financials/estimates/get-site-estimate`;
export const getEstimateDetailsEndPoint = (id) =>
  `financials/estimates/${id}/get-details`;
export const searchEstimatesEndPoint = `financials/estimates/search`;
export const updateEstimateDetailsEndPoint = (id) =>
  `financials/estimates/${id}/update-details`;
//financials invoice endpoints
export const createFinancialsInvoiceEndPoint = `financials/invoices/create-invoice`;
export const deleteFinancialsInvoiceEndPoint = (id) =>
  `financials/invoices/${id}/delete-invoice`;
export const getAllFinancialsInvoicesEndPoint = `financials/invoices/get-details`;
export const getAllClientFinancialsInvoicesEndPoint = `financials/invoices/get-client-invoices`;
export const getFinancialsInvoiceDetailsEndPoint = (id) =>
  `financials/invoices/${id}/get-details`;
export const sortFinancialsInvoicesEndPoint = `financials/invoices/sort`;
export const updateFinancialsInvoiceDetailsEndPoint = (id) =>
  `financials/invoices/${id}/update-details`;
export const updateFinancialsInvoiceStateEndPoint = (id) =>
  `financials/invoices/${id}/update-state`;
export const getFinancialsInvoiceStatsEndPoint = `financials/invoices/get-stats`;
//financials quote endpoints
export const createFinancialsQuoteEndPoint = `financials/quotes/create-quote`;
export const deleteFinancialsQuoteEndPoint = (id) =>
  `financials/quotes/${id}/delete-quote`;
export const getAllFinancialsQuotesEndPoint = `financials/quotes/get-details`;
export const getAllClientFinancialsQuotesEndPoint = `financials/quotes/get-client-quotes`;
export const getFinancialsQuoteDetailsEndPoint = (id) =>
  `financials/quotes/${id}/get-details`;
export const sortFinancialsQuotesEndPoint = `financials/quotes/sort`;
export const updateFinancialsQuoteDetailsEndPoint = (id) =>
  `financials/quotes/${id}/update-details`;
export const updateFinancialsQuoteStateEndPoint = (id) =>
  `financials/quotes/${id}/update-state`;
//commande endpoints
export const createCommandeEndPoint = `financials/commandes/create-commande`;
export const deleteCommandeEndPoint = (id) =>
  `financials/commandes/${id}/delete-commande`;
export const getAllCommandesEndPoint = `financials/commandes/get-details`;
export const getAllClientCommandesEndPoint = `financials/commandes/get-client-commandes`;
export const getCommandeDetailsEndPoint = (id) =>
  `financials/commandes/${id}/get-details`;
export const sortCommandesEndPoint = `financials/commandes/sort`;
export const updateCommandeDetailsEndPoint = (id) =>
  `financials/commandes/${id}/update-details`;
export const updateCommandeStateEndPoint = (id) =>
  `financials/commandes/${id}/update-state`;
//invoice endpoints
export const createInvoiceEndPoint = `invoices/create-invoice`;
export const deleteInvoiceEndPoint = (id) => `invoices/${id}/delete-invoice`;
export const getAllInvoicesEndPoint = `invoices/get-details`;
export const getAllClientInvoicesEndPoint = `invoices/get-client-invoices`;
export const getInvoiceDetailsEndPoint = (id) => `invoices/${id}/get-details`;
export const sortInvoicesEndPoint = `invoices/sort`;
export const updateInvoiceDetailsEndPoint = (id) =>
  `invoices/${id}/update-details`;
export const updateInvoiceStateEndPoint = (id) => `invoices/${id}/update-state`;
export const getInvoiceStatsEndPoint = `invoices/get-stats`;
export const getAllInvoiceStatsEndPoint = `invoices/get-all-stats`;
//quote endpoints
export const createQuoteEndPoint = `quotes/create-quote`;
export const deleteQuoteEndPoint = (id) => `quotes/${id}/delete-quote`;
export const getAllQuotesEndPoint = `quotes/get-details`;
export const getAllClientQuotesEndPoint = `quotes/get-client-quotes`;
export const getQuoteDetailsEndPoint = (id) => `quotes/${id}/get-details`;
export const sortQuotesEndPoint = `quotes/sort`;
export const updateQuoteDetailsEndPoint = (id) => `quotes/${id}/update-details`;
export const updateQuoteStateEndPoint = (id) => `quotes/${id}/update-state`;
//email endpoints
export const getNotificationsEndPoint = `notifications/get-details`;
export const sortNotificationsEndPoint = `notifications/sort`;
export const getNotificationEndPoint = (id) =>
  `notifications/${id}/get-details`;
// task endpoints
export const createTodoEndPoint = `todos/create-todo`;
export const getTodoDetailsEndPoint = (id) => `todos/${id}/get-details`;
export const getUserTodosEndPoint = `todos/me/get-details`;
export const getTodosDetailsEndPoint = `todos/get-details`;
export const updateTodoDetailsEndPoint = (id) => `todos/${id}/update-details`;
export const deleteTodoEndPoint = (id) => `todos/${id}/delete-todo`;
