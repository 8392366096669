import React, { useEffect, useState } from "react";
// import { LeftOutlined, RightOutlined, DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
// import { deletePersonnels, filterPersonnel, getAllPersonnels } from '../../redux/actions/PersonnelAction';
import { useDispatch, useSelector } from "react-redux";
// import ModalDeleteGuide from '../Modals/DeleteModal';
// import ModalPersonnelDetails from '../Modals/ModalPersonnelDetails';
// import AddCongeModal from '../Modals/AddCongeModal';
import {
  getAllConges,
  getAllCongesyClient,
} from "../../redux/actions/CongeAction";
import DetailsConge from "../Modals/DetailsConge";
import NotFoundError from "Components/NotFoundError";

function ListeConge() {
  const dispatch = useDispatch();
  const conges = useSelector((state) => state.conge.conges);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredConges, setFilteredConges] = useState([]);
  const [showMonthList, setShowMonthList] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth()); // mois actuel
  const [activeButton, setActiveButton] = useState("Acceptés");
  useEffect(() => {
    const clienId = localStorage.getItem("idClient");
    dispatch(getAllCongesyClient(clienId));
  }, []);
  useEffect(() => {
    setFilteredConges(
      conges.filter(
        (conge) =>
          conge.typeConge.toLowerCase().includes(searchQuery.toLowerCase()) ||
          conge.employe.firstname
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          conge.employe.lastName
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery]);
  useEffect(() => {
    if (activeButton === "En congé") {
      const today = new Date();
      setFilteredConges(
        conges.filter((conge) => {
          const startDate = new Date(conge.dateDebut);
          const endDate = new Date(conge.dateFin);
          return (
            conge.statutDemande === "Approuvée" &&
            startDate <= today &&
            endDate >= today
          );
        })
      );
    } else if (activeButton === "Acceptés") {
      const currentYear = new Date().getFullYear();
      setFilteredConges(
        conges.filter(
          (conge) =>
            conge.statutDemande === "Approuvée" &&
            (new Date(conge.dateDebut).getFullYear() === currentYear ||
              new Date(conge.dateFin).getFullYear() === currentYear)
        )
      );
    } else if (activeButton === "Par Mois") {
      setFilteredConges(
        conges.filter((conge) => {
          const startDate = new Date(conge.dateDebut);
          const endDate = new Date(conge.dateFin);
          const startMonth = startDate.getMonth();
          const endMonth = endDate.getMonth();
          return startMonth === currentMonth || endMonth === currentMonth;
        })
      );
    }
    // }else {
    //     setFilteredConges(conges.filter(conge => conge.typeConge.toLowerCase().includes(searchQuery.toLowerCase())));
    // }
  }, [conges, activeButton, currentMonth]);

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    if (buttonName === "Par Mois") {
      setShowMonthList(!showMonthList);
    }
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // const handleButtonClick = (buttonName) => {
  //     setActiveButton(buttonName);

  // };

  const toggleMonthList = () => {
    setShowMonthList(!showMonthList);
  };

  const changeMonth = (direction) => {
    if (direction === "previous") {
      setCurrentMonth((prevMonth) => (prevMonth === 0 ? 11 : prevMonth - 1)); // Si le mois actuel est janvier, passe à décembre
    } else {
      setCurrentMonth((prevMonth) => (prevMonth === 11 ? 0 : prevMonth + 1)); // Si le mois actuel est décembre, passe à janvier
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const [personnelDetails, setPersonnelDetails] = useState(null);
  const hanldeOpenModalDetails = async (personnel) => {
    await setPersonnelDetails(personnel);
    openModal();
  };
  return (
    <div className="container-fluid personnel-table-container">
      {modalOpen && (
        <DetailsConge
          isOpen={modalOpen}
          onClose={closeModal}
          personnel={personnelDetails}
        />
      )}
      <div className="personnel-header-container">
        <div className="triAndSearch">
          <div>
            {/* <button className={activeButton === 'Tous' ? 'active' : ''} onClick={() => handleButtonClick('Tous')}>Tous</button> */}
            <button
              className={activeButton === "Acceptés" ? "active" : ""}
              onClick={() => handleButtonClick("Acceptés")}
            >
              Acceptés
            </button>
            <button
              className={activeButton === "En congé" ? "active" : ""}
              onClick={() => handleButtonClick("En congé")}
            >
              En congé
            </button>
            <button
              className={activeButton === "Par Mois" ? "active" : ""}
              onClick={() => (handleButtonClick("Par Mois"), toggleMonthList())}
            >
              Par Mois
            </button>
          </div>
        </div>
        <div className="actionsContainer">
          {showMonthList && (
            <div className="filter-month">
              <button onClick={() => changeMonth("previous")}>
                <svg
                  width="15"
                  height="12"
                  viewBox="0 0 15 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.3871 0.209705L5.29289 0.292893L0.292893 5.29289C-0.0675907 5.65338 -0.0953203 6.22061 0.209705 6.6129L0.292893 6.70711L5.29289 11.7071C5.68342 12.0976 6.31658 12.0976 6.70711 11.7071C7.06759 11.3466 7.09532 10.7794 6.7903 10.3871L6.70711 10.2929L3.414 6.999L14 7C14.5523 7 15 6.55228 15 6C15 5.44772 14.5523 5 14 5L3.416 4.999L6.70711 1.70711C7.06759 1.34662 7.09532 0.779392 6.7903 0.387101L6.70711 0.292893C6.34662 -0.0675907 5.77939 -0.0953203 5.3871 0.209705Z"
                    fill="#1E5EFF"
                  />
                </svg>
              </button>
              <p>
                {currentMonth === 0
                  ? "Janvier"
                  : currentMonth === 1
                  ? "Février"
                  : currentMonth === 2
                  ? "Mars"
                  : currentMonth === 3
                  ? "Avril"
                  : currentMonth === 4
                  ? "Mai"
                  : currentMonth === 5
                  ? "Juin"
                  : currentMonth === 6
                  ? "Juillet"
                  : currentMonth === 7
                  ? "Août"
                  : currentMonth === 8
                  ? "Septembre"
                  : currentMonth === 9
                  ? "Octobre"
                  : currentMonth === 10
                  ? "Novembre"
                  : "Décembre"}
              </p>
              <button onClick={() => changeMonth("next")}>
                <svg
                  width="15"
                  height="12"
                  viewBox="0 0 15 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.6129 0.209705C9.22061 -0.0953203 8.65338 -0.0675907 8.29289 0.292893L8.2097 0.387101C7.90468 0.779392 7.93241 1.34662 8.29289 1.70711L11.5852 5H1C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7H11.5852L8.29289 10.2929L8.2097 10.3871C7.90468 10.7794 7.93241 11.3466 8.29289 11.7071C8.68342 12.0976 9.31658 12.0976 9.70711 11.7071L14.7071 6.70711L14.7903 6.6129C15.0953 6.22061 15.0676 5.65338 14.7071 5.29289L9.70711 0.292893L9.6129 0.209705Z"
                    fill="#1E5EFF"
                  />
                </svg>
              </button>
            </div>
          )}
          <input
            className="search-container-personnel"
            type="search"
            placeholder="Recherche..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div className="personnel-table-content">
        <table className="table-personnel">
          <thead>
            <tr>
              <th>Employé</th>
              <th>Type de congé</th>
              <th>Jours</th>
              <th>Début de congé</th>
              <th>Fin du congés</th>
              <th>Solde</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {filteredConges.length > 0 ? (
              filteredConges.map((conge, index) => (
                <tr key={index}>
                  <td>
                    {conge?.employe?.firstname} {conge?.employe?.lastName}{" "}
                    <br />
                    <span>{conge?.employe?.NumEmpl}</span>
                  </td>
                  <td>{conge?.typeConge}</td>
                  <td>{conge?.dureeConge}</td>
                  <td>{conge?.dateDebut.slice(0, 10)}</td>
                  <td>{conge?.dateFin.slice(0, 10)}</td>
                  <td>{conge?.employe?.soldeConge}</td>
                  <td>
                    <button
                      className="actionsBtn"
                      onClick={() => hanldeOpenModalDetails(conge?.employe)}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM10 4C10.5128 4 10.9355 4.38604 10.9933 4.88338L11 5V9.585L13.7071 12.2929C14.0676 12.6534 14.0953 13.2206 13.7903 13.6129L13.7071 13.7071C13.3466 14.0676 12.7794 14.0953 12.3871 13.7903L12.2929 13.7071L9.29289 10.7071C9.13661 10.5508 9.0374 10.3481 9.00867 10.1314L9 10V5C9 4.44772 9.44771 4 10 4Z"
                          fill="#1E5EFF"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <NotFoundError
                  text={"Aucun congé trouvé"}
                  sx={{ width: "300%", height: "60px" }}
                />
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ListeConge;
