import React, { useState, useRef } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import './Forms.css'
import { addClients } from '../../redux/actions/ClientAction';
import { useDispatch, useSelector } from 'react-redux';
import ValidateModal from '../Modals/ValidateModal';
import { useNavigate } from 'react-router-dom';
const validationSchema = Yup.object().shape({
    nom: Yup.string().required('Required'),
    adresse: Yup.string().required('Required'),
    phone: Yup.string().required('Required'),
    dirigeant: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    webSite: Yup.string().url('Invalid URL').required('Required'),
    activite: Yup.string().required('Required'),
    creationDate: Yup.date().required('Required'),
    effectif: Yup.number().positive('Must be a positive number').integer('Must be an integer').required('Required'),
    formeJuridique: Yup.string().required('Required'),
    codeNAF: Yup.string().required('Required'),
    numSiren: Yup.string().required('Required'),
    numTVA: Yup.string().required('Required'),
    image: Yup.mixed().required('Please select an image').test(
        'fileSize',
        'File size too large',
        (value) => value && value.size <= 10485760 // 10 MB
    )
});
function AddClient() {
    const navigate = useNavigate()
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();
    const [image, setImage] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const errors = useSelector((state) => state.client.error); 
    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };
    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        setImage(file);
    };

    const successAdd = () => {
        openModal()
        setTimeout(() => {
            closeModal()

        }, 2000);
    }
    // const handleSave = async (values) => {


    //     await dispatch(addClients(values))
    //         .then((result) => {
    //             if (result) {
    //                 successAdd()
    //             }
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });

    // }
    const handleSave = async (values) => {
        try {
            await dispatch(addClients(values));
            successAdd();
            await new Promise(resolve => setTimeout(resolve, 2000));
            navigate(-1);
        } catch (error) {
            console.error(error);
            // console.error(error.response.data.errors);
        }
    }
    return (
        <div className="formulaire-main-container">
            {modalOpen &&
                <ValidateModal isOpen={modalOpen} onClose={closeModal} message="Demande d'ajout envoyée" />
            }
            <Formik
                enableReinitialize
                validationSchema={validationSchema}

                initialValues={{
                    nom: "",
                    dirigeant: "",
                    adresse: "",
                    email: "",
                    phone: "",
                    webSite: "",
                    activite: "",
                    creationDate: "",
                    effectif: 0,
                    formeJuridique: "",
                    codeNAF: "",
                    numSiren: "",
                    numTVA: "",
                    image: null
                }}
                onSubmit={(values, actions) => {
                    handleSave(values);

                }}
            >
                {({
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    values,
                    errors,
                    isValid,
                    touched,
                    setFieldValue,
                }) => (
                    <Form className="formulaire-conatiner">
                        <div className="row">

                            <h3 className='form-title'>Informations Générales</h3>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="nom" className="form-label">Nom du client</label>
                                    <input type="text" className="form-control"
                                        name="nom"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.nom}
                                    />
                                    {errors.nom && touched.nom && (
                                        <span className="errorText">
                                            {errors.nom}
                                        </span>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="adresse" className="form-label">Adresse</label>
                                    <input type="text" className="form-control"
                                        name="adresse"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.adresse}
                                    />
                                    {errors.adresse && touched.adresse && (
                                        <span className="errorText">
                                            {errors.adresse}
                                        </span>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="phone" className="form-label">Numéro de téléphone</label>
                                    <input type="text" className="form-control"
                                        name="phone"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phone}
                                    />
                                    {errors.phone && touched.phone && (
                                        <span className="errorText">
                                            {errors.phone}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="dirigeant" className="form-label">Dirigeant</label>
                                    <input type="text" className="form-control"
                                        name="dirigeant"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.dirigeant}
                                    />
                                    {errors.dirigeant && touched.dirigeant && (
                                        <span className="errorText">
                                            {errors.dirigeant}
                                        </span>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Mail</label>
                                    <input type="text" className="form-control"
                                        name="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                    />
                                    {errors.email && touched.email && (
                                        <span className="errorText">
                                            {errors.email}
                                        </span>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="webSite" className="form-label">Site web</label>
                                    <input type="text" className="form-control"
                                        name="webSite"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.webSite}
                                    />
                                    {errors.webSite && touched.webSite && (
                                        <span className="errorText">
                                            {errors.webSite}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <h3 className='form-title'>Informations légales</h3>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="activite" className="form-label">Activité</label>
                                    <input type="text" className="form-control"
                                        name="activite"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.activite}
                                    />
                                    {errors.activite && touched.activite && (
                                        <span className="errorText">
                                            {errors.activite}
                                        </span>
                                    )}
                                </div>
                                <div className="mb-3 d-flex justify-content-between">
                                    <div className='small_inpt_form'>
                                        <label htmlFor="formeJuridique" className="form-label">Forme juridique</label>
                                        {/* <input type="text" className="form-control"
                                            name="formeJuridique"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.formeJuridique}
                                        /> */}
                                        <select name="formeJuridique" className="form-control" onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.formeJuridique}>
                                            <option value="" disabled> Sélectionnez</option>
                                            <option value="SAS">SAS</option>
                                            <option value="SARL">SARL</option>
                                            <option value="SA">SA</option>
                                            <option value="Micro entreprise">Micro entreprise</option>
                                        </select>
                                        {errors.formeJuridique && touched.formeJuridique && (
                                            <span className="errorText">
                                                {errors.formeJuridique}
                                            </span>
                                        )}
                                    </div>
                                    <div className='small_inpt_form'>
                                        <label htmlFor="codeNAF" className="form-label">Code NAF</label>
                                        <input type="text" className="form-control"
                                            name="codeNAF"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.codeNAF}
                                        />
                                        {errors.codeNAF && touched.codeNAF && (
                                            <span className="errorText">
                                                {errors.codeNAF}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="mb-3 d-flex justify-content-between">
                                    <div className='small_inpt_form'>
                                        <label htmlFor="creationDate" className="form-label">Date création de l’entreprise</label>
                                        <input type="date" className="form-control"
                                            name="creationDate"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.creationDate}
                                        />
                                        {errors.creationDate && touched.creationDate && (
                                            <span className="errorText">
                                                {errors.creationDate}
                                            </span>
                                        )}
                                    </div>
                                    <div className='small_inpt_form'>
                                        <label htmlFor="effectif" className="form-label">Effectif (nombre de salariés)</label>
                                        <input type="number" className="form-control"
                                            min={0}
                                            name="effectif"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.effectif}
                                        />
                                        {errors.effectif && touched.effectif && (
                                            <span className="errorText">
                                                {errors.effectif}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="mb-3 d-flex justify-content-between">
                                    <div className='small_inpt_form'>
                                        <label htmlFor="numSiren" className="form-label">Numéro Siren</label>
                                        <input type="text" className="form-control"
                                            name="numSiren"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.numSiren}
                                        />
                                        {errors.numSiren && touched.numSiren && (
                                            <span className="errorText">
                                                {errors.numSiren}
                                            </span>
                                        )}
                                    </div>
                                    <div className='small_inpt_form'>
                                        <label htmlFor="numTVA" className="form-label">Numéro TVA</label>
                                        <input type="text" className="form-control"
                                            name="numTVA"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.numTVA}
                                        />
                                        {errors.numTVA && touched.numTVA && (
                                            <span className="errorText">
                                                {errors.numTVA}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div
                                    className="mb-3 d-flex flex-column align-items-center justify-content-center addImageFormContainer"
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                >

                                    <button className="btn-add-image-form" onClick={handleButtonClick}>
                                        Ajouter logo
                                    </button>
                                    <label htmlFor="retauName" className="image-label mt-2">
                                        Ou glisser-déposer de fichiers
                                    </label>
                                    <input
                                        type="file"
                                        id="photoInput"
                                        accept="image/*"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={(e) => {
                                            const selectedFile = e.currentTarget.files[0];
                                            setImage(selectedFile);
                                            setFieldValue("image", selectedFile); // Update Formik field value
                                        }}
                                    />

                                    {image && (
                                        <div className="small-img-cont-form">
                                            <img src={URL.createObjectURL(image)} alt="selected-image" />
                                        </div>
                                    )}

                                    {errors.image && touched.image && (
                                        <span className="errorText">
                                            {errors.image}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Submit button */}
                        <div className="d-flex flex-row justify-content-end" >
                            <button type="submit" style={{ width: "120px" }} className='DarkBtn d-flex justify-content-center'>Ajouter</button>
                        </div>
                        {/* <div className="row">
                            <button style={{width:"150px"}} className='DarkBtn' type="submit">Ajouter</button>
                        </div> */}
                    </Form >

                )
                }
            </Formik >
        </div>
    )
}

export default AddClient