import { useTheme } from "@emotion/react";
import { BorderColorOutlined, DeleteOutline } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import SiteService from "Api/SiteService";
import CustomDialog from "Components/CustomDialog";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setGlobalIsLoading } from "state/global";
import { deleteSite } from "state/site";
import { formatDate } from "utils/format_date";

const SiteCard2 = ({ site }) => {
  const theme = useTheme();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [open,setOpen] = useState(false)
  const handleClick = async () => {
    try {
      dispatch(setGlobalIsLoading({isLoading:true}))
      await SiteService.deleteSite(site._id)
      dispatch(deleteSite({ id: site._id }));
      dispatch(setGlobalIsLoading({isLoading:false}))
    } catch (error) {
      dispatch(setGlobalIsLoading({isLoading:false}))
      toast(`${error}`);
    }
  };
  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="225px"
        margin="10px 5px"
        padding="20px"
        borderRadius="6px"
        sx={{
          backgroundColor: theme.palette.grey[100],
        }}
      >
        <Box flex="1">
          <Typography
            variant="h4"
            fontWeight="bold"
            color={theme.palette.secondary.main}
          >
            {site.name}
          </Typography>
          <Typography variant="h5" color={theme.palette.grey[400]}>
            {site.type}
          </Typography>
        </Box>
        <Box flex="1">
          <Typography variant="h6" color={theme.palette.secondary.main}>
            {site.address}
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
            }}
          >
            <Typography variant="h6" color={theme.palette.secondary.main}>
              DE {formatDate(site.begin_date)}
            </Typography>
            <Typography variant="h6" color={theme.palette.secondary.main}>
              À {formatDate(site.end_date)}
            </Typography>
          </Box>
        </Box>
        <Box
          flex="1"
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            margin: "5px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
            }}
          >
            <IconButton
              sx={{
                border: "1px solid",
                borderRadius: "4px",
                borderColor: theme.palette.grey.light,
                backgroundColor: theme.palette.primary.light,
              }}
              onClick={()=>{
                navigate(`${site._id}/edit`)
              }}
            >
              <BorderColorOutlined sx={{ color: theme.palette.alt.main }} />
            </IconButton>
            <IconButton
              sx={{
                border: "1px solid",
                borderRadius: "4px",
                borderColor: theme.palette.grey.light,
                backgroundColor: theme.palette.primary.light,
              }}
              onClick={()=>setOpen(true)}
            >
              <DeleteOutline sx={{ color: theme.palette.alt.main }} />
            </IconButton>
          </Box>
          <Button
            sx={{
              color: theme.palette.alt.main,
              textTransform: "none",
              fontSize: "12px",
              fontWeight: "600",
            }}
            onClick={()=>{
              navigate(`${site._id}/tasks`)
            }}
          >
            Détails
          </Button>
        </Box>
      </Box>
      <Divider
        sx={{
          margin:"15px 30px 15px 0",
          width: "100%",
          height: "1px",
          backgroundColor: theme.palette.grey[400],
        }}
      />
      <CustomDialog 
      title={`Supprimer chantier ${site.name}`} 
      content={`Vous êtes sûr de supprimer le chantier ${site.name}?`} 
      onClick={handleClick}
      open={open}
      setOpen={setOpen}
      />
    </Box>
  );
};

export default SiteCard2;
