import React from "react";
import Header from "../../Components/Headers/Header";
import Layout from "../../Components/Layouts/Layout";
import SimpleHeader from "../../Components/Headers/SimpleHeader";
import Exmeple from "../../Components/Personnels/Index";
import { Box } from "@mui/material";

function ListPersonnels() {
  return (
    <Box
    className='content'
    >
      <SimpleHeader title="Personnels" />
      <Exmeple />
    </Box>
  );
}

export default ListPersonnels;
