import CustomCircularProgress from "Components/CustomCircularProgress";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  selectFinancialsInvoices,
  setFinancialsInvoices,
} from "state/financials";
import FinancialsInvoiceService from "Api/FinancialsInvoiceService";
import FinancialsInvoiceTable from "./FinancialsInvoiceTable";
import FinancialsInvoiceBanner from "./FinancialsInvoiceBanner";
import { useLocation } from "react-router-dom";

const FinancialsInvoicesList = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelecteDate] = useState(new Date());
  const [filterFinancialsInvoices, setFilterFinancialsInvoices] =
    useState(null);
  const query = useLocation();
  const invoices = useSelector((state) => selectFinancialsInvoices(state));
  useEffect(() => {
    const getFinancialsInvoices = async () => {
      try {
        setIsLoading(true);
        const data =
          await FinancialsInvoiceService.getClientFinancialsInvoicesDetails(
            selectedDate
          );
        dispatch(setFinancialsInvoices({ invoices: data }));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast(`${error}`);
      }
    };
    getFinancialsInvoices();
  }, [selectedDate]);

  useEffect(() => {
    const search = async () => {
      try {
        const terms = query.search.split("=");
        if (!terms[1]) {
          setFilterFinancialsInvoices(null);
          return;
        }
        setIsLoading(true);
        const data = await FinancialsInvoiceService.sortFinancialsInvoices(
          terms[1]
        );
        setFilterFinancialsInvoices(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast(`${error}`);
      }
    };
    search();
  }, [query]);
  return (
    <>
      {isLoading ? (
        <CustomCircularProgress sx={{ height: "60vh" }} />
      ) : (
        <>
          <FinancialsInvoiceBanner
            selectedDate={selectedDate}
            setSelectedDate={setSelecteDate}
            invoices={invoices}
          />
          <FinancialsInvoiceTable
            invoices={
              filterFinancialsInvoices ? filterFinancialsInvoices : invoices
            }
            selectedDate={selectedDate}
            setSelectedDate={setSelecteDate}
          />
        </>
      )}
    </>
  );
};

export default FinancialsInvoicesList;
