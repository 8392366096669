import React from 'react'
import { Route, Routes } from 'react-router-dom'
import TasksList from './TasksList'
import NotFound from 'Pages/NotFound'
import TasksCalendar from './TasksCalendar'
import TasksPlanification from './MonthsView'

const Tasks = () => {
  return (
    <Routes>
        <Route path='/' element={<TasksList />} />
        <Route path='/calendar' element={<TasksCalendar />} />
        <Route path='/planification' element={<TasksPlanification />} />
        <Route path="/*" element={<NotFound />} />
    </Routes>
  )
}

export default Tasks