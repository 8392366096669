import React, { useState } from "react";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { filterClient } from "../../redux/actions/ClientAction";
function ListClientHeader({ title }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clientCopie = useSelector((state) => state.client.clientsCopie);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const handleSearchChange = (e) => {
    const newQuery = e.target.value;
    setSearchQuery(newQuery);
    const newClient = clientCopie.filter(
      (item) =>
        item.nom.toLowerCase().includes(newQuery.toLowerCase()) ||
        item.formeJuridique.toLowerCase().includes(newQuery.toLowerCase()) ||
        item.activite.toLowerCase().includes(newQuery.toLowerCase()) ||
        item.creationDate.toLowerCase().includes(newQuery.toLowerCase())
    );
    dispatch(filterClient(newClient));
  };

  const handleSortChange = (e) => {
    const newSelectedOption = e.target.value;
    setSelectedOption(newSelectedOption);
    let sortedData = [...clientCopie];

    if (newSelectedOption === "nom") {
      sortedData.sort((a, b) => a.nom.localeCompare(b.nom));
    } else if (newSelectedOption === "formeJuridique") {
      sortedData.sort((a, b) =>
        a.formeJuridique.localeCompare(b.formeJuridique)
      );
    } else if (newSelectedOption === "activite") {
      sortedData.sort((a, b) => a.activite.localeCompare(b.activite));
    } else if (newSelectedOption === "creationDate") {
      sortedData.sort((a, b) => a.creationDate.localeCompare(b.creationDate));
    } else if (newSelectedOption === "") {
      dispatch(filterClient(sortedData));
    }
    // Mettre à jour les données triées
    dispatch(filterClient(sortedData));
  };
  return (
    <div className="header-gest-pages">
      <div className="header-gest-pages-left">
        <div className="text-and-back">
          <h3> {title} </h3>
        </div>
      </div>
      <div className="header-gest-pages-right">
        <div className="triAndSearch">
          <select
            className="sort-select"
            value={selectedOption}
            onChange={handleSortChange}
          >
            <option value="none">Trier par</option>
            <option value="nom">Nom</option>
            <option value="formeJuridique">Forme juridique</option>
            <option value="activite">Activité</option>
            <option value="creationDate">Date de création</option>
          </select>

          <input
            className="search-container-personnel"
            type="search"
            placeholder="Recherche..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <button onClick={() => navigate("/addclient")} className="DarkBtn">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7 0C7.55228 0 8 0.447715 8 1V5.999L13 6C13.5523 6 14 6.44772 14 7C14 7.55228 13.5523 8 13 8L8 7.999V13C8 13.5523 7.55228 14 7 14C6.44772 14 6 13.5523 6 13V7.999L1 8C0.447715 8 0 7.55228 0 7C0 6.44772 0.447715 6 1 6L6 5.999V1C6 0.447715 6.44772 0 7 0Z"
              fill="white"
            />
          </svg>{" "}
          Ajouter Client
        </button>
      </div>
    </div>
  );
}

export default ListClientHeader;
