import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  equipments: [],
};

const equipmentSlice = createSlice({
  name: "equipment",
  initialState,
  reducers: {
    // equipment events
    setEquipments: (state, action) => {
      const { equipments } = action.payload;
      state.equipments = equipments;
    },
    updateEquipment: (state, action) => {
      const { equipment } = action.payload;
      for (const [key, value] of Object.entries(state.equipments)) {
        if (value._id?.toString() === equipment._id?.toString()) {
          state.equipments[key] = equipment;
          break;
        }
      }
    },
    addEquipment: (state, action) => {
      const { equipment } = action.payload;
      state.equipments.push(equipment);
    },
    deleteEquipment: (state, action) => {
      const { id } = action.payload;
      state.equipments = state.equipments.filter(
        (equipment) => equipment._id?.toString() !== id?.toString()
      );
    },
  },
});

export const { setEquipments, updateEquipment, addEquipment, deleteEquipment } =
  equipmentSlice.actions;

export default equipmentSlice.reducer;

export const selectEquipments = (state) => state.equipment.equipments;
