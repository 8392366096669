import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AddEditCommande from './AddEditCommande'

const Commandes = () => {
  return (
    <Routes>
      <Route path='/:commandeId' element={<AddEditCommande />} />
      <Route path='/add' element={<AddEditCommande />} />
    </Routes>
  )
}

export default Commandes