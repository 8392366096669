import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Modal.css';
import { fetchCongeEmploye } from '../../redux/actions/CongeAction';


const DetailsConge = ({ isOpen, onClose, personnel }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const conges = useSelector((state) => state.conge.congeEmploye);
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                await dispatch(fetchCongeEmploye(personnel._id));
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log('Erreur lors de la récupération des données :', error);
            }
        };
        fetchData();
    }, []);


    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };
    return (
        <div className={`modal ${isOpen ? 'open' : ''}`} onClick={handleOverlayClick}>
            {loading ? <p>Chargement...</p> :
                <div className="modal-content modal-content-conge-details">
                    <div className="client-detail-modal-content">
                          <p>{personnel?.firstname} {personnel?.lastName} | {personnel?.NumEmpl} </p>
                          <p>Solde: {personnel?.soldeConge}  Jours</p>
                    </div>
                    <div className="table-personnel-container"><table className="table-personnel">
                        <thead>
                            <tr>
                                <th>Type de congé</th>
                                <th>Date</th>
                                <th>Jours</th>
                                <th>Décision</th>
                            </tr>
                        </thead>
                        <tbody>
                        {conges.length > 0 && conges.map((conge, index) => (
                            <tr key={index}>
                                <td>{conge?.typeConge}</td>
                                <td>{conge?.dateDebut.slice(0,10)}</td>
                                <td>{conge?.dureeConge}  {conge?.dureeConge == 1 ? "jour" : "jours"}</td>
                                <td>{conge?.statutDemande == "Approuvée" ? <p className='AcceptedConge'>Accepté</p> : <p className='RefusedConge'>Refusé</p>}</td>
                            </tr>
))}
                        </tbody>
                    </table></div>
                    
                </div>
            }
        </div>
    );
};

export default DetailsConge;
