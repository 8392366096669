import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const exportPdf = async (selector, fileName) => {
  const capture = document.querySelector(selector);
  const canvas = await html2canvas(capture);
  const imgData = canvas.toDataURL("img/png");
  const doc = new jsPDF("p", "em", "a4");
  const componentWidth = doc.internal.pageSize.getWidth();
  const componentHeight = doc.internal.pageSize.getHeight();
  doc.addImage(imgData, "PNG", 0, 0, componentWidth, componentHeight);
  doc.save(fileName);
};
