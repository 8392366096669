import React from 'react'
import "./Header.css"
function SimpleHeader({ title }) {

    return (
        <div className="header-gest-pages">
            <div className="header-gest-pages-left">
                <div className="text-and-back">
                    <h3>  {title} </h3>
                </div>
            </div>
        </div>
    )
}

export default SimpleHeader