import React from 'react'
import "./styles.css"
import Image from "../../Images/headerBg.png"
function Header() {
    return (
        <header id='header'>
            <div className="headercontent">
                <p className='headercontentWelcome'><svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="11" height="11" fill="#1E5EFF" />
                </svg>
                    Bienvenue sur notre site
                </p>
                <h2 className='headercontentSlogant'>Chez CS Consulting, Votre <span>Succès</span> Est Notre Priorité.</h2>
                <p className='headercontentText'>En tant que partenaire indispensable pour des services de Consulting, d'Affaires, et de Gestion d’entreprise, nous sommes là pour transformer vos défis en opportunités et vos ambitions en réalités.
                </p>
                <a className='headercontentButton' href='#service'>En savoir plus</a>
            </div>
            <div className="image-containerForHeader">
                <img src={Image} alt="csc" />
            </div>
        </header>
    )
}

export default Header