import { useTheme } from "@emotion/react";
import {
  ArrowBackOutlined,
  KeyboardArrowDownOutlined,
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import FlexBetween from "Components/FlexBetween";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { selectTasks, setTasks } from "state/task";
import DaysView from "./DaysView";
import MonthsView from "./MonthsView";
import PopUp from "Components/Popup";
import PlanificationExport from "./PlanificationExport";
import TaskService from "Api/TaskService";
import CustomCircularProgress from "Components/CustomCircularProgress";
import { toast } from "react-toastify";

const TasksCalendar = () => {
  const theme = useTheme();
  const { siteId } = useParams();
  const navigate = useNavigate();
  const [planificationOpen, setPlanificationOpen] = useState(false);
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const tasks = useSelector((state) => selectTasks(state));
  const [isDaysView, setIsDaysView] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const changeDate = (value) => {
    const newDate = selectedDate;
    newDate.setDate(selectedDate.getDate() + value);
    setSelectedDate(new Date(newDate));
  };
  useEffect(() => {
    const getSiteTasks = async () => {
      try {
        setIsLoading(true);
        const currentTasks = await TaskService.getTasksDetails(siteId);
        dispatch(setTasks({ tasks: currentTasks }));
        setIsLoading(false);
      } catch (error) {
        toast(`${error}`);
      }
    };
    getSiteTasks();
  }, []);

  return (
    <Box className='content'>
      {isLoading ? (
        <CustomCircularProgress />
      ) : (
        <>
          {/* Header */}
          <Box>
            <Box     
            padding="10px 30px">
              <Typography
                variant="h3"
                fontWeight="bold"
                color={theme.palette.secondary.light}
              >
                Calendrier des tâches
              </Typography>
              <Button
                startIcon={
                  <ArrowBackOutlined
                    sx={{
                      color: theme.palette.grey["600"],
                      height: "15px",
                      width: "18px",
                    }}
                  />
                }
                sx={{
                  color: theme.palette.grey["600"],
                  textTransform: "none",
                  fontSize: "14px",
                }}
                onClick={() => navigate(-1)}
              >
                Retour
              </Button>
            </Box>
            <FlexBetween className="header-gest-pages">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: theme.palette.alt.main,
                    fontWeight: "bold",
                  }}
                >
                  {selectedDate
                    .toLocaleDateString("fr", {
                      year: "numeric",
                      month: "long",
                    })
                    .toUpperCase()}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                  }}
                >
                  <IconButton
                    sx={{
                      border: "1px solid",
                      borderColor: theme.palette.grey[50],
                      borderRadius: "4px",
                      width: "38px",
                      height: "35px",
                    }}
                    onClick={() => changeDate(-1)}
                  >
                    <KeyboardArrowLeftOutlined
                      fontSize="small"
                      sx={{
                        color: theme.palette.grey.main,
                      }}
                    />
                  </IconButton>
                  <Box
                    sx={{
                      border: "1px solid",
                      borderColor: theme.palette.grey[50],
                      borderRadius: "4px",
                      height: "35px",
                      width: "60px",
                      color: theme.palette.grey[400],
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {selectedDate.getDate().toString()}
                  </Box>
                  <IconButton
                    sx={{
                      border: "1px solid",
                      borderColor: theme.palette.grey[50],
                      borderRadius: "4px",
                      width: "38px",
                      height: "35px",
                    }}
                    onClick={() => changeDate(1)}
                  >
                    <KeyboardArrowRightOutlined
                      fontSize="small"
                      sx={{
                        color: theme.palette.grey.main,
                      }}
                    />
                  </IconButton>
                </Box>
                <Select
                  value={isDaysView}
                  renderValue={(p) => (p ? "Jour" : "Mois")}
                  sx={{
                    border: "1px solid",
                    borderColor: theme.palette.grey[50],
                    borderRadius: "4px",
                    width: "102px",
                    fontSize: "16px",
                    color: theme.palette.grey[400],
                    fontWeight: "400",
                    ".css-v3zyv7-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        p: "5px 10px",
                      },
                    ".css-1faml0z-MuiSvgIcon-root-MuiSelect-icon": {
                      color: theme.palette.grey.main,
                    },
                  }}
                  IconComponent={KeyboardArrowDownOutlined}
                >
                  <MenuItem
                    sx={{
                      fontSize: "16px",
                      color: theme.palette.grey[400],
                      fontWeight: "400",
                    }}
                    onClick={() => setIsDaysView(true)}
                  >
                    Jour
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontSize: "16px",
                      color: theme.palette.grey[400],
                      fontWeight: "400",
                    }}
                    onClick={() => setIsDaysView(false)}
                  >
                    Mois
                  </MenuItem>
                </Select>
              </Box>
              {isDaysView && (
                <Button
                  sx={{
                    color: theme.palette.alt.main,
                    border: "1px solid",
                    borderColor: theme.palette.grey.light,
                    borderRadius: "4px",
                    textTransform: "none",
                    fontSize: "16px",
                  }}
                  onClick={() => setPlanificationOpen(true)}
                >
                  Exporter la planification du jour
                </Button>
              )}
            </FlexBetween>
            <Divider
              sx={{
                m: "20px 0",
                width: "100%",
                height: "1px",
                backgroundColor: theme.palette.grey[800],
              }}
            />
          </Box>
          {/* Body */}
          {isDaysView ? (
            <DaysView
              tasks={tasks}
              setSelectedDate={setSelectedDate}
              selectedDate={selectedDate}
            />
          ) : (
            <MonthsView tasks={tasks} selectedDate={selectedDate} />
          )}
          <PopUp open={planificationOpen}>
            <PlanificationExport
              tasks={tasks}
              selectedDate={selectedDate}
              setOpen={setPlanificationOpen}
            />
          </PopUp>
        </>
      )}
    </Box>
  );
};

export default TasksCalendar;
