import React from 'react';
import "./forgetpwd.css";
import Logo from '../../Images/logo.svg';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import refreshToken  from "../../helpers/functions";
const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
        .min(6, 'Le mot de passe doit comporter au moins 6 caractères')
        .required('Mot de passe obligatoire'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Les mots de passe ne correspondent pas')
        .required('Confirmation du mot de passe obligatoire'),
});

function ResetPassword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userId, token } = useParams();

    const handlePasswordReset = async (values) => {
        let http = await refreshToken()
        try {
            const response = await http.put(`password-reset/${userId}/${token}`, {
                password: values.newPassword,
            });
            navigate('/'); 
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <div className="container">
            <div className="login-box">
                <div className="decoration-bar">
                    <img src={Logo} alt="logo" />
                </div>
                <div className="forget-body">
                    <div className="text">
                        <h2>Rénitialisation de mot de passe</h2>
                    </div>
                    <div className="forget-main">
                        <Formik
                            enableReinitialize
                            validationSchema={validationSchema}
                            initialValues={{
                                newPassword: "",
                                confirmPassword: "",
                            }}
                            onSubmit={handlePasswordReset}
                        >
                            {({
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                values,
                                errors,
                                isValid,
                                touched,
                            }) => (
                                <div className="form-container-forget">
                                    <div className="form-group">
                                        <label htmlFor="newPassword" className="label-login">Nouveau mot de passe</label>
                                        <input
                                            type="password"
                                            placeholder="Mot de passe"
                                            className="input-field-login"
                                            id="newPassword"
                                            name="newPassword"
                                            onChange={handleChange("newPassword")}
                                            onBlur={handleBlur("newPassword")}
                                            value={values.newPassword}
                                        />
                                        {errors.newPassword && touched.newPassword && (
                                            <span className="errorText">
                                                {errors.newPassword}
                                            </span>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="confirmPassword" className="label-login">Confirmation du nouveau mot de passe</label>
                                        <input
                                            type="password"
                                            placeholder="Confirmez le mot de passe"
                                            className="input-field-login"
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            onChange={handleChange("confirmPassword")}
                                            onBlur={handleBlur("confirmPassword")}
                                            value={values.confirmPassword}
                                        />
                                        {errors.confirmPassword && touched.confirmPassword && (
                                            <span className="errorText">
                                                {errors.confirmPassword}
                                            </span>
                                        )}
                                    </div>
                                    <button type="submit" onClick={handleSubmit} className="button-login">Envoyer</button>
                                </div>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;
