import React from 'react'
import { Route, Routes } from 'react-router-dom'
import EquipmentsList from './EquipmentsList'
import NotFound from 'Pages/NotFound'

const Equipments = () => {
  return (
    <Routes>
      <Route path='/' element={<EquipmentsList />}  />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  )
}

export default Equipments