import { useTheme } from "@emotion/react";
import { Box, Button, Typography } from "@mui/material";
import { formatDate } from "utils/format_date";
import React, { useState } from "react";
import FlexBetween from "Components/FlexBetween";
import { exportPdf } from "utils/esport_pdf";

const EquipmentExport = ({ equipment, setOpen }) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const downloadPlan = async () => {
    try {
      setIsLoading(true);
      await exportPdf(".equipment-body", `equipment-${equipment.name}.pdf`);
      setIsLoading(false);
      setOpen(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <Box
      sx={{
        width: "482px",
        borderRadius: "6px",
        backgroundColor: theme.palette.primary.main,
        p: "20px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          right: "20px",
        }}
      >
        <Button
          sx={{
            width: "150px",
            fontSize: "16px",
            fontWeight: "400",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.alt.main,
            border: "1px solid",
            borderRadius: "4px",
            borderColor: theme.palette.grey.light,
            textTransform: "none",
            ":hover": {
              backgroundColor: theme.palette.primary[400],
            },
          }}
          onClick={() => downloadPlan()}
          disabled={isLoading}
        >
          {isLoading ? "Exportation..." : "Exporter"}
        </Button>
      </Box>
      <Box
        className="equipment-body"
        sx={{
          width: "100%",
          borderRadius: "6px",
          backgroundColor: theme.palette.primary.main,
          p: "20px",
          pt: "0",
        }}
      >
        <Typography
          sx={{
            color: theme.palette.secondary[700],
            fontSize: "16px",
            fontWeight: "bold",
            mb: "5px",
          }}
        >
          {equipment.name}
        </Typography>
        <Typography
          sx={{
            color: theme.palette.grey[600],
            fontSize: "14px",
          }}
        >
          {equipment.serial_number}
        </Typography>

        <Box
          sx={{
            m: "10px",
            p: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            borderTop: "1px solid",
            borderTopColor: theme.palette.grey[200],
          }}
        >
          <Typography
            sx={{
              color: theme.palette.grey[600],
              fontSize: "14px",
              mb: "20px",
            }}
          >
            {equipment.description}
          </Typography>
          <CustomWidget title="Catégorie" description={equipment.category} />
          <CustomWidget
            title="État actuel de la ressource"
            description={equipment.state}
          />
          <CustomWidget
            title="Lieu"
            description={function () {
              if (equipment.place_type === "stock") {
                return "dépôt";
              } else if (equipment.place_type === "site") {
                return equipment.site.name;
              } else {
                return equipment.place ?? "";
              }
            }}
          />
          <CustomWidget title="Durée de vie" description={equipment.lifetime} />
          <CustomWidget title="Nombre" description={equipment.number} />
          <CustomWidget
            title="Responsable du matériel"
            description={equipment.responsible}
          />
          {!equipment.is_rental && (
            <CustomWidget
              title="Date d’acquisition"
              description={formatDate(equipment.acquisition_date)}
            />
          )}
          <CustomWidget
            title="Nom du fournisseur"
            description={equipment.supplier_name}
          />
          <CustomWidget
            title="Acquisition"
            description={equipment.is_rental ? "Oui" : "Non"}
          />
          {equipment.is_rental && (
            <CustomWidget title="Prix" description={equipment.rental_price} />
          )}
          {equipment.is_rental && (
            <CustomWidget
              title="Date d’Acquisition"
              description={formatDate(equipment.rental_begin_acquisition_date)}
            />
          )}
          {equipment.is_rental && (
            <CustomWidget
              title="Date fin d’Acquisition"
              description={formatDate(equipment.rental_end_acquisition_date)}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

const CustomWidget = ({ title, description }) => {
  const theme = useTheme();
  return (
    <FlexBetween>
      <Typography
        sx={{
          color: theme.palette.grey[600],
          fontSize: "14px",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          color: theme.palette.grey[400],
          fontSize: "16px",
        }}
      >
        {typeof description === "function" ? description() : description}
      </Typography>
    </FlexBetween>
  );
};

export default EquipmentExport;
