import refreshToken from "../../helpers/functions";

export const getAllFichePaie = async () => {
    let http = await refreshToken();
    let result = await http.get(`fichePaie`);
    return result.data;
}

export const getFichePaieById = async (id) => {
    let http = await refreshToken();
    let result = await http.get(`fichePaie/${id}`);
    return result.data;
}

export const addFichePaie = async (data) => {
    let http = await refreshToken();
    let result = await http.post(`fichePaie`, data);
    return result.data;
}

export const updateFichePaie = async (id, data) => {
    let http = await refreshToken();
    let result = await http.put(`fichePaie/${id}`, data);
    return result.data;
}

export const deleteFichePaie = async (id) => {
    let http = await refreshToken();
    let result = await http.delete(`fichePaie/${id}`);
    return result.data;
}

export const getFicheByClientId = async (id) => {
    let http = await refreshToken();
    let result = await http.get(`fichePaie/client/${id}`);
    return result.data
}
export const triFiches = async (query) => {
    let http = await refreshToken();
    let result = await http.get(`fichePaie/tri/${query}`);
    return result.data
}
export const searchFiches = async (query,idClient) => {
    let http = await refreshToken();
    let result = await http.get(`fichePaie/recherche/${query}/${idClient}`);
    return result.data
}
export const searchFichesByMonth = async (query,idClient) => {
    let http = await refreshToken();
    let result = await http.get(`fichePaie/rechercheByMonth/${query}/${idClient}`);
    return result.data
}
// export const getCongeByEmploye = async (id) => {
// let http =  await refreshToken();
// let result = await http.get(`conge/employe/${id}`)
// return result
// }