import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // auth events
    setUser: (state, action) => {
      const { user } = action.payload;
      state.user = user;
    },
  },
});

export const { setUser } = authSlice.actions;

export default authSlice.reducer;

export const selectUser = (state) => state.auth.user;
