import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Login from "./Pages/login/Login";
import LandingPage from "./Pages/LandingPage";
import ForgetPassword from "./Pages/login/ForgetPassword";
import ResetPassword from "./Pages/login/ResetPassword";
import PrivateRoute from "./Routes/PrivateRoute";
import AdminRoute from "./Routes/AdminRoute";

const ROLES = {
  ADMIN: "ADMIN",
  SUPER_ADMIN: "SUPER_ADMIN",
};

function App() {
  const user = useSelector((state) => state.authentification.user);
  const token = useSelector((state) => state.authentification.userToken);

  // useEffect(() => {
  //   const fetchToken = async () => {
  //     const token = localStorage.getItem("@userCsc");
  //     if (token) {
  //       const decoded = jwtDecode(token);
  //       const user = await fetchUser(decoded.id);
  //       dispatch(setInitialState(user));
  //       navigate("/");
  //     } else {
  //       navigate("/login");
  //     }
  //   };
  //   fetchToken();
  // }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={
          token ? (
            user?.role === ROLES.ADMIN || user?.role === ROLES.SUPER_ADMIN ? (
              <PrivateRoute requiredRoles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}>
                <AdminRoute />
              </PrivateRoute>
            ) : (
              <LandingPage />
            )
          ) : (
            <LandingPage />
          )
        }
      />
      <Route path="/404" element={<NotFound />} />
      <Route
        path="/*"
        element={
          <PrivateRoute requiredRoles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}>
            <AdminRoute />
          </PrivateRoute>
        }
      />
      <Route
        path="/login"
        element={
          token &&
          (user?.role === ROLES.ADMIN || user?.role === ROLES.SUPER_ADMIN) ? (
            <Navigate to="/" />
          ) : (
            <Login />
          )
        }
      />
      <Route
        path="/forgetPassword"
        element={
          token &&
          (user?.role === ROLES.ADMIN || user?.role === ROLES.SUPER_ADMIN) ? (
            <Navigate to="/" />
          ) : (
            <ForgetPassword />
          )
        }
      />
      <Route
        path="/password-reset/:userId/:token"
        element={
          token &&
          (user?.role === ROLES.ADMIN || user?.role === ROLES.SUPER_ADMIN) ? (
            <Navigate to="/" />
          ) : (
            <ResetPassword />
          )
        }
      />
    </Routes>
  );
}

const NotFound = () => <h2>Page Not Found</h2>;

export default App;
