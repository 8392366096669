import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import { jwtDecode } from "jwt-decode";
import AuthenticationReducer from "./reducers/AuthenticationReducer";
import { getUser } from "./services/user.service";
// import userReducer from "./reducers/UserReducer";
import clientReducer from "./reducers/ClientReducer";
import personnelReducer from "./reducers/PersonnelReducer";
import congeReducer from "./reducers/CongeReducer";
import fichePaieReducer from "./reducers/FichePaieReducer";
import authReducer from "../state/auth.js";
import siteReducer from "../state/site.js";
import taskReducer from "../state/task.js";
import equipmentReducer from "../state/equipment.js";
import workerReducer from "../state/worker.js";
import globalReducer from "../state/global.js";
import conversationReducer from "../state/conversation.js";
import quoteReducer from "../state/quote.js";
import financialsReducer from "../state/financials.js";
import invoiceReducer from "../state/invoice.js";
import emailReducer from "../state/email.js";
import notificationReducer from "../state/notification.js";
import todoReducer from "../state/todo.js";
import userReducer from "./reducers/UserReducer";
const middleware = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  authentification: AuthenticationReducer,
  client: clientReducer,
  personnel: personnelReducer,
  conge: congeReducer,
  fichePaie: fichePaieReducer,
  auth: authReducer,
  global: globalReducer,
  site: siteReducer,
  task: taskReducer,
  equipment: equipmentReducer,
  worker: workerReducer,
  conversation: conversationReducer,
  financials: financialsReducer,
  invoice: invoiceReducer,
  quote: quoteReducer,
  email: emailReducer,
  user: userReducer,
  notification: notificationReducer,
  todo: todoReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middleware),
});

const getAsyncStorage = () => {
  return async (dispatch) => {
    let token = localStorage.getItem("@userCsc");
    if (token) {
      let decoded = jwtDecode(token);
      let user = await getUser(decoded.id);
      dispatch({
        type: "SIGN_IN_SUCCESS",
        payload: { user, token },
      });
    }
  };
};

store.dispatch(getAsyncStorage());
export default store;
