import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  quotes: [],
};

const quoteSlice = createSlice({
  name: "quote",
  initialState,
  reducers: {
    // quote events
    setQuotes: (state, action) => {
      const { quotes } = action.payload;
      state.quotes = quotes;
    },
    updateQuote: (state, action) => {
      const { quote } = action.payload;
      for (const [key, value] of Object.entries(state.quotes)) {
        if (value._id?.toString() === quote._id?.toString()) {
          state.quotes[key] = quote;
          break;
        }
      }
    },
    addQuote: (state, action) => {
      const { quote } = action.payload;
      state.quotes.push(quote);
    },
    deleteQuote: (state, action) => {
      const { id } = action.payload;
      state.quotes = state.quotes.filter(
        (quote) => quote._id?.toString() !== id?.toString()
      );
    },
  },
});

export const { setQuotes, updateQuote, addQuote, deleteQuote } =
  quoteSlice.actions;

export default quoteSlice.reducer;

export const selectQuotes = (state) => state.quote.quotes;
