import React from 'react'
import "./Modal.css"
const ValidateModal = ({ isOpen, onClose, message }) => {
    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`} onClick={handleOverlayClick}>
            <div className="modal-content-animation">
                <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="29" cy="29" r="29" fill="#ECF2FF" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M34.9526 23.7042C35.3344 23.3176 35.9592 23.32 36.338 23.7094C36.7059 24.0877 36.7039 24.6906 36.3335 25.0664L26.544 35L21.6645 30.0325C21.2951 29.6564 21.2936 29.0542 21.6611 28.6763C22.0403 28.2864 22.6658 28.2843 23.0476 28.6716L26.544 32.219L34.9526 23.7042Z" fill="#1E5EFF" />
                </svg>

                <p className='messageModal'>{message}</p>

            </div>
        </div>
    );
};

export default ValidateModal