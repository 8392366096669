import React from 'react'
import Image from '../../Images/Group 1000007168.png'
function Ascension() {
    return (
        <div className='ascensionContainer'>
                    <div className="ascensionDescreptionContainer">
                        <div className='title-container'>
                            <p><svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="11" height="11" fill="#1E5EFF" />
                            </svg>Au Fil du Temps</p>
                            <h3>L'Ascension de CS Consulting</h3>
                        </div>
                        <div className='text-container'>
                            <p>Créée en 2018 par Hakim HAMOUDA, CS Consulting débute comme HC Consulting, se spécialisant dans une gestion sur mesure. L'arrivée de Zohra TRABELSI à la direction en 2020 catalyse un essor notable, diversifiant nos activités. Notre collaboration avec SAS PEC établit notre crédibilité sur le marché. Sous l'égide de Sana KADI, de 2022 à 2023, nous connaissons une phase d'expansion dynamique, et l'innovation de Serina HAMOUDA en 2023 optimise nos opérations internes. <br />
                                En 2024, l'adoption du nom CS Consulting reflète notre vision élargie et notre engagement envers l'excellence, marquant notre parcours par une croissance soutenue et une adaptation constante aux défis du marché.
                            </p>
                        </div>
                    </div>
                    <div className="ascensionImageContainer">
                        <img src={Image} alt="" />
                    </div>
                </div>
    )
}

export default Ascension