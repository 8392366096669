import React from "react";
import SiteCard1 from "./SiteCard1";
import { useTheme } from "@emotion/react";
import { Box, List, Typography } from "@mui/material";
import FlexBetween from "Components/FlexBetween";
import NotFoundError from "Components/NotFoundError";

const ListView = ({ sites }) => {
  const theme = useTheme();
  return (
    <>
      {sites && Array.isArray(sites) && sites.length !== 0 ? (
        <Box
          sx={{
            backgroundColor: theme.palette.grey[100],
            borderRadius: "6px",
            padding: "5px",
            pb: "20px",
            height: "70vh",
          }}
        >
          <FlexBetween width="50%">
            <Box
              display="flex"
              alignContent="center"
              alignItems="center"
              gap="5px"
            >
              <Typography color={theme.palette.info.main} fontSize="25px">
                ●
              </Typography>
              <Typography variant="h6" color={theme.palette.grey[600]}>
                À Faire
              </Typography>
            </Box>
            <Box
              display="flex"
              alignContent="center"
              alignItems="center"
              gap="5px"
            >
              <Typography color={theme.palette.warning.main} fontSize="25px">
                ●
              </Typography>
              <Typography variant="h6" color={theme.palette.grey[600]}>
                En cours
              </Typography>
            </Box>
            <Box
              display="flex"
              alignContent="center"
              alignItems="center"
              gap="5px"
            >
              <Typography color={theme.palette.error.main} fontSize="25px">
                ●
              </Typography>
              <Typography variant="h6" color={theme.palette.grey[600]}>
                Suspendu
              </Typography>
            </Box>
            <Box
              display="flex"
              alignContent="center"
              alignItems="center"
              gap="5px"
            >
              <Typography color={theme.palette.success.main} fontSize="25px">
                ●
              </Typography>
              <Typography variant="h6" color={theme.palette.grey[600]}>
                Terminé
              </Typography>
            </Box>
          </FlexBetween>
          <Box
            sx={{
              overflow: "auto",
              height: "95%",
            }}
          >
            <List>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {sites.map((item) => (
                  <SiteCard1 key={item._id} site={item} />
                ))}
              </Box>
            </List>
          </Box>
        </Box>
      ) : (
        <NotFoundError text="Aucun chantier trouvé !" sx={{ height: "60%" }} />
      )}
    </>
  );
};

export default ListView;
