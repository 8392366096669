
import {
    getAllFichePaie,
    getFichePaieById,
    addFichePaie,
    updateFichePaie,
    deleteFichePaie,
    getFicheByClientId,
    triFiches,
    searchFiches,
    searchFichesByMonth
} from "../services/fichePaie.service";

export const GET_ALL_FICHE_PAIE = "GET_ALL_FICHE_PAIE";
export const GET_FICHE_PAIE_BY_ID = "GET_FICHE_PAIE_BY_ID";
export const ADD_FICHE_PAIE = "ADD_FICHE_PAIE";
export const UPDATE_FICHE_PAIE = "UPDATE_FICHE_PAIE";
export const DELETE_FICHE_PAIE = "DELETE_FICHE_PAIE";
export const GET_ALL_FICHE_PAIE_BY_CLIENT = "GET_ALL_FICHE_PAIE_BY_CLIENT"
export const GET_ALL_FICHE_PAIE_BY_CLIENT_COPIE = "GET_ALL_FICHE_PAIE_BY_CLIENT_COPIE"
export const getAllFichesPaie = () => (dispatch) => {
    return getAllFichePaie()
        .then(
            (result) => {
                dispatch({
                    type: GET_ALL_FICHE_PAIE,
                    payload: result,
                });
                return result;
            }

        );
};

export const getFichePaie = (id) => (dispatch) => {
    return getFichePaieById(id)
        .then(
            (result) => {
                dispatch({
                    type: GET_FICHE_PAIE_BY_ID,
                    payload: result,
                });
                return result;
            }
        );
}

export const getFicheByClient = (id) => (dispatch) => {
    return getFicheByClientId(id)
        .then(
            (result) => {
                dispatch({
                    type: GET_ALL_FICHE_PAIE_BY_CLIENT,
                    payload: result
                })
                return result
            }
        );
}
export const triFichesPaie = (query) => (dispatch) => {
    return triFiches(query)
        .then(
            (result) => {
                dispatch({
                    type: GET_ALL_FICHE_PAIE_BY_CLIENT,
                    payload: result
                })
                return result
            }
        )
}
export const searchFichesPaie = (query,idClient) => (dispatch) => {
    return searchFiches(query,idClient)
        .then(
            (result) => {
                dispatch({
                    type: GET_ALL_FICHE_PAIE_BY_CLIENT,
                    payload: result
                })
                return result
            }
        )
}
export const searchFichesPaieByMonth = (query,idClient) => (dispatch) => {
    return searchFichesByMonth(query,idClient)
        .then(
            (result) => {
                dispatch({
                    type: GET_ALL_FICHE_PAIE_BY_CLIENT,
                    payload: result
                })
                dispatch({
                    type:GET_ALL_FICHE_PAIE_BY_CLIENT_COPIE,
                    payload: result
                })
                return result
            }
        )
}
export const addFichesPaie = (data) => (dispatch) => {
    return addFichePaie(data)
        .then(
            (result) => {
                dispatch({
                    type: ADD_FICHE_PAIE,
                    payload: result,
                });
                return result;
            }
        );
}

export const updateFichesPaie = (id, data) => (dispatch) => {
    return updateFichePaie(id, data)
        .then(
            (result) => {
                dispatch({
                    type: UPDATE_FICHE_PAIE,
                    payload: result,
                });
                return result;
            }
        );
}

export const deleteFichesPaie = (id) => (dispatch) => {
    return deleteFichePaie(id)
        .then(
            (result) => {
                dispatch({
                    type: DELETE_FICHE_PAIE,
                    payload: id,
                });
                return result;
            }
        );
}

