import CommandeService from "Api/CommandeService";
import CustomCircularProgress from "Components/CustomCircularProgress";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import CommandeTable from "./CommandeTable";
import { useLocation } from "react-router-dom";
import { selectCommandes, setCommandes } from "state/financials";

const CommandesList = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [filterCommandes, setFilterCommandes] = useState(null);
  const query = useLocation();
  const commandes = useSelector((state) => selectCommandes(state));

  useEffect(() => {
    const getCommandes = async () => {
      try {
        setIsLoading(true);
        const data = await CommandeService.getClientCommandesDetails();
        dispatch(setCommandes({ commandes: data }));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast(`${error}`);
      }
    };
    getCommandes();
  }, []);
  useEffect(() => {
    const search = async () => {
      try {
        const terms = query.search.split("=");
        if (!terms[1]) {
          setFilterCommandes(null);
          return;
        }
        setIsLoading(true);
        const data = await CommandeService.sortCommandes(terms[1]);
        setFilterCommandes(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast(`${error}`);
      }
    };
    search();
  }, [query]);
  return (
    <>
      {isLoading ? (
        <CustomCircularProgress sx={{ height: "60vh" }} />
      ) : (
        <CommandeTable
          commandes={filterCommandes ? filterCommandes : commandes}
        />
      )}
    </>
  );
};

export default CommandesList;
