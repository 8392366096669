import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NotFoundError from "./NotFoundError";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setGlobalIsLoading } from "state/global";
import SiteService from "Api/SiteService";
import CustomCircularProgress from "./CustomCircularProgress";

const DeleteWorkersPopUp = ({
  open,
  setOpen,
  selectedWorker,
  handleWorkerToggle,
  setWorkers,
}) => {
  const theme = useTheme();
  const [sites, setSites] = useState([]);
  const [checkedSites, setCheckededSites] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const getWorkerSites = async () => {
      try {
        setIsLoading(true);
        const data = await SiteService.getWorkerSites(selectedWorker._id);
        setSites(data);
        setIsLoading(false);
      } catch (error) {
        toast(`${error}`);
      }
    };
    getWorkerSites();
  }, []);
  const deleteWorkerFromSites = async () => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      const worker = await SiteService.deleteWorkerFromSites(
        selectedWorker._id,
        checkedSites
      );
      setWorkers((prevWorkers) =>
        prevWorkers.map((item) => (item._id === worker._id ? worker : item))
      );
      dispatch(setGlobalIsLoading({ isLoading: false }));
      setOpen(false);
      handleWorkerToggle(selectedWorker);
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };
  const handleSiteClick = (site) => {
    const isSelected = checkedSites.includes(site._id);
    if (isSelected) {
      setCheckededSites((prevSites) =>
        prevSites.filter((item) => item !== site._id)
      );
    } else {
      setCheckededSites((prevSites) => [...prevSites, site._id]);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-content"
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "white",
          maxWidth: "600px",
          height: "240px",
          width: "540px",
          height: "60vh",
          maxHeight: "60vh",
        },
      }}
    >
      {isLoading ? (
        <CustomCircularProgress />
      ) : (
        <>
          <DialogTitle
            sx={{
              fontSize: "20px",
              color: theme.palette.secondary.main,
              fontWeight: "bold",
            }}
            id="dialog-title"
          >
            L'employé est affecter à d'autres chantiers
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                fontSize: "16px",
                color: theme.palette.secondary.main,
              }}
              id="dialog-content"
            >
              Veuillez sélectionner les sites sur lesquels vous souhaitez
              supprimer l'employé :
            </DialogContentText>
            <Box>
              <Box
                sx={{
                  width: "100%",
                  overflow: "auto",
                  height: "70%",
                }}
              >
                <List>
                  {sites.map((site) => (
                    <ListItem
                      key={site._id}
                      disablePadding
                      sx={{ ml: "-15px" }}
                    >
                      <ListItemButton
                        onClick={() => {
                          handleSiteClick(site);
                        }}
                        dense
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checkedSites.includes(site._id)}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                              "aria-labelledby": "site-label-id",
                            }}
                            style={{
                              color: checkedSites.includes(site._id)
                                ? theme.palette.alt.main
                                : theme.palette.grey.light,
                            }}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: "28px",
                              },
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id="site-label-id"
                          sx={{ ml: "-15px" }}
                          primaryTypographyProps={{
                            style: {
                              color: theme.palette.secondary.main,
                              fontSize: "14px",
                              fontWeight: "500",
                            },
                          }}
                          secondaryTypographyProps={{
                            style: {
                              color: theme.palette.grey[600],
                              fontSize: "14px",
                            },
                          }}
                          primary={`${site.name}`}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                color: theme.palette.error.main,
                fontSize: "16px",
                textTransform: "none",
                fontWeight: "400",
              }}
              onClick={() => setOpen(false)}
            >
              Annuler
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderColor: theme.palette.error[600],
                color: theme.palette.error[600],
                borderRadius: "4px",
                fontSize: "16px",
                textTransform: "none",
                fontWeight: "400",
                height: "35px",
                ":hover": {
                  borderColor: theme.palette.error[600],
                },
              }}
              onClick={() => {
                setOpen(false);
                handleWorkerToggle(selectedWorker);
              }}
            >
              Continuer sans supprimer
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: theme.palette.error[600],
                color: theme.palette.primary.main,
                borderRadius: "4px",
                fontSize: "16px",
                textTransform: "none",
                fontWeight: "400",
                height: "35px",
                ":hover": {
                  backgroundColor: theme.palette.error[600],
                  color: theme.palette.primary.main,
                },
              }}
              onClick={async () => {
                await deleteWorkerFromSites();
              }}
            >
              Supprimer
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

const WorkersList = ({
  workers,
  checkedWorkers,
  setWorkers,
  handleWorkerToggle,
  site,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedWorker, setSelectedWorker] = useState({});
  return (
    <>
      {workers && workers?.length !== 0 ? (
        <Box
          sx={{
            width: "100%",
            overflow: "auto",
            height: "70%",
          }}
        >
          <List>
            {workers.map((worker) => (
              <ListItem
                key={worker._id}
                disablePadding
                sx={{ ml: "-15px" }}
                secondaryAction={
                  <Typography
                    variant="h6"
                    sx={{
                      color: theme.palette.secondary.main,
                    }}
                  >
                    {worker.department}
                  </Typography>
                }
              >
                <ListItemButton
                  onClick={() => {
                    if (
                      !worker.isAvailable &&
                      !site.workers.some((item) => item._id === worker._id) &&
                      !checkedWorkers.some((item) => item._id === worker._id)
                    ) {
                      setSelectedWorker(worker);
                      setOpen(true);
                    } else {
                      handleWorkerToggle(worker);
                    }
                  }}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checkedWorkers.some(
                        (item) => item._id === worker._id
                      )}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": "worker-label-id",
                      }}
                      style={{
                        color: checkedWorkers.some(
                          (item) => item._id === worker._id
                        )
                          ? theme.palette.alt.main
                          : theme.palette.grey.light,
                      }}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: "28px",
                        },
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id="worker-label-id"
                    sx={{ ml: "-15px" }}
                    primaryTypographyProps={{
                      style: {
                        color: theme.palette.secondary.main,
                        fontSize: "14px",
                        fontWeight: "500",
                      },
                    }}
                    secondaryTypographyProps={{
                      style: {
                        color: theme.palette.grey[600],
                        fontSize: "14px",
                      },
                    }}
                    primary={`${worker.firstname} ${worker.lastName}`}
                    secondary={worker.phone}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      ) : (
        <NotFoundError sx={{ height: "60%" }} text="Aucun Membre trouvé !" />
      )}
      {open && (
        <DeleteWorkersPopUp
          handleWorkerToggle={handleWorkerToggle}
          open={open}
          selectedWorker={selectedWorker}
          setOpen={setOpen}
          setWorkers={setWorkers}
        />
      )}
    </>
  );
};

export default WorkersList;
