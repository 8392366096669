import React, { useRef, useState } from "react";
import { formatDate } from "utils/format_date";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import NotFoundError from "Components/NotFoundError";
import {
  BorderColorOutlined,
  DeleteOutline,
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
  SaveAltOutlined,
} from "@mui/icons-material";
import CustomDialog from "Components/CustomDialog";
import { toast } from "react-toastify";
import { setGlobalIsLoading } from "state/global";
import InvoiceService from "Api/InvoiceService";
import { useDispatch } from "react-redux";
import { deleteInvoice, updateInvoice } from "state/invoice";
import ActionButton from "Components/ActionButton";
import { exportPdf } from "utils/esport_pdf";
import DetailsPopUp from "Components/DetailsPopUp";

const InvoiceTable = ({ invoices, selectedDate, setSelectedDate }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const exportRef = useRef(null);
  const [deleteInvoiceOpen, setDeleteInvoiceOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const navigate = useNavigate();
  const stateButtonRef = useRef(null);
  const [stateMenuOpen, setStateMenuOpen] = useState(false);
  const getDate = (value) => {
    const date = new Date(value);
    const month = date
      .toLocaleDateString("fr", { month: "long" })
      .toUpperCase()
      .substring(0, 3);
    const year = date.getFullYear();
    return `${month[0].toUpperCase() + month.slice(1, 3)} ${year}`;
  };
  const tableHeader = [
    "N° Facture",
    "Client",
    "Total HT",
    "Date d’échéance",
    "Statut",
    <Box
      sx={{
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <IconButton
        onClick={() => {
          const newMonth = new Date(selectedDate);
          newMonth.setMonth(newMonth.getMonth() - 1);
          setSelectedDate(newMonth);
        }}
      >
        <KeyboardArrowLeftOutlined
          sx={{
            width: "24px",
            height: "24px",
            color: theme.palette.secondary[700],
          }}
        />
      </IconButton>
      <Typography
        sx={{
          color: theme.palette.grey.main,
          fontSize: "16px",
          fontWeight: "bold",
        }}
      >
        {getDate(selectedDate)}
      </Typography>
      <IconButton
        onClick={() => {
          const newMonth = new Date(selectedDate);
          newMonth.setMonth(newMonth.getMonth() + 1);
          setSelectedDate(newMonth);
        }}
      >
        <KeyboardArrowRightOutlined
          sx={{
            width: "24px",
            height: "24px",
            color: theme.palette.secondary[700],
          }}
        />
      </IconButton>
    </Box>,
  ];
  const invoiceStates = ["draft", "topay", "payed", "late"];
  const exportFile = async () => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      await exportPdf(
        ".details-page",
        `facture ${selectedInvoice.invoice_number}.pdf`
      );
      dispatch(setGlobalIsLoading({ isLoading: false }));
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };
  const updateState = async (state) => {
    try {
      if (selectedInvoice.state === "draft") {
        setStateMenuOpen(false);
        throw new Error(
          "Vous ne pouvez pas changer de statut brouillon à un autre status, vous devez d'abord accéder à la page de modification"
        );
      }
      dispatch(setGlobalIsLoading({ isLoading: true }));
      const data = await InvoiceService.updateInvoiceState(
        selectedInvoice._id,
        state
      );
      dispatch(updateInvoice({ invoice: data }));
      dispatch(setGlobalIsLoading({ isLoading: false }));
      setStateMenuOpen(false);
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };
  const getState = (state) => {
    if (state === "draft") {
      return { state: "Brouillon", color: theme.palette.secondary[700] };
    } else if (state === "topay") {
      return { state: "À payer", color: theme.palette.warning[600] };
    } else if (state === "payed") {
      return { state: "Payée", color: theme.palette.success.main };
    } else if (state === "late") {
      return { state: "En retard", color: theme.palette.error[600] };
    } else {
      return { state: "", color: "" };
    }
  };
  const onClick = async () => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      await InvoiceService.deleteInvoice(selectedInvoice._id);
      dispatch(deleteInvoice({ id: selectedInvoice._id }));
      dispatch(setGlobalIsLoading({ isLoading: false }));
      setDetailsOpen(false);
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          alignItems: "end",
          p: "15px",
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: "240px",
            backgroundColor: theme.palette.primary.main,
            display: "flex",
          }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {tableHeader.map((header) => (
                  <TableCell
                    key={header}
                    sx={{
                      color: theme.palette.grey[600],
                      fontSize: "14px",
                      fontWeight: "400",
                      borderBottom: "2px solid",
                      borderBottomColor: theme.palette.grey.light,
                      pt: "5px",
                      pb: "5px",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices && Array.isArray(invoices) && invoices.length !== 0 ? (
                <>
                  {invoices.map((invoice) => (
                    <TableRow
                      key={invoice._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "& .MuiTableCell-root": {
                          color: theme.palette.secondary.dark,
                          fontSize: "14px",
                          borderBottom: "1px solid",
                          borderBottomColor: theme.palette.grey.light,
                          pt: "5px",
                          pb: "5px",
                        },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography
                          sx={{
                            textWrap: "nowrap",
                          }}
                        >
                          {invoice.invoice_number}
                        </Typography>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Typography
                          sx={{
                            textWrap: "nowrap",
                          }}
                        >
                          {invoice.client?.nom}
                        </Typography>
                      </TableCell>
                      <TableCell>{invoice.ht_total}</TableCell>
                      <TableCell>{formatDate(invoice.payment_date)}</TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color: getState(invoice.state).color,
                            fontSize: "14px",
                            textWrap: "nowrap",
                          }}
                        >
                          {getState(invoice.state).state}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                          }}
                        >
                          <IconButton
                            onClick={async () => {
                              setSelectedInvoice(invoice);
                              setDetailsOpen(true);
                              setTimeout(async () => {
                                await exportFile();
                                setDetailsOpen(false);
                              }, 1100);
                            }}
                          >
                            <SaveAltOutlined
                              sx={{
                                color: theme.palette.alt.main,
                                fontSize: "24px",
                              }}
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => navigate(`${invoice._id}`)}
                          >
                            <BorderColorOutlined
                              sx={{
                                color: theme.palette.alt.main,
                                fontSize: "24px",
                              }}
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setSelectedInvoice(invoice);
                              setDeleteInvoiceOpen(true);
                            }}
                          >
                            <DeleteOutline
                              sx={{
                                color: theme.palette.alt.main,
                                fontSize: "24px",
                              }}
                            />
                          </IconButton>
                          <Button
                            sx={{
                              color: theme.palette.alt.main,
                              textTransform: "none",
                              fontSize: "12px",
                              fontWeight: "600",
                            }}
                            onClick={() => {
                              setSelectedInvoice(invoice);
                              setDetailsOpen(true);
                            }}
                          >
                            Détails
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <NotFoundError
                      text="Aucune Facture trouvée !"
                      sx={{ height: "80%" }}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomDialog
          title={`Supprimer facture n°${selectedInvoice.invoice_number} ?`}
          content={`Vous êtes sûr de supprimer facture n°${selectedInvoice.invoice_number} ?`}
          onClick={onClick}
          open={deleteInvoiceOpen}
          setOpen={setDeleteInvoiceOpen}
        />
        <DetailsPopUp
          open={detailsOpen}
          setOpen={setDetailsOpen}
          descriptions={selectedInvoice.descriptions}
          data={{
            name: selectedInvoice.recipient_name,
            email: selectedInvoice.recipient_email,
            phone: selectedInvoice.recipient_phone,
            address: selectedInvoice.recipient_address,
          }}
          values={selectedInvoice}
          isSupplier={false}
          name={`Facture ${selectedInvoice.invoice_number ?? ""}`}
          client={selectedInvoice.client ?? {}}
          actionButtons={
            <>
              <Box ref={stateButtonRef}>
                <ActionButton
                  iconName="info"
                  text="Statut du facture"
                  isEndIcon={true}
                  onClick={() => setStateMenuOpen(true)}
                  subText={getState(selectedInvoice.state).state}
                />
                <Menu
                  anchorReference="anchorEl"
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                  }}
                  anchorEl={stateButtonRef.current}
                  open={stateMenuOpen}
                  onClose={() => setStateMenuOpen(false)}
                  MenuListProps={{
                    style: {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.grey[600],
                      border: "1px solid",
                      borderColor: theme.palette.primary.main,
                      borderRadius: "6px",
                    },
                  }}
                >
                  {invoiceStates.map((state) => (
                    <MenuItem
                      key={state}
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        ":hover": {
                          backgroundColor: theme.palette.grey[50],
                        },
                        backgroundColor:
                          selectedInvoice?.state === state &&
                          theme.palette.grey[50],
                        minWidth: "100px",
                      }}
                      disabled={selectedInvoice?.state === state}
                      onClick={() => updateState(state)}
                    >
                      {getState(state).state}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <ActionButton
                ref={exportRef}
                iconName="download"
                text="Exporter en PDF"
                onClick={exportFile}
              />
              <ActionButton
                iconName="edit"
                text="Modifier la facture"
                onClick={() => {
                  setDetailsOpen(false);
                  navigate(selectedInvoice._id);
                }}
              />
              <ActionButton
                iconName="remove"
                text="Supprimer la facture"
                onClick={() => setDeleteInvoiceOpen(true)}
              />
            </>
          }
        />
      </Box>
    </>
  );
};
export default InvoiceTable;
