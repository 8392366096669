import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllClients } from '../../redux/actions/ClientAction';
import CardClient from '../Cards/CardClient';
import "./listClient.css"
function ListClient() {
    const dispatch = useDispatch();
    const clients = useSelector((state) => state.client.clients); 
    const [sortedClients, setSortedClients] = useState([]);

    useEffect(() => {
        dispatch(getAllClients());
    }, []);

    useEffect(() => {
        setSortedClients(clients);
    }, [clients]);

    return (
        <div className='container-fluid' >
            <div className="row">
                {sortedClients.length > 0 ? (
                    sortedClients.map((client, index) => (
                        <div className="col-md-4" key={index}>
                            <CardClient key={client._id} client={client}  />
                        </div>
                    ))
                ) : (
                    <p>Loading clients...</p>
                )}
            </div>
        </div>
    );
}

export default ListClient;