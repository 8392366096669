import FlexBetween from "Components/FlexBetween";
import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useNavigate, useParams } from "react-router-dom";
import EstimateArray from "./EstimateArray";
import CustomCircularProgress from "Components/CustomCircularProgress";
import EstimateService from "Api/EstimateService";
import { toast } from "react-toastify";
import { formatDate } from "utils/format_date";
import NotFoundError from "Components/NotFoundError";
import { ArrowBackOutlined } from "@mui/icons-material";
import { formatCurrency } from "utils/format_currency";

const EstimateDetails = () => {
  const { estimateId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const [estimate, setEstimate] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [resources, setResources] = useState([]);
  const navigate = useNavigate();

  const getSiteType = (type) => {
    if (type === "residential") {
      return "résidentiel";
    } else if (type === "commercial") {
      return "commercial";
    } else if (type === "infrastructure") {
      return "infrastructure";
    } else {
      return "";
    }
  };
  const getTotal = (items) => {
    let amount = 0;
    items.map((item) => (amount += item.amount));
    return amount;
  };

  useEffect(() => {
    const getEstimate = async () => {
      try {
        setIsLoading(true);
        const data = await EstimateService.getEstimateDetails(estimateId);
        setEstimate(data);
        setResources(data.estimates);
        setIsLoading(false);
        setFirstLoad(false);
      } catch (error) {
        setIsLoading(false);
        toast(`${error}`);
      }
    };
    getEstimate();
  }, []);
  useEffect(() => {
    const updateEstimate = async () => {
      try {
        const data = await EstimateService.updateEstimateDetails(estimateId, {
          estimates: resources,
          site: estimate.site._id,
        });
        setEstimate(data);
      } catch (error) {
        toast(`${error}`);
      }
    };
    if (!firstLoad && resources.length !== estimate?.estimates?.length) {
      updateEstimate();
    }
  }, [resources]);

  return (
    <Box margin="20px" width="100%">
      {/* Header */}
      {isLoading ? (
        <CustomCircularProgress />
      ) : (
        <>
          {estimate && estimate.site ? (
            <>
              <FlexBetween>
                <Box>
                  <Typography
                    variant="h3"
                    fontWeight="bold"
                    color={theme.palette.secondary.light}
                  >
                    Estimation de {estimate.site.name}
                  </Typography>
                  <Button
                    startIcon={
                      <ArrowBackOutlined
                        sx={{
                          color: theme.palette.grey["600"],
                          height: "15px",
                          width: "18px",
                        }}
                      />
                    }
                    sx={{
                      color: theme.palette.grey["600"],
                      textTransform: "none",
                      fontSize: "14px",
                    }}
                    onClick={() => navigate(-1)}
                  >
                    Retour
                  </Button>
                </Box>

                <Button
                  startIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="white"
                        d="m17 15l1 4h3v3H3v-3h3l1-4zm-2-7l1 4H8l1-4zm-2-7l1 4h-4l1-4z"
                      />
                    </svg>
                  }
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    backgroundColor: theme.palette.alt.main,
                    textTransform: "none",
                    ":hover": {
                      backgroundColor: theme.palette.alt[400],
                    },
                  }}
                  onClick={()=>navigate(`/sites/${estimate.site._id}`)}
                >
                  Voir Le Chantier
                </Button>
              </FlexBetween>
              <Box
                sx={{
                  backgroundColor: theme.palette.grey[100],
                  borderRadius: "6px",
                  height: "712px",
                  padding: "5px",
                  mt: "20px",
                  p: "20px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "20px",
                    rowGap: "20px",
                  }}
                >
                  <Box
                    sx={{
                      height: "45px",
                      backgroundColor: theme.palette.primary.main,
                      p: "5px 20px",
                      border: "1px solid",
                      borderColor: theme.palette.grey[200],
                      borderRadius: "4px",
                    }}
                  >
                    <FlexBetween>
                      <Box>
                        <Typography
                          sx={{
                            color: theme.palette.grey[600],
                            fontSize: "12px",
                          }}
                        >
                          Type de chantier
                        </Typography>
                        <Typography
                          sx={{
                            color: theme.palette.grey[400],
                            fontSize: "12px",
                          }}
                        >
                          {getSiteType(estimate.site.type)}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: theme.palette.grey[600],
                            fontSize: "12px",
                          }}
                        >
                          Date de début
                        </Typography>
                        <Typography
                          sx={{
                            color: theme.palette.grey[400],
                            fontSize: "12px",
                          }}
                        >
                          {formatDate(estimate.site.begin_date)}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: theme.palette.grey[600],
                            fontSize: "12px",
                          }}
                        >
                          Date de fin
                        </Typography>
                        <Typography
                          sx={{
                            color: theme.palette.grey[400],
                            fontSize: "12px",
                          }}
                        >
                          {formatDate(estimate.site.end_date)}
                        </Typography>
                      </Box>
                    </FlexBetween>
                  </Box>
                  <Typography
                    sx={{
                      color: theme.palette.alt[900],
                      fontWeight: "bold",
                      fontSize: "20px",
                      textAlign: "right",
                    }}
                  >
                    Estimation Total:{" "}
                    {formatCurrency(getTotal(estimate.estimates),'euro')}
                  </Typography>
                  <EstimateArray
                    title="Estimation des ressources matérielles"
                    type="material"
                    estimates={resources}
                    setEstimates={setResources}
                  />
                  <EstimateArray
                    title="Estimation des ressources humaines"
                    type="human"
                    estimates={resources}
                    setEstimates={setResources}
                  />
                  <EstimateArray
                    title="Estimation des charges logistiques"
                    type="logical"
                    estimates={resources}
                    setEstimates={setResources}
                  />
                  <EstimateArray
                    title="Estimation des autres charges"
                    type="other"
                    estimates={resources}
                    setEstimates={setResources}
                  />
                </Box>
              </Box>
            </>
          ) : (
            <NotFoundError />
          )}
        </>
      )}
    </Box>
  );
};

export default EstimateDetails;
