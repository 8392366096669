
import React from 'react';
import './Modal.css';

const ModalDeleteGuide = ({ isOpen, onClose, handleAction, title, message }) => {
    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };
    const handleDeleteClick = (e) => {
        handleAction(); // Call the handleAction function (delete) when the button is clicked
        e.stopPropagation(); // Stop event propagation to prevent it from bubbling up
        onClose()
    };

    
    return (
        <div className={`modal ${isOpen ? 'open' : ''}`} onClick={handleOverlayClick}>
            <div className="modal-content">
                <p className='titleModal'> {title} </p>
                <p className='messageModal'>

                    {message}

                </p>
                <div className='d-flex align-items-center justify-content-end'>
                    <button className="cancel-button" onClick={onClose}>
                        Annuler
                    </button>
                    <button className="action-button" onClick={handleDeleteClick}>
                        Supprimer
                    </button>
                </div>


            </div>
        </div>
    );
};

export default ModalDeleteGuide;