import React, { useEffect, useState } from "react";
import Header from "../../Components/Headers/Header";
import Layout from "../../Components/Layouts/Layout";
import FicheDePaieForm from "../../Components/Prepai/FicheDePaieForm";
import HeadersWithbuttonsPaie from "../../Components/Headers/HeadersWithbuttonsPaie";
import FicheDePaieFormUpdate from "../../Components/Prepai/fichePaieFormUpdate";
// import PersonnelList from '../../Components/Prepai/PersonnelList';
// import FicheDePai from '../../Components/Prepai/FicheDePai';
import { getFichePaie } from "../../redux/actions/FichePaieAction";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
function UpdatePrepaie() {
  let { idFiche } = useParams();
  const dispatch = useDispatch();

  const fiche = useSelector((state) => state.fichePaie.fichePaie);
  const [dataFromChild, setDataFromChild] = useState();
  useEffect(() => {
    dispatch(getFichePaie(idFiche));
  }, []);
  const handleDataFromChild = (data) => {
    setDataFromChild(data);
  };
  return (
    <Box flex={1}>
      <HeadersWithbuttonsPaie
        update
        title="Fiches de prepaie"
        data={dataFromChild}
        btnTitle="Aperçu"
        btn2="Annuler"
      />
      <div className="container-fluid  personnel-table-container">
        <div className="row">
          <div className="col-md-12">
            {Object.keys(fiche).length !== 0 && (
              <FicheDePaieFormUpdate
                onDataPassed={handleDataFromChild}
                fiche={fiche}
              />
            )}
          </div>
        </div>
      </div>
    </Box>
  );
}

export default UpdatePrepaie;
