import React from "react";
import "./forgetpwd.css";
import Logo from "../../Images/logo.svg";
import { Formik } from "formik";
import * as Yup from "yup";

import refreshToken from "../../helpers/functions";
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Adresse e-mail invalide")
    .required("Email obligatoire"),
});

function ForgetPassword() {
  const handleFormSubmit = async (values, actions) => {
    let http = await refreshToken();

    try {
      const response = await http.post(`password-reset`, {
        email: values.email,
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="container">
      <div className="login-box">
        <div className="decoration-bar">
          <img src={Logo} alt="logo" />
        </div>
        <div className="forget-body">
          <div className="text">
            <h2>Mot de passe oublier!</h2>
            <p>
              On va vous envoyer un email de confirmation contenant le lien de
              réinitialisation de mot de passe
            </p>
          </div>

          <div className="forget-main">
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                email: "",
              }}
              onSubmit={handleFormSubmit}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                isValid,
                touched,
                setFieldValue,
              }) => (
                <div className="form-container-forget">
                  <div className="form-group">
                    <label htmlFor="username" className="label-login">
                      Email
                    </label>

                    <input
                      type="email"
                      placeholder="Email"
                      className="input-field-login"
                      id="email"
                      name="email"
                      onChange={handleChange("email")}
                      onBlur={handleBlur("email")}
                      value={values.email}
                    />

                    {errors.email && touched.email && (
                      <span className="errorText">{errors.userName}</span>
                    )}
                  </div>

                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="button-login"
                  >
                    Envoyer
                  </button>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
