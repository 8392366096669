import { useTheme } from "@emotion/react";
import { Box, Skeleton, Typography } from "@mui/material";
import AuthService from "Api/AuthService";
import { useEffect, useState } from "react";
import CustomField from "./CustomField";
import { toast } from "react-toastify";

const RecipientInfo = ({
  client,
  setClient,
  values,
  isGrid,
  setFieldValue,
}) => {
  const theme = useTheme();
  const [isLoading, setIsloading] = useState(true);
  useEffect(() => {
    const getClientDetails = async () => {
      try {
        setIsloading(true);
        const data = await AuthService.getClientDetails();
        setClient(data);
        setFieldValue("recipient_name", data.nom);
        setFieldValue("recipient_address", data.adresse);
        setFieldValue("recipient_phone", data.phone);
        setFieldValue("recipient_email", data.email);
        setIsloading(false);
      } catch (error) {
        toast(`${error}`);
      }
    };
    getClientDetails();
  }, []);
  return (
    <Box
      sx={{
        pr: "30px",
      }}
    >
      {isLoading ? (
        <Skeleton
          animation="pulse"
          variant="rectangular"
          sx={{
            bgcolor: theme.palette.grey[900],
            borderRadius: "6px",
            height: "100%",
          }}
        />
      ) : (
        <Box
          sx={{
            borderRight: "1px solid",
            borderRightColor: theme.palette.grey.main,
            pr: "20px",
          }}
        >
          <Typography
            sx={{
              color: theme.palette.secondary[700],
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Informations {client.nom}
          </Typography>
          <Box
            sx={{
              display: isGrid ? "grid" : "flex",
              gridTemplateColumns: "1fr 1fr",
              flexDirection: "column",
              gap: isGrid && "15px",
              rowGap: "0",
            }}
          >
            <CustomField
              name="recipient_name"
              title="Nom de Destinataire"
              value={values.recipient_name}
              sx={{
                mt: "10px",
                mb: "0",
              }}
            />
            <CustomField
              name="recipient_address"
              title="Adresse"
              value={values.recipient_address}
              sx={{
                mt: "11px",
                mb: "0",
              }}
            />
            <CustomField
              name="recipient_phone"
              title="Numéro de téléphone"
              value={values.recipient_phone}
              sx={{
                mt: "11px",
                mb: "0",
              }}
            />
            <CustomField
              name="recipient_email"
              title="Mail"
              value={values.recipient_email}
              sx={{
                mt: "11px",
                mb: "0",
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default RecipientInfo;
