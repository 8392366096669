import { useTheme } from "@emotion/react";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";

const CustomDatePicker = ({ name, disabled, sx, value, setFieldValue }) => {
  const theme = useTheme();
  return (
    <DatePicker
      name={name}
      disabled={disabled}
      sx={{
        width: "100%",
        border: "1px solid",
        borderRaduis: "4px",
        borderColor: theme.palette.grey[200],
        height: "40px",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.grey.light,
        ...sx,
      }}
      value={value}
      onChange={(value) => setFieldValue(name, value.toDate())}
    />
  );
};

export default CustomDatePicker;
