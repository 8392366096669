import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  FormControlLabel,
  List,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import CustomField from "Components/CustomField";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { equipmentSchema } from "Pages/schemas/schemas";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { updateEquipment } from "state/equipment";
import PopUp from "Components/Popup";
import { formatDate } from "utils/format_date";
import CustomCircularProgress from "Components/CustomCircularProgress";
import { setGlobalIsLoading } from "state/global";
import EquipmentService from "Api/EquipmentService";
import AddSuccessPopUp from "Components/AddSuccessPopUp";
import CustomDatePicker from "Components/CustomDatePicker";
import CustomDropDown from "Components/CustomDropDown";
import { toast } from "react-toastify";
import SiteService from "Api/SiteService";

const AddEditEquipment = ({ equipmentId, setOpen, getPage, currentPage }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [addEquipmentSuccessOpen, setAddEquipmentSuccessOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(!!equipmentId);
  const [sites, setSites] = useState([]);
  const equipmentCategory = ["residential", "commercial", "infrastructure"];
  const equipmentState = ["new", "used", "damaged"];
  const placeType = ["site", "stock", "location"];

  const getEquipmentCategory = (category) => {
    if (category === "residential") {
      return "résidentiel";
    } else if (category === "commercial") {
      return "commercial";
    } else if (category === "infrastructure") {
      return "infrastructure";
    } else {
      return "";
    }
  };
  const getEquipmentState = (state) => {
    if (state === "new") {
      return "neuf";
    } else if (state === "used") {
      return "usagé";
    } else if (state === "damaged") {
      return "endommagé";
    } else {
      return "";
    }
  };
  const getPlaceType = (type) => {
    if (type === "site") {
      return "chantier";
    } else if (type === "stock") {
      return "dépôt";
    } else if (type === "location") {
      return "emplacement précis";
    } else {
      return "";
    }
  };

  const [equipment, setEquipment] = useState({
    name: "",
    description: "",
    supplier_name: "",
    responsible: "",
    category: equipmentCategory[0],
    number: "",
    lifetime: "",
    state: equipmentState[0],
    serial_number: "",
    place_type: placeType[0],
    place: "",
    is_rental: false,
    rental_price: "",
    acquisition_date: new Date(),
    rental_end_acquisition_date: new Date(),
    rental_begin_acquisition_date: new Date(),
    site: {},
  });

  const handleAddSuccessClick = () => {
    setAddEquipmentSuccessOpen(false);
    setOpen(false);
    getPage(currentPage);
  };

  useEffect(() => {
    const getEquipmentData = async () => {
      try {
        const data = await EquipmentService.getEquipmentDetails(equipmentId);
        setEquipment({ ...data, site: data.site ? data.site._id : null });
        setIsLoading(false);
      } catch (error) {
        toast(`${error}`);
      }
    };
    const getSitesDetails = async () => {
      try {
        setIsLoading(true);
        const data = await SiteService.getAllClientSites();
        setSites(data);
        if (equipmentId) {
          getEquipmentData();
        } else {
          if (data && data.length > 0) {
            setEquipment((prevEquip) => {
              return { ...prevEquip, site: data[0]._id };
            });
          }
          setIsLoading(false);
        }
      } catch (error) {
        toast(`${error}`);
      }
    };
    getSitesDetails();
  }, []);

  const onSubmit = async (values) => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      const data = {
        name: values.name,
        description: values.description,
        acquisition_date: formatDate(values.acquisition_date),
        rental_end_acquisition_date: values.is_rental
          ? formatDate(values.rental_end_acquisition_date)
          : null,
        rental_begin_acquisition_date: values.is_rental
          ? formatDate(values.rental_begin_acquisition_date)
          : null,
        state: values.state,
        supplier_name: values.supplier_name,
        place_type: values.place_type,
        responsible: values.responsible,
        category: equipmentCategory[0],
        number: values.number,
        lifetime: values.lifetime,
        serial_number: values.serial_number,
        place_type: values.place_type,
        is_rental: values.is_rental,
        rental_price: values.is_rental ? values.rental_price : null,
      };
      if (values.place_type === "location") {
        data.place = values.place;
      } else if (values.place_type === "site") {
        data.site = values.site;
      } else {
        data.place = null;
      }
      if (equipmentId) {
        const updatedEquipment = await EquipmentService.updateEquipmentDetails(
          equipment._id,
          data
        );
        dispatch(updateEquipment({ equipment: updatedEquipment }));
        setOpen(false);
      } else {
        await EquipmentService.createEquipment(data);
        setAddEquipmentSuccessOpen(true);
      }
      dispatch(setGlobalIsLoading({ isLoading: false }));
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      onSubmit={onSubmit}
      initialValues={equipment}
      validationSchema={equipmentSchema}
      validateOnMount={true}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <Box
          sx={{
            "span.error-message": {
              m: ".5px -10px",
              fontSize: "12px",
              color: theme.palette.error.main,
              position: "absolute",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "50%",
            },
            bgcolor: theme.palette.primary.main,
            p: "20px",
            pr: "10px",
            width: "580px",
          }}
        >
          <Form onSubmit={(e) => handleSubmit(e)}>
            {isLoading ? (
              <CustomCircularProgress />
            ) : (
              <Box>
                <CustomField
                  value={values.name}
                  isDisabled={false}
                  name="name"
                  type="text"
                  title="Nom de l&rsquo;équipement"
                />
                <CustomField
                  value={values.description}
                  multiline={true}
                  isDisabled={false}
                  rows={3}
                  name="description"
                  type="text"
                  title="Description"
                />
                <CustomField
                  value={values.supplier_name}
                  isDisabled={false}
                  name="supplier_name"
                  type="text"
                  title="Nom du fournisseur"
                />
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    rowGap: "0",
                  }}
                >
                  <CustomField
                    value={values.responsible}
                    isDisabled={false}
                    name="responsible"
                    type="text"
                    title="Responsable du matériel"
                  />
                  <Box
                    sx={{
                      "& .MuiInputBase-root": {
                        color: theme.palette.grey[400],
                        m: "-5px",
                      },
                      "& .MuiButtonBase-root": {
                        color: theme.palette.grey[400],
                      },
                    }}
                  >
                    <Typography
                      height="15px"
                      variant="h6"
                      color={
                        values.is_rental
                          ? theme.palette.grey.light
                          : theme.palette.grey[600]
                      }
                      mb=".6rem"
                    >
                      Date d&rsquo;acquisition
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="acquisition_date"
                        disabled={values.is_rental}
                        sx={{
                          backgroundColor: values.is_rental
                            ? theme.palette.grey.light
                            : theme.palette.primary.main,
                          color: values.is_rental
                            ? theme.palette.primary.main
                            : theme.palette.grey.light,
                        }}
                        value={
                          values.is_rental
                            ? null
                            : dayjs(values.acquisition_date ?? new Date())
                        }
                        setFieldValue={setFieldValue}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box>
                    <Typography
                      height="15px"
                      variant="h6"
                      color={theme.palette.grey[600]}
                      mb=".6rem"
                      ml=".6rem"
                    >
                      Catégorie
                    </Typography>
                    <CustomDropDown
                      name="category"
                      value={values.category}
                      items={equipmentCategory}
                      getItems={getEquipmentCategory}
                    />
                  </Box>
                  <CustomField
                    value={values.number}
                    isDisabled={false}
                    name="number"
                    type="text"
                    title="Nombre"
                  />
                  <Box>
                    <Typography
                      height="15px"
                      variant="h6"
                      color={theme.palette.grey[600]}
                      mb=".6rem"
                      ml=".6rem"
                    >
                      État actuel de la ressource
                    </Typography>
                    <CustomDropDown
                      name="state"
                      value={values.state}
                      items={equipmentState}
                      getItems={getEquipmentState}
                    />
                  </Box>
                  <CustomField
                    value={values.serial_number}
                    isDisabled={false}
                    name="serial_number"
                    type="text"
                    title="Numéro de série"
                  />
                  <Box>
                    <Typography
                      height="15px"
                      variant="h6"
                      color={theme.palette.grey[600]}
                      mb=".6rem"
                      ml=".6rem"
                    >
                      Lieu
                    </Typography>
                    <CustomDropDown
                      name="place_type"
                      value={values.place_type}
                      items={placeType}
                      getItems={getPlaceType}
                    />
                  </Box>
                  {values.place_type === "site" ? (
                    <Box>
                      <Typography
                        height="15px"
                        variant="h6"
                        color={theme.palette.grey[600]}
                        mb=".6rem"
                        ml=".6rem"
                      >
                        Nom du chantier
                      </Typography>
                      <CustomDropDown
                        name="site"
                        value={values.site}
                        items={sites.map((item) => item._id)}
                        getItems={(id) =>
                          sites.find((site) => site._id === id).name
                        }
                      />
                    </Box>
                  ) : (
                    <CustomField
                      value={values.place}
                      isDisabled={values.place_type === "stock"}
                      name="place"
                      type="text"
                      title={
                        values.place_type === "location"
                          ? "Emplacement précis"
                          : "dépôt"
                      }
                    />
                  )}
                  <CustomField
                    value={values.lifetime}
                    isDisabled={false}
                    name="lifetime"
                    type="text"
                    title="Durée de vie"
                    sx={{
                      gridColumn: "span 2",
                    }}
                  />
                  <Box sx={{}}>
                    <Typography
                      height="15px"
                      variant="h6"
                      color={theme.palette.grey[600]}
                      mb=".6rem"
                      ml=".6rem"
                    >
                      Location
                    </Typography>
                    <RadioGroup
                      name="is_rental"
                      onChange={(event, value) =>
                        setFieldValue("is_rental", !values.is_rental)
                      }
                      row
                      sx={{
                        ".MuiFormControlLabel-root span": {
                          color: theme.palette.grey.light,
                        },
                        ".MuiFormControlLabel-label.css-ibwmxy-MuiTypography-root":
                          {
                            color: theme.palette.secondary[700],
                          },
                        ".Mui-checked span": {
                          color: theme.palette.primary.main,
                        },
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        width: "100%",
                        flexWrap: "nowrap",
                      }}
                    >
                      <FormControlLabel
                        value={true}
                        control={
                          <Radio
                            sx={{
                              ".MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-qtxgh1-MuiSvgIcon-root":
                                {
                                  border: values.is_rental && "6px solid",
                                  borderColor:
                                    values.is_rental && theme.palette.alt[900],
                                  borderRadius: values.is_rental && "50%",
                                },
                            }}
                          />
                        }
                        label="Oui"
                      />
                      <FormControlLabel
                        value={false}
                        control={
                          <Radio
                            name="is_rental"
                            sx={{
                              ".MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-qtxgh1-MuiSvgIcon-root":
                                {
                                  border: !values.is_rental && "6px solid",
                                  borderColor:
                                    !values.is_rental && theme.palette.alt[900],
                                  borderRadius: !values.is_rental && "50%",
                                },
                            }}
                          />
                        }
                        label="Non"
                        sx={{
                          color: "green",
                        }}
                      />
                    </RadioGroup>
                  </Box>
                  <CustomField
                    value={values.rental_price}
                    isDisabled={!values.is_rental}
                    name="rental_price"
                    type="text"
                    title="Prix"
                  />
                </Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    display="flex"
                    sx={{
                      "& .MuiInputBase-root": {
                        color: theme.palette.grey[400],
                        m: "-5px",
                      },
                      "& .MuiButtonBase-root": {
                        color: theme.palette.grey[400],
                      },
                      gap: "5px",
                      m: "1.5rem 0",
                    }}
                  >
                    <Box flex="1">
                      <Typography
                        height="15px"
                        variant="h6"
                        color={
                          values.is_rental
                            ? theme.palette.grey[600]
                            : theme.palette.grey.light
                        }
                        mb=".6rem"
                      >
                        Date d&rsquo;acquisition
                      </Typography>
                      <CustomDatePicker
                        name="rental_begin_acquisition_date"
                        disabled={!values.is_rental}
                        setFieldValue={setFieldValue}
                        sx={{
                          backgroundColor: values.is_rental
                            ? theme.palette.primary.main
                            : theme.palette.grey.light,
                        }}
                        value={
                          values.is_rental
                            ? dayjs(
                                values.rental_begin_acquisition_date ??
                                  new Date()
                              )
                            : null
                        }
                      />
                    </Box>
                    <Box flex="1">
                      <Typography
                        height="15px"
                        variant="h6"
                        color={
                          values.is_rental
                            ? theme.palette.grey[600]
                            : theme.palette.grey.light
                        }
                        mb=".6rem"
                      >
                        Fin d&rsquo;acquisition
                      </Typography>
                      <CustomDatePicker
                        name="rental_end_acquisition_date"
                        disabled={!values.is_rental}
                        setFieldValue={setFieldValue}
                        value={
                          values.is_rental
                            ? dayjs(
                                values.rental_end_acquisition_date ?? new Date()
                              )
                            : null
                        }
                        sx={{
                          backgroundColor: values.is_rental
                            ? theme.palette.primary.main
                            : theme.palette.grey.light,
                        }}
                      />
                    </Box>
                  </Box>
                </LocalizationProvider>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "end",
                mr: "20px",
              }}
            >
              <Button
                sx={{
                  width: "100px",
                  fontSize: "16px",
                  fontWeight: "400",
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.alt.main,
                  border: "1px solid",
                  borderRadius: "4px",
                  borderColor: theme.palette.grey.light,
                  textTransform: "none",
                  ":hover": {
                    backgroundColor: theme.palette.primary[400],
                  },
                }}
                onClick={() => setOpen(false)}
              >
                Annuler
              </Button>
              <Button
                sx={{
                  width: "100px",
                  fontSize: "16px",
                  fontWeight: "400",
                  backgroundColor: theme.palette.alt.main,
                  textTransform: "none",
                  ":hover": {
                    backgroundColor: theme.palette.alt[400],
                  },
                }}
                disabled={isLoading}
                type="submit"
              >
                {equipmentId ? "Modifier" : "Ajouter"}
              </Button>
            </Box>
          </Form>

          <PopUp open={addEquipmentSuccessOpen}>
            <AddSuccessPopUp
              title="Ajout de nouveau ressource confirmé"
              onClick={handleAddSuccessClick}
            />
          </PopUp>
        </Box>
      )}
    </Formik>
  );
};

export default AddEditEquipment;
