import React from 'react'
import ListClientHeader from '../../Components/Headers/ListClientHeader'
import ListClient from '../../Components/Clients/ListClient'
import MainLayout from '../../Components/Layouts/MainLayout'
import MainHeader from 'Components/Headers/MainHeader'

function ListClients() {
  return (
    <>
    <MainHeader />
    <MainLayout >
      <ListClientHeader title="Clients" />

      <ListClient />
    </MainLayout>
  </>
  )
}

export default ListClients