import React, { useState } from "react";
import "./Cards.css";
import ModalDeleteGuide from "../Modals/DeleteModal";
import { deleteClients } from "../../redux/actions/ClientAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../Images/logo.svg";
function CardClient({ client }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [modalOpenDelete, setModalOpenDelete] = useState(false);
  const openModalDelete = () => {
    setModalOpenDelete(true);
  };

  const closeModalDelete = () => {
    setModalOpenDelete(false);
  };

  const handleDelete = (id) => {
    dispatch(deleteClients(id))
      .then((result) => {
        // if (result) {
        //     dispatch(fetchAllAmicals())
        // }
      })
      .catch((error) => {
        console.log("Error :", error);
      });
  };

  const testDelete = () => {
    openModalDelete();
  };

  const handleNavigate = () => {
    localStorage.setItem("idClient", client._id);
    localStorage.setItem("NameClient", client.nom);
    navigate(`/clientDetails/${client._id}`);
  };
  return (
    <div
      className="cardClientContainer"
      style={{
        backgroundImage: client.logo
          ? `url("${process.env.REACT_APP_UPLOAD_URL}${
              client.logo.split("\\")[1]
            }")`
          : `url(${logo})`,
      }}
    >
      {modalOpenDelete && (
        <ModalDeleteGuide
          isOpen={modalOpenDelete}
          onClose={closeModalDelete}
          handleAction={() => handleDelete(client._id)}
          title="Supprimer le client X"
          message="Voulez-vous vraiment supprimer le client ?"
        />
      )}
      <div className="cardClientMain">
        <h4>{client.nom}</h4>
        <div className="cardClientBtnsConatiner">
          <button className="actionsBtn" onClick={() => handleNavigate()}>
            <svg
              width="20"
              height="12"
              viewBox="0 0 20 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 0C12.5773 0 15.9107 2 20 6C15.9107 10 12.5773 12 10 12C7.42267 12 4.08934 10 0 6C4.08934 2 7.42267 0 10 0ZM10 2C8.41651 2 6.12903 3.20868 3.26355 5.70283L2.926 6L2.95433 6.02502C5.86439 8.61416 8.19959 9.91203 9.83348 9.99568L10 10C11.5835 10 13.871 8.79132 16.7364 6.29717L17.073 6L17.0457 5.97498C14.1356 3.38584 11.8004 2.08797 10.1665 2.00432L10 2ZM10 4C11.1046 4 12 4.89543 12 6C12 7.10457 11.1046 8 10 8C8.89543 8 8 7.10457 8 6C8 4.89543 8.89543 4 10 4Z"
                fill="#1E5EFF"
              />
            </svg>
          </button>
          <button
            className="actionsBtn"
            onClick={() => navigate(`/updateclient/${client._id}`)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19 19C19.5523 19 20 19.4477 20 20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20C4 19.4477 4.44772 19 5 19H19ZM18.003 3.58492L19.4151 4.99703C20.195 5.77692 20.195 7.04137 19.4151 7.82126L11.1778 16.0586C11.025 16.2114 10.8268 16.3105 10.6129 16.341L6 17L6.65899 12.3871C6.68954 12.1732 6.78864 11.975 6.94141 11.8222L15.1787 3.58492C15.9586 2.80503 17.2231 2.80503 18.003 3.58492ZM16.5909 4.99703L8.58911 12.9988L8.35399 14.646L10.0012 14.4109L18.003 6.40914L16.5909 4.99703Z"
                fill="#1E5EFF"
              />
            </svg>
          </button>
          <button className="actionsBtn" onClick={() => testDelete(client._id)}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 8V19C18 20.1046 17.1046 21 16 21H8C6.89543 21 6 20.1046 6 19V8H18ZM16 10H8V19H16V10ZM12 3C12.5523 3 13 3.44772 13 4V5H18C18.5523 5 19 5.44772 19 6C19 6.55228 18.5523 7 18 7H6C5.44772 7 5 6.55228 5 6C5 5.44772 5.44772 5 6 5H11V4C11 3.44772 11.4477 3 12 3Z"
                fill="#1E5EFF"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

export default CardClient;
