import { useTheme } from "@emotion/react";
import { Box, List, Menu, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import frLocal from "@fullcalendar/core/locales/fr";
import TaskCard from "./TaskCard";
import { formatDate } from "utils/format_date";

const MonthsView = ({ tasks, selectedDate }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [eventDate, setEventDate] = useState(formatDate(Date.now()));
  const dayRef = useRef(null);
  const [currentTasks,setCurrentTasks] = useState([])

  const eventList = () => {
    const events = tasks.map((task) => {
      return {
        title: task.name,
        date: formatDate(task.begin_date).replace(/\//g, "-"),
        backgroundColor: theme.palette.secondary.light,
        borderColor: priorityColor(task.priority),
      };
    });
    return events;
  };
  const priorityColor = (priority) => {
    switch (priority) {
      case "urgent": {
        return theme.palette.error[600];
      }
      case "important": {
        return theme.palette.warning[600];
      }
      case "regular": {
        return theme.palette.success.main;
      }
      default: {
        return theme.palette.primary.main;
      }
    }
  };
  const handleDateClick = (value) => {
    dayRef.current = value.dayEl;
    const date = formatDate(new Date(value.date));
    setEventDate(date)
    setOpen(true);
  };
  useEffect(()=>{
    setCurrentTasks(tasks.filter((task) => formatDate(task.begin_date) === eventDate))
  },[eventDate, tasks])
  return (
    <Box
      sx={{
        "& .fc-daygrid-day-frame": {
          bgcolor: theme.palette.grey[100],
          cursor: "pointer",
          display:'flex',
          flexDirection:'column'
        },
        "& .fc-theme-standard .fc-popover-header": {
          color: theme.palette.secondary.main,
        },
        "& .fc-daygrid-day-number": {
          color: theme.palette.secondary.light,
          textDecoration: "none",
        },
        "& .fc-col-header-cell-cushion": {
          textDecoration: "none",
        },
        "& .fc-event-main": {
          textOverflow: "clip",
          padding: "1px",
          fontSize: "12px",
          color: theme.palette.primary.main,
          height: "28px",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          pl: "5px",
        },
        "& .fc-daygrid-event-harness": {
          pointerEvents: "none",
          width: "90%",
          ml: "5%",
          mb: "2px",
        },
        "& .fc-daygrid-day-events": {
          flexGrow:'1',
          display:'flex',
          flexDirection:'column',
          alignItems:'center',
          justifyContent:'center'

        },
        "& .fc-daygrid-more-link": {
          pointerEvents: "none",
          cursor: "none",
        },
        height: "80%",
      }}
    >
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        locale={frLocal}
        headerToolbar="none"
        events={eventList()}
        height="100%"
        initialDate={selectedDate}
        dayMaxEvents={3}
        moreLinkContent={() => {
          return (
            <Box
              sx={{
                flexGrow: "1",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "3px",
                mt: "5px",
              }}
            >
              <Box
                sx={{
                  bgcolor: theme.palette.secondary.light,
                  width: "5px",
                  height: "5px",
                  borderRadius: "50%",
                }}
              />
              <Box
                sx={{
                  bgcolor: theme.palette.secondary.light,
                  width: "5px",
                  height: "5px",
                  borderRadius: "50%",
                }}
              />
              <Box
                sx={{
                  bgcolor: theme.palette.secondary.light,
                  width: "5px",
                  height: "5px",
                  borderRadius: "50%",
                }}
              />
            </Box>
          );
        }}
        eventClick={function (info) {
          info.jsEvent.preventDefault();
        }}
        moreLinkClick={() => null}
        dateClick={(date) => handleDateClick(date)}
      />
      <Menu
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={dayRef.current}
        anchorOrigin={{
          horizontal: "right",
          vertical: "center",
        }}
        sx={{
          "& .css-6hp17o-MuiList-root-MuiMenu-list": {
            paddingTop:'0px',
            paddingBottom:'0px'
          },
          "& .MuiPopover-paper.css-kc02vp-MuiPaper-root-MuiPopover-paper-MuiMenu-paper": {
            maxHeight:'630px',
          },

        }}
      >
        <Box
          height="630px"
          width="322px"
          overflow="auto"
          bgcolor={theme.palette.secondary.main}
          pr="20px"
          m="0"
        >
          <Box
            sx={{
              m: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "start",
              gap: "10px",
            }}
          >
            <Typography
              variant="h3"
              fontWeight="bold"
              sx={{
                color: theme.palette.primary.main,
              }}
            >
              {selectedDate
                .toLocaleDateString("fr", {
                  weekday: "long",
                  day: "numeric",
                  month: "long",
                })
                .toUpperCase()}
            </Typography>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                gap: "5px",
                alignItems: "baseline",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  border: "1px solid",
                  borderRadius: "4px",
                  borderColor: theme.palette.error.main,
                  color: theme.palette.error.main,
                  p: "1px 5px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "10",
                    fontWeight: "bold",
                  }}
                >
                  Urgente
                </Typography>
              </Box>
              <Box
                sx={{
                  border: "1px solid",
                  borderRadius: "4px",
                  borderColor: theme.palette.warning[600],
                  color: theme.palette.warning[600],
                  p: "1px 5px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "10",
                    fontWeight: "bold",
                  }}
                >
                  importante
                </Typography>
              </Box>
              <Box
                sx={{
                  border: "1px solid",
                  borderRadius: "4px",
                  borderColor: theme.palette.success.main,
                  color: theme.palette.success.main,
                  p: "1px 5px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "10",
                    fontWeight: "bold",
                  }}
                >
                  Régulière
                </Typography>
              </Box>
            </Box>
          </Box>
          <List
            sx={{
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
              height:'85%',
            }}
          >
            <Stack direction="row">
              {currentTasks
                .filter((item) => item.priority === "urgent")
                .map((task) => (
                  <TaskCard
                    sx={{ height: "150px", width: "260px", minWidth: "260px" }}
                    key={task._id}
                    task={task}
                  />
                ))}
            </Stack>
            <Stack direction="row">
              {currentTasks
                .filter((item) => item.priority === "important")
                .map((task) => (
                  <TaskCard
                    sx={{ height: "150px", width: "260px", minWidth: "260px" }}
                    key={task._id}
                    task={task}
                  />
                ))}
            </Stack>
            <Stack direction="row">
              {currentTasks
                .filter((item) => item.priority === "regular")
                .map((task) => (
                  <TaskCard
                    sx={{ height: "150px", width: "260px", minWidth: "260px" }}
                    key={task._id}
                    task={task}
                  />
                ))}
            </Stack>
          </List>
        </Box>
      </Menu>
    </Box>
  );
};

export default MonthsView;
