import React from 'react'
import SimpleHeader from '../../Components/Headers/SimpleHeader'
import UpdateClient from "../../Components/Forms/UpdateClient"
import MainLayout from '../../Components/Layouts/MainLayout'
import MainHeader from 'Components/Headers/MainHeader'
function UpdateClients() {
    return (
        <>
            <MainHeader />
            <MainLayout>
                <SimpleHeader title="Clients" />
                <UpdateClient />
            </MainLayout>
        </>
    )
}

export default UpdateClients