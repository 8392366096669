import React, { useState } from "react";
import Header from "../../Components/Headers/Header";
import ListeConge from "../../Components/Conges/ListeConge2";
import Layout from "../../Components/Layouts/Layout";
import HeaderWithButton from "../../Components/Headers/HeaderWithButton";
import AddCongeModal from "../../Components/Modals/AddCongeModal";
import { Box } from "@mui/material";

function ListConges() {
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Box
    className='content'
    
    >
      {modalOpen && <AddCongeModal isOpen={modalOpen} onClose={closeModal} />}
      <HeaderWithButton
        title="Congés"
        btnTitle="Add congé"
        btnFunction={openModal}
      />
      <ListeConge />
    </Box>
  );
}

export default ListConges;
