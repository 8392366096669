import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./SideBar.css";
import { useSelector } from "react-redux";
import { signOut } from "../../redux/actions/UserAction";
import { useDispatch } from "react-redux";
const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const getInitialIndex = () => {
    const pathname = location.pathname;
    if (pathname.includes("clientdetails")) {
      return 1;
    } else if (pathname.includes("listpersonnel")) {
      return 2;
    } else if (pathname.includes("Listconge")) {
      return 3;
    } else if (pathname.includes("prepaielist")) {
      return 4;
    } else if (pathname.includes("sites")) {
      return 5;
    } else if (pathname.includes("equipments")) {
      return 6;
    } else if (pathname.includes("financials")) {
      return 7;
    } else if (pathname.includes("invoices")) {
      return 8;
    } else if (pathname.includes("quotes")) {
      return 9;
    } else {
      return 1;
    }
  };
  const [activeNavItem, setActiveNavItem] = useState(getInitialIndex());
  const [clientName, setClientName] = useState("");
  const user = useSelector((state) => state.authentification.user);
  const getDetailsLink = () => {
    return `clientdetails/${localStorage.getItem("idClient")}`;
  };
  const [navItems, setNavItems] = useState([]);
  const handleNavItemClick = (item, index) => {
    navigate(`/${item.link.replace(" ", "-").toLowerCase()}`);
    setActiveNavItem(index);
  };

  useEffect(() => {
    setClientName(localStorage.getItem("NameClient"));
  }, []);

  useEffect(() => {
    const items = [
      {
        id: 1,
        icon: (
          <svg
            width="16"
            height="18"
            viewBox="0 0 16 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.32873 0.515579C9.3873 0.567641 9.44276 0.6231 9.49482 0.68167L15.4948 7.43167C15.8202 7.79777 16 8.27057 16 8.7604V16C16 17.1046 15.1046 18 14 18H10.002C10.0013 18 10.0007 18 10 18H6C5.99934 18 5.99868 18 5.99803 18H2C0.89543 18 0 17.1046 0 16V8.7604C0 8.27057 0.179757 7.79777 0.505181 7.43167L6.50518 0.68167C7.23902 -0.143896 8.50316 -0.218257 9.32873 0.515579ZM7 16H9V12H7V16ZM11 16V11C11 10.4477 10.5523 10 10 10H6C5.44772 10 5 10.4477 5 11V16H2V8.7604L8 2.0104L14 8.7604V16H11Z"
              fill="#7E84A3"
            />
          </svg>
        ),
        activeIcon: (
          <svg
            width="16"
            height="18"
            viewBox="0 0 16 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.32873 0.515579C9.3873 0.567641 9.44276 0.6231 9.49482 0.68167L15.4948 7.43167C15.8202 7.79777 16 8.27057 16 8.7604V16C16 17.1046 15.1046 18 14 18H10.002C10.0013 18 10.0007 18 10 18H6C5.99934 18 5.99868 18 5.99803 18H2C0.89543 18 0 17.1046 0 16V8.7604C0 8.27057 0.179757 7.79777 0.505181 7.43167L6.50518 0.68167C7.23902 -0.143896 8.50316 -0.218257 9.32873 0.515579ZM7 16H9V12H7V16ZM11 16V11C11 10.4477 10.5523 10 10 10H6C5.44772 10 5 10.4477 5 11V16H2V8.7604L8 2.0104L14 8.7604V16H11Z"
              fill="#fff"
            />
          </svg>
        ),
        name: "Général",
        link: getDetailsLink(),
        categorie: "",
      },
    ];
    if (user.hrManagement) {
      items.push(
        ...[
          {
            id: 2,
            icon: (
              <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 9C12.3349 9 15.8645 12.4478 15.9962 16.7508L16 17V18H0V17C0 12.5817 3.58172 9 8 9ZM8 11C5.14444 11 2.75479 12.9948 2.14861 15.667L2.10104 15.8977L2.084 16H13.915L13.899 15.8977C13.4095 13.2618 11.1969 11.2312 8.47386 11.0184L8.22494 11.0041L8 11ZM8 0C10.2091 0 12 1.79086 12 4C12 6.20914 10.2091 8 8 8C5.79086 8 4 6.20914 4 4C4 1.79086 5.79086 0 8 0ZM8 2C6.89543 2 6 2.89543 6 4C6 5.10457 6.89543 6 8 6C9.10457 6 10 5.10457 10 4C10 2.89543 9.10457 2 8 2Z"
                  fill="#7E84A3"
                />
              </svg>
            ),
            activeIcon: (
              <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 9C12.3349 9 15.8645 12.4478 15.9962 16.7508L16 17V18H0V17C0 12.5817 3.58172 9 8 9ZM8 11C5.14444 11 2.75479 12.9948 2.14861 15.667L2.10104 15.8977L2.084 16H13.915L13.899 15.8977C13.4095 13.2618 11.1969 11.2312 8.47386 11.0184L8.22494 11.0041L8 11ZM8 0C10.2091 0 12 1.79086 12 4C12 6.20914 10.2091 8 8 8C5.79086 8 4 6.20914 4 4C4 1.79086 5.79086 0 8 0ZM8 2C6.89543 2 6 2.89543 6 4C6 5.10457 6.89543 6 8 6C9.10457 6 10 5.10457 10 4C10 2.89543 9.10457 2 8 2Z"
                  fill="#fff"
                />
              </svg>
            ),
            name: "Personnels",
            link: "listpersonnel",
            categorie: "Ressources Humaines",
          },
          {
            id: 3,
            icon: (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16 2C16.5523 2 17 2.44772 17 3V4H20C21.1046 4 22 4.89543 22 6V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V6C2 4.89543 2.89543 4 4 4H7V3C7 2.44772 7.44772 2 8 2C8.55228 2 9 2.44772 9 3V4H15V3C15 2.44772 15.4477 2 16 2ZM7 6H4V20H20V6H17V7C17 7.55228 16.5523 8 16 8C15.4477 8 15 7.55228 15 7V6H9V7C9 7.55228 8.55228 8 8 8C7.44772 8 7 7.55228 7 7V6ZM16.3073 10.6783L16.324 10.6951C16.7001 11.0818 16.6995 11.6978 16.3225 12.0837L10.544 18L7.67599 15.0511C7.29965 14.6642 7.29965 14.0479 7.67599 13.661C8.01122 13.3163 8.54579 13.2827 8.91974 13.5652L9.0281 13.6609L10.544 15.219L14.9701 10.6932C15.3352 10.3198 15.9339 10.3132 16.3073 10.6783Z"
                  fill="#7E84A3"
                />
              </svg>
            ),
            activeIcon: (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16 2C16.5523 2 17 2.44772 17 3V4H20C21.1046 4 22 4.89543 22 6V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V6C2 4.89543 2.89543 4 4 4H7V3C7 2.44772 7.44772 2 8 2C8.55228 2 9 2.44772 9 3V4H15V3C15 2.44772 15.4477 2 16 2ZM7 6H4V20H20V6H17V7C17 7.55228 16.5523 8 16 8C15.4477 8 15 7.55228 15 7V6H9V7C9 7.55228 8.55228 8 8 8C7.44772 8 7 7.55228 7 7V6ZM16.3073 10.6783L16.324 10.6951C16.7001 11.0818 16.6995 11.6978 16.3225 12.0837L10.544 18L7.67599 15.0511C7.29965 14.6642 7.29965 14.0479 7.67599 13.661C8.01122 13.3163 8.54579 13.2827 8.91974 13.5652L9.0281 13.6609L10.544 15.219L14.9701 10.6932C15.3352 10.3198 15.9339 10.3132 16.3073 10.6783Z"
                  fill="#fff"
                />
              </svg>
            ),
            name: "Congés",
            link: "Listconge",
            categorie: "Ressources Humaines",
          },
          {
            id: 4,
            icon: (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15 2H15.306L20 6.69398V7V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V4C4 2.89543 4.89543 2 6 2H15ZM13 4H6V20H18V9H13V4ZM17.693 7L15 4.306V7H17.693Z"
                  fill="#7E84A3"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.5531 11C13.073 11 13.58 11.1011 14.0518 11.295C14.2949 11.3949 14.5271 11.5188 14.7454 11.6648C15.0115 11.8427 15.08 12.1982 14.8984 12.4589C14.7169 12.7195 14.3539 12.7867 14.0878 12.6088C13.9341 12.506 13.7709 12.4189 13.6005 12.3489C13.2707 12.2133 12.9172 12.1429 12.5531 12.1429C11.6378 12.1429 10.8224 12.5911 10.3102 13.2859L12.0833 13.2857C12.4055 13.2857 12.6666 13.5416 12.6666 13.8571C12.6666 14.1727 12.4055 14.4286 12.0833 14.4286L9.80603 14.4284C9.76928 14.613 9.74999 14.8042 9.74999 15C9.74999 15.196 9.76932 15.3874 9.80615 15.5722L12.0833 15.5714C12.4055 15.5714 12.6666 15.8273 12.6666 16.1429C12.6666 16.4584 12.4055 16.7143 12.0833 16.7143L10.3107 16.7147C10.8228 17.4091 11.638 17.8571 12.5531 17.8571C12.9172 17.8571 13.2707 17.7867 13.6005 17.6511C13.7709 17.5811 13.9341 17.494 14.0878 17.3912C14.3539 17.2133 14.7169 17.2805 14.8984 17.5411C15.08 17.8018 15.0115 18.1573 14.7454 18.3352C14.5271 18.4812 14.2949 18.6051 14.0518 18.705C13.58 18.8989 13.073 19 12.5531 19C10.9672 19 9.60216 18.0645 8.96563 16.7146L8.58333 16.7143C8.26117 16.7143 8 16.4584 8 16.1429C8 15.8273 8.26117 15.5714 8.58333 15.5714L8.62362 15.5718C8.59707 15.385 8.58333 15.1941 8.58333 15C8.58333 14.8061 8.59704 14.6154 8.62354 14.4288L8.58333 14.4286C8.26117 14.4286 8 14.1727 8 13.8571C8 13.5416 8.26117 13.2857 8.58333 13.2857L8.96536 13.2859C9.60178 11.9358 10.967 11 12.5531 11Z"
                  fill="#7E84A3"
                />
              </svg>
            ),
            activeIcon: (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15 2H15.306L20 6.69398V7V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V4C4 2.89543 4.89543 2 6 2H15ZM13 4H6V20H18V9H13V4ZM17.693 7L15 4.306V7H17.693Z"
                  fill="#fff"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.5531 11C13.073 11 13.58 11.1011 14.0518 11.295C14.2949 11.3949 14.5271 11.5188 14.7454 11.6648C15.0115 11.8427 15.08 12.1982 14.8984 12.4589C14.7169 12.7195 14.3539 12.7867 14.0878 12.6088C13.9341 12.506 13.7709 12.4189 13.6005 12.3489C13.2707 12.2133 12.9172 12.1429 12.5531 12.1429C11.6378 12.1429 10.8224 12.5911 10.3102 13.2859L12.0833 13.2857C12.4055 13.2857 12.6666 13.5416 12.6666 13.8571C12.6666 14.1727 12.4055 14.4286 12.0833 14.4286L9.80603 14.4284C9.76928 14.613 9.74999 14.8042 9.74999 15C9.74999 15.196 9.76932 15.3874 9.80615 15.5722L12.0833 15.5714C12.4055 15.5714 12.6666 15.8273 12.6666 16.1429C12.6666 16.4584 12.4055 16.7143 12.0833 16.7143L10.3107 16.7147C10.8228 17.4091 11.638 17.8571 12.5531 17.8571C12.9172 17.8571 13.2707 17.7867 13.6005 17.6511C13.7709 17.5811 13.9341 17.494 14.0878 17.3912C14.3539 17.2133 14.7169 17.2805 14.8984 17.5411C15.08 17.8018 15.0115 18.1573 14.7454 18.3352C14.5271 18.4812 14.2949 18.6051 14.0518 18.705C13.58 18.8989 13.073 19 12.5531 19C10.9672 19 9.60216 18.0645 8.96563 16.7146L8.58333 16.7143C8.26117 16.7143 8 16.4584 8 16.1429C8 15.8273 8.26117 15.5714 8.58333 15.5714L8.62362 15.5718C8.59707 15.385 8.58333 15.1941 8.58333 15C8.58333 14.8061 8.59704 14.6154 8.62354 14.4288L8.58333 14.4286C8.26117 14.4286 8 14.1727 8 13.8571C8 13.5416 8.26117 13.2857 8.58333 13.2857L8.96536 13.2859C9.60178 11.9358 10.967 11 12.5531 11Z"
                  fill="#fff"
                />
              </svg>
            ),
            name: "fiches de paie",
            link: "prepaielist",
            categorie: "Ressources Humaines",
          },
        ]
      );
    }
    if (user.projectManagement) {
      items.push(
        ...[
          {
            id: 5,
            icon: (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.204 9.75027L13.0598 3.74742C12.9818 3.52871 12.8381 3.33947 12.6484 3.20565C12.4587 3.07184 12.2322 3 12 3C11.7678 3 11.5413 3.07184 11.3516 3.20565C11.1619 3.33947 11.0182 3.52871 10.9402 3.74742L8.796 9.75027H15.204ZM18.0165 17.6251L16.4089 13.1252H7.59113L5.9835 17.6251H18.0165ZM4.125 18.7501C3.82663 18.7501 3.54048 18.8686 3.3295 19.0796C3.11853 19.2905 3 19.5767 3 19.875C3 20.1734 3.11853 20.4595 3.3295 20.6705C3.54048 20.8815 3.82663 21 4.125 21H19.875C20.1734 21 20.4595 20.8815 20.6705 20.6705C20.8815 20.4595 21 20.1734 21 19.875C21 19.5767 20.8815 19.2905 20.6705 19.0796C20.4595 18.8686 20.1734 18.7501 19.875 18.7501H4.125Z"
                  fill="#7E84A3"
                />
              </svg>
            ),
            activeIcon: (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.204 9.75027L13.0598 3.74742C12.9818 3.52871 12.8381 3.33947 12.6484 3.20565C12.4587 3.07184 12.2322 3 12 3C11.7678 3 11.5413 3.07184 11.3516 3.20565C11.1619 3.33947 11.0182 3.52871 10.9402 3.74742L8.796 9.75027H15.204ZM18.0165 17.6251L16.4089 13.1252H7.59113L5.9835 17.6251H18.0165ZM4.125 18.7501C3.82663 18.7501 3.54048 18.8686 3.3295 19.0796C3.11853 19.2905 3 19.5767 3 19.875C3 20.1734 3.11853 20.4595 3.3295 20.6705C3.54048 20.8815 3.82663 21 4.125 21H19.875C20.1734 21 20.4595 20.8815 20.6705 20.6705C20.8815 20.4595 21 20.1734 21 19.875C21 19.5767 20.8815 19.2905 20.6705 19.0796C20.4595 18.8686 20.1734 18.7501 19.875 18.7501H4.125Z"
                  fill="#ffff"
                />
              </svg>
            ),
            name: "Chantiers",
            link: "sites",
            categorie: "Gestion des chantiers",
          },
          {
            id: 6,
            icon: (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.54957 18.803C2.28725 19.0648 2.10856 19.3986 2.03611 19.7621C1.96367 20.1256 2.00074 20.5024 2.14263 20.8447C2.28453 21.1871 2.52485 21.4797 2.83316 21.6853C3.14147 21.891 3.50388 22.0005 3.87449 22C4.12056 22.0005 4.36429 21.9522 4.59167 21.8582C4.81905 21.7641 5.02559 21.626 5.1994 21.4518L11.3495 15.3013L8.69963 12.6525L2.54957 18.803ZM17.9204 7.40368L20.5964 6.01873L22 3.30752L20.6926 2L17.9815 3.40368L16.5967 6.07992L13.335 9.34187L14.6586 10.6656L17.9204 7.40368ZM18.506 14.5133C18.4211 14.5083 18.3399 14.4883 18.2538 14.4883C17.7967 14.4883 17.3634 14.582 16.9588 14.7318L9.26906 7.04152C9.41891 6.6369 9.51256 6.20356 9.51256 5.74649C9.51256 5.66032 9.49258 5.57914 9.48759 5.49422C9.35522 3.5448 7.74934 2 5.76633 2C5.18941 2 4.64871 2.14112 4.16045 2.37465L6.64919 4.86357C6.78031 4.99469 6.87022 5.14955 6.93016 5.31314C7.09375 5.75648 7.00509 6.27224 6.64919 6.62941C6.40569 6.87293 6.08601 6.99532 5.76633 6.99532C5.61898 6.99532 5.47288 6.96285 5.33302 6.9104C5.16465 6.85027 5.01129 6.75442 4.88347 6.62941L2.39472 4.14049C2.15086 4.64091 2.02282 5.18982 2.0201 5.74649C2.0201 7.72963 3.5648 9.33562 5.51409 9.468C5.599 9.47299 5.68017 9.49297 5.76633 9.49297C6.22337 9.49297 6.65669 9.39931 7.06128 9.24945L14.751 16.9397C14.5934 17.3535 14.5109 17.792 14.5075 18.2348C14.5075 18.3209 14.5275 18.4021 14.5325 18.487C14.6649 20.4365 16.2708 21.9813 18.2538 21.9813C18.8307 21.9813 19.3714 21.8401 19.8597 21.6066L17.3709 19.1177C17.2455 18.9902 17.1496 18.8367 17.0899 18.6681C17.0059 18.4448 16.9877 18.2021 17.0375 17.9688C17.0873 17.7355 17.203 17.5213 17.3709 17.3519C17.6144 17.1083 17.9341 16.9859 18.2538 16.9859C18.4011 16.9859 18.5472 17.0184 18.6871 17.0709C18.8507 17.1321 19.0055 17.2207 19.1366 17.3519L21.6254 19.8408C21.8589 19.3525 22 18.8117 22 18.2348C22 16.2516 20.4553 14.6456 18.506 14.5133Z"
                  fill="#7E84A3"
                />
              </svg>
            ),
            activeIcon: (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.54957 18.803C2.28725 19.0648 2.10856 19.3986 2.03611 19.7621C1.96367 20.1256 2.00074 20.5024 2.14263 20.8447C2.28453 21.1871 2.52485 21.4797 2.83316 21.6853C3.14147 21.891 3.50388 22.0005 3.87449 22C4.12056 22.0005 4.36429 21.9522 4.59167 21.8582C4.81905 21.7641 5.02559 21.626 5.1994 21.4518L11.3495 15.3013L8.69963 12.6525L2.54957 18.803ZM17.9204 7.40368L20.5964 6.01873L22 3.30752L20.6926 2L17.9815 3.40368L16.5967 6.07992L13.335 9.34187L14.6586 10.6656L17.9204 7.40368ZM18.506 14.5133C18.4211 14.5083 18.3399 14.4883 18.2538 14.4883C17.7967 14.4883 17.3634 14.582 16.9588 14.7318L9.26906 7.04152C9.41891 6.6369 9.51256 6.20356 9.51256 5.74649C9.51256 5.66032 9.49258 5.57914 9.48759 5.49422C9.35522 3.5448 7.74934 2 5.76633 2C5.18941 2 4.64871 2.14112 4.16045 2.37465L6.64919 4.86357C6.78031 4.99469 6.87022 5.14955 6.93016 5.31314C7.09375 5.75648 7.00509 6.27224 6.64919 6.62941C6.40569 6.87293 6.08601 6.99532 5.76633 6.99532C5.61898 6.99532 5.47288 6.96285 5.33302 6.9104C5.16465 6.85027 5.01129 6.75442 4.88347 6.62941L2.39472 4.14049C2.15086 4.64091 2.02282 5.18982 2.0201 5.74649C2.0201 7.72963 3.5648 9.33562 5.51409 9.468C5.599 9.47299 5.68017 9.49297 5.76633 9.49297C6.22337 9.49297 6.65669 9.39931 7.06128 9.24945L14.751 16.9397C14.5934 17.3535 14.5109 17.792 14.5075 18.2348C14.5075 18.3209 14.5275 18.4021 14.5325 18.487C14.6649 20.4365 16.2708 21.9813 18.2538 21.9813C18.8307 21.9813 19.3714 21.8401 19.8597 21.6066L17.3709 19.1177C17.2455 18.9902 17.1496 18.8367 17.0899 18.6681C17.0059 18.4448 16.9877 18.2021 17.0375 17.9688C17.0873 17.7355 17.203 17.5213 17.3709 17.3519C17.6144 17.1083 17.9341 16.9859 18.2538 16.9859C18.4011 16.9859 18.5472 17.0184 18.6871 17.0709C18.8507 17.1321 19.0055 17.2207 19.1366 17.3519L21.6254 19.8408C21.8589 19.3525 22 18.8117 22 18.2348C22 16.2516 20.4553 14.6456 18.506 14.5133Z"
                  fill="#fff"
                />
              </svg>
            ),
            name: "Ressources Matérielles",
            link: "equipments",
            categorie: "Gestion des chantiers",
          },
          {
            id: 7,
            icon: (
              <svg
                width="19"
                height="16"
                viewBox="0 0 19 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15 0C16.1046 0 17 0.89543 17 2V4H18C18.5523 4 19 4.44772 19 5V11C19 11.5523 18.5523 12 18 12H17V14C17 15.1046 16.1046 16 15 16H2C0.89543 16 0 15.1046 0 14V2C0 0.89543 0.89543 0 2 0H15ZM4 2H2V14H4V2ZM15 2H6V14H15V12H12C11.4477 12 11 11.5523 11 11V5C11 4.44772 11.4477 4 12 4H15V2ZM17 6H13V10H17V6Z"
                  fill="#7E84A3"
                />
              </svg>
            ),
            activeIcon: (
              <svg
                width="19"
                height="16"
                viewBox="0 0 19 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15 0C16.1046 0 17 0.89543 17 2V4H18C18.5523 4 19 4.44772 19 5V11C19 11.5523 18.5523 12 18 12H17V14C17 15.1046 16.1046 16 15 16H2C0.89543 16 0 15.1046 0 14V2C0 0.89543 0.89543 0 2 0H15ZM4 2H2V14H4V2ZM15 2H6V14H15V12H12C11.4477 12 11 11.5523 11 11V5C11 4.44772 11.4477 4 12 4H15V2ZM17 6H13V10H17V6Z"
                  fill="#fff"
                />
              </svg>
            ),
            name: "Ressources financières",
            link: "financials",
            categorie: "Gestion des chantiers",
          },
        ]
      );
    }
    if (user.financialManagement) {
      items.push(
        ...[
          {
            id: 8,
            icon: (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 12C16.3349 12 19.8645 15.4478 19.9962 19.7508L20 20V21H4V20C4 15.5817 7.58172 12 12 12ZM12 14C9.14444 14 6.75479 15.9948 6.14861 18.667L6.10104 18.8977L6.084 19H17.915L17.899 18.8977C17.4095 16.2618 15.1969 14.2312 12.4739 14.0184L12.2249 14.0041L12 14ZM12 3C14.2091 3 16 4.79086 16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3ZM12 5C10.8954 5 10 5.89543 10 7C10 8.10457 10.8954 9 12 9C13.1046 9 14 8.10457 14 7C14 5.89543 13.1046 5 12 5Z"
                  fill="#7E84A3"
                />
              </svg>
            ),
            activeIcon: (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 12C16.3349 12 19.8645 15.4478 19.9962 19.7508L20 20V21H4V20C4 15.5817 7.58172 12 12 12ZM12 14C9.14444 14 6.75479 15.9948 6.14861 18.667L6.10104 18.8977L6.084 19H17.915L17.899 18.8977C17.4095 16.2618 15.1969 14.2312 12.4739 14.0184L12.2249 14.0041L12 14ZM12 3C14.2091 3 16 4.79086 16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3ZM12 5C10.8954 5 10 5.89543 10 7C10 8.10457 10.8954 9 12 9C13.1046 9 14 8.10457 14 7C14 5.89543 13.1046 5 12 5Z"
                  fill="#fff"
                />
              </svg>
            ),
            name: "Gestion des factures",
            link: "invoices",
            categorie: "GESTION FINANCIÈRE",
          },
          {
            id: 9,
            icon: (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16 2C16.5523 2 17 2.44772 17 3V4H20C21.1046 4 22 4.89543 22 6V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V6C2 4.89543 2.89543 4 4 4H7V3C7 2.44772 7.44772 2 8 2C8.55228 2 9 2.44772 9 3V4H15V3C15 2.44772 15.4477 2 16 2ZM7 6H4V20H20V6H17V7C17 7.55228 16.5523 8 16 8C15.4477 8 15 7.55228 15 7V6H9V7C9 7.55228 8.55228 8 8 8C7.44772 8 7 7.55228 7 7V6ZM16.3073 10.6783L16.324 10.6951C16.7001 11.0818 16.6995 11.6978 16.3225 12.0837L10.544 18L7.67599 15.0511C7.29965 14.6642 7.29965 14.0479 7.67599 13.661C8.01122 13.3163 8.54579 13.2827 8.91974 13.5652L9.0281 13.6609L10.544 15.219L14.9701 10.6932C15.3352 10.3198 15.9339 10.3132 16.3073 10.6783Z"
                  fill="#7E84A3"
                />
              </svg>
            ),
            activeIcon: (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16 2C16.5523 2 17 2.44772 17 3V4H20C21.1046 4 22 4.89543 22 6V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V6C2 4.89543 2.89543 4 4 4H7V3C7 2.44772 7.44772 2 8 2C8.55228 2 9 2.44772 9 3V4H15V3C15 2.44772 15.4477 2 16 2ZM7 6H4V20H20V6H17V7C17 7.55228 16.5523 8 16 8C15.4477 8 15 7.55228 15 7V6H9V7C9 7.55228 8.55228 8 8 8C7.44772 8 7 7.55228 7 7V6ZM16.3073 10.6783L16.324 10.6951C16.7001 11.0818 16.6995 11.6978 16.3225 12.0837L10.544 18L7.67599 15.0511C7.29965 14.6642 7.29965 14.0479 7.67599 13.661C8.01122 13.3163 8.54579 13.2827 8.91974 13.5652L9.0281 13.6609L10.544 15.219L14.9701 10.6932C15.3352 10.3198 15.9339 10.3132 16.3073 10.6783Z"
                  fill="#fff"
                />
              </svg>
            ),
            name: "Gestion des devis",
            link: "quotes",

            categorie: "GESTION FINANCIÈRE",
          },
        ]
      );
    }
    setNavItems(items);
  }, [user]);

  const categories = {};

  navItems.forEach((item) => {
    if (!categories[item.categorie]) {
      categories[item.categorie] = [];
    }
    categories[item.categorie].push(item);
  });

  const handleLogout = () => {
    dispatch(signOut());
    window.location.href = "/login";
  };

  return (
    <div className="sideBar">
      <div className="top-sideBar">
        <h4 className="sideBar-client">
          {clientName ? clientName.toUpperCase() : ""}
        </h4>
        <div className="side-bar-main-links">
          {Object.keys(categories).map((categorie, categorieIndex) => (
            <React.Fragment key={categorieIndex}>
              {categorie != "" && (
                <div className="category-title">
                  <p>{categorie.toLowerCase()}</p>
                </div>
              )}

              <ul>
                {categories[categorie].map((item) => (
                  <li
                    key={item.id}
                    className={
                      item.id === parseInt(activeNavItem)
                        ? "active "
                        : "notActive"
                    }
                    onClick={() => handleNavItemClick(item, item.id)}
                  >
                    <div className="sideBar-icons-conatainer">
                      {item.id === parseInt(activeNavItem)
                        ? item.activeIcon
                        : item.icon}
                    </div>
                    <span>{item.name}</span>
                  </li>
                ))}
              </ul>
            </React.Fragment>
          ))}
          <button className="logout-button" onClick={handleLogout}>
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.57 13.4746H15C15.1989 13.4746 15.3897 13.3956 15.5303 13.2549C15.671 13.1143 15.75 12.9235 15.75 12.7246C15.75 12.5257 15.671 12.3349 15.5303 12.1942C15.3897 12.0536 15.1989 11.9746 15 11.9746H3.57L6.48 9.55207C6.63316 9.42477 6.72948 9.24183 6.74777 9.04351C6.76605 8.8452 6.70481 8.64773 6.5775 8.49457C6.51447 8.41873 6.43711 8.35605 6.34985 8.31011C6.2626 8.26417 6.16714 8.23586 6.06894 8.22681C5.87063 8.20852 5.67316 8.26977 5.52 8.39707L1.02 12.1471C0.935072 12.2174 0.866709 12.3057 0.819786 12.4055C0.772863 12.5053 0.748535 12.6143 0.748535 12.7246C0.748535 12.8349 0.772863 12.9438 0.819786 13.0436C0.866709 13.1435 0.935072 13.2317 1.02 13.3021L5.52 17.0521C5.65494 17.1639 5.82476 17.2249 6 17.2246C6.11019 17.2248 6.21905 17.2007 6.31887 17.154C6.41868 17.1073 6.50699 17.0392 6.5775 16.9546C6.64065 16.8788 6.68823 16.7913 6.71752 16.6971C6.7468 16.6029 6.75721 16.5038 6.74815 16.4056C6.7391 16.3074 6.71075 16.2119 6.66474 16.1246C6.61872 16.0374 6.55595 15.96 6.48 15.8971L3.57 13.4746Z"
                fill="white"
              />
              <path
                d="M15.0001 4.47461C13.3951 4.47485 11.8249 4.94328 10.4821 5.82251C9.13931 6.70173 8.08213 7.95356 7.44011 9.42461C7.40039 9.51518 7.37897 9.61271 7.37709 9.71158C7.37521 9.81046 7.3929 9.90873 7.42915 10.0007C7.46539 10.0928 7.51948 10.1767 7.5883 10.2477C7.65711 10.3188 7.73929 10.3755 7.83011 10.4146C8.01219 10.4925 8.21768 10.4952 8.40175 10.4221C8.58581 10.3491 8.73351 10.2062 8.81261 10.0246C9.43291 8.60333 10.5237 7.43897 11.9015 6.72733C13.2793 6.01568 14.8601 5.80016 16.3782 6.11699C17.8962 6.43382 19.2589 7.26369 20.237 8.46705C21.2151 9.67041 21.7491 11.1739 21.7491 12.7246C21.7491 14.2754 21.2151 15.7788 20.237 16.9822C19.2589 18.1855 17.8962 19.0154 16.3782 19.3322C14.8601 19.6491 13.2793 19.4335 11.9015 18.7219C10.5237 18.0102 9.43291 16.8459 8.81261 15.4246C8.73351 15.2431 8.58581 15.1002 8.40175 15.0271C8.21768 14.954 8.01219 14.9567 7.83011 15.0346C7.73929 15.0738 7.65711 15.1305 7.5883 15.2015C7.51948 15.2725 7.46539 15.3565 7.42915 15.4485C7.3929 15.5405 7.37521 15.6388 7.37709 15.7376C7.37897 15.8365 7.40039 15.934 7.44011 16.0246C8.03509 17.3879 8.98757 18.5649 10.1967 19.4312C11.4059 20.2974 12.8268 20.8207 14.309 20.9455C15.7912 21.0703 17.2796 20.7921 18.6166 20.1403C19.9536 19.4885 21.0895 18.4873 21.9041 17.2428C22.7188 15.9982 23.1817 14.5566 23.2441 13.0705C23.3065 11.5843 22.9659 10.109 22.2584 8.80058C21.5509 7.49216 20.5028 6.39936 19.2251 5.63783C17.9474 4.8763 16.4876 4.47438 15.0001 4.47461Z"
                fill="white"
              />
            </svg>
            <span>Déconnexion</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
