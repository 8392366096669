import { useTheme } from "@emotion/react";
import { Box, Button, Typography } from "@mui/material";
import CustomCircularProgress from "Components/CustomCircularProgress";
import CustomField from "Components/CustomField";
import FlexBetween from "Components/FlexBetween";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ClientInfo from "Components/ClientInfo";
import CustomDropDown from "Components/CustomDropDown";
import CustomDatePicker from "Components/CustomDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import DescriptionTable from "Components/DescriptionTable";
import FinancialsQuoteService from "Api/FinancialsQuoteService";
import { toast } from "react-toastify";
import { setGlobalIsLoading } from "state/global";
import { useDispatch } from "react-redux";
import { addFinancialsQuote, updateFinancialsQuote } from "state/financials";
import { quoteSchema } from "Pages/schemas/schemas";
import AddSuccessPopUp from "Components/AddSuccessPopUp";
import PopUp from "Components/Popup";
import DetailsPopUp from "Components/DetailsPopUp";
import ActionButton from "Components/ActionButton";
import { exportPdf } from "utils/esport_pdf";

const AddEditFinancialsQuote = () => {
  const { quoteId } = useParams();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(!!quoteId);
  const dispatch = useDispatch();
  const [addSuccessOpen, setAddSuccessOpen] = useState(false);
  const [addSuccessText, setAddSuccessText] = useState("");
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [client, setClient] = useState({});
  const navigate = useNavigate();
  const [quote, setFinancialsQuote] = useState({
    quote_number: "",
    date: new Date(),
    payment_date: new Date(),
    currency: "euro",
    language: "FR",
    ht_total: 0,
    tva_amount: 0,
    ttc_total: 0,
    recipient_name: "",
    recipient_phone: "",
    recipient_email: "",
    recipient_address: "",
    descriptions: [],
  });
  const getLanguage = (l) => {
    if (l === "FR") {
      return "Français";
    } else if (l === "ANG") {
      return "Anglais";
    } else {
      return "";
    }
  };
  const getCurrency = (c) => {
    if (c === "euro") {
      return "Euro €";
    } else if (c === "dollard") {
      return "Dollar $";
    } else {
      return "";
    }
  };
  const exportFile = async (fileName) => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      await exportPdf(".details-page", fileName);
      dispatch(setGlobalIsLoading({ isLoading: false }));
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };
  const onSubmit = async (values) => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      const data = {
        quote_number: values.quote_number,
        date: values.date,
        payment_date: values.payment_date,
        language: values.language,
        ht_total: values.ht_total,
        tva_amount: values.tva_amount,
        ttc_total: values.ttc_total,
        currency: values.currency,
        recipient_name: values.recipient_name,
        recipient_phone: values.recipient_phone,
        recipient_email: values.recipient_email,
        recipient_address: values.recipient_address,
        descriptions: values.descriptions,
      };
      if(values.state == 'draft'){
        data.state = 'created'
      }
      if (quoteId) {
        const updatedFinancialsQuote =
          await FinancialsQuoteService.updateFinancialsQuoteDetails(
            quoteId,
            data
          );
        dispatch(updateFinancialsQuote({ quote: updatedFinancialsQuote }));
        setAddSuccessText(
          `Modification de devis ${updatedFinancialsQuote.quote_number} confirmé`
        );
      } else {
        const newFinancialsQuote =
          await FinancialsQuoteService.createFinancialsQuote(data);
        dispatch(addFinancialsQuote({ quote: newFinancialsQuote }));
        setAddSuccessText("Ajout du devis confirmé");
      }
      dispatch(setGlobalIsLoading({ isLoading: false }));
      setAddSuccessOpen(true);
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };
  const saveDraft = async (values) => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      const data = {
        quote_number: values.quote_number,
        date: values.date,
        payment_date: values.payment_date,
        language: values.language,
        ht_total: values.ht_total,
        tva_amount: values.tva_amount,
        ttc_total: values.ttc_total,
        currency: values.currency,
        recipient_name: values.recipient_name,
        recipient_phone: values.recipient_phone,
        recipient_email: values.recipient_email,
        recipient_address: values.recipient_address,
        descriptions: values.descriptions,
        state: "draft",
      };
      const newFinancialsQuote =
        await FinancialsQuoteService.createFinancialsQuote(data);
      dispatch(addFinancialsQuote({ quote: newFinancialsQuote }));
      setAddSuccessText("Devis Ajouté comme brouillon");
      dispatch(setGlobalIsLoading({ isLoading: false }));
      setAddSuccessOpen(true);
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };

  useEffect(() => {
    const getFinancialsQuoteDetails = async () => {
      try {
        setIsLoading(true);
        const data = await FinancialsQuoteService.getFinancialsQuoteDetails(
          quoteId
        );
        setFinancialsQuote(data);
        setIsLoading(false);
      } catch (error) {
        toast(`${error}`);
      }
    };
    if (quoteId) {
      getFinancialsQuoteDetails();
    }
  }, []);

  return (
    <Box className='content'>
      {isLoading ? (
        <CustomCircularProgress />
      ) : (
        <Box>
          {/* Header */}
          <FlexBetween className="header-gest-pages"> 
            <Typography
              variant="h3"
              fontWeight="bold"
              color={theme.palette.secondary.light}
            >
              {quoteId
                ? `Modification du devis N°${quote.quote_number}`
                : "Nouveau devis"}
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
              }}
            >
              <Button
                sx={{
                  width: "100px",
                  fontSize: "16px",
                  fontWeight: "400",
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.alt.main,
                  border: "1px solid",
                  borderRadius: "4px",
                  borderColor: theme.palette.grey.light,
                  textTransform: "none",
                  ":hover": {
                    backgroundColor: theme.palette.primary[400],
                  },
                }}
                onClick={() => navigate(-1)}
              >
                Annuler
              </Button>
              <Button
                sx={{
                  width: "100px",
                  fontSize: "16px",
                  fontWeight: "400",
                  backgroundColor: theme.palette.alt.main,
                  textTransform: "none",
                  ":hover": {
                    backgroundColor: theme.palette.alt[400],
                  },
                }}
                onClick={() => setDetailsOpen(true)}
              >
                Aperçu
              </Button>
            </Box>
          </FlexBetween>
          <Formik
            enableReinitialize={true}
            onSubmit={onSubmit}
            initialValues={quote}
            validationSchema={quoteSchema}
            validateOnMount={true}
          >
            {({ values, handleSubmit, setFieldValue, isValid }) => (
              <Box
                sx={{
                  backgroundColor: theme.palette.grey[100],
                  borderRadius: "6px",
                  height: "calc(100vh - 140px)",
                  padding: "5px",
                  // mt: "20px",
                  p: "20px",
                  overflow: "auto",
                  width: "95%",
                  margin:"auto",
                }}
              >
                {/* Top */}
                <Form>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      rowGap: "30px",
                    }}
                  >
                    {/* Clinet Info */}
                    <ClientInfo client={client} setClient={setClient} />
                    {/* Language */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "end",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        height="15px"
                        variant="h6"
                        color={theme.palette.grey[600]}
                        mb=".6rem"
                        textAlign="left"
                        width="243px"
                      >
                        Langue
                      </Typography>
                      <CustomDropDown
                        items={["FR", "ANG"]}
                        name="language"
                        value={values.language}
                        getItems={getLanguage}
                        sx={{
                          width: "243px",
                          height: "43px",
                        }}
                      />
                    </Box>
                    {/* Form */}
                    <Box
                      sx={{
                        borderRight: "1px solid",
                        borderRightColor: theme.palette.grey.main,
                        pr: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: theme.palette.secondary[700],
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Informations Destinataire
                      </Typography>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: "15px",
                          rowGap: "0",
                        }}
                      >
                        <CustomField
                          name="recipient_name"
                          title="Nom de Destinataire"
                          value={values.recipient_name}
                          sx={{
                            mt: "10px",
                            mb: "0",
                          }}
                        />
                        <CustomField
                          name="recipient_address"
                          title="Adresse"
                          value={values.recipient_address}
                          sx={{
                            mt: "11px",
                            mb: "0",
                          }}
                        />
                        <CustomField
                          name="recipient_phone"
                          title="Numéro de téléphone"
                          value={values.recipient_phone}
                          sx={{
                            mt: "11px",
                            mb: "0",
                          }}
                        />
                        <CustomField
                          name="recipient_email"
                          title="Mail"
                          value={values.recipient_email}
                          sx={{
                            mt: "11px",
                            mb: "0",
                          }}
                        />
                      </Box>
                    </Box>
                    {/* Form */}
                    <Box>
                      <Box
                        sx={{
                          ml: "20px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: theme.palette.secondary[700],
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Informations Devis
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr",
                              rowGap: "10px",
                              p: "11px 10px 0 10px",
                              "& .MuiInputBase-root": {
                                color: theme.palette.grey[400],
                              },
                              "& .MuiButtonBase-root": {
                                color: theme.palette.grey[400],
                              },
                            }}
                          >
                            <CustomField
                              name="quote_number"
                              title="Numéro"
                              value={values.quote_number}
                              sx={{
                                m: "0",
                                mr: "10px",
                              }}
                            />
                            <Box
                              sx={{
                                ml: "10px",
                                mb: "-10px",
                              }}
                            >
                              <Typography
                                height="15px"
                                variant="h6"
                                color={theme.palette.grey[600]}
                                mb=".6rem"
                                textAlign="left"
                              >
                                Devise
                              </Typography>
                              <CustomDropDown
                                items={["euro", "dollard"]}
                                name="currency"
                                value={values.currency}
                                getItems={getCurrency}
                                sx={{
                                  m: "0",
                                  width: "100%",
                                  height: "38px",
                                  overflow: "clip",
                                }}
                                iconStyle={{
                                  mb: "-10px",
                                  mr: "5px",
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                mr: "10px",
                                "& .MuiInputBase-root": {
                                  m: "-5px",
                                },
                              }}
                            >
                              <Typography
                                height="15px"
                                variant="h6"
                                color={theme.palette.grey[600]}
                                mb=".6rem"
                              >
                                Date
                              </Typography>
                              <CustomDatePicker
                                name="date"
                                value={dayjs(values.date)}
                                setFieldValue={setFieldValue}
                                sx={{
                                  m: "0",
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                ml: "10px",
                                "& .MuiInputBase-root": {
                                  m: "-5px",
                                },
                              }}
                            >
                              <Typography
                                height="15px"
                                variant="h6"
                                color={theme.palette.grey[600]}
                                mb=".6rem"
                              >
                                Echéance (date)
                              </Typography>
                              <CustomDatePicker
                                name="payment_date"
                                value={dayjs(values.payment_date)}
                                setFieldValue={setFieldValue}
                                sx={{
                                  m: "0",
                                  height: "40px",
                                }}
                              />
                            </Box>
                          </Box>
                        </LocalizationProvider>
                      </Box>
                    </Box>
                  </Box>
                </Form>
                {/* Desctiption */}
                <DescriptionTable
                  setSuperFieldValue={setFieldValue}
                  superValues={values}
                />
                <DetailsPopUp
                  open={detailsOpen}
                  setOpen={setDetailsOpen}
                  descriptions={values.descriptions}
                  data={{
                    name: values.recipient_name,
                    email: values.recipient_email,
                    phone: values.recipient_phone,
                    address: values.recipient_address,
                  }}
                  values={values}
                  isSupplier={false}
                  name={`Devis ${values.quote_number ?? ""}`}
                  client={client}
                  actionButtons={
                    <>
                      <ActionButton
                        iconName="confirm"
                        text="Confirmer le devis"
                        onClick={(event) => {
                          handleSubmit(event);
                          if (!isValid) {
                            setDetailsOpen(false);
                          }
                        }}
                      />
                      <ActionButton
                        iconName="download"
                        text="Exporter en PDF"
                        onClick={() =>
                          exportFile(
                            `devis ${values.quote_number ?? ""}.pdf`
                          )
                        }
                      />
                      <ActionButton
                        iconName="save"
                        text="Enregistrer comme brouillon"
                        onClick={async () => await saveDraft(values)}
                      />
                      <ActionButton
                        iconName="adjust"
                        text="Continuer la création"
                        onClick={() => setDetailsOpen(false)}
                      />
                    </>
                  }
                />
              </Box>
            )}
          </Formik>
        </Box>
      )}
      <PopUp open={addSuccessOpen}>
        <AddSuccessPopUp
          title={addSuccessText}
          onClick={() => {
            setAddSuccessOpen(false);
            setDetailsOpen(false);
            navigate("/financials/quotes");
          }}
        />
      </PopUp>
    </Box>
  );
};

export default AddEditFinancialsQuote;
