import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './Modal.css';
import Select from 'react-select';
import { getAllPersonnelsByClient } from '../../redux/actions/PersonnelAction';
import { addConges } from '../../redux/actions/CongeAction';
import ValidateModal from './ValidateModal';

const AddCongeModal = ({ isOpen, onClose }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [selectedOption, setSelectedOption] = useState(null);
    const [searchInput, setSearchInput] = useState('');
    const personnels = useSelector(state => state.personnel.personnels);
   
    useEffect(() => {
        const fetchData = async () => {
            try {
                const idClient =  localStorage.getItem('idClient')
                
                setLoading(true);
                await dispatch(getAllPersonnelsByClient(idClient));
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log('Erreur lors de la récupération des données :', error);
            }
        };
        fetchData();
    }, [dispatch]);

    const filteredOptions = personnels.filter(personnel =>
        `${personnel?.firstname} ${personnel?.lastName}` || `${personnel?.NumEmpl}`
            .toLowerCase()
            .includes(searchInput.toLowerCase())
    );

    const handleChangeEmp = (selectedOption, { setFieldValue }) => {
        setSelectedOption(selectedOption);
        setFieldValue("employe", selectedOption._id);
    };

    const initialValues = {
        employe: '',
        typeConge: '',
        dateDebut: '',
        dateFin: '',
        dureeConge: 0,
        statutDemande: ''
    };

    const validationSchema = Yup.object().shape({
        typeConge: Yup.string().required('Le type de congé est requis'),
        employe: Yup.string().required('Le personnel est requis'),
        dateDebut: Yup.string().required('La date de début est requise'),
        dateFin: Yup.string().required('La date de fin est requise'),
        dureeConge: Yup.number().required('La durée de congé est requise'),
        statutDemande: Yup.string().required('Le statut de la demande est requis'),
    });

    const handleSubmit = async (values) => {
        try {
            const dateDebut = new Date(values.dateDebut);
            const dateFin = new Date(values.dateFin);
            const differenceEnMilliseconds = dateFin - dateDebut;
            const differenceEnJours = Math.ceil(differenceEnMilliseconds / (1000 * 60 * 60 * 24));

            // Ajouter la durée du congé au formulaire pour être soumis
            values.dureeConge = differenceEnJours;

            await dispatch(addConges(values));
            successAdd();
        
            // await dispatch(addConges(values));
            // Gérer le succès ici si nécessaire
        } catch (error) {
            console.log('Erreur lors de l\'ajout de congés :', error.response.data.message);
            // Gérer l'erreur ici si nécessaire
        }
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const successAdd = () => {
        openModal()
        setTimeout(() => {
            closeModal()
            onClose();
        }, 2000);
    }
    return (
        <div className={`modal ${isOpen ? 'open' : ''}`} onClick={handleOverlayClick}>
            {modalOpen &&
                <ValidateModal isOpen={modalOpen} onClose={closeModal} message="Demande d'ajout envoyée" />
            }
            {loading ? <p>Chargement...</p> :
                <div className="modal-content" style={{ width: '600px' }}>
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                        {({ errors, touched, values, setFieldValue, handleChange }) => (
                            <Form style={{ width: "100%", height: '100%' }}>

                                <div className='d-flex justify-content-between'>
                                    <div className="mt-3" style={{ flex: "1", padding: " 0 5px" }}>
                                        <label className="form-label" htmlFor="employe">Nom ou numéro de l’employé </label>
                                        <Select
                                            style={{ height: "38px" }}
                                            name="employe"
                                            value={selectedOption}
                                            onChange={(selectedOption) => handleChangeEmp(selectedOption, { setFieldValue })}
                                            options={filteredOptions}
                                            isSearchable
                                            onInputChange={(inputValue) => setSearchInput(inputValue)}
                                            placeholder="Sélectionnez un employé"
                                            className='gestionContainerHeaderSelect'
                                            getOptionLabel={(option) => `${option.firstname} ${option.lastName} - ${option.NumEmpl}`}
                                            getOptionValue={(option) => option._id}
                                        />
                                        {errors.employe && touched.employe && (
                                            <span className="errorText">
                                                {errors.employe}
                                            </span>
                                        )}
                                    </div>
                                    <div className="mt-3 inpt-container " style={{ flex: "1", padding: " 0 5px" }}>
                                        <label className="form-label" htmlFor="typeConge">Type de Congé</label>
                                        <select name="typeConge" className="form-select"
                                            onChange={handleChange}
                                            style={{ height: "38px" }}>
                                            <option disabled value="">
                                                Sélectionnez un type
                                            </option>
                                            <option value="Repos">Repos</option>
                                            <option value="Maladie">Maladie</option>
                                            <option value="Payé">Payé</option>
                                            <option value="Non payé">Non payé</option>
                                            <option value="Fractionnement">Fractionnement</option>
                                            <option value="Familiales">Familiales</option>
                                            <option value="Paternité">Paternité</option>
                                            <option value="Maternité">Maternité</option>
                                        </select >
                                        <ErrorMessage name="typeConge" component="span" className="errorText" />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div className="mt-3" style={{ flex: "1", padding: " 0 5px" }}>
                                        <div className=''>
                                            <label htmlFor="dateDebut" className="form-label">Début de Congé</label>
                                            <Field type="date" className="form-control" name="dateDebut" />
                                            <ErrorMessage name="dateDebut" component="span" className="errorText" />
                                        </div>
                                    </div>
                                    <div className="mt-3" style={{ flex: "1", padding: " 0 5px" }}>
                                        <div className=''>
                                            <label htmlFor="dateFin" className="form-label">Fin de Congé</label>
                                            <Field type="date" className="form-control" name="dateFin" />
                                            <ErrorMessage name="dateFin" component="span" className="errorText" />
                                        </div>
                                    </div>
                                </div>
                                <div className='radio-form-container mt-3'>
                                    <div className="form-check" style={{ paddingLeft: '0' }}>
                                        <label htmlFor="statutDemande" className="form-label">Complete File</label>
                                        <Field type="radio" name="statutDemande" value="Approuvée" /> <label className="form-check-label" htmlFor="statutDemande">Approuvée</label>
                                        <Field type="radio" name="statutDemande" value="Refusée" /> <label className="form-check-label" htmlFor="statutDemande">Refusée</label>
                                    </div>
                                    <ErrorMessage name="statutDemande" component="div" className="errorText" />
                                </div>
                                <div className="d-flex flex-row justify-content-end" >
                                    <button type="submit" style={{ width: "120px" }} className='DarkBtn d-flex justify-content-center'>Ajouter</button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            }
        </div>
    );
};

export default AddCongeModal;
