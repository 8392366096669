import React from 'react'
import Image from "../../Images/contact.png"
function Contact() {
    return (
        <div className="container mt-5" id='contact'>
            <div className="row">
                <div className="col-md-6 contact-image">
                    <img src={Image} alt="csc" />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className='title-container'>
                        <p className='mb-0'><svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="11" height="11" fill="#1E5EFF" />
                        </svg>Contactez-Nous</p>
                        <h3>Contactez-Nous</h3>
                        <p className='text-descreption-start'>Contactez-nous dès aujourd'hui pour commencer votre parcours vers le succès.
                        </p>
                    </div>
                    <div className='contact-info'>
                        <div>
                            <svg width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="34.2664" cy="34.5" r="34" fill="#1E5EFF" />
                                <path d="M48.4548 41.8734C47.4623 40.8734 45.0585 39.4141 43.8922 38.826C42.3734 38.0611 42.2484 37.9986 41.0546 38.8854C40.2584 39.4772 39.729 40.006 38.7971 39.8072C37.8652 39.6085 35.8401 38.4879 34.067 36.7205C32.2938 34.9531 31.1082 32.8694 30.9088 31.9407C30.7094 31.012 31.2469 30.4889 31.8332 29.6908C32.6594 28.5658 32.5969 28.3783 31.8907 26.8597C31.34 25.6785 29.8381 23.2973 28.8344 22.3099C27.7606 21.2493 27.7606 21.4368 27.0687 21.7243C26.5054 21.9612 25.965 22.2493 25.4543 22.5848C24.4542 23.2492 23.8992 23.801 23.5111 24.6304C23.123 25.4597 22.9486 27.404 24.953 31.0451C26.9574 34.6862 28.3637 36.548 31.2744 39.4504C34.1851 42.3527 36.4233 43.9133 39.6952 45.7482C43.7428 48.015 45.2954 47.5731 46.1273 47.1857C46.9592 46.7982 47.5136 46.2482 48.1792 45.2482C48.5156 44.7384 48.8044 44.1986 49.0417 43.6358C49.3299 42.9465 49.5174 42.9465 48.4548 41.8734Z" stroke="white" stroke-width="2" stroke-miterlimit="10" />
                            </svg>
                            <div>
                                <p>Avez-vous des questions ?</p>
                                <h4>+33 0488409624</h4>
                            </div>
                        </div>
                        <div>
                            <svg width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="34.2664" cy="34.5" r="34" fill="#1E5EFF" />
                                <path d="M44.7664 24.5H23.7664C22.3856 24.5 21.2664 25.6193 21.2664 27V42C21.2664 43.3807 22.3856 44.5 23.7664 44.5H44.7664C46.1471 44.5 47.2664 43.3807 47.2664 42V27C47.2664 25.6193 46.1471 24.5 44.7664 24.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M25.2664 28.5L34.2664 35.5L43.2664 28.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <div>
                                <p>Envoyez-nous un e-mail</p>
                                <h4>compta.hcconsulting@gmail.com</h4>
                            </div>
                        </div>
                        <div>
                            <svg width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="34.2664" cy="34.5" r="34" fill="#1E5EFF" />
                                <path d="M34.2664 20.5C28.7455 20.5 24.2664 24.9274 24.2664 30.3803C24.2664 36.6546 30.933 46.5977 33.3809 50.032C33.4826 50.177 33.6157 50.2949 33.7696 50.3763C33.9235 50.4576 34.0937 50.5 34.2664 50.5C34.439 50.5 34.6092 50.4576 34.7631 50.3763C34.917 50.2949 35.0502 50.177 35.1518 50.032C37.5997 46.5991 44.2664 36.6597 44.2664 30.3803C44.2664 24.9274 39.7872 20.5 34.2664 20.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M34.2664 35.5C36.4755 35.5 38.2664 33.7091 38.2664 31.5C38.2664 29.2909 36.4755 27.5 34.2664 27.5C32.0572 27.5 30.2664 29.2909 30.2664 31.5C30.2664 33.7091 32.0572 35.5 34.2664 35.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <div >
                                <p >Siège social</p>
                                <h4>Centre tertiaire la grande pyramide,
                                    rue de l'equerre, istres, 13800, france</h4>

                                <p className='mt-3'>Agence</p>
                                <h4>27, cours Landrivon, 13110, Port de bouc</h4>

                            </div>
                        </div>
                        {/* <div>
                            <svg width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="34.2664" cy="34.5" r="34" fill="#1E5EFF" />
                                <path d="M34.2664 20.5C28.7455 20.5 24.2664 24.9274 24.2664 30.3803C24.2664 36.6546 30.933 46.5977 33.3809 50.032C33.4826 50.177 33.6157 50.2949 33.7696 50.3763C33.9235 50.4576 34.0937 50.5 34.2664 50.5C34.439 50.5 34.6092 50.4576 34.7631 50.3763C34.917 50.2949 35.0502 50.177 35.1518 50.032C37.5997 46.5991 44.2664 36.6597 44.2664 30.3803C44.2664 24.9274 39.7872 20.5 34.2664 20.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M34.2664 35.5C36.4755 35.5 38.2664 33.7091 38.2664 31.5C38.2664 29.2909 36.4755 27.5 34.2664 27.5C32.0572 27.5 30.2664 29.2909 30.2664 31.5C30.2664 33.7091 32.0572 35.5 34.2664 35.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact