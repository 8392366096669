import { useTheme } from "@emotion/react";
import {
  BorderColorOutlined,
  DeleteOutline,
  InfoOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SiteService from "Api/SiteService";
import CustomDialog from "Components/CustomDialog";
import FlexBetween from "Components/FlexBetween";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setGlobalIsLoading } from "state/global";
import { deleteSite, updateSite } from "state/site";
import { formatDate } from "utils/format_date";

const SiteCard1 = ({ site }) => {
  const isSmallScreen = useMediaQuery("(max-width:920px)");
  const [menuOpen, setMenuOpen] = useState(false);
  const iconRef = useRef(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  
  const handleClick = async () => {
    try {
      dispatch(setGlobalIsLoading({isLoading:true}))
      await SiteService.deleteSite(site._id)
      dispatch(deleteSite({ id: site._id }));
      dispatch(setGlobalIsLoading({isLoading:false}));
    } catch (error) {
      dispatch(setGlobalIsLoading({isLoading:false}));
      toast(`${error}`);
    }
  };
  const changeSiteState = async (state) => {
    try {
      dispatch(setGlobalIsLoading({isLoading:true}))
      const updatedSite = await SiteService.updateSiteState(site._id,state)
      dispatch(updateSite({ site: updatedSite }));
      dispatch(setGlobalIsLoading({isLoading:false}))
      setMenuOpen(false);
    } catch (error) {
      dispatch(setGlobalIsLoading({isLoading:false}))
      toast(`${error}`);
    }
  };
  const bandeColor = () => {
    switch (site.state) {
      case "inprogress": {
        return theme.palette.warning.main;
      }
      case "suspended": {
        return theme.palette.error.main;
      }
      case "done": {
        return theme.palette.success.main;
      }
      default: {
        return theme.palette.info.main;
      }
    }
  };
  return (
    <FlexBetween
      sx={{
        flex: isSmallScreen ? "1 0 99%" : "1 0 45%",
        backgroundColor: theme.palette.primary.main,
        height: "150px",
        margin: "12px",
        borderRadius: "6px",
      }}
    >
      {/* left Side */}
      <Box
        sx={{
          display: "flex",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          gap: "15px",
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "35px",
            backgroundColor: bandeColor(),
            textAlign: "center",
            padding: "5px 0",
            borderBottomLeftRadius: "6px",
            borderTopLeftRadius: "6px",
          }}
        >
          <Box>
            <IconButton ref={iconRef} onClick={() => setMenuOpen(true)}>
              <InfoOutlined />
            </IconButton>
            <Menu
              anchorEl={iconRef.current}
              open={menuOpen}
              onClose={() => setMenuOpen(false)}
              anchorReference="anchorEl"
              anchorOrigin={{
                horizontal:'left',
                vertical:'bottom'
              }}
              MenuListProps={{
                style: {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.grey[600],
                  border: "1px solid",
                  borderColor: theme.palette.primary.main,
                  borderRadius: "6px",
                  width: "125px",
                },
              }}
            >
              <MenuItem
                sx={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  ":hover": {
                    backgroundColor: theme.palette.grey[50],
                  },
                  backgroundColor:
                    site.state === "todo" && theme.palette.grey[50],
                }}
                onClick={() => changeSiteState("todo")}
              >
                À faire
              </MenuItem>
              <MenuItem
                sx={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  ":hover": {
                    backgroundColor: theme.palette.grey[50],
                  },
                  backgroundColor:
                    site.state === "inprogress" && theme.palette.grey[50],
                }}
                onClick={() => changeSiteState("inprogress")}
              >
                En cours
              </MenuItem>
              <MenuItem
                sx={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  ":hover": {
                    backgroundColor: theme.palette.grey[50],
                  },
                  backgroundColor:
                    site.state === "suspended" && theme.palette.grey[50],
                }}
                onClick={() => changeSiteState("suspended")}
              >
                Suspendu
              </MenuItem>
              <MenuItem
                sx={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  ":hover": {
                    backgroundColor: theme.palette.grey[50],
                  },
                  backgroundColor:
                    site.state === "done" && theme.palette.grey[50],
                }}
                onClick={() => changeSiteState("done")}
              >
                Terminé
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <Box>
            <Typography
              variant="h4"
              fontWeight="bold"
              color={theme.palette.secondary.main}
            >
              {site.name}
            </Typography>
            <Typography variant="h5" color={theme.palette.grey[400]}>
              {site.type}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6" color={theme.palette.secondary.main}>
            {`${site.zip_code} ${site.zone}, ${site.region}, ${site.country}`}
            </Typography>

            <Box
              sx={{
                display: "flex",
                gap: "10px",
              }}
            >
              <Typography variant="h6" color={theme.palette.secondary.main}>
                DE {formatDate(site.begin_date)}
              </Typography>
              <Typography variant="h6" color={theme.palette.secondary.main}>
                À {formatDate(site.end_date)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* right Side */}
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          margin: "5px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <IconButton
            sx={{
              border: "1px solid",
              borderRadius: "4px",
              borderColor: theme.palette.grey.light,
            }}
            onClick={() => {
              navigate(`${site._id}/edit`);
            }}
          >
            <BorderColorOutlined sx={{ color: theme.palette.alt.main }} />
          </IconButton>
          <IconButton
            sx={{
              border: "1px solid",
              borderRadius: "4px",
              borderColor: theme.palette.grey.light,
            }}
            onClick={() => setOpen(true)}
          >
            <DeleteOutline sx={{ color: theme.palette.alt.main }} />
          </IconButton>
        </Box>
        <Button
          sx={{
            color: theme.palette.alt.main,
            textTransform: "none",
            fontSize: "12px",
            fontWeight: "600",
          }}
          onClick={() => {
            navigate(`${site._id}/tasks`);
          }}
        >
          Détails
        </Button>
      </Box>
      <CustomDialog
        title={`Supprimer chantier ${site.name}`}
        content={`Vous êtes sûr de supprimer le chantier ${site.name}?`}
        onClick={handleClick}
        open={open}
        setOpen={setOpen}
      />
    </FlexBetween>
  );
};

export default SiteCard1;
