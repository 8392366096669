import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
};
const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setGlobalIsLoading: (state, action) => {
      const { isLoading } = action.payload;
      state.isLoading = isLoading;
    },
  },
});
export const { setGlobalIsLoading } = globalSlice.actions;

export default globalSlice.reducer;

export const selectGlobalIsLoading = (state) => state.global.isLoading;
