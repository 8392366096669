import "dayjs/locale/fr";
import { useEffect, useState } from "react";
const { useTheme } = require("@emotion/react");
const { Box, Typography, List, Stack } = require("@mui/material");
const { LocalizationProvider, DateCalendar } = require("@mui/x-date-pickers");
const { AdapterDayjs } = require("@mui/x-date-pickers/AdapterDayjs");
const { default: FlexBetween } = require("Components/FlexBetween");
const dayjs = require("dayjs");
const { default: TaskCard } = require("./TaskCard");
const { formatDate } = require("utils/format_date");

const DaysView = ({ tasks, selectedDate, setSelectedDate }) => {
  const theme = useTheme();
  const [currentTasks, setCurrentTasks] = useState([]);
  useEffect(() => {
    setCurrentTasks(
      tasks.filter(
        (item) => formatDate(item.begin_date) === formatDate(selectedDate)
      )
    );
  }, [selectedDate, tasks]);
  return (
    <Box
      sx={{
        "& .MuiIconButton-root": {
          color: theme.palette.secondary.main,
          bgcolor: theme.palette.primary.main,
          borderRadius: "4px",
          ml: "4px",
        },
        "& .MuiPickersCalendarHeader-label": {
          color: theme.palette.secondary.main,
          fontSize: "16px",
          fontWeight: "bold",
        },
        "& .MuiButtonBase-root": {
          color: theme.palette.secondary.main,
          fontSize: "14px",
        },
        "& .MuiButtonBase-root.Mui-selected": {
          color: theme.palette.secondary.main,
          bgcolor: theme.palette.alt.main,
          fontSize: "16px",
          borderRadius: "4px",
        },
        "& .MuiButtonBase-root.Mui-selected:hover": {
          bgcolor: theme.palette.alt.main,
        },
        "& .css-1acgcob-MuiButtonBase-root-MuiPickersDay-root.Mui-selected": {
          bgcolor: theme.palette.alt.main,
        },
        "& .css-1acgcob-MuiButtonBase-root-MuiPickersDay-root:focus ": {
          bgcolor: theme.palette.alt.main,
        },
        "& .css-1acgcob-MuiButtonBase-root-MuiPickersDay-root:focus.Mui-selected":
          {
            bgcolor: theme.palette.alt.main,
          },
        "& .MuiDayCalendar-weekDayLabel": {
          color: theme.palette.grey[400],
          fontSize: "14px",
        },
        "& .MuiPickersYear-root": {
          color: theme.palette.secondary.main,
        },
        "& .MuiPickersYear-yearButton": {
          fontSize: "16px",
        },
        "& .css-9yhg0o-MuiPickersYear-yearButton.Mui-selected": {
          bgcolor: theme.palette.alt.main,
        },
        display: "flex",
        height: "65vh",
        gap: "10px",
        width: "100%",
      }}
    >
      <Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            sx={{
              bgcolor: theme.palette.grey[100],
              height: "100%",
            }}
            onChange={(value) => setSelectedDate(new Date(value))}
            defaultValue={dayjs(new Date())}
            value={dayjs(selectedDate)}
          />
        </LocalizationProvider>
      </Box>
      <Box
        sx={{
          flex: "2",
          bgcolor: theme.palette.secondary.main,
          p: "20px",
          height: "58vh",
        }}
      >
        <FlexBetween>
          <Typography
            variant="h3"
            fontWeight="bold"
            sx={{
              color: theme.palette.primary.main,
            }}
          >
            {selectedDate
              .toLocaleDateString("fr", {
                weekday: "long",
                day: "numeric",
                month: "long",
              })
              .toUpperCase()}
          </Typography>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              gap: "5px",
              alignItems: "baseline",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                border: "1px solid",
                borderRadius: "4px",
                borderColor: theme.palette.error.main,
                color: theme.palette.error.main,
                p: "1px 5px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10",
                  fontWeight: "bold",
                }}
              >
                Urgente
              </Typography>
            </Box>
            <Box
              sx={{
                border: "1px solid",
                borderRadius: "4px",
                borderColor: theme.palette.warning[600],
                color: theme.palette.warning[600],
                p: "1px 5px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10",
                  fontWeight: "bold",
                }}
              >
                importante
              </Typography>
            </Box>
            <Box
              sx={{
                border: "1px solid",
                borderRadius: "4px",
                borderColor: theme.palette.success.main,
                color: theme.palette.success.main,
                p: "1px 5px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10",
                  fontWeight: "bold",
                }}
              >
                Régulière
              </Typography>
            </Box>
          </Box>
        </FlexBetween>
        <Box mt="20px" height="95%" overflow="auto">
          <Stack direction="row">
            {currentTasks
              .filter((item) => item.priority === "urgent")
              .map((task) => (
                <TaskCard
                  sx={{ height: "115px", width: "260px" }}
                  key={task._id}
                  task={task}
                />
              ))}
          </Stack>
          <Stack direction="row">
            {currentTasks
              .filter((item) => item.priority === "important")
              .map((task) => (
                <TaskCard
                  sx={{ height: "115px", width: "260px" }}
                  key={task._id}
                  task={task}
                />
              ))}
          </Stack>
          <Stack direction="row">
            {currentTasks
              .filter((item) => item.priority === "regular")
              .map((task) => (
                <TaskCard
                  sx={{ height: "115px", width: "260px" }}
                  key={task._id}
                  task={task}
                />
              ))}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default DaysView;
