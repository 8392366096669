import React from "react";
import "./Layout.css";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "../Headers/Header";
import SideBar from "../Navigations/SideBar";
const LayoutX = () => {
  return (
    <Box>
      <Header />
      <Box display="flex">
        <Box>
          <SideBar />
        </Box>
        <Outlet />
      </Box>
    </Box>
  );
};

export default LayoutX;
