import handleError from "utils/handelError";
import axiosClient from "./client";
import {
  createFinancialsInvoiceEndPoint,
  deleteFinancialsInvoiceEndPoint,
  getAllClientFinancialsInvoicesEndPoint,
  getAllFinancialsInvoicesEndPoint,
  getFinancialsInvoiceDetailsEndPoint,
  getFinancialsInvoiceStatsEndPoint,
  sortFinancialsInvoicesEndPoint,
  updateFinancialsInvoiceDetailsEndPoint,
  updateFinancialsInvoiceStateEndPoint,
} from "./Constants";

const FinancialsInvoiceService = {
  createFinancialsInvoice: async (data) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.post(
        createFinancialsInvoiceEndPoint,
        { ...data, client: clientId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const invoice = response.data.invoice;
      return invoice;
    } catch (error) {
      handleError(error);
    }
  },

  getFinancialsInvoiceDetails: async (id) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(
        getFinancialsInvoiceDetailsEndPoint(id),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const invoice = response.data.invoice;
      return invoice;
    } catch (error) {
      handleError(error);
    }
  },

  getFinancialsInvoiceStats: async (date) => {
    try {
      const clientId = localStorage.getItem("idClient");
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(
        getFinancialsInvoiceStatsEndPoint,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            clientId,
            date,
          },
        }
      );
      const stats = response.data.stats;
      return stats;
    } catch (error) {
      handleError(error);
    }
  },

  getFinancialsInvoicesDetails: async () => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getAllFinancialsInvoicesEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const invoices = response.data.invoices;
      return invoices;
    } catch (error) {
      handleError(error);
    }
  },

  getClientFinancialsInvoicesDetails: async (date) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.get(
        getAllClientFinancialsInvoicesEndPoint,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            clientId,
            date,
          },
        }
      );
      const invoices = response.data.invoices;
      return invoices;
    } catch (error) {
      handleError(error);
    }
  },

  updateFinancialsInvoiceDetails: async (id, data) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.put(
        updateFinancialsInvoiceDetailsEndPoint(id),
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const invoice = response.data.invoice;
      return invoice;
    } catch (error) {
      handleError(error);
    }
  },

  deleteFinancialsInvoice: async (id) => {
    try {
      const token = localStorage.getItem("@userCsc");
      await axiosClient.delete(deleteFinancialsInvoiceEndPoint(id), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      handleError(error);
    }
  },

  updateFinancialsInvoiceState: async (id, state) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.put(
        updateFinancialsInvoiceStateEndPoint(id),
        { state },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const invoice = response.data.invoice;
      return invoice;
    } catch (error) {
      handleError(error);
    }
  },

  sortFinancialsInvoices: async (option) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.get(sortFinancialsInvoicesEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          option,
          client: clientId,
        },
      });
      const invoices = response.data.invoices;
      return invoices;
    } catch (error) {
      handleError(error);
    }
  },
};

export default FinancialsInvoiceService;
