import React, { useRef, useState } from "react";
import { formatDate } from "utils/format_date";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  MenuItem,
  Menu,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import NotFoundError from "Components/NotFoundError";
import {
  BorderColorOutlined,
  DeleteOutline,
  SaveAltOutlined,
} from "@mui/icons-material";
import CustomDialog from "Components/CustomDialog";
import { toast } from "react-toastify";
import { setGlobalIsLoading } from "state/global";
import FinancialsQuoteService from "Api/FinancialsQuoteService";
import DetailsPopUp from "Components/DetailsPopUp";
import { useDispatch } from "react-redux";
import { deleteFinancialsQuote, updateFinancialsQuote } from "state/financials";
import ActionButton from "Components/ActionButton";
import { exportPdf } from "utils/esport_pdf";

const FinancialsQuoteTable = ({ quotes }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [deleteFinancialsQuoteOpen, setDeleteFinancialsQuoteOpen] =
    useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [selectedFinancialsQuote, setSelectedFinancialsQuote] = useState({});
  const navigate = useNavigate();
  const stateButtonRef = useRef(null);
  const [stateMenuOpen, setStateMenuOpen] = useState(false);
  const tableHeader = [
    "N° Devis",
    "Client",
    "Total HT",
    "Date d’échéance",
    "Statut",
    "",
  ];
  const quoteStates = ["draft", "created", "accepted", "refused"];

  const exportFile = async () => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      await exportPdf(
        ".details-page",
        `devis ${selectedFinancialsQuote.quote_number}.pdf`
      );
      dispatch(setGlobalIsLoading({ isLoading: false }));
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };
  const updateState = async (state) => {
    try {
      if (selectedFinancialsQuote.state === "draft") {
        setStateMenuOpen(false);
        throw new Error(
          "Vous ne pouvez pas changer de statut brouillon à un autre status, vous devez d'abord accéder à la page de modification"
        );
      }
      dispatch(setGlobalIsLoading({ isLoading: true }));
      const data = await FinancialsQuoteService.updateFinancialsQuoteState(
        selectedFinancialsQuote._id,
        state
      );
      dispatch(updateFinancialsQuote({ quote: data }));
      setSelectedFinancialsQuote(data);
      dispatch(setGlobalIsLoading({ isLoading: false }));
      setStateMenuOpen(false);
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };
  const getState = (state) => {
    if (state === "draft") {
      return "Brouillon";
    } else if (state === "created") {
      return "Créé";
    } else if (state === "accepted") {
      return "Accepté";
    } else if (state === "refused") {
      return "Réfusé";
    } else {
      return "";
    }
  };
  const onClick = async () => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      await FinancialsQuoteService.deleteFinancialsQuote(
        selectedFinancialsQuote._id
      );
      dispatch(deleteFinancialsQuote({ id: selectedFinancialsQuote._id }));
      dispatch(setGlobalIsLoading({ isLoading: false }));
      setDetailsOpen(false);
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };

  return (
    <>
      {quotes && Array.isArray(quotes) && quotes.length !== 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "end",
            p: "15px",
          }}
        >
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: "440px",
              backgroundColor: theme.palette.primary.main,
              display: "flex",
            }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {tableHeader.map((header) => (
                    <TableCell
                      key={header}
                      sx={{
                        color: theme.palette.grey[600],
                        fontSize: "14px",
                        fontWeight: "400",
                        borderBottom: "2px solid",
                        borderBottomColor: theme.palette.grey.light,
                        pt: "5px",
                        pb: "5px",
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {quotes.map((quote) => (
                  <TableRow
                    key={quote._id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "& .MuiTableCell-root": {
                        color: theme.palette.secondary.dark,
                        fontSize: "14px",
                        borderBottom: "1px solid",
                        borderBottomColor: theme.palette.grey.light,
                        pt: "5px",
                        pb: "5px",
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <Typography
                        sx={{
                          textWrap: "nowrap",
                        }}
                      >
                        {quote.quote_number}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography
                        sx={{
                          textWrap: "nowrap",
                        }}
                      >
                        {quote.client?.nom}
                      </Typography>
                    </TableCell>
                    <TableCell>{quote.ht_total}</TableCell>
                    <TableCell>{formatDate(quote.payment_date)}</TableCell>
                    <TableCell>{getState(quote.state)}</TableCell>
                    <TableCell align="center">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          onClick={async () => {
                            setSelectedFinancialsQuote(quote);
                            setDetailsOpen(true);
                            setTimeout(async () => {
                              await exportFile();
                              setDetailsOpen(false);
                            }, 1100);
                          }}
                        >
                          <SaveAltOutlined
                            sx={{
                              color: theme.palette.alt.main,
                              fontSize: "24px",
                            }}
                          />
                        </IconButton>
                        <IconButton onClick={() => navigate(`${quote._id}`)}>
                          <BorderColorOutlined
                            sx={{
                              color: theme.palette.alt.main,
                              fontSize: "24px",
                            }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setSelectedFinancialsQuote(quote);
                            setDeleteFinancialsQuoteOpen(true);
                          }}
                        >
                          <DeleteOutline
                            sx={{
                              color: theme.palette.alt.main,
                              fontSize: "24px",
                            }}
                          />
                        </IconButton>
                        <Button
                          sx={{
                            color: theme.palette.alt.main,
                            textTransform: "none",
                            fontSize: "12px",
                            fontWeight: "600",
                          }}
                          onClick={() => {
                            setSelectedFinancialsQuote(quote);
                            setDetailsOpen(true);
                          }}
                        >
                          Détails
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomDialog
            title={`Supprimer devis n°${selectedFinancialsQuote.quote_number} ?`}
            content={`Vous êtes sûr de supprimer devis n°${selectedFinancialsQuote.quote_number} ?`}
            onClick={onClick}
            open={deleteFinancialsQuoteOpen}
            setOpen={setDeleteFinancialsQuoteOpen}
          />
          <DetailsPopUp
            open={detailsOpen}
            setOpen={setDetailsOpen}
            descriptions={selectedFinancialsQuote.descriptions}
            name={`Devis ${selectedFinancialsQuote.quote_number ?? ""}`}
            client={selectedFinancialsQuote.client ?? {}}
            data={{
              name: selectedFinancialsQuote.recipient_name,
              email: selectedFinancialsQuote.recipient_email,
              phone: selectedFinancialsQuote.recipient_phone,
              address: selectedFinancialsQuote.recipient_address,
            }}
            values={selectedFinancialsQuote}
            isSupplier={false}
            actionButtons={
              <>
                <Box ref={stateButtonRef}>
                  <ActionButton
                    iconName="info"
                    text="Statut du devis"
                    isEndIcon={true}
                    onClick={() => setStateMenuOpen(true)}
                    subText={getState(selectedFinancialsQuote.state)}
                  />
                  <Menu
                    anchorReference="anchorEl"
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                    anchorEl={stateButtonRef.current}
                    open={stateMenuOpen}
                    onClose={() => setStateMenuOpen(false)}
                    MenuListProps={{
                      style: {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.grey[600],
                        border: "1px solid",
                        borderColor: theme.palette.primary.main,
                        borderRadius: "6px",
                      },
                    }}
                  >
                    {quoteStates.map((state) => (
                      <MenuItem
                        key={state}
                        sx={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          ":hover": {
                            backgroundColor: theme.palette.grey[50],
                          },
                          backgroundColor:
                            selectedFinancialsQuote?.state === state &&
                            theme.palette.grey[50],
                          minWidth: "100px",
                        }}
                        disabled={selectedFinancialsQuote?.state === state}
                        onClick={() => updateState(state)}
                      >
                        {getState(state)}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
                <ActionButton
                  iconName="download"
                  text="Exporter en PDF"
                  onClick={exportFile}
                />
                <ActionButton
                  iconName="edit"
                  text="Modifier le devis"
                  onClick={() => {
                    setDetailsOpen(false);
                    navigate(selectedFinancialsQuote._id);
                  }}
                />
                <ActionButton
                  iconName="remove"
                  text="Supprimer le devis"
                  onClick={() => setDeleteFinancialsQuoteOpen(true)}
                />
              </>
            }
          />
        </Box>
      ) : (
        <NotFoundError text="Aucun Devis trouvé !" sx={{ height: "80%" }} />
      )}
    </>
  );
};

export default FinancialsQuoteTable;
