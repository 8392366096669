import { useTheme } from "@emotion/react";
import { Box, Button, List, Typography } from "@mui/material";
import React, { useState } from "react";
import TaskCard from "./TaskCard";
import { AddCircleOutline } from "@mui/icons-material";
import { Draggable, Droppable } from "@hello-pangea/dnd";
import PopUp from "Components/Popup";
import AddEditTask from "./AddEditTask";
import NotFoundError from "Components/NotFoundError";

const TasksColumn = ({ state, isFirst, title, color, tasks }) => {
  const priorityOrder = {
    urgent: 0,
    important: 1,
    regular: 2,
  };
  const theme = useTheme();
  const [addEditOpen, setAddEditOpen] = useState(false);
  const currentTasks = tasks
    .filter((task) => task.state === state)
    .sort((a, b) => priorityOrder[a.priority] - priorityOrder[b.priority]);
  // const [detailsOpen, setDetailsOpen] = useState(false);

  return (
    <Box
      sx={{
        height: "65vh",
        flex: "1",
        bgcolor: theme.palette.secondary.main,
        borderRadius: "6px",
        position: "relative",
      }}
      pr="5px"
      pb="40px"
    >
      <Typography
        variant="h6"
        sx={{
          color: color,
          fontWeight: "bold",
          p: "10px",
          height: "40px",
        }}
      >
        {title}
      </Typography>
      <Droppable
        droppableId={state}
        type="card"
        isCombineEnabled
        isDropDisabled={false}
      >
        {(provided) => (
          <List
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{
              height: "94%",
              overflow: "auto",
            }}
          >
            {currentTasks && currentTasks.length !== 0 ? (
              <>
                {currentTasks.map((task, key) => (
                  <Draggable
                    key={task._id.toString()}
                    draggableId={task._id.toString()}
                    index={key}
                  >
                    {(provided) => (
                      <Box
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <TaskCard key={task._id.toString()} task={task} />
                      </Box>
                    )}
                  </Draggable>
                ))}
              </>
            ) : (
              <NotFoundError text="Aucune tâche trouvé !" />
            )}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
      {isFirst && (
        <Button
          startIcon={
            <AddCircleOutline
              sx={{
                fontSize: "55px",
                color: theme.palette.grey.main,
              }}
            />
          }
          sx={{
            width: "100%",
            height: "42px",
            textTransform: "none",
            justifyContent: "start",
            fontSize: "14",
            fontWeight: "bold",
            color: theme.palette.grey.main,
            backgroundColor: theme.palette.secondary.main,
            position: "absolute",
            bottom: 0,
            boxShadow: "0 -4px 4px 0 rgba(0,0,0,0.25)",
            ":hover": {
              backgroundColor: theme.palette.secondary.main,
            },
          }}
          onClick={() => setAddEditOpen(true)}
        >
          Ajouter Une Tâche
        </Button>
      )}
      <PopUp open={addEditOpen} setOpen={setAddEditOpen}>
        <AddEditTask setOpen={setAddEditOpen} />
      </PopUp>
    </Box>
  );
};

export default TasksColumn;
