import React from "react";
import SiteCard2 from "./SiteCard2";
import { Box, Divider, List, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import NotFoundError from "Components/NotFoundError";

const KanbanView = ({ sites }) => {
  const theme = useTheme();
  return (
    <>
      {sites && Array.isArray(sites) && sites.length !== 0 ? (
        <Box
          sx={{
            width: "100%",
            padding: "5px",
          }}
        >
          <Divider
            sx={{
              marginBottom: "20px",
              width: "100%",
              height: "1px",
              backgroundColor: theme.palette.grey[800],
            }}
          />
          <Box
            sx={{
              overflow: "auto",
              height: "65vh",
            }}
          >
            <List>
              <Box display="flex" gap="5px" width="100%">
                <Box flex="1">
                  <Box
                    height="32px"
                    textAlign="center"
                    sx={{
                      padding: "5px",
                      backgroundColor: theme.palette.info.main,
                      borderTopLeftRadius: "6px",
                      borderTopRightRadius: "6px",
                    }}
                  >
                    <Typography variant="h6" fontWeight="bold">
                      À Faire
                    </Typography>
                  </Box>
                  {/* Items */}
                  {sites
                    .filter((site) => site.state === "todo")
                    .map((item) => (
                      <SiteCard2 key={item._id} site={item} />
                    ))}
                </Box>
                {/* <Box
              sx={{
                margin: "40px 0",
                width: "2px",
                height: "91%",
                backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A1A7C4FF' stroke-width='5' stroke-dasharray='2%2c22' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
              }}
            /> */}
                <Box
                  sx={{
                    borderStyle: "dashed",
                    margin: "40px 0",
                    borderWidth: "1px",
                    backgroundColor: theme.palette.grey[400],
                  }}
                />
                <Box flex="1">
                  <Box
                    height="32px"
                    sx={{
                      padding: "5px",
                      backgroundColor: theme.palette.warning.main,
                      borderTopLeftRadius: "6px",
                      borderTopRightRadius: "6px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      textAlign="center"
                      fontWeight="bold"
                    >
                      En cours
                    </Typography>
                  </Box>
                  {/* Items */}
                  {sites
                    .filter((site) => site.state === "inprogress")
                    .map((item) => (
                      <SiteCard2 key={item._id} site={item} />
                    ))}
                </Box>
                <Box
                  sx={{
                    borderStyle: "dashed",
                    margin: "40px 0",
                    borderWidth: "1px",
                    backgroundColor: theme.palette.grey[400],
                  }}
                />
                <Box flex="1">
                  <Box
                    height="32px"
                    sx={{
                      padding: "5px",
                      backgroundColor: theme.palette.error.main,
                      borderTopLeftRadius: "6px",
                      borderTopRightRadius: "6px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      textAlign="center"
                      fontWeight="bold"
                    >
                      Suspendu
                    </Typography>
                  </Box>
                  {/* Items */}
                  {sites
                    .filter((site) => site.state === "suspended")
                    .map((item) => (
                      <SiteCard2 key={item._id} site={item} />
                    ))}
                </Box>
                <Box
                  sx={{
                    borderStyle: "dashed",
                    margin: "40px 0",
                    borderWidth: "1px",
                    backgroundColor: theme.palette.grey[400],
                  }}
                />
                <Box flex="1">
                  <Box
                    height="32px"
                    sx={{
                      padding: "5px",
                      backgroundColor: theme.palette.success.main,
                      borderTopLeftRadius: "6px",
                      borderTopRightRadius: "6px",
                    }}
                  >
                    <Typography
                      textAlign="center"
                      variant="h6"
                      fontWeight="bold"
                    >
                      Terminé
                    </Typography>
                  </Box>
                  {/* Items */}
                  {sites
                    .filter((site) => site.state === "done")
                    .map((item) => (
                      <SiteCard2 key={item._id} site={item} />
                    ))}
                </Box>
              </Box>
            </List>
          </Box>
        </Box>
      ) : (
        <NotFoundError text="Aucun chantier trouvé !" sx={{ height: "60%" }} />
      )}
    </>
  );
};

export default KanbanView;
