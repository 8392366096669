import {
    GET_ALL_USERS,
    ADD_ADMIN_SUCCESS,
    ADD_ADMIN_ERROR,
    UPDATE_ADMIN_SUCCESS,
    UPDATE_ADMIN_ERROR,
    DELETE_ADMIN_SUCCESS,
    DELETE_ADMIN_ERROR,
    UPDATE_ADMIN,
    DELETE_ADMIN,
    ADD_ADMIN
} from '../actions/UserAction';

// État initial
const initialState = {
    users: [],
    usersCopie: [],
    user: {},
    error: null,
};

// Fonction réductrice
const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_USERS:
            return { ...state, users: action.payload };
        case ADD_ADMIN:
            return { ...state, users: [...state.users, action.payload.user] };
        case UPDATE_ADMIN:
            return {
                ...state,
                users: state.users.map(user =>
                    user._id === action.payload._id ? action.payload : user
                ),
            };
        case DELETE_ADMIN:
            return {
                ...state,
                users: state.users.filter(user => user._id !== action.payload),

            }
        default:
            return state;
    }
};

export default userReducer;
