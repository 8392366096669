import * as yup from "yup";

const InvalidFieldMessage = "Veuillez renseigner ce champ";
const RequiredFieldMessage = "Ce champ est obligatoire";

export const siteSchema = yup.object().shape({
  name: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  owner: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  country: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  region: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  zone: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  zip_code: yup
    .string()
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  cotegory: yup.string(InvalidFieldMessage).typeError(InvalidFieldMessage),
});

export const taskSchema = yup.object().shape({
  name: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  description: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  place: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  instructions: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
});
export const equipmentSchema = yup.object().shape({
  name: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  description: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  supplier_name: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  responsible: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  is_rental: yup.boolean(InvalidFieldMessage),
  number: yup
    .number(InvalidFieldMessage)
    .min(0, InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  serial_number: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  rental_price: yup
    .number(InvalidFieldMessage)
    .min(0, InvalidFieldMessage)
    .when("is_rental", {
      is: true,
      then: (schema) =>
        schema.required(RequiredFieldMessage).typeError(InvalidFieldMessage),
      otherwise: (schema) => schema.typeError(InvalidFieldMessage).nullable(),
    }),
  lifetime: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  place_type: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  place: yup.string(InvalidFieldMessage).when("place_type", {
    is: (is_rental) => is_rental === "stock" || is_rental === "site",
    then: (schema) => schema.nullable(),
    otherwise: (schema) =>
      schema.required(RequiredFieldMessage).typeError(InvalidFieldMessage),
  }),
});
export const estimateSchema = yup.object().shape({
  resource: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  amount: yup
    .number(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .min(0, InvalidFieldMessage)
    .typeError(InvalidFieldMessage),
});
export const quoteSchema = yup.object().shape({
  recipient_name: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  recipient_phone: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  recipient_address: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  recipient_email: yup
    .string(InvalidFieldMessage)
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  quote_number: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  descriptions: yup.array().of(
    yup.object().shape({
      description: yup
        .string(InvalidFieldMessage)
        .required(RequiredFieldMessage)
        .typeError(InvalidFieldMessage),
      quantity: yup
        .number(InvalidFieldMessage)
        .required(RequiredFieldMessage)
        .min(0, InvalidFieldMessage)
        .typeError(InvalidFieldMessage),
      unit_price: yup
        .number(InvalidFieldMessage)
        .required(RequiredFieldMessage)
        .min(0, InvalidFieldMessage)
        .typeError(InvalidFieldMessage),
      tva: yup
        .number(InvalidFieldMessage)
        .required(RequiredFieldMessage)
        .min(0, InvalidFieldMessage)
        .typeError(InvalidFieldMessage),
    })
  ),
});
export const invoiceSchema = yup.object().shape({
  recipient_name: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  recipient_phone: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  recipient_email: yup
    .string(InvalidFieldMessage)
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  recipient_address: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  invoice_number: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  payment_condition: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  is_discount: yup.boolean(InvalidFieldMessage).required(RequiredFieldMessage),
  discount_amount: yup.number(InvalidFieldMessage).when("is_discount", {
    is: true,
    then: (schema) =>
      schema.required(RequiredFieldMessage).typeError(InvalidFieldMessage),
    otherwise: (schema) => schema.nullable(),
  }),
  category: yup.string().nullable(),
  limited_to: yup.number(InvalidFieldMessage).when("category", {
    is: "recurring",
    then: (schema) =>
      schema.required(RequiredFieldMessage).typeError(InvalidFieldMessage),
    otherwise: (schema) => schema.nullable(),
  }),
  deposit_amount: yup.number(InvalidFieldMessage).when("category", {
    is: "deposit",
    then: (schema) =>
      schema.required(RequiredFieldMessage).typeError(InvalidFieldMessage),
    otherwise: (schema) => schema.nullable(),
  }),
  descriptions: yup.array().of(
    yup.object().shape({
      description: yup
        .string(InvalidFieldMessage)
        .required(RequiredFieldMessage)
        .typeError(InvalidFieldMessage),
      quantity: yup
        .number(InvalidFieldMessage)
        .required(RequiredFieldMessage)
        .min(0, InvalidFieldMessage)
        .typeError(InvalidFieldMessage),
      unit_price: yup
        .number(InvalidFieldMessage)
        .required(RequiredFieldMessage)
        .min(0, InvalidFieldMessage)
        .typeError(InvalidFieldMessage),
      tva: yup
        .number(InvalidFieldMessage)
        .required(RequiredFieldMessage)
        .min(0, InvalidFieldMessage)
        .typeError(InvalidFieldMessage),
    })
  ),
});
export const commandeSchema = yup.object().shape({
  supplier_name: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  supplier_address: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  supplier_phone: yup
    .string(InvalidFieldMessage)
    .min(6, InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  supplier_email: yup
    .string(InvalidFieldMessage)
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  commande_number: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
  payment_condition: yup
    .string(InvalidFieldMessage)
    .required(RequiredFieldMessage)
    .typeError(InvalidFieldMessage),
});
export const descriptionSchema = yup.object().shape({
  descriptions: yup.array().of(
    yup.object().shape({
      description: yup
        .string(InvalidFieldMessage)
        .required(RequiredFieldMessage)
        .typeError(InvalidFieldMessage),
      quantity: yup
        .number(InvalidFieldMessage)
        .required(RequiredFieldMessage)
        .min(0, InvalidFieldMessage)
        .typeError(InvalidFieldMessage),
      unit_price: yup
        .number(InvalidFieldMessage)
        .required(RequiredFieldMessage)
        .min(0, InvalidFieldMessage)
        .typeError(InvalidFieldMessage),
      tva: yup
        .number(InvalidFieldMessage)
        .required(RequiredFieldMessage)
        .min(0, InvalidFieldMessage)
        .typeError(InvalidFieldMessage),
    })
  ),
});
// export const emailSchema = yup.object().shape({
//   receivers: yup
//   .string(InvalidFieldMessage)
//   // .required(RequiredFieldMessage)
//   .typeError(InvalidFieldMessage),
//   cc: yup
//   .string(InvalidFieldMessage)
//   .typeError(InvalidFieldMessage),
//   cci: yup
//   .string(InvalidFieldMessage)
//   .typeError(InvalidFieldMessage),
//   object: yup
//   .string(InvalidFieldMessage)
//   .typeError(InvalidFieldMessage),
//   body: yup
//   .string(InvalidFieldMessage)
//   .typeError(InvalidFieldMessage),
// });
