import {
    ADD_FICHE_PAIE,
    GET_ALL_FICHE_PAIE,
    GET_FICHE_PAIE_BY_ID,
    DELETE_FICHE_PAIE,
    UPDATE_FICHE_PAIE,
    GET_ALL_FICHE_PAIE_BY_CLIENT,
    GET_ALL_FICHE_PAIE_BY_CLIENT_COPIE
} from '../actions/FichePaieAction';

// État initial
const initialState = {
    fichesPaie: [],
    fichePaieCopie: [],
    fichePaie: {},
};

// Fonction réductrice
const fichePaieReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_FICHE_PAIE:
            return { ...state, fichesPaie: action.payload };
        case GET_ALL_FICHE_PAIE_BY_CLIENT:
            return { ...state, fichesPaie: action.payload };
        case GET_FICHE_PAIE_BY_ID:
            return { ...state, fichePaie: action.payload };
        case ADD_FICHE_PAIE:
            return { ...state, fichesPaie: [...state.fichesPaie, action.payload] };
        case UPDATE_FICHE_PAIE:
            const updatedfiche = action.payload;
            const updatedFicheList = state.fichesPaie.map((fiche) => {
                if (fiche._id === updatedfiche._id) {
                    return updatedfiche;
                }
                return fiche;
            });
            return { ...state, fichesPaie: updatedFicheList , fichePaieCopie :updatedFicheList };
        case DELETE_FICHE_PAIE:
            const deletedficheId = action.payload;
            const updatedFiches = state.fichesPaie.filter((fiche) => fiche._id !== deletedficheId);
            return { ...state, fichesPaie: updatedFiches, fichePaieCopie :updatedFiches };
        case GET_ALL_FICHE_PAIE_BY_CLIENT_COPIE:
            return { ...state, fichePaieCopie: action.payload };
        default:
            return state;
    }
};

export default fichePaieReducer;
