import React, { useState } from "react";
import Header from "../../Components/Headers/Header";
import Layout from "../../Components/Layouts/Layout";
import FicheDePaieForm from "../../Components/Prepai/FicheDePaieForm";
import HeadersWithbuttonsPaie from "../../Components/Headers/HeadersWithbuttonsPaie";
import { Box } from "@mui/material";
// import PersonnelList from '../../Components/Prepai/PersonnelList';
// import FicheDePai from '../../Components/Prepai/FicheDePai';
function AddPrepaie() {
  const [dataFromChild, setDataFromChild] = useState(null);

  const handleDataFromChild = (data) => {
    setDataFromChild(data);
  };
  return (
    <Box
    className='content'
    >
      <HeadersWithbuttonsPaie
        title="Fiches de prepaie"
        data={dataFromChild}
        btnTitle="Aperçu"
        btn2="Annuler"
      />
      <div className="container-fluid  personnel-table-container">
        <div className="row">
          <div className="col-md-12">
            <FicheDePaieForm onDataPassed={handleDataFromChild} />
          </div>
        </div>
      </div>
    </Box>
  );
}

export default AddPrepaie;
