import React from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'
function Devis() {
    const validationSchema = Yup.object().shape({
        nomPrenom: Yup.string().required('Ce champ est requis'),
        email: Yup.string().email('Adresse e-mail invalide').required('Ce champ est requis'),
        telephone: Yup.string().required('Ce champ est requis'),
        objet: Yup.string().required('Ce champ est requis'),
        besoin: Yup.string().required('Ce champ est requis'),
    });

    const handleSubmit = (values, { resetForm }) => {
        // Envoyer les données par e-mail ici
       
        resetForm();
    };
    return (
        <div className="container mt-5">
            <div className='title-container'>
                <p className='text-center mb-1'><svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="11" height="11" fill="#1E5EFF" />
                </svg>Tarifs</p>
                <h3 className='text-center mb-1'>Demande de devis</h3>
                <p className='text-descreption'>Éclairez votre chemin de réussite : Réservez votre devis en remplissant ce <br /> formulaire !
                </p>
            </div>
            <Formik
                initialValues={{
                    nomPrenom: '',
                    email: '',
                    telephone: '',
                    objet: '',
                    besoin: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ errors, touched }) => (
                    <Form className='devis-form'>
                        <div className="mt-3">
                            <div>
                                <Field type="text" id="nomPrenom" name="nomPrenom" placeholder="Nom et Prénom" />
                                <ErrorMessage name="nomPrenom" component="div" className="error" />
                            </div>
                            <div>
                                <Field type="email" id="email" name="email" placeholder="Adresse e-mail" />
                                <ErrorMessage name="email" component="div" className="error" />
                            </div>
                        </div>
                        <div className="mt-3">
                            <div>
                                <Field type="text" id="telephone" name="telephone" placeholder="Numéro de téléphone" />
                                <ErrorMessage name="telephone" component="div" className="error" />
                            </div>
                            <div>
                                <Field type="text" id="objet" name="objet" placeholder="Objet" />
                                <ErrorMessage name="objet" component="div" className="error" />
                            </div>
                        </div>
                        <div className="mt-3">
                            <div>
                                <Field as="textarea" id="besoin" name="besoin" placeholder="Votre besoin" />
                                <ErrorMessage name="besoin" component="div" className="error" />
                            </div>

                        </div>
                        <div className="mt-3">
                            <div>
                                <button type="submit" className='submit-devis'>Envoyer</button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default Devis